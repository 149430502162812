import axios from 'axios'


const state = {
    authStatus: null,
    authInfo: null,
    initCodeForPhoneNumber: null,
    userWantsToUseNewPhoneNumberToAuth: JSON.parse(localStorage.getItem('userWantsToUseNewPhoneNumberToAuth'))
        || false,

    verificationStatus: null,

    //// проверить все данные ниже на использование
    authenticated: false,
    user: {
        id: '',
        email: '',
        phone: '',
        name: '',
        surname: '',
    },


    forgotPasswordStatus: null,
    forgotPasswordErrors: null,
    registerStatus: null,
    registerErrors: null,
    loginStatus: null,
    loginErrors: null,

    registerPhoneError: null,
    registerEmailError: null,
};

const getters = {
    authStatus: state => {
        return state.authStatus
    },
    authInfo: state => {
        return state.authInfo
    },
    userWantsToUseNewPhoneNumberToAuth: state => {
        return state.userWantsToUseNewPhoneNumberToAuth
    },
    initCodeForPhoneNumber: state => {
        return state.initCodeForPhoneNumber
    },


    ///// ниже проверить методы на использование
    authenticated: state => {
        return state.authenticated
    },

    user: state => {
        return state.user
    },

    forgotPasswordErrors: state => {
        return state.forgotPasswordErrors
    },

    forgotPasswordStatus: state => {
        return state.forgotPasswordStatus
    },

    registerErrors: state => {
        return state.registerErrors
    },

    registerStatus: state => {
        return state.registerStatus
    },
    loginErrors: state => {
        return state.loginErrors
    },
    loginStatus: state => {
        return state.loginStatus
    },
    registerEmailError: state => {
        return state.registerEmailError
    },
    registerPhoneError: state => {
        return state.registerPhoneError
    },

};

const actions = {
    async getAuthStatus({commit, state}) {
        await axios.get('/api/auth/status')
            .then(res => {
                commit('SET_AUTH_STATUS', res.data.data.authStatus)
                //if (res.data.data.authStatus) {
                commit('SET_AUTH_INFO', res.data.data)
                // }
            }).catch(() => {
            })
    },
    async authIdentifiedAndUnauthorizedUserByNewPhone({commit, state}, value) {
        console.log('aj')
        await commit('AUTH_IDENTIFIED_AND_UNAUTHORIZED_USER_BY_NEW_PHONE', value)
        console.log(state.userWantsToUseNewPhoneNumberToAuth)
    },

    initPhoneNumber({commit}, phone) {
        axios.post('/api/auth/init', {'login': phone})
            .then(res => {
                commit('SET_CODE_FOR_PHONE_NUMBER', res.data.data.attributes.code)
            }).catch(() => {
        })

    },
    async signIn({commit, dispatch}, {phone, code}) {
        console.log(phone)
        await axios.post('/api/auth/login', {'login': phone, 'password': code})
            .then(res => {
                console.log(res)
                commit('SET_AUTH_INFO', res.data.data)
                dispatch('getAuthStatus')
            }).catch(() => {
                this.loginErrorMessage = 'Введены некорректные данные'

                return next({redirect: '/404'})
            })
    },
    verifyPhone({dispatch}) {
        axios.post('/api/verification/phone/verify', {
            'phone': this.phone,
            'code': this.code
        }).then(res => {
            dispatch('getAuthStatus')
        })
    },
    initVerificationPhoneNumber({dispatch}, phone) {
        axios.post('/api/verification/phone/init', {'phone': phone})
            .then(res => {
                dispatch('getAuthStatus')
            })
    },
    getVerificationStatus() {
        axios.get('/api/verification/phone/status').then(r => {
        })
    },
    async deactivateUserPhone(_, userId) {
        await axios.post('/api/verification/phone/deactivate', {
            userId: +userId
        })
    },


    async signOut({dispatch}) {
        await axios.post('/api/logout')
            .then(() => {
                dispatch('getAuthStatus')
            })
            .catch(error => {
                console.error(error)
            })
    },

    async forgotPassword({commit}, credentials) {
        commit('SET_FORGOT_PASSWORD_STATUS', 'loading')
        commit('SET_FORGOT_PASSWORD_ERRORS', '')

        await axios.post('/api/password/forgot', credentials)
            .then(res => {
                commit('SET_FORGOT_PASSWORD_STATUS', 'success')
            })
            .catch(error => {
                commit('SET_FORGOT_PASSWORD_STATUS', 'error')
                commit('SET_FORGOT_PASSWORD_ERRORS', error.response.data)
            })

    },

    async resetPassword({dispatch}, credentials) {
        await axios.post('/api/password/reset', credentials)
        await axios.get('/sanctum/csrf-cookie')
        await axios.post('/api/login', credentials)
        return dispatch('me')

    },

    async register({dispatch, commit}, credentials) {
        await axios.post('/api/register', credentials)
            .then((response) => {
            })
            .catch(error => {
                if (error.response.data.errors) {
                    commit('setRegisterEmailError', error.response.data.errors.email)
                    commit('setRegisterPhoneError', error.response.data.errors.phone)
                }
            })

        await axios.get('/sanctum/csrf-cookie')
        await axios.post('/api/login', credentials)
        return dispatch('me')


    },
    resetRegisterPhoneError({commit}) {
        commit('setRegisterPhoneError', null)
    },
    resetRegisterEmailError({commit}) {
        commit('setRegisterEmailError', null)
    },

    me({commit}) {
        return axios.get('/api/user').then((response) => {
            commit('SET_AUTHENTICATED', true)
            commit('SET_USER', response.data)
        }).catch(() => {
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', {
                id: '',
                email: '',
                phone: '',
                name: '',
                surname: ''
            })
        })
    },

    setEmail({commit, state}, email) {
        commit('setEmail', email);
    },
    setPhone({commit, state}, phone) {
        commit('setPhone', phone);
    },
    setName({commit, state}, name) {
        commit('setName', name);
    },
    setSurname({commit, state}, surname) {
        commit('setSurname', surname);
    },

};

const mutations = {
    SET_AUTH_STATUS(state, value) {
        state.authStatus = value
    },
    AUTH_IDENTIFIED_AND_UNAUTHORIZED_USER_BY_NEW_PHONE(state, value) {
        state.userWantsToUseNewPhoneNumberToAuth = value
        localStorage.setItem('userWantsToUseNewPhoneNumberToAuth', JSON.stringify(value))
    },
    SET_CODE_FOR_PHONE_NUMBER(state, value) {
        state.initCodeForPhoneNumber = value
    },
    SET_AUTH_INFO(state, value) {
        state.authInfo = value
    },


    SET_AUTHENTICATED(state, value) {
        state.authenticated = value
    },

    SET_USER(state, value) {
        state.user = value
    },

    SET_FORGOT_PASSWORD_STATUS(state, status) {
        state.forgotPasswordStatus = status
    },

    SET_FORGOT_PASSWORD_ERRORS(state, errors) {
        state.forgotPasswordErrors = errors
    },

    SET_REGISTER_STATUS(state, status) {
        state.registerStatus = status
    },

    SET_REGISTER_ERRORS(state, errors) {
        state.registerErrors = errors
    },

    SET_LOGIN_STATUS(state, status) {
        state.loginStatus = status
    },

    SET_LOGIN_ERRORS(state, errors) {
        state.loginErrors = errors
    },

    setEmail(state, email) {
        state.user.email = email;
    },
    setPhone(state, phone) {
        state.user.phone = phone;
    },
    setName(state, name) {
        state.user.name = name;
    },
    setSurname(state, surname) {
        state.user.surname = surname;
    },

    setRegisterEmailError(state, error) {
        state.registerEmailError = error;
    },
    setRegisterPhoneError(state, error) {
        state.registerPhoneError = error;
    },
};

export default {
    state, getters, actions, mutations,
}


