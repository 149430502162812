<template>
    <div class="w-full px-6 lg:px-0 lg:w-256 lg:mx-auto text-gray-main">
        <h1
            class="my-10 w-full text-gray-main text-2xl font-normal uppercase tracking-widest text-center"
        >
            Мой Аккаунт
        </h1>

        <div class="w-full relative">
            <div class="absolute top-0 right-0 flex items-center">
                <img
                    class="px-2 cursor-pointer"
                    src="/storage/images/icons/edit.svg"
                    alt="edit"
                />
                <img
                    class="px-2 cursor-pointer"
                    src="/storage/images/icons/log-out.svg"
                    alt="logout"
                    @click="signOut"
                />
            </div>
            <div class="text-center">
                {{ authInfo?.relations?.authUser?.data?.attributes?.name }}
                {{ authInfo?.relations?.authUser?.data?.attributes?.surname }}
            </div>
            <div class="text-center">{{ authInfo?.relations?.authUser?.data?.attributes?.email }}</div>
            <div class="text-center">{{ authInfo?.relations?.authUser?.data?.attributes?.phone }}</div>
        </div>

        <h2
            class="my-6 w-full text-gray-main text-xl font-normal uppercase text-center"
        >
            Заказы
        </h2>

        <div v-if="userOrdersStatus === 'success'" class="w-full">
            <div v-if="userOrders.data.length > 0">
                <accordion>
                    <accordion-item
                        v-for="order in userOrders.data"
                        :key="order.id"
                    >
                        <template class="flex" slot="accordion-trigger">
                            <div
                                class="w-full my-2 flex items-center justify-between"
                            >
                                <div>
                                    <div class="">
                                        №{{ order.data.order_id }} от
                                        {{ order.data.attributes.created_at }}
                                    </div>
                                    <div class="text-brown-dark">
                                        {{
                                            order.data.attributes.status.data
                                                .attributes.title
                                        }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template slot="accordion-content">
                            <div
                                v-for="line in order.data.attributes.lines.data"
                                class="w-full flex items-center justify-start border-t last:border-b"
                            >
                                <img
                                    class="h-25"
                                    :src="
                                        line.data.attributes.product.data
                                            .attributes.image_path
                                    "
                                    :alt="
                                        line.data.attributes.product.data
                                            .attributes.title
                                    "
                                />
                                <div class="ml-2">
                                    <div>
                                        {{
                                            line.data.attributes.product.data
                                                .attributes.title
                                        }}
                                    </div>
                                    <div>
                                        размер:
                                        {{
                                            line.data.attributes.size.data
                                                .attributes.title
                                        }},
                                        {{ line.data.attributes.quantity }} шт.
                                    </div>
                                    <div>
                                        {{ line.data.attributes.amount }} ₽
                                    </div>
                                </div>
                            </div>
                        </template>
                    </accordion-item>
                </accordion>
            </div>
            <div v-else>У вас пока нет заказов...</div>
        </div>
        <div v-else>
            <Loading />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Accordion from "../components/Accordion";
import AccordionItem from "../components/AccordionItem";
import Loading from "../components/Loading";

export default {
    name: "Account",
    components: {
        Accordion,
        AccordionItem,
        Loading,
    },
    methods: {
        ...mapActions({
            signOutAction: "signOut",
            fetchUserOrders: "fetchUserOrders",
            getAuthStatus: "getAuthStatus",
        }),

        async signOut() {
            await this.signOutAction();
            this.$router.replace({ name: "MainPage" });
        },
    },
    computed: {
        ...mapGetters({
            user: "user",
            authenticated: "authenticated",
            userOrders: "userOrders",
            userOrdersStatus: "userOrdersStatus",
            authInfo: "authInfo",
        }),
    },
    async mounted() {
        await this.getAuthStatus();
        await this.fetchUserOrders();
    },
};
</script>
