var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("router-link", { attrs: { to: _vm.block.data.attributes.link } }, [
        _vm.block.data.attributes.video == true
          ? _c("div", [
              _c("div", { staticClass: "relative" }, [
                _c(
                  "div",
                  { staticClass: "md:hidden" },
                  [
                    _c("vimeo-player", {
                      ref: "player",
                      staticClass: "vimeo",
                      attrs: {
                        "video-id": _vm.block.data.attributes.video_phone_id,
                        options: _vm.options,
                      },
                      on: { ready: _vm.onReady },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hidden md:block" },
                  [
                    _c("vimeo-player", {
                      ref: "player",
                      staticClass: "vimeo",
                      attrs: {
                        "video-id": _vm.block.data.attributes.video_id,
                        options: _vm.options,
                      },
                      on: { ready: _vm.onReady },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.block.data.attributes.text_align == "center"
                        ? "absolute w-full top-1/2  flex flex-col items-center"
                        : "absolute w-full inset-x-0 bottom-0 flex flex-col items-center pb-15",
                    ],
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.block.data.attributes.text_case == "lowercase"
                            ? "lowercase"
                            : "uppercase",
                        ],
                        style:
                          "color: " +
                          _vm.block.data.attributes.text_color +
                          ";",
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.block.data.attributes.text_size == "large"
                                ? "sm:text-lg lg:text-2xl mt-4 text-center  font-normal tracking-widest"
                                : "text-lg mt-4 text-center  font-normal tracking-widest",
                            ],
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.block.data.attributes.h3) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.block.data.attributes.text_size == "large"
                                ? "sm:text-2xl lg:text-4xl mt-4 text-center  font-normal tracking-widest"
                                : "text-2xl mt-4 text-center font-normal tracking-widest",
                            ],
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.block.data.attributes.h2) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.block.data.attributes.button_text != null
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-4 mx:auto flex items-center justify-center",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: _vm.block.data.attributes.link },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                                      style:
                                        "border-color: " +
                                        _vm.block.data.attributes
                                          .border_button_color +
                                        ";" +
                                        "color: " +
                                        _vm.block.data.attributes.text_color +
                                        ";",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.block.data.attributes.button_text
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ])
          : _c("div", [
              _c("div", { staticClass: "w-full h-195 sm:h-full relative" }, [
                _c("div", { staticClass: "hidden md:block" }, [
                  _c("img", {
                    staticClass: "w-full h-195 sm:h-full object-cover",
                    attrs: {
                      src: _vm.block.data.attributes.image.data.attributes.path,
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "md:hidden" }, [
                  _c("img", {
                    staticClass: "w-full h-195 object-cover",
                    attrs: {
                      src: _vm.block.data.attributes.image_phone.data.attributes
                        .path,
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.block.data.attributes.text_align == "center"
                        ? "absolute w-full top-1/2  flex flex-col items-center"
                        : "absolute w-full inset-x-0 bottom-0 flex flex-col items-center pb-15",
                    ],
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.block.data.attributes.text_case == "lowercase"
                            ? "lowercase"
                            : "uppercase",
                        ],
                        style:
                          "color: " +
                          _vm.block.data.attributes.text_color +
                          ";",
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.block.data.attributes.text_size == "large"
                                ? "sm:text-lg lg:text-2xl mt-4 text-center font-normal tracking-widest"
                                : "text-lg mt-4 text-center font-normal tracking-widest",
                            ],
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.block.data.attributes.h3) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.block.data.attributes.text_size === "small"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "sm:text-2xl lg:text-4xl mt-4 text-center font-normal tracking-widest'",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.block.data.attributes.h2) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                class: [
                                  _vm.block.data.attributes.text_size ===
                                  "the largest"
                                    ? "text-4xl lg:text-9xl mt-4 text-center font-normal tracking-widest"
                                    : "text-4xl lg:text-8xl mt-4 text-center font-normal widest",
                                ],
                              },
                              [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(_vm.block.data.attributes.h2) +
                                    "\n                             "
                                ),
                              ]
                            ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.block.data.attributes.button_text != null
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-4 mx:auto flex items-center justify-center",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: _vm.block.data.attributes.link },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                                      style:
                                        "border-color: " +
                                        _vm.block.data.attributes
                                          .border_button_color +
                                        ";" +
                                        "color: " +
                                        _vm.block.data.attributes.text_color +
                                        ";",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.block.data.attributes.button_text
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }