<template>
    <div class="w-full my-10 items-center justify-center">
        <div class="uppercase text-16 text-normal text-gray-main text-center lg:text-24">
            По вашему запросу ничего не найдено
        </div>
        <div>
            <div class="w-76 mt-8 mx-auto text-13 text-normal text-black text-center lg:w-118 lg:text-16">
                Очистите фильтр для комфотного поиска
            </div>
        </div>
        <div class="flex mt-8 justify-center">
            <button @click="resetAllFiltersAndSorting"
                    class="h-9 py-1 px-5 uppercase text-16 text-white border border-black bg-black r focus:outline-none lg:px-16">
                <span>Очистить фильтр</span>
            </button>
        </div>
        <div data-popmechanic-embed="40996"></div>
    </div>
</template>

<script>
export default {
    name: "NoProductsByFilters",
    props:{
        payload:null
    },
    methods:{
        resetAllFiltersAndSorting() {
            this.$emit('resetAllFiltersAndSorting');
        }
    }
}
</script>

<style scoped>

</style>
