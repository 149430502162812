<template>
    <div class="flex justify-center">
        <div data-popmechanic-embed="70640"></div>
    </div>
</template>

<script>
export default {
    name: "AdvertisementLendingMindboxPage"
}
</script>

<style scoped>

</style>


