var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "w-21 h-10 flex items-end justify-between text-11 text-gray-light lg:text-16",
        },
        [
          _c(
            "button",
            {
              staticClass:
                "w-8 h-full hover:bg-black hover:text-white focus:outline-none",
              on: {
                click: function ($event) {
                  return _vm.decrementCartOrderLineItem(_vm.item, _vm.index)
                },
              },
            },
            [_vm._v("\n            -\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-9 h-full flex items-center justify-center" },
            [_vm._v(_vm._s(_vm.item.attributes.userQuantity))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "w-8 h-full hover:bg-black hover:text-white focus:outline-none",
              on: {
                click: function ($event) {
                  return _vm.incrementCartOrderLineItem(_vm.item, _vm.index)
                },
              },
            },
            [_vm._v("\n            +\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.quantityIsNotAllowedPopup
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c(
              "div",
              {
                staticClass:
                  "mt-4 w-full justify-center items-center flex-none text-gray-main",
              },
              [
                _c("div", { staticClass: "flex-none justify-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 w-full text-center text-xl justify-center flex-none",
                    },
                    [
                      _vm._v(
                        "\n                    Количество товаров, которое вы хотели бы добавить, отсутствует на складе.\n                    Мы добавим вам максимум, который можем\n                "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }