var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "-mt-3 ml-4 font-gothic text-gray-main text-2xl" },
        [_vm._v("Предупреждение")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "mt-4 w-full text-left justify-center flex-none text-gray-main",
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.deliveryRestrictedMessage.data.attributes.message) +
              "\n    "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }