var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.block.data.attributes.non_swipable_category_text == null
      ? _c("div", [
          _c("div", { staticClass: "w-full h-full relative" }, [
            _c("img", {
              staticClass: "w-full h-150 object-cover",
              attrs: {
                src: _vm.block.data.attributes.image.data.attributes.path,
                alt: "",
              },
            }),
          ]),
        ])
      : _c("div", [
          _c("div", { staticClass: "w-full h-full relative" }, [
            _c("img", {
              staticClass: "w-full h-150 object-cover",
              attrs: {
                src: _vm.block.data.attributes.image.data.attributes.path,
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mx-auto w-100" }, [
              _c(
                "div",
                {
                  staticClass:
                    "py-10 px-7 absolute w-100 bg-white top-1/2 flex flex-col items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 text-uppercase text-center text-gblack text-15 font-normal tracking-widest",
                    },
                    [
                      _vm._v(
                        "\n                       " +
                          _vm._s(_vm.block.data.attributes.text_title)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "mt-4 underline text-17 font-normal",
                      attrs: { to: _vm.block.data.attributes.text_link },
                    },
                    [_vm._v(_vm._s(_vm.block.data.attributes.text_on_link))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }