<template>
    <div class="w-full text-13 lg:overflow-x-auto ">
        <table class="w-full lg:scrollbar-none">
            <thead>
            <tr class="text-black">
                <th class="text-left">Размер</th>
                <th class="pl-3 text-center" v-for="sizeTitle in uniqueSizeTitles" :key="sizeTitle">{{ sizeTitle }}</th>
            </tr>
            </thead>
            <tbody class="text-gray-main-2">
            <tr v-for="parameter in uniqueParameters" :key="parameter">
                <td class="pt-5"> {{ parameter }}</td>
                <td class="pl-3 pt-5 text-center" v-for="sizeTitle in uniqueSizeTitles" :key="sizeTitle">
                    {{ getValue(parameter, sizeTitle) }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "MeasurementsTable",
    props: {
        measurementsTable: null
    },
    computed: {
        parsedField() {
            return this.measurementsTable;
        },
        uniqueParameters() {
            return [...new Set(this.parsedField.map(item => item.parameter))];
        },
        uniqueSizeTitles() {
            return [...new Set(this.parsedField.map(item => item.size_title))];
        }
    },
    methods: {
        getValue(parameter, sizeTitle) {
            const item = this.parsedField.find(
                item =>
                    item.parameter === parameter && item.size_title === sizeTitle
            );
            return item ? item.value : "";
        }
    },
};
</script>
