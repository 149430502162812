var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "my-4 h-9 px-3 lg:my-2 flex items-center border border-black",
          on: { click: _vm.loadTheWidget },
        },
        [
          _c(
            "a",
            {
              staticClass: "uppercase text-11 lg:mx-auto lg:text-13",
              attrs: { href: "javascript:void(0)" },
            },
            [_vm._v("Выбрать ПВЗ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isCdekPopupVisible
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c("div", {
              staticStyle: { height: "600px", "margin-top": "8px" },
              attrs: { id: "WIDJET" },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }