var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "relative" }, [
      _c(
        "div",
        {
          ref: "swiper",
          staticClass:
            "lg:h-250 overflow-auto no-scrollbar relative swiper xl:h-360 2xl:h-450 3xl:h-screen",
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            [
              _vm.product.data.attributes.video_id != null
                ? _c("div", { staticClass: "swiper-slide" }, [
                    _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "relative" },
                        [
                          _c("div", {
                            staticClass:
                              "absolute w-full h-full items-center z-10",
                          }),
                          _vm._v(" "),
                          _c("vimeo-player", {
                            ref: "player",
                            staticClass: "z-0",
                            attrs: {
                              controls: "false",
                              "video-id": _vm.product.data.attributes.video_id,
                              options: _vm.options,
                            },
                            on: { ready: _vm.onReady },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.product.data.attributes.images.data, function (image) {
                return _c(
                  "div",
                  {
                    key: image.data.product_image_id,
                    staticClass: "swiper-slide",
                  },
                  [
                    _c("img", {
                      staticClass: "w-full h-full object-cover",
                      attrs: { src: image.data.attributes.path, alt: "" },
                    }),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hidden lg:block lg:absolute lg:top-0 lg:z-20" },
        [
          _c("div", [
            _c(
              "div",
              { ref: "thumbsSwiper", staticClass: "pl-7 pt-7 thumbsSwiper" },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  [
                    _vm.product.data.attributes.video_id != null
                      ? _c("div", { staticClass: "swiper-slide pb-0.5" }, [
                          _c("img", {
                            staticClass:
                              "w-15 h-21 mt-2 cursor-pointer object-fill",
                            attrs: {
                              src: _vm.product.data.attributes
                                .product_image_preview_video.data.attributes
                                .path,
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.product.data.attributes.images.data,
                      function (image) {
                        return _c(
                          "div",
                          {
                            key: image.data.product_image_id,
                            staticClass: "swiper-slide pb-0.5",
                          },
                          [
                            _c("img", {
                              staticClass:
                                "w-15 h-21 mt-2 cursor-pointer object-fill",
                              attrs: {
                                src: image.data.attributes.path,
                                alt: "",
                              },
                            }),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }