<template>
    <div>
        <div @click="open">
            <slot name="accordion-trigger" v-bind:visible="visible">
            </slot>
        </div>

        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end">

            <div v-show="visible">
                <slot name="accordion-content">
                </slot>
            </div>
        </transition>

    </div>

</template>

<script>

export default {
    name: "AccordionItem",
    inject: ["Accordion"],

    data: () => {
        return {
            index: null
        }
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active;
        }
    },
    created() {
        this.index = this.Accordion.count++;
    },
    methods : {
        open() {
            if (this.visible) {
                this.Accordion.active = null;
            } else {
                this.Accordion.active = this.index;
            }
        },
        start(el) {
            el.style.height = el.scrollHeight + "px";
        },
        end(el) {
            el.style.height = "";
        }
    }
}
</script>

<style lang="scss" scoped>

.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}

</style>
