var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-transparent" }, [
    _c(
      "svg",
      {
        staticStyle: {
          margin: "auto",
          background: "none repeat scroll 0% 0%",
          display: "block",
          "shape-rendering": "auto",
        },
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          width: "100px",
          height: "100px",
          viewBox: "0 0 100 100",
          preserveAspectRatio: "xMidYMid",
        },
      },
      [
        _c("g", { attrs: { transform: "translate(71,50)" } }, [
          _c("g", { attrs: { transform: "rotate(0)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "4",
                  fill: "#000000",
                  "fill-opacity": "1",
                },
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.875s",
                    values: "1.5 1.5;1 1",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                  },
                }),
                _vm._v(" "),
                _c("animate", {
                  attrs: {
                    attributeName: "fill-opacity",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                    values: "1;0",
                    begin: "-0.875s",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "g",
          {
            attrs: {
              transform: "translate(64.8492424049175,64.8492424049175)",
            },
          },
          [
            _c("g", { attrs: { transform: "rotate(45)" } }, [
              _c(
                "circle",
                {
                  attrs: {
                    cx: "0",
                    cy: "0",
                    r: "4",
                    fill: "#000000",
                    "fill-opacity": "0.875",
                  },
                },
                [
                  _c("animateTransform", {
                    attrs: {
                      attributeName: "transform",
                      type: "scale",
                      begin: "-0.75s",
                      values: "1.5 1.5;1 1",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                    },
                  }),
                  _vm._v(" "),
                  _c("animate", {
                    attrs: {
                      attributeName: "fill-opacity",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                      values: "1;0",
                      begin: "-0.75s",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("g", { attrs: { transform: "translate(50,71)" } }, [
          _c("g", { attrs: { transform: "rotate(90)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "4",
                  fill: "#000000",
                  "fill-opacity": "0.75",
                },
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.625s",
                    values: "1.5 1.5;1 1",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                  },
                }),
                _vm._v(" "),
                _c("animate", {
                  attrs: {
                    attributeName: "fill-opacity",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                    values: "1;0",
                    begin: "-0.625s",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "g",
          {
            attrs: {
              transform: "translate(35.1507575950825,64.8492424049175)",
            },
          },
          [
            _c("g", { attrs: { transform: "rotate(135)" } }, [
              _c(
                "circle",
                {
                  attrs: {
                    cx: "0",
                    cy: "0",
                    r: "4",
                    fill: "#000000",
                    "fill-opacity": "0.625",
                  },
                },
                [
                  _c("animateTransform", {
                    attrs: {
                      attributeName: "transform",
                      type: "scale",
                      begin: "-0.5s",
                      values: "1.5 1.5;1 1",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                    },
                  }),
                  _vm._v(" "),
                  _c("animate", {
                    attrs: {
                      attributeName: "fill-opacity",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                      values: "1;0",
                      begin: "-0.5s",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("g", { attrs: { transform: "translate(29,50)" } }, [
          _c("g", { attrs: { transform: "rotate(180)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "4",
                  fill: "#000000",
                  "fill-opacity": "0.5",
                },
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.375s",
                    values: "1.5 1.5;1 1",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                  },
                }),
                _vm._v(" "),
                _c("animate", {
                  attrs: {
                    attributeName: "fill-opacity",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                    values: "1;0",
                    begin: "-0.375s",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "g",
          {
            attrs: {
              transform: "translate(35.150757595082496,35.1507575950825)",
            },
          },
          [
            _c("g", { attrs: { transform: "rotate(225)" } }, [
              _c(
                "circle",
                {
                  attrs: {
                    cx: "0",
                    cy: "0",
                    r: "4",
                    fill: "#000000",
                    "fill-opacity": "0.375",
                  },
                },
                [
                  _c("animateTransform", {
                    attrs: {
                      attributeName: "transform",
                      type: "scale",
                      begin: "-0.25s",
                      values: "1.5 1.5;1 1",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                    },
                  }),
                  _vm._v(" "),
                  _c("animate", {
                    attrs: {
                      attributeName: "fill-opacity",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                      values: "1;0",
                      begin: "-0.25s",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("g", { attrs: { transform: "translate(49.99999999999999,29)" } }, [
          _c("g", { attrs: { transform: "rotate(270)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "4",
                  fill: "#000000",
                  "fill-opacity": "0.25",
                },
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.125s",
                    values: "1.5 1.5;1 1",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                  },
                }),
                _vm._v(" "),
                _c("animate", {
                  attrs: {
                    attributeName: "fill-opacity",
                    keyTimes: "0;1",
                    dur: "1s",
                    repeatCount: "indefinite",
                    values: "1;0",
                    begin: "-0.125s",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "g",
          {
            attrs: {
              transform: "translate(64.84924240491749,35.150757595082496)",
            },
          },
          [
            _c("g", { attrs: { transform: "rotate(315)" } }, [
              _c(
                "circle",
                {
                  attrs: {
                    cx: "0",
                    cy: "0",
                    r: "4",
                    fill: "#000000",
                    "fill-opacity": "0.125",
                  },
                },
                [
                  _c("animateTransform", {
                    attrs: {
                      attributeName: "transform",
                      type: "scale",
                      begin: "0s",
                      values: "1.5 1.5;1 1",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                    },
                  }),
                  _vm._v(" "),
                  _c("animate", {
                    attrs: {
                      attributeName: "fill-opacity",
                      keyTimes: "0;1",
                      dur: "1s",
                      repeatCount: "indefinite",
                      values: "1;0",
                      begin: "0s",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }