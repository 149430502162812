<template>
    <div class="flex flex-wrap w-full justify-center items-center">
        <div class="flex flex-wrap max-w-xl">
            <div class="p-2 text-2xl text-gray-light "><h1>Восстановление пароля</h1></div>
            <div class="p-2 w-full">
                <form action="#" @submit.prevent="submit">
                    <div class="p-2 w-full">
                        <label for="email">E-mail</label>
                        <span class="w-full text-red-500" v-if="errors.email">{{errors.email[0]}}</span>
                        <input id="email" class="appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-brown-light tracking-normal"  type="email" v-model="form.email">
                    </div>
                    <div class="p-2 w-full">
                        <label for="password">Пароль</label>
                        <span class="w-full text-red-500" v-if="errors.password">{{errors.password[0]}}</span>
                        <input id="password" class="appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-brown-light tracking-normal" type="password" v-model="form.password" name="password">
                    </div>
                    <div class="p-2 w-full">
                        <label for="confirm_password">Подтверждение пароля</label>
                        <span class="w-full text-red-500" v-if="errors.password">{{errors.password[0]}}</span>
                        <input id="confirm_password" class="appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-brown-light tracking-normal" type="password" v-model="form.password_confirmation" name="password_confirmation">
                    </div>
                    <div class="p-2 w-full mt-4">
                        <button type="submit" class="w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider uppercase focus:outline-none">Восстановить пароль</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'ResetPassword',

    data () {
        return {
            form: {
                email: this.$route.query.email,
                password:'',
                password_confirmation:'',
                token: this.$route.query.token,
            },
            errors:[]
        }
    },

    methods: {
        ...mapActions({
            resetPassword: 'resetPassword'
        }),

        async submit () {
            await this.resetPassword(this.form)
            this.$router.replace({ name: 'Account' })

        }
    }
}

</script>

<style scoped>

</style>
