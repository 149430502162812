<template>
<div>
    <div v-if="block.data.attributes.non_swipable_category_text == null">
        <div class="w-full h-full relative">
            <img class="w-full h-150 object-cover" :src=block.data.attributes.image.data.attributes.path alt="">
        </div>
    </div>
    <div v-else>
        <div class="w-full h-full relative ">
            <img class="w-full h-150 object-cover" :src=block.data.attributes.image.data.attributes.path alt="">
           <div class="mx-auto w-100">
               <div class="py-10 px-7 absolute w-100 bg-white top-1/2 flex flex-col items-center">
                   <div class="mt-4 text-uppercase text-center text-gblack text-15 font-normal tracking-widest">
                       {{block.data.attributes.text_title}}</div>
                   <router-link :to="block.data.attributes.text_link" class="mt-4 underline text-17  font-normal">{{block.data.attributes.text_on_link}}</router-link>
               </div>
           </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
name: "NonSwipableBlock",
    props: {
        block: null,
    },
}
</script>

<style scoped>

</style>
