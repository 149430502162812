<template>
  <PopupOnRightSide @closePopup="$emit('closePopup')">
    <div class="mt-7 uppercase text-14 text-gray-lightest tracking-widest"> Наличие в магазинах </div>
    <div class="mt-5 text-14 text-black">
      Перед тем, как ехать в магазин, рекомендуем уточнить наличие вещи по телефону.
      Иногда информация на сайте обновляется не сразу
    </div>
    <div v-if="product.data.attributes.retail_stock_balance" class="mt-5">
      <div v-for="stock in productStockBalance.data"
           :key="stock.data.id">
        <div v-if="hasAnyStockBalancesInStock(stock)" class="border-t border-gray-lightest py-5">
          <div class="flex justify-between items-center">
            <div>
              <div class="uppercase text-14 text-black">
                {{stock.data.attributes.title}}
              </div>
              <div class="mt-2 text-11 text-black">
                {{stock.data.attributes.address}}
              </div>
            </div>
            <div class="mt-1 text-11 text-black">
              <div class="flex justify-end items-right">
                  <a :href="'tel:' + stock.data.attributes.phone">
                      <div class="flex justify-end items-right mb-2">
                          <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                              <path
                                  d="M0.556474 4.38462C1.83662 7.1744 4.11448 9.39244 6.94648 10.5949L7.40044 10.7973C8.43904
                        11.2603 9.65997 10.9085 10.2935 9.96373L10.8867 9.07898C11.0796 8.79133 11.0208 8.40389
                        10.7514 8.18639L8.7395 6.56235C8.444 6.32381 8.00946 6.37937 7.78339 6.6846L7.161
                        7.52494C5.56396 6.73668 4.26734 5.4393 3.47955 3.84132L4.31939 3.21857C4.62443 2.99237
                        4.67996 2.55758 4.44156 2.26189L2.81842 0.248747C2.60109 -0.0208006 2.21399 -0.0795968
                        1.92651 0.113279L1.03618 0.710615C0.0860593 1.34806 -0.263556 2.5798 0.209873
                        3.62178L0.555953 4.38348L0.556474 4.38462Z"
                                  fill="black"
                              />
                          </svg>
                      </div>
                      {{ stock.data.attributes.phone }}
                  </a>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div>
              <div v-for="size in stock.data.relations.stockBalances"
                   :key="size.data.id">
                <div v-if="size.data.attributes.quantity > 0" class="w-15 flex justify-between">
                  <div class="text-11 text-black">
                    {{size.data.attributes.sizeTitle}}
                  </div>
                  <div v-if="size.data.attributes.quantity < 3" class="text-11 text-gray-bright">
                    мало
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mt-5 text-14 text-black">
        К сожалению, на текущий момент изделия нет в наличии в магазинах.
      </div>
    </div>
  </PopupOnRightSide>
</template>

<script>

import PopupOnRightSide from "./PopupOnRightSide.vue";

export default {
  name: "AvailabilityInRetailStocksPopup",
  props: {
    product: null,
    productStockBalance: null
  },
  methods: {
      hasAnyStockBalancesInStock(stock) {
         return stock.data.relations.stockBalances.some((item) => item.data.attributes.quantity > 0)
      }
  },
  components: {
    PopupOnRightSide,
  },
}
</script>

<style scoped>
.underlinedPositions {
  text-decoration: underline;
  text-underline-offset: 3px;
}
</style>


