var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "-mt-3 text-gray-main text-16" }, [
          _vm._v("Спасибо!"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-2 text-gray-main" }, [
          _vm._v("Товар успешно добавлен в корзину"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("router-link", { attrs: { to: { name: "PreorderCart" } } }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 bg-black text-white text-16 font-normal focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                    Оформить предзаказ\n                "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mt-4 underline cursor-pointer text-gray-main text-center",
            on: {
              click: function ($event) {
                return _vm.$emit("closePopup")
              },
            },
          },
          [_vm._v("Продолжить покупки")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }