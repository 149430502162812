<template>
    <div id="streamsApp"></div>
</template>

<script>
export default {
    name: "LivePage",

    mounted() {
        const streamerceScript = document.createElement('script')
        streamerceScript.setAttribute('src', 'https://streamerce.ru/static/js/app.js')
        streamerceScript.setAttribute('type', 'text/javascript')
        document.head.appendChild(streamerceScript)

        streamerceScript.onload = function() {
            initStreamerceApp({elementId: 'streamsApp', merchantName: 'presentandsimple'})
        };
    }
}
</script>

<style scoped>

</style>
