var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPopupActive
    ? _c("PopupOnRightSide", { on: { closePopup: _vm.closePopup } }, [
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass:
                  "w-full mt-15 mb-10 flex items-center justify-center uppercase tracking-wider text-18 text-black",
              },
              [_vm._v("\n            Вход или регистрация\n        ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex mb-10" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full flex items-center justify-center uppercase tracking-wider text-14 text-black",
                  class: !_vm.isAuthByBlock ? "underline" : "",
                  on: { click: _vm.showAuthByPhone },
                },
                [_vm._v("\n                Телефон\n            ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full flex items-center justify-center uppercase tracking-wider text-14 text-black",
                  class: _vm.isAuthByBlock ? "underline" : "",
                  on: { click: _vm.showAuthByEmail },
                },
                [_vm._v("\n                Email\n            ")]
              ),
            ]),
            _vm._v(" "),
            !_vm.isAuthByBlock
              ? _c("AuthByPhone", {
                  on: {
                    authByPhoneSuccessClosePopup: _vm.authSuccessClosePopup,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isAuthByBlock
              ? _c("AuthByEmail", {
                  on: {
                    authByEmailSuccessClosePopup: _vm.authSuccessClosePopup,
                    closePopupBecauseOfForgotPassport: _vm.closePopup,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }