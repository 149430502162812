var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "pr-5 mt-5 ml-5 h-20 bg-gray-lightest bg-opacity-30 lg:pr-0 lg:w-88",
      },
      [
        _c(
          "div",
          { staticClass: "ml-6 grid grid-cols-4 lg:flex" },
          _vm._l(
            _vm.dateAndPricesToPayDolyame,
            function (dateAndPartToPayDolyame, index) {
              return _c("div", { staticClass: "pl-1.5" }, [
                _c(
                  "div",
                  {
                    staticClass: "mt-4",
                    class: index === 0 ? "text-black" : "text-gray-bright",
                  },
                  [
                    _c("div", { staticClass: "text-11" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(dateAndPartToPayDolyame.dateOfPay) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2 text-13" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("formatNumber")(dateAndPartToPayDolyame.part)
                          ) +
                          " ₽\n          "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  class: index === 0 ? "lineActive" : "lineNoActive",
                }),
              ])
            }
          ),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "ml-5 text-11 text-gray-light lg:w-88 lg:text-13" },
      [
        _vm._v(
          "\n    Оплатите 25% от стоимости покупки, а оставшиеся 3 части спишутся автоматически с шагом в две недели.\n    "
        ),
        _c(
          "a",
          {
            staticClass: "underlinedPositions",
            attrs: { href: "https://dolyame.ru/" },
          },
          [_vm._v("Подробнее")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }