<template>
    <div>
        <button class="hidden lg:block">
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.90577 0.188289C9.78856 0.0677294 9.62959 0 9.46383 0C9.29807 0 9.1391 0.0677294 9.02189 0.188289L1.36157 8.06748C1.28304 8.14824 1.22686 8.24907 1.1988 8.35958L0.365464 11.6411C0.309389 11.8619 0.371338 12.0967 0.528233 12.2581C0.685129 12.4195 0.913447 12.4832 1.12813 12.4255L4.31848 11.5684C4.42593 11.5395 4.52395 11.4817 4.60247 11.401L12.2628 3.52179C12.5069 3.27074 12.5069 2.86371 12.2628 2.61265L9.90577 0.188289ZM2.36536 8.85328L9.46383 1.55199L10.937 3.06722L3.8385 10.3685L1.84453 10.9042L2.36536 8.85328Z" fill="black"/>
                <path d="M0.625 13.7143C0.279822 13.7143 0 14.0021 0 14.3571C0 14.7122 0.279822 15 0.625 15H13.125C13.4702 15 13.75 14.7122 13.75 14.3571C13.75 14.0021 13.4702 13.7143 13.125 13.7143H0.625Z" fill="black"/>
            </svg>
        </button>
        <button class="lg:hidden">
            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.92462 0.150631C7.83085 0.0541835 7.70367 0 7.57107 0C7.43846 0 7.31128 0.0541835 7.21751 0.150631L1.08925 6.45398C1.02644 6.51859 0.981489 6.59925 0.959037 6.68766L0.292371 9.31287C0.247511 9.48952 0.29707 9.67739 0.422587 9.8065C0.548103 9.9356 0.730758 9.98658 0.902502 9.94043L3.45479 9.25472C3.54074 9.23163 3.61916 9.1854 3.68198 9.12078L9.81024 2.81743C10.0055 2.61659 10.0055 2.29096 9.81024 2.09012L7.92462 0.150631ZM1.89229 7.08262L7.57107 1.2416L8.74958 2.45378L3.0708 8.2948L1.47562 8.72337L1.89229 7.08262Z" fill="black"/>
                <path d="M0.5 10.9714C0.223858 10.9714 0 11.2017 0 11.4857C0 11.7697 0.223858 12 0.5 12H10.5C10.7761 12 11 11.7697 11 11.4857C11 11.2017 10.7761 10.9714 10.5 10.9714H0.5Z" fill="black"/>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    name: "ChangeSvgButton"
}
</script>

<style scoped>

</style>
