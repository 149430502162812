import axios from "axios";
import qs from "qs";
import Vue from "vue";

const state = {
    payload: JSON.parse(localStorage.getItem('payload')) || {
        filter: {
            mainCategoryId: null,
            sizes: [],
            stocks: [],
            categories: [],
            colors: [],
            price: {
                from: null,
                to: null,
            },
        },
        sort: "default",
    },

    rangeOfPricesValue: [],
    minValue: JSON.parse(localStorage.getItem('minValue')) || 0,
    maxValue: JSON.parse(localStorage.getItem('maxValue')) || 1000000,

    filteredStatus: 'loading',
    filteredProducts: null,

    availableFiltersForCategoryLocal: JSON.parse(localStorage.getItem('availableFiltersForCategoryLocal')) || null,
};

const getters = {
    payload: state => {
        return state.payload;
    },
    filteredProducts: state => {
        return state.filteredProducts;
    },
    availableFiltersForCategoryLocal: state => {
        return state.availableFiltersForCategoryLocal;
    },
    filteredStatus: state => {
        return state.filteredStatus;
    },
    minValue: state => {
        return state.minValue;
    },
    maxValue: state => {
        return state.maxValue;
    },
    rangeOfPricesValue: state => {
        return state.rangeOfPricesValue;
    },
};


const actions = {
    //Методы для оттображения подчеркивания
    findAllClickedElements({dispatch}, resData) {
        dispatch('findClickedElement', {data: resData, filterType: 'colors', action: 'findClicked'});
        dispatch('findClickedElement', {data: resData, filterType: 'sizes', action: 'findClicked'});
        dispatch('findClickedElement', {data: resData, filterType: 'stocks', action: 'findClicked'});
    },
    unClickAllElements({dispatch}, resData) {
        dispatch('findClickedElement', {data: resData, filterType: 'colors', action: 'unClickedAll'});
        dispatch('findClickedElement', {data: resData, filterType: 'sizes', action: 'unClickedAll'});
        dispatch('findClickedElement', {data: resData, filterType: 'stocks', action: 'unClickedAll'});
    },
    findClickedElement({state}, {data, filterType, action}) {
        const filters = data.meta.availableFilters[filterType].data;
        filters.forEach(filter => {
            if (action === 'findClicked') {
                filter.data.clicked = !!state.payload.filter[filterType].find(element => element === filter.data.id);
            } else if (action === 'unClickedAll') {
                filter.data.clicked = false;
            }
        });
    },


    cleanPayloadFilterColors({commit, dispatch}) {
        dispatch('findClickedElement', {
            data: state.availableFiltersForCategoryLocal,
            filterType: 'colors',
            action: 'unClickedAll'
        });
        commit('CLEAN_PAYLOAD_FILTER_COLORS')
    },
    cleanPayloadFilterSizes({commit, dispatch}) {
        dispatch('findClickedElement', {
            data: state.availableFiltersForCategoryLocal,
            filterType: 'sizes',
            action: 'unClickedAll'
        });
        commit('CLEAN_PAYLOAD_FILTER_SIZES')
    },
    cleanPayloadFilterStocks({commit, dispatch}) {
        dispatch('findClickedElement', {
            data: state.availableFiltersForCategoryLocal,
            filterType: 'stocks',
            action: 'unClickedAll'
        });
        commit('CLEAN_PAYLOAD_FILTER_STOCKS')
    },
    resetAllFilters({state, commit, dispatch}) {
        dispatch('unClickAllElements', state.availableFiltersForCategoryLocal);
        commit('CLEAN_PAYLOAD_FILTERS', [state.minValue, state.maxValue])
    },

    putTypeOfSortingIntoPayload({commit, dispatch}, typeOfSorting) {
        commit('PUT_TYPE_OF_SORTING_INTO_PAYLOAD', typeOfSorting)
    },

    generateFilterData({state}, filterType) {
        const filterData = [];
        const filters = state.availableFiltersForCategoryLocal
            ? state.availableFiltersForCategoryLocal.meta.availableFilters[filterType].data
            : null;
        if (filters) {
            filters.forEach(filter => {
                if (filter.data.clicked) {
                    filterData.push(filter.data.id);
                }
            });
        }
        return filterData;
    },


    putColorIntoFilter({state}, color) {
        color.data.clicked = !color.data.clicked;
    },

    putSizeIntoFilter({state}, size) {
        size.data.clicked = !size.data.clicked;
    },

    putCategoriesIntoFilter({commit}, categoryId) {
        commit('setCategoriesToPayload', categoryId);
    },

    putStockIntoFilter({state}, stock) {
        stock.data.clicked = !stock.data.clicked;
    },

    async initFilterData({state, dispatch, commit}) {
        state.payload.filter.colors = state.availableFiltersForCategoryLocal
            ? await dispatch('generateFilterData', 'colors')
            : [];
        state.payload.filter.sizes = state.availableFiltersForCategoryLocal
            ? await dispatch('generateFilterData', 'sizes')
            : [];

        state.payload.filter.stocks = state.availableFiltersForCategoryLocal
            ? await dispatch('generateFilterData', 'stocks')
            : [];

        commit("setAvailableFiltersForCategoryLocal", state.availableFiltersForCategoryLocal);
    },
    async filter({state, dispatch, commit}) {
        commit('setFilteredStatus', 'loading')
        try {
            const response = await axios.get('/api/products', {
                params: state.payload,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'brackets'}),
            });

            dispatch('findAllClickedElements', response.data)

            if (!state.availableFiltersForCategoryLocal ||
                (state.availableFiltersForCategoryLocal &&
                    response.data.meta.currentFilters.mainCategoryId
                    !== state.availableFiltersForCategoryLocal.meta.currentFilters.mainCategoryId)) {

                dispatch('unClickAllElements', response.data)
                commit('setAvailableFiltersForCategoryLocal', response.data);
                commit('setMinValue', state.availableFiltersForCategoryLocal.meta.availableFilters.price.from);
                commit('setMaxValue', state.availableFiltersForCategoryLocal.meta.availableFilters.price.to);
                commit('setRangeOfPricesValue', [state.minValue, state.maxValue]);
                commit('setPricesToPayload', state.rangeOfPricesValue);

            } else {
                commit('setRangeOfPricesValue', [state.payload.filter.price.from, state.payload.filter.price.to]);
                commit('setPayload', state.payload);
            }
            commit('setFilteredProducts', response.data);
            commit('setFilteredStatus', 'success');
        } catch (error) {
            commit('setFilteredStatus', 'error');
        }
    },

    async sortAndFilter({state, dispatch, commit}, categoryId) {
        if (state.payload.filter.mainCategoryId !== categoryId ||
            categoryId === 15
            || categoryId === 196) {
            await commit('setNewPayloadOnFirstLoadOfCategory', categoryId);
            await commit('cleanFiltersAndSortingInPayload', [[]]);
        } else {
            await commit('setMainCategoryIdToPayload', categoryId);
            await dispatch('initFilterData');
        }
        await dispatch('filter')
    },

    setRangeOfPricesValue({commit}, value) {
        commit('setRangeOfPricesValue', value);
        commit('setPricesToPayload', state.rangeOfPricesValue);
    },
};

const mutations = {
    PUT_TYPE_OF_SORTING_INTO_PAYLOAD(state, typeOfSorting) {
        if (state.payload) {
            state.payload.sort = typeOfSorting;
            localStorage.setItem('payload', JSON.stringify(state.payload));
        } else {
            console.error('Payload is undefined in mutation');
        }
    },

    setFilteredProducts(state, products) {
        state.filteredProducts = products;
    },

    setAvailableFiltersForCategoryLocal(state, filters) {
        state.availableFiltersForCategoryLocal = filters;
        localStorage.setItem('availableFiltersForCategoryLocal', JSON.stringify(filters));
    },

    setPayload(state, payload) {
        state.payload = payload;
        localStorage.setItem('payload', JSON.stringify(payload));
    },

    setMinValue(state, minValue) {
        state.minValue = minValue;
        localStorage.setItem('minValue', JSON.stringify(minValue));
    },

    setMaxValue(state, maxValue) {
        state.maxValue = maxValue;
        localStorage.setItem('maxValue', JSON.stringify(maxValue));
    },

    setFilteredStatus(state, status) {
        state.filteredStatus = status;
    },

    setRangeOfPricesValue(state, newValue) {
        if (Array.isArray(newValue) && newValue.length === 2) {
            Vue.set(state, 'rangeOfPricesValue', newValue);
            localStorage.setItem('rangeOfPricesValue', JSON.stringify(newValue));
        } else {
            console.error('Invalid value for rangeOfPricesValue:', newValue);
        }
    },

    cleanFiltersAndSortingInPayload(state) {
        state.payload.filter.colors = [];
        state.payload.filter.sizes = [];
        state.payload.filter.stocks = [];
        state.payload.filter.categories = [];
        state.payload.filter.price.from = null
        state.payload.filter.price.to = null
        state.payload.sort = 'default'
        localStorage.setItem('payload', JSON.stringify(state.payload));

    },
    cleanColorsPayload(state) {
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setNewPayloadOnFirstLoadOfCategory(state, categoryId) {
        state.payload.filter.mainCategoryId = categoryId;
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setMainCategoryIdToPayload(state, mainCategory) {
        state.payload.filter.mainCategoryId = mainCategory;
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    CLEAN_PAYLOAD_FILTER_COLORS(state) {
        state.payload.filter.colors = [];
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    CLEAN_PAYLOAD_FILTER_SIZES(state) {
        state.payload.filter.sizes = [];
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    CLEAN_PAYLOAD_FILTER_STOCKS(state) {
        state.payload.filter.stocks = [];
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    CLEAN_PAYLOAD_FILTERS(state, priceValues) {
        state.payload.filter.colors = [];
        state.payload.filter.sizes = [];
        state.payload.filter.stocks = [];
        state.payload.filter.categories = [];
        state.payload.filter.price.from = priceValues[0]
        state.payload.filter.price.to = priceValues[1]
        state.payload.sort = 'default'
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setPayloadFilterSizes(state, sizes) {
        state.payload.filter.sizes = sizes;
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setCategoriesToPayload(state, id) {
        state.payload.filter.categories = [id];
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setPayloadFilterStocks(state, stocks) {
        state.payload.filter.stocks = stocks;
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setPayloadFilterPrice(state, price) {
        state.payload.filter.price = price;
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    setPricesToPayload(state, rangeOfPrices) {
        state.payload.filter.price.from = rangeOfPrices[0]
        state.payload.filter.price.to = rangeOfPrices[1]

        localStorage.setItem('payload', JSON.stringify(state.payload));
    },
};
export default {
    state, getters, actions, mutations,
}
