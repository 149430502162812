var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "top-12 sticky sm:top-15 bg-white",
      class: _vm.isFiltersPopupActive ? "z-30" : "z-20",
    },
    [
      _c("FiltersSubCategories", {
        attrs: {
          filteredProducts: _vm.filteredProducts,
          availableFiltersForCategoryLocal:
            _vm.availableFiltersForCategoryLocal,
          categoryId: _vm.categoryId,
          countRemainderStocks: _vm.getCountRemainder,
          categoryTitle: _vm.categoryTitle,
        },
        on: { filterBySubCategory: _vm.filterBySubCategory },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "py-5 ml-5 flex justify-between items-center lg:justify-end lg:ml-0",
        },
        [
          _vm.getCountRemainder
            ? _c(
                "button",
                {
                  staticClass:
                    "uppercase text-14 text-black tracking-wider cursor-pointer focus:outline-none",
                  on: { click: _vm.openFiltersPopup },
                },
                [
                  _vm._v("\n            Фильтры\n            "),
                  _vm.quantityOfAppliedFilters
                    ? _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          "\n             (" +
                            _vm._s(_vm.quantityOfAppliedFilters) +
                            ")\n         "
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mr-5 ml-13" }, [
            _vm.getCountRemainder
              ? _c(
                  "button",
                  {
                    staticClass:
                      "uppercase text-14 text-black tracking-wider cursor-pointer focus:outline-none",
                    on: { click: _vm.openSortingPopup },
                  },
                  [_vm._v("\n                Сортировка\n            ")]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.payload && _vm.payload.sort
        ? _c("Sorting", {
            attrs: { isSortShown: _vm.isSortPopupActive, sortBy: _vm.sortBy },
            on: {
              putTypeOfSortingIntoSortingAndFiltration:
                _vm.putTypeOfSortingIntoSortingAndFiltration,
              closeSortingPopup: _vm.closeFilterAndSortingPopup,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isFiltersPopupActive
        ? _c("Filters", {
            attrs: {
              isFiltersActive: _vm.isFiltersPopupActive,
              "category-id": _vm.categoryId,
            },
            on: {
              applyFilters: _vm.applyFilters,
              resetColorFilter: _vm.resetColorFilter,
              resetSizeFilter: _vm.resetSizeFilter,
              resetStockFilter: _vm.resetStockFilter,
              resetAllFilters: _vm.resetAllFilters,
              successFiltration: _vm.filteredProducts2,
              closeFilterPopup: _vm.closeFilterAndSortingPopup,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.filteredProducts?.data && _vm.filteredProducts?.data.length === 0
        ? _c(
            "div",
            [
              _c("NoProductsByFilters", {
                attrs: { payload: _vm.payload },
                on: {
                  resetAllFiltersAndSorting: _vm.resetAllFiltersAndSorting,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }