var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "mt-4 text-14 font-normal text-black" }, [
          _vm._v(
            "\n            Следующий предзаказ можно оформить только после оплаты заказа, который лежит в вашей корзине  «Предзаказ»\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("router-link", { attrs: { to: { name: "PreorderCart" } } }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full h-9 mt-2.5 bg-black text-14 font-normal text-white flex items-center justify-center focus:outline-none lg:w-98",
                },
                [_vm._v("Оформить предзаказ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "w-full h-9 mt-2.5 bg-white border border-black text-14 font-normal text-black flex items-center justify-center focus:outline-none lg:w-98",
                on: {
                  click: function ($event) {
                    return _vm.$emit("replaceProductInPreorderCart")
                  },
                },
              },
              [_vm._v("Заменить товар")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }