var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col gap-3" }, [
    _vm.getVerificationOfUser
      ? _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone",
              },
            ],
            staticClass:
              "mt-2 appearance-none text-11 border outline-none border-gray-lighter text-gray-light rounded-none w-full h-9 px-2 focus:outline-none focus:border-black lg:text-13 no-spinner",
            attrs: { id: "phone", readonly: "" },
            domProps: { value: _vm.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.phone = $event.target.value
              },
            },
          }),
        ])
      : _c(
          "div",
          [
            _c("vue-tel-input", {
              attrs: {
                inputOptions: { placeholder: "Номер телефона" },
                preferredCountries: ["ru", "by", "kz"],
                defaultCountry: "ru",
                mode: "international",
                required: true,
                styleClasses: _vm.styleClasses,
              },
              on: { focus: _vm.resetPhoneInput },
              model: {
                value: _vm.phone,
                callback: function ($$v) {
                  _vm.phone = $$v
                },
                expression: "phone",
              },
            }),
            _vm._v(" "),
            _vm.phone && _vm.$v.phone.$invalid
              ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s("Пожалуйста, введите корректный номер телефона") +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
    _vm._v(" "),
    _vm.isCodeSend
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.code,
              expression: "code",
            },
          ],
          staticClass:
            "mt-2 appearance-none text-11 text-gray-light rounded-none w-full h-9 px-2 focus:outline-none focus:border-black lg:text-13 no-spinner",
          attrs: {
            type: "number",
            maxlength: "4",
            placeholder: "Введите код из СМС",
          },
          domProps: { value: _vm.code },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.code = $event.target.value
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.authWayErrorMessage
      ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
          _vm._v("\n        " + _vm._s(_vm.authWayErrorMessage) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isButtonDisabled
      ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
          _vm._v(
            "\n        Вы можете повторно отправить код через " +
              _vm._s(_vm.timeLeft) +
              " секунд"
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isCodeSend
      ? _c(
          "button",
          {
            staticClass:
              "w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14",
            class:
              _vm.isPhoneValid && _vm.timeLeft === 0 && !_vm.initServerError
                ? "border border-black bg-black"
                : "border border-gray-light-2 bg-gray-light-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.getCode()
              },
            },
          },
          [_vm._v("\n        " + _vm._s("Получить код") + "\n    ")]
        )
      : _vm.isCodeSend && _vm.isPhoneValid && _vm.attemptsLeft > 0
      ? _c(
          "button",
          {
            staticClass:
              "w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14",
            class:
              _vm.isPhoneValid && !_vm.authServerError
                ? "border border-black bg-black"
                : "border border-gray-light-2 bg-gray-light-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.authorizeByCredentials()
              },
            },
          },
          [_vm._v("\n        " + _vm._s("Войти") + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.resendLeft > 0 && _vm.timeLeft === 0
      ? _c(
          "button",
          {
            staticClass:
              "w-full mt-2 h-10 py-1 px-5 text-green-dark r focus:outline-none text-14",
            on: {
              click: function ($event) {
                return _vm.getCode()
              },
            },
          },
          [_vm._v("\n        Получить код повторно\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }