var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap w-full justify-center items-center" },
    [
      _c("div", { staticClass: "flex flex-wrap max-w-xl" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c(
            "form",
            {
              attrs: { action: "#" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "p-2 w-full" }, [
                _c("label", { attrs: { for: "email" } }, [_vm._v("E-mail")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass:
                    "appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-black tracking-wide",
                  attrs: { type: "text", name: "email", id: "email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.status == "loading"
                ? _c("span", { staticClass: "p-2 text-xs text-gray-main" }, [
                    _vm._v(
                      "\n                    Загрузка...\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "success"
                ? _c("span", { staticClass: "p-2 text-xs text-green-500" }, [
                    _vm._v(
                      "\n                    Ссылка на восстановление пароля была отправлена на вашу почту!\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "error"
                ? _c("span", { staticClass: "p-2 text-xs text-red-500" }, [
                    _vm._v("\n                    Ошибка :(\n                "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(1),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2 text-2xl text-gray-light" }, [
      _c("h1", [_vm._v("Восстановление пароля")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2 w-full mt-4" }, [
      _c(
        "button",
        {
          staticClass:
            "w-full py-3 px-5 bg-black text-white text-sm tracking-wider uppercase focus:outline-none",
          attrs: { type: "submit" },
        },
        [_vm._v("Восстановить пароль")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }