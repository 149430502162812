<template>

    <div>
        <div class="swiper-container " ref="swipableFullSizeBlockSwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide"
                    v-for="block_swiper in block.data.attributes.swipable_full_size_sub_blocks.data"
                     :key=block_swiper.data.swipable_full_size_sub_block_id >
                    <div>
                        <div class = "w-full h-220 sm:h-full relative">
                            <!-- Для фото ссылка такая же как у кнопки -->
                            <a class="hidden md:block" :href="block_swiper.data.attributes.button_link">
                                <img class="w-full h-220 sm:h-full object-cover" :src=block_swiper.data.attributes.image.data.attributes.path alt="">
                            </a>

                            <a class="md:hidden" :href="block_swiper.data.attributes.button_link">
                                <img class="w-full h-220 sm:h-full object-cover" :src=block_swiper.data.attributes.mobile_image.data.attributes.path alt="">
                            </a>

                            <div :class="[block_swiper.data.attributes.text_align == 'center' ? 'absolute w-full top-1/2  flex flex-col items-center' : 'absolute w-full inset-x-0 bottom-0 flex flex-col items-center pb-15']">
                                <div :class="[block_swiper.data.attributes.text_case == 'lowercase'
                                ? 'lowercase'
                                : 'uppercase']"
                                     :style="'color: ' + block_swiper.data.attributes.text_color + ';'">
                                    <div :class="[block_swiper.data.attributes.text_size == 'large'
                                      ? 'sm:text-lg lg:text-2xl mt-4 text-center font-normal tracking-widest'
                                      : 'text-lg mt-4 text-center font-normal tracking-widest']">
                                        {{block_swiper.data.attributes.h3}}
                                    </div>

                                    <div v-if="block_swiper.data.attributes.text_size === 'small'"
                                         class="sm:text-2xl lg:text-4xl mt-4 text-center font-normal tracking-widest'">
                                        {{block_swiper.data.attributes.h2}}
                                    </div>

                                    <div v-else
                                         :class="[block_swiper.data.attributes.text_size === 'the largest'
                                      ? 'text-4xl lg:text-9xl mt-4 text-center font-normal tracking-widest'
                                      : 'text-4xl lg:text-8xl mt-4 text-center font-normal widest']">
                                        {{block_swiper.data.attributes.h2}}
                                    </div>
                                </div>
                                <div v-if="block_swiper.data.attributes.button_text != null">
                                    <div  class="mt-4 mx:auto flex items-center justify-center">
                                        <router-link :to="block_swiper.data.attributes.button_link">
                                            <button :style="'border-color: ' + block_swiper.data.attributes.border_button_color + ';'+'background-color: ' + block_swiper.data.attributes.background_button_color + ';'+'color: ' + block_swiper.data.attributes.button_text_color + ';'"
                                                    class="py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm">{{ block_swiper.data.attributes.button_text }}</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-pagination" slot="pagination" ></div>
        </div>
    </div>
</template>

<script>
import {Pagination, Autoplay, EffectFade, Swiper} from "swiper";
import 'swiper/swiper-bundle.css';
//import 'swiper/css/autoplay';

Swiper.use([Pagination, Autoplay, EffectFade]);

export default {
    name: "SwipableFullSizeBlock",
    props: {
        block: null,
    },
    data: () => ({
        swiper: null,
    }),
    mounted() {

        this.swiper = new Swiper(this.$refs.swipableFullSizeBlockSwiper, this.swiperSettings);

    },
    computed: {

        swiperSettings() {
            return {
                pagination: {
                    el: '.swiper-pagination',
                },
                autoplay:{
                  delay: 5000,
                  stopOnLastSlide:false,
                  disableOnInteraction:false,
                },
                effect:'fade',
                speed: 1500,
                loop:true,
            }
        },

    },
}
</script>

<style scoped>

.swiper-pagination >>> .swiper-pagination-bullet-active {
    background-color: white;
}

.swiper-pagination  {
    position: absolute;
    text-align: right;
    padding-right: 30px;
    padding-bottom: 15px;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
}

</style>
