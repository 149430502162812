<template>
    <div :style="'background-color: ' + block.data.attributes.background_color + ';'">
        <div class="flex-none relative">
            <div class="swiper-container mx-auto w-full" ref="categorySwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide px-0.5 items-center justify-center"
                         v-for="item in block.data.attributes.swipable_look_book_category_sub_blocks.data"
                         :key="item.data.swipable_look_book_category_sub_block_id">
                        <div>
                            <a :href="item.data.attributes.link">
                                <img class="relative" :src=item.data.attributes.image.data.attributes.path alt="">
                            </a>
                            <div v-if="item.data.attributes.h2 != null"
                                 class="px-4 md:px-7 absolute left-0 ml-1 mt-2  top-0">
                                <div>
                                    <div v-if="item.data.attributes.text_size === 'small'"
                                         :style="'color: ' + item.data.attributes.text_color + ';'"
                                         class="md:text-lg mt-4 uppercase font-bold pr-7 font-normal tracking-widest'">
                                        {{ item.data.attributes.h2 }}
                                    </div>
                                    <div v-else :style="'color: ' + item.data.attributes.text_color + ';'"
                                         :class="[item.data.attributes.text_size === 'the largest'
                                  ? 'md:text-3xl mt-4 text-center uppercase font-bold pr-7 tracking-widest'
                                  : 'md:text-xl mt-4 text-center uppercase font-bold pr-7 widest']">
                                        {{ item.data.attributes.h2 }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.data.attributes.button_text != null">
                                <a :href="item.data.attributes.link">
                                    <div class="px-4 md:px-7 absolute bottom-0 mb-2  divide-y  divide-white">
                                        <div :style="'color: ' + item.data.attributes.button_text_color + ';'"
                                             class=" first:pt-0 last:pb-0 md:text-xl font-bold ">
                                            {{ item.data.attributes.button_text }}
                                        </div>
                                        <div class="">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden md:block">
                <div class="swiper-button-prev absolute w-1/2"></div>
            </div>
            <div class="hidden md:block">
                <div class="swiper-button-next absolute w-1/2"></div>
            </div>
        </div>
    </div>
</template>


<script>

import {Navigation, Swiper} from 'swiper';
import 'swiper/swiper-bundle.css';
import ProductBlock from "./ProductBlock";

Swiper.use([Navigation]);

export default {
    name: "SwipableLookBookCategoryBlock",
    components: {ProductBlock},
    props: {
        block: null,
        colorBg: null,
    },
    data: () => ({
        swiper: null,
    }),
    computed: {
        swiperSettings() {
            return {
                slidesPerView: 2,
                grabCursor: true,
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: this.block.data.attributes.slides_quantity_on_tablets,
                    },
                    // when window width is >= 1280px
                    1280: {
                        slidesPerView: this.block.data.attributes.slides_quantity_for_desktop,
                    }
                },
            }
        },
    },
    mounted() {
        this.swiper = new Swiper(this.$refs.categorySwiper, this.swiperSettings);
    },
}
</script>

<style lang="css" scoped>

:root {
    /*
    вытащила все из документации по css в Swiper
    без --swiper-navigation-size ширину и высоту стрелок нельзя регулировать
    */
}

.swiper-button-prev {
    --swiper-navigation-size: 25px;
    color: #ffffff !important;
}

.swiper-button-next {
    color: #ffffff !important;
    --swiper-navigation-size: 25px;
}
</style>
