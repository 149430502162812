<template>
    <PopupOnRightSide v-if="isPopupActive" @closePopup="closePopup">
        <div>
            <div
                class="w-full mt-15 mb-10 flex items-center justify-center uppercase tracking-wider text-18 text-black">
                Вход или регистрация
            </div>
            <div class="flex mb-10">
                <button @click="showAuthByPhone"
                        class="w-full flex items-center justify-center uppercase tracking-wider text-14 text-black"
                        :class="!isAuthByBlock ? 'underline' : ''">
                    Телефон
                </button>
                <button @click="showAuthByEmail"
                        class="w-full  flex items-center justify-center uppercase tracking-wider text-14 text-black"
                        :class="isAuthByBlock ? 'underline' : ''">
                    Email
                </button>
            </div>
            <AuthByPhone v-if="!isAuthByBlock"
                         @authByPhoneSuccessClosePopup="authSuccessClosePopup"/>
            <AuthByEmail v-if="isAuthByBlock"
                         @authByEmailSuccessClosePopup="authSuccessClosePopup"
                         @closePopupBecauseOfForgotPassport="closePopup"/>
        </div>
    </PopupOnRightSide>
</template>

<script>
import PopupOnRightSide from "../popup/PopupOnRightSide.vue";
import AuthByEmail from "../Auth/AuthByEmail.vue";
import AuthByPhone from "../Auth/AuthByPhone.vue";

export default {
    name: 'Login',
    components: {AuthByPhone, AuthByEmail, PopupOnRightSide},
    props: {
        isPopupActive: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            isAuthByBlock: false,
        }
    },
    methods: {
        showAuthByPhone() {
            this.isAuthByBlock = false
        },
        authSuccessClosePopup() {
            this.closePopup()
            this.$router.replace({name: 'Account'});
        },
        showAuthByEmail() {
            this.isAuthByBlock = true
        },
        closePopup() {
            this.$emit('closePopup', {popupActive: false})
        },
    },
}
</script>
<style lang="scss" scoped>
.placeholder {
    color: #8E8E8E;
}
</style>
