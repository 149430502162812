var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.order
      ? _c("div", { staticClass: "w-full p-6 lg:w-256 lg:mx-auto" }, [
          _c("div", [
            _vm._v("Заказ "),
            _c("span", [_vm._v(" " + _vm._s(_vm.order.data.order_id) + " ")]),
            _vm._v(
              " успешно создан.\n            В течение пары секунд вы будете перемещены на страницу оплаты.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("Спасибо!")]),
        ])
      : _c("div", { staticClass: "w-full p-6 lg:w-256 lg:mx-auto" }, [
          _c("div", [
            _vm._v("Предзаказ "),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.preorder.data.order_id) + " "),
            ]),
            _vm._v(
              " успешно создан.\n            В течение пары секунд вы будете перемещены на страницу оплаты.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("Спасибо!")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }