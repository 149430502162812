var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isPreorderCartPopupActive
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c(
              "div",
              {
                staticClass:
                  "my-4 w-full justify-center items-center flex-none text-black",
              },
              [
                _c("div", { staticClass: "flex-none justify-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 w-full text-center text-18 justify-center flex-none",
                    },
                    [
                      _vm._v(
                        "\n                    Условия предзаказа\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-5 w-full flex-none justify-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-left text-14 text-black" },
                        [
                          _vm._v(
                            "\n                        Предзаказ доступен по полной предоплате.\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-3 text-left text-14 text-black" },
                        [
                          _vm._v(
                            "\n                        Стоимость забронированных вещей нельзя оплатить\n                        подарочными сертификатами или использовать промокод.\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-3 text-left text-14 text-black" },
                        [
                          _vm._v(
                            "\n                        Оформить экспресс-доставку или доставку\n                        в дальнее зарубежье для предзаказа не получится. Доступные способы получить заказ:\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-black" },
                          [
                            _vm._v(
                              "\n                            Самовывоз из магазинов в Москве, Петербурге и Екатеринбурге\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-gray-main-2" },
                          [
                            _vm._v(
                              "\n                            Срок: 2-10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            Стоимость: бесплатно\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-black" },
                          [
                            _vm._v(
                              "\n                            Курьерская доставка СДЭК\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-gray-main-2" },
                          [
                            _vm._v(
                              "\n                            Срок: 1–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            Стоимость: бесплатно\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-black" },
                          [
                            _vm._v(
                              "\n                            Самовывоз из пункта выдачи Boxberry, СДЭК\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-gray-main-2" },
                          [
                            _vm._v(
                              "\n                            Срок: 2–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            Стоимость: бесплатно\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-black" },
                          [
                            _vm._v(
                              "\n                            Курьерская доставка СДЭК в СНГ\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-left text-14 text-gray-main-2" },
                          [
                            _vm._v(
                              "\n                            Срок: 1–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            Стоимость: от 990 ₽\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-11 tracking-wide text-gray-main lg:text-13" },
        [
          _c(
            "button",
            {
              staticClass: "flex underline uppercase focus:outline-none",
              on: { click: _vm.preorderInfo },
            },
            [_vm._v("\n            условия предзаказа\n        ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }