<template>
    <div class="mx-1 px-1 py-0 bg-white text-gray-light text-xs lowercase lg:text-base">
        {{tag.data.attributes.title}}
    </div>
</template>

<script>
export default {
    name: "ProductTag",
    props: {
        tag : null,
    }
}
</script>

<style scoped>

</style>
