var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-9" }, [
    !_vm.product.data.attributes.active_stock_balance &&
    !_vm.product.data.attributes.not_for_sale
      ? _c(
          "div",
          {
            staticClass:
              "mb-3 text-14 font-normal text-left text-green-dark font-bold",
          },
          [
            _vm.product.data.attributes.hasPreorderStockBalance
              ? _c("span", [_vm._v("Предзаказ")])
              : _vm.product.data.attributes.coming_soon
              ? _c("span", [_vm._v("Скоро в продаже")])
              : _vm.product.data.attributes.retail_stock_balance
              ? _c("span", [_vm._v("Только в магазинах")])
              : _c("span", [_vm._v("Нет в наличии")]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "text-18 text-black uppercase tracking-widest" }, [
      _vm._v(_vm._s(_vm.product.data.attributes.title)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3 flex text-14 uppercase" }, [
      _vm.product.data.attributes.discount
        ? _c("div", { staticClass: "text-black" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("formatNumber")(
                    _vm.product.data.attributes.cost -
                      _vm.product.data.attributes.discount.data.attributes
                        .amount
                  )
                ) +
                " ₽\n            "
            ),
            _c(
              "span",
              { staticClass: "ml-1 text-gray-main-2 line-through lg:ml-4" },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("formatNumber")(_vm.product.data.attributes.cost)
                  ) + " ₽"
                ),
              ]
            ),
          ])
        : _c("div", { staticClass: "text-black" }, [
            _vm._v(
              _vm._s(_vm._f("formatNumber")(_vm.product.data.attributes.cost)) +
                " ₽"
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }