<template>
    <Popup @closePopup="$emit('closePopup')">
        <div class="mt-4 w-full text-left justify-center flex-none text-gray-main">
            <div>
                <div class="mt-4 w-full text-center  justify-center flex-none">
                    Мы запустили доставку по всему миру. Транспортировку заказов осуществляет компания DHL, срок доставки зависит от страны назначения и занимает от 5 до 10 дней. Оплата возможно только выпущенными в России картами.</div>
            </div>
        </div>
    </Popup>
</template>

<script>
import Popup from "./Popup";

export default {
    name: "DeliveryAbroadIsAvailableConditionPopup",
    components: {
        Popup
    },
}
</script>

<style scoped>

</style>
