<template>
  <div class="flex justify-center">

  </div>
</template>

<script>
export default {
  name: "UnsubscribeLendingMindboxPage"
}
</script>

<style scoped>

</style>




