var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full mt-10 justify-center" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "flex mt-8 justify-center",
          attrs: { to: "/catalog/novinki" },
        },
        [
          _c(
            "button",
            {
              staticClass:
                "h-9 py-1 px-5 uppercase text-16 text-white border border-black bg-black r focus:outline-none lg:px-16",
            },
            [_c("span", [_vm._v("Перейти в каталог")])]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { "data-popmechanic-embed": "40996" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "uppercase text-16 text-normal text-gray-main text-center lg:text-24",
      },
      [
        _vm._v("\n        Кажется, товар закончился "),
        _c("br"),
        _vm._v(" или страница больше неактивна\n    "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "w-76 mt-8 mx-auto text-13 text-normal text-black text-center lg:w-118 lg:text-16",
        },
        [
          _vm._v(
            "\n            Чтобы продолжить ваше путешествие по Present & Simple, предлагаем познакомиться с нашими новинками и найти\n            для себя что-то интересное\n        "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }