var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isDeliveryInfoPopupVisible
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c(
              "div",
              {
                staticClass:
                  "mt-4 w-full justify-center items-center flex-none text-gray-main",
              },
              [
                _c("div", { staticClass: "flex-none justify-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 w-full uppercase text-center text-16 justify-center flex-none",
                    },
                    [
                      _vm._v(
                        "\n                    Получить заказ можно следующими способами:\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-8 w-full flex-none justify-center" },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "text-left text-13 text-black" },
                          [
                            _vm._v(
                              "\n                            • Экспресс-доставка по Москве\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Срок: в течение рабочего дня "
                            ),
                            _c("br"),
                            _vm._v(
                              " Стоимость: 790 ₽\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-13 text-black" },
                          [
                            _vm._v(
                              "\n                            • Курьерская доставка СДЭК, СДЭК Экспресс\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Срок: 1–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              " Стоимость: 550 ₽ по Москве и Петербургу; от 690 ₽ по России\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                           Отправка: по России только в будние дни\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-13 text-black" },
                          [
                            _vm._v(
                              "\n                            • Самовывоз из магазинов в Москве, Петербурге и Екатеринбурге\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Срок: 2–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              " Стоимость: бесплатно\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-13 text-black" },
                          [
                            _vm._v(
                              "\n                            • Самовывоз из пункта выдачи Boxberry\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Срок: 2–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              " Стоимость: 190 ₽\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Отправка: только в будние дни\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-13 text-black" },
                          [
                            _vm._v(
                              "\n                            • Самовывоз из пункта выдачи СДЭК\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Срок: 2–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              " Стоимость: 290 ₽\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Отправка: только в будние дни\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-left text-13 text-black" },
                          [
                            _vm._v(
                              "\n                            • Курьерская доставка СДЭК в СНГ\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-4 text-left text-11 text-gray-light",
                          },
                          [
                            _vm._v(
                              "\n                            Срок: 1–10 дней "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            Стоимость: от 990 ₽\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center mt-5" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "flex items-center",
                          attrs: { to: "/delivery-and-return" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "w-45 h-10 py-2 bg-black uppercase text-white text-16 focus:outline-none",
                            },
                            [
                              _vm._v(
                                "\n                            Подробнее\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isReturnInfoPopupVisible
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c(
              "div",
              {
                staticClass:
                  "mt-4 w-full justify-center items-center flex-none text-gray-main",
              },
              [
                _c("div", { staticClass: "flex-none justify-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 w-full uppercase text-center text-16 justify-center flex-none",
                    },
                    [_vm._v("\n                    ВОЗВРАТ\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 w-full text-left text-13 text-black justify-center flex-none lg:text-16",
                    },
                    [
                      _vm._v(
                        "\n                    Доступен бесплатный курьерский возврат по России с возмещением полной стоимости товара, исключая цену доставки. Мы рекомендуем оформить его в течение первых 7 дней с момента получения заказа,\n                    чтобы уложиться в установленные сроки. Передать возврат курьеру можно в течение 14 дней. "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n                    Возврат заказов с самовывозом возможно оформить только в том магазине, откуда забирали заказ."
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n                    Деньги будут возвращены на счет в течение 3-10 рабочих дней после получения и одобрения возврата.\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center mt-5" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "flex items-center",
                          attrs: { to: "/delivery-and-return?return=true" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "w-45 h-10 py-2 bg-black uppercase text-white text-16 focus:outline-none",
                            },
                            [
                              _vm._v(
                                "\n                            Подробнее\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-11 tracking-wide text-gray-main lg:text-13" },
        [
          _c(
            "button",
            {
              staticClass: "my-3 flex underline uppercase focus:outline-none",
              on: { click: _vm.deliveryInfo },
            },
            [_vm._v("\n            условия доставки\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "my-3 flex underline uppercase focus:outline-none",
              on: { click: _vm.returnInfo },
            },
            [_vm._v("\n            условия возврата\n        ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }