var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "block text-black" }, [
      _c(
        "div",
        { staticClass: "block w-full" },
        [
          _c("div", { staticClass: "flex justify-between" }, [
            _c(
              "div",
              {
                staticClass:
                  "uppercase text-13 text-black text-left flex-1 lg:text-16",
              },
              [_vm._v("\n                    Получатель\n                ")]
            ),
            _vm._v(" "),
            this.cart.data.attributes.hasUserData === true
              ? _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "button",
                    { staticClass: "lg:hidden", on: { click: _vm.closeForm } },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "8",
                            height: "5",
                            viewBox: "0 0 8 5",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M4 1L1 4",
                              stroke: "black",
                              "stroke-linecap": "square",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M7 3.99976L4 0.999756",
                              stroke: "black",
                              "stroke-linecap": "square",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "hidden lg:block",
                      on: { click: _vm.closeForm },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "11",
                            height: "7",
                            viewBox: "0 0 11 7",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M5.5 1.00049L1 5.50049",
                              stroke: "black",
                              "stroke-linecap": "square",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M10 5.50024L5.5 1.00024",
                              stroke: "black",
                              "stroke-linecap": "square",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          !_vm.authInfo.authStatus ||
          !_vm.authInfo?.relations.authUser?.data.attributes.isPhoneVerified
            ? _c("AuthByPhone", {
                on: { authByPhoneSuccessClosePopup: _vm.userSuccessfullyLogin },
              })
            : _c("div", { staticClass: "mt-10" }, [
                _c("div", { staticClass: "my-4 lg:my-2 flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-26 uppercase text-11 lg:text-13" },
                    [_vm._v("Имя")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full ml-7 h-9 mr-20 lg:h-11" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name",
                        },
                      ],
                      staticClass:
                        "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                      class: [
                        _vm.submitted && _vm.$v.name.$invalid
                          ? "border-red-400"
                          : "border-gray-lighter",
                      ],
                      attrs: { type: "text", name: "name" },
                      domProps: { value: _vm.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.name = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitted && !_vm.$v.name.required
                      ? _c(
                          "div",
                          { staticClass: "mb-2 text-11 text-red-light ml-1" },
                          [
                            _vm._v(
                              "\n                            Заполните имя\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "my-4 lg:my-2 flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-26 uppercase text-11 lg:text-13" },
                    [_vm._v("Фамилия")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full ml-7 h-9 mr-20 lg:h-11" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.surname,
                          expression: "surname",
                        },
                      ],
                      staticClass:
                        "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                      class: [
                        _vm.submitted && _vm.$v.surname.$invalid
                          ? "border-red-400"
                          : "border-gray-lighter",
                      ],
                      attrs: { type: "text", name: "surname" },
                      domProps: { value: _vm.surname },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.surname = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitted && !_vm.$v.surname.required
                      ? _c(
                          "div",
                          { staticClass: "text-red-light text-11 ml-1" },
                          [
                            _vm._v(
                              "Заполните\n                            фамилию\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "my-4 lg:my-2 flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-26 uppercase text-11 lg:text-13" },
                    [_vm._v("Телефон")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full ml-7 h-9 mr-20 lg:h-11" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass:
                        "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                      attrs: { readonly: "" },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.phone = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "my-4 lg:my-2 flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-26 uppercase text-11 lg:text-13" },
                    [_vm._v("E-mail")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full ml-7 h-9 mr-20 lg:h-11" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass:
                        "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                      class: [
                        _vm.submitted && _vm.$v.email.$invalid
                          ? "border-red-400"
                          : "border-gray-lighter",
                      ],
                      attrs: { type: "email", name: "email" },
                      domProps: { value: _vm.email },
                      on: {
                        focus: _vm.resetEmailError,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.submitted && _vm.$v.email.$invalid
                      ? _c(
                          "div",
                          { staticClass: "text-11 text-red-light ml-1" },
                          [
                            !_vm.$v.email.required
                              ? _c("span", [_vm._v("Заполните email")])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.email.email
                              ? _c("span", [_vm._v("Неверный email")])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.emailError?.length
                      ? _c(
                          "span",
                          { staticClass: "text-11 text-red-light ml-1" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  "Данный email уже используется другим аккаунтом. Попробуйте другой"
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "my-7" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-full uppercase h-9 py-1 px-5 border border-black bg-black text-white text-11 r focus:outline-none lg:text-11 lg:text-13",
                      on: {
                        click: function ($event) {
                          return _vm.sendUserInfo(
                            _vm.name,
                            _vm.surname,
                            _vm.email
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Далее\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }