<template>
    <div class="p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main text-base font-normal">
        <h1 class="uppercase mb-12 ">Инструкция: сарафан Camilla</h1>
        <vimeo-player class="w-full mx-auto lg:w-110" ref="player"
                      video-id="829410793"
                      @ready="onReady"
                      :options="options">
        </vimeo-player>
    </div>
</template>

<script>
export default {
    name: "CareGuideForProductsPage",
    data: () => ({
        options: {
            muted: true,
            autoplay: false,
            controls:true,
            responsive: true,
        },
        playerReady: true,
    }),
    methods: {
        onReady() {
            this.playerReady = true
        },
    }
}
</script>

<style scoped>

</style>
