import axios from "axios";


const state = {
    page: null,
    pageStatus: 'loading',
};

const getters = {
    page: state => {
        return state.page;
    },
    pageStatus: state => {
        return state.pageStatus;
    },
};

const actions = {
    fetchPage({commit, dispatch}, pageType) {
        commit('setPageStatus', 'loading')
        axios.get('/api/pages/' + pageType)
            .then(res => {
                commit('setPage', res.data)
                commit('setPageStatus', 'success')
            })
            .catch(error => {
                commit('setPageStatus', 'error')

            });
    },

};

const mutations = {

    setPage(state, page) {
        state.page = page;
    },
    setPageStatus(state, status) {
        state.pageStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
