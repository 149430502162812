<template>
  <div class="mt-7 mb-8">
    <div class="w-full -ml-3 flex items-left justify-start">
      <div class="flex grid grid-cols-10">
        <div v-for="color in computedObj">
          <div class="mt-2 ml-3">
            <router-link :to="{ name: 'Product', params: { category: color.links.meta.categorySlug, product: color.links.meta.productSlug }}">
              <div :class="color.data.product_id === product.data.product_id ? 'p-0.5 -mt-1 flex items-center justify-center border border-black rounded-full' : 'flex items-center justify-center rounded-full'">
                <div v-if="color.data.attributes.color.data.attributes.code" class="w-4 h-4 rounded-full"
                     :style="'background-color: ' + color.data.attributes.color.data.attributes.code + ';'">
                </div>
                <div v-else-if="color.data.attributes.color.data.attributes.image" class="w-4 h-4 rounded-full overflow-hidden">
                  <img :src="color.data.attributes.color.links.color_image" alt="product color image" class="object-cover">
                </div>
                <div v-else class="w-4 h-4 border border-border-gray-light rounded-full overflow-hidden"></div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="text-11 font-normal text-black">
        {{ product.data.attributes.color.data.attributes.title }}
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "ProductColorInfoBlock",
  props: {
    product: null
  },
  computed: {
    computedObj() {
      return this.product.data.attributes.color_related_products.data.sort();
    },
  },
}

</script>

<style scoped>
</style>
