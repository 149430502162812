<template>
    <div>
        <div class="flex-none">
            <div class="swiper-container " ref="pressBlockSwiper">
                <div class="mt-12 mb-4 w-full text-black text-lg font-medium tracking-widest text-left ml-3 lg:text-2xl lg:mt-15 lg:mb-8">{{block.data.attributes.title}}</div>
                <div class="swiper-wrapper">
                    <div class="swiper-slide px-0.5 items-center justify-center mb-15"
                         v-for="image in block.data.attributes.press_block_image.data"
                         :key=image.data.press_block_image_id>
                          <div>
                              <a :href="image.data.attributes.link">
                                  <div>
                                      <img :src=image.data.attributes.image.data.attributes.path alt="">
                                  </div>

                              </a>
                          </div>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {Pagination, Swiper} from "swiper";
import {mapGetters} from "vuex";

Swiper.use([Pagination]);

export default {
    name: "PressSwipableBlock",

    props:{
        block: null
    },
    data: () => ({
        swiper: null,

    }),
    mounted() {

        this.swiper = new Swiper(this.$refs.pressBlockSwiper, this.swiperSettings);

    },
    computed: {
        swiperSettings() {

            return {

                slidesPerView: 2 ,
                grabCursor: true,
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 4,
                    },
                    // when window width is >= 1280px
                    1280: {
                        slidesPerView: 5,
                    }
                }
            }
        },

    },
}
</script>

<style scoped>

</style>
