var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      {},
      _vm._l(
        _vm.cart.data.relationships.additionalOrderLines.data,
        function (item) {
          return _c(
            "div",
            {
              key:
                "cart_item_" +
                item.relationships.product.data.product_id +
                "size" +
                item.relationships.size_id,
              staticClass:
                "py-4 border-t border-gray-lighter flex justify-between",
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-21 h-full flex-none overflow-hidden lg:w-26",
                  },
                  [
                    _c("img", {
                      staticClass: "w-full object-cover",
                      attrs: {
                        src: item.relationships.product.data.attributes.images
                          .data[0].data.attributes.path,
                        alt: item.relationships.product.data.attributes.title,
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-4 uppercase text-11 text-black font-normal lg:w-95 lg:ml-5 lg:text-16",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            item.relationships.product.data.attributes.title
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-4 items-center text-11 text-gray-light font-normal lg:ml-5 lg:text-16",
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n                            Артикул: " +
                            _vm._s(
                              item.relationships.product.data.attributes
                                .article_number
                            ) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex mt-0.5 items-center" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-3.5 h-3.5 mr-1.5 border border-black rounded rounded-full flex items-center justify-center",
                          },
                          [
                            item.relationships.product.data.attributes.color
                              .data.attributes.code
                              ? _c("div", {
                                  staticClass:
                                    "w-2.5 h-2.5 rounded rounded-full border-border-gray-light",
                                  style:
                                    "background-color: " +
                                    item.relationships.product.data.attributes
                                      .color.data.attributes.code +
                                    ";",
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {}, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                item.relationships.product.data.attributes.color
                                  .data.attributes.title
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-4 pt-4 uppercase text-11 text-black font-normal lg:text-16 lg:ml-5",
                    },
                    _vm._l(
                      item.relationships.product.data.attributes.sizes.data,
                      function (size) {
                        return _c(
                          "div",
                          { key: size.id, staticClass: "bg-white" },
                          [
                            size.data.size_id === item.attributes.sizeId
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(size.data.attributes.title) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid justify-items-end" }, [
                _c(
                  "div",
                  { staticClass: "grid justify-items-end content-end" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mr-2 flex text-11 text-gray-light text-right lg:text-16",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.attributes.userQuantity) +
                            " шт.\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mr-2 flex text-11 text-black text-right lg:text-16",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm._f("formatNumber")(0)) +
                              " ₽\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }