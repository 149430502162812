<template>
<div>
    <div class="flex flex-col items-baseline">
        <div class="w-16 mr-5 text-11 lg:w-17 lg:mt-6 lg:text-13">
            Сертификат
        </div>
        <div class="w-full">
            <div v-if="this.cart.data.relationships.cartGiftCertificate"
                 class="mt-5">
                <div class="flex items-center ml-2 w-full" >
                    <div class="w-full text-11 text-gray-light lg:w-2/3 lg:text-13">
                        <input readonly="readonly" class="appearance-none"
                               v-model="this.cart.data.relationships.cartGiftCertificate.data.attributes.code">
                    </div>
                    <div>
                        <div class="w-20 pl-7 lg:w-25 focus:outline-none" @click="deleteGiftCertificate">
                            <button class="lg:hidden">
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999977 8.07107L8.07104 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M0.999978 1.00022L8.07104 8.07129" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </button>
                            <button class="hidden lg:block">
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 10L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00024L10 10.0002" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <span v-if="this.cart.data.relationships.cartGiftCertificate.data.attributes.errorMessage"
                      class="flex items-center pl-2 text-11 text-red-500 lg:text-13">
                            {{ this.cart.data.relationships.cartGiftCertificate.data.attributes.errorMessage }}
                </span>
                <span v-if="this.cart.data.relationships.cartGiftCertificate.data.attributes.errorMessage === null"
                      class="flex items-center pl-2 text-11 text-green-light lg:text-13">
                            Сертификат успешно применен
                </span>
            </div>
            <div v-else class="w-full">
                <div class="flex w-full">
                    <div class="flex items-center w-full lg:w-2/3 lg:h-10">
                        <input class="w-full h-6 px-2 appearance-none rounded-none text-11 text-gray-light border border-gray-lighter focus:outline-none focus:border-black lg:h-8 lg:text-13"
                               v-model="giftCertificateModel"
                               type="text"
                               name="promoCode"
                        >
                    </div>
                    <div class="flex items-center pl-2 lg:h-10">
                        <button class="w-25 lg:w-30 h-6 py-1 px-2 bg-transparent text-black custom-border text-11 r focus:outline-none lg:h-8 lg:text-13" @click="addGiftCertificate">
                            Применить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "CartGiftCertificateBlock",
    data: () =>({
        giftCertificateModel: '',
    }),
    props: {
        cart:null,
        isGiftCertificateBlockFromCartCheckout:false
    },
    methods:{
        ...mapActions({
            addGiftCertificateInCartCheckoutVuex:'addGiftCertificateInCartCheckout',
            addGiftCertificateVuex:'addGiftCertificate',
            deleteGiftCertificateVuex:'deleteGiftCertificate',
            deleteGiftCertificateInCartCheckoutVuex:'deleteGiftCertificateInCartCheckout',
            getPaymentTypesVuex:'getPaymentTypes',
        }),
        addGiftCertificate() {
            if(this.isGiftCertificateBlockFromCartCheckout === true) {
                this.addGiftCertificateInCartCheckoutVuex(this.giftCertificateModel)
            } else{
                this.addGiftCertificateVuex(this.giftCertificateModel)
            }
        },
        deleteGiftCertificate() {
            if(this.isGiftCertificateBlockFromCartCheckout === true) {
                this.deleteGiftCertificateInCartCheckoutVuex(this.giftCertificateModel)
            } else{
                this.deleteGiftCertificateVuex(this.giftCertificateModel)
            }
        }
    }
}
</script>

<style lang="css">
.custom-border {
    border: 1px solid black;
}
</style>
