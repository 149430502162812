<template>
    <div id="container">
        <div>
            <input class="form-control col-sm-4" type="text" v-model="searchGoods" placeholder="Search">
            <div v-if="filtered.length">
                <ul class="col-sm-4 list-group" v-if="goodsList.length">
                    <li :class="{'active': active.id === item.id}" v-for="item in filtered" class="list-group-item list-group-item-action" @click="addGoods(item.id)">{{item.name}}</li>
                </ul>
            </div>
            <div>Products: {{JSON.stringify(products)}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "KeyboardSelection",
    data() {
        return {
            products: [],
            goodsList: [{
                id: '1',
                name: 'Cheese'
            },
                {
                    id: '2',
                    name: 'Meat'
                },
                {
                    id: '3',
                    name: 'Fruits'
                },
                {
                    id: '4',
                    name: 'Vegetables '
                },
                {
                    id: '5',
                    name: 'Sweets'
                },
                {
                    id: '6',
                    name: 'Furniture'
                },
                {
                    id: '7',
                    name: 'Fish'
                },
                {
                    id: '8',
                    name: 'Lamb'
                },
                {
                    id: '9',
                    name: 'Utensils'
                }
            ],
            filtered: [],
            active: {
                id: -1,
                index: 0
            },
            searchGoods: ''
        }
    },
    watch: {
        searchGoods: function() {
            if (!this.searchGoods) {
                this.filtered = [];
                return;
            }

            const normalized = this.searchGoods.toLowerCase();
            this.filtered = this.goodsList.filter(({name}) => name.toLowerCase().includes(normalized))

            if(!this.filtered.length) {
                return;
            }

            const id = this.filtered[this.active.index].id;
            this.$set(this.active, 'id', id);
        }
    },
    created() {
        window.addEventListener('keydown', this.onKey)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onKey)
    },
    methods: {
        onKey(event) {
            const key = event.key;

            const map = {
                Enter: () => {
                    this.addGoods(this.active.id);
                },
                ArrowDown: () => {
                    const {active, filtered} = this;
                    const index = (active.index + 1) % this.filtered.length;
                    const id = filtered[index].id;

                    this.active = {
                        index,
                        id
                    };
                },
                ArrowUp: () => {
                    const {active, filtered} = this;
                    let index = active.index - 1;
                    index = index < 0 ? filtered.length - 1 : index;
                    const id = filtered[index].id;

                    this.active = {
                        index,
                        id
                    };
                }
            }

            const func = map[key];
            if (func) {
                func();
            }
        },
        addGoods(id) {
            let goods = this.goodsList.find(item => item.id == id);
            this.products.push({
                id: id,
                goods_name: goods.name
            });
            this.searchGoods = '';
            this.filtered = [];
        },
    }
}
</script>

<style scoped>
.active {
    border: 1px solid black;
}

</style>
