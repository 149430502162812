<template>
    <div class="w-full px-6 lg:px-0 lg:w-256 lg:mx-auto">
        <div v-if="$route.params.status==='success'">
            <h2 class="my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center">
                Спасибо за ваш заказ!</h2>
            <div>
                <div>Заказ
                    <span v-if="order != null"> {{ order.data.order_id }} </span>
                    <span
                        v-if="giftCertificateOrder != null"> {{ giftCertificateOrder.data.gift_certificate_order_id }} </span>
                    успешно оформлен.
                </div>
                <div>Спасибо!</div>
            </div>
        </div>
        <div v-else-if="$route.params.status === 'error'">
            <h2 class="my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center">
                Ошибка</h2>
            <div>
                <div>К сожалению, при оплате заказа
                    <span v-if="order !== null"> {{ order.data.order_id }} </span>
                    <span
                        v-if="giftCertificateOrder !== null"> {{ giftCertificateOrder.data.gift_certificate_order_id }} </span>
                    произошла ошибка.
                </div>
                <div>Вы можете продолжить оформление заказа из
                    <router-link class="underline" to="/account">личного кабинета</router-link>
                    ,
                    либо
                    <router-link class="underline" to="/info/contacts">связаться с нами</router-link>
                    для уточнения деталей
                </div>
                <div>Спасибо!</div>
            </div>
        </div>
        <div v-else-if="$route.params.status==='on_demand'">
            <h2 class="my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center">
                Спасибо за ваш заказ!</h2>
            <div>
                <div>Ваш заказ
                    <span v-if="order !== null"> {{ order.data.order_id }} </span>
                    <span
                        v-if="giftCertificateOrder !== null"> {{ giftCertificateOrder.data.gift_certificate_order_id }} </span>
                    оформлен.
                </div>
                <div>При изменении статуса заказа, мы отправим email на вашу почту.</div>
                <div>Если у вас остались вопросы свяжитесь с нами любым удобным способом:</div>
                <a href="tel:+78003010424">По телефону +7 800 301 04 24</a><br>
                <a href="https://wa.me/+78003010424">В WhatsApp: +7 800 301 04 24</a><br>
                <a href="mailto:hello@presentandsimple.com">По почте hello@presentandsimple.com</a>
            </div>
        </div>
        <div v-else-if="$route.params.status==='rejected'">
            <h2 class="my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center">
                Платеж отклонен</h2>
            <div>
                <div>К сожалению, мы вынуждены отменить заказ
                    <span v-if="order !== null"> {{ order.data.order_id }} </span> — сервис «Долями» отказывает в его
                    оплате.
                    <div class="mt-2">
                        Мы не знаем причину отказа, так как все заявки обрабатываются
                        на стороне сервиса. <br>Обратитесь в службу поддержки покупателей <a href="https://dolyame.ru"
                                                                                             class="underline">«Долями»</a>
                        на сайте сервиса или в мобильном приложении.
                        <div class="my-2">Вы можете оформить покупку заново и оплатить ее любыми другими доступными
                            способами.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            Произошла какая-то ошибка...
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Payment",
    computed: {
        ...mapGetters({
            order: 'order',
            giftCertificateOrder: 'giftCertificateOrder'
        })
    },
    methods: {
        ...mapActions({
            flushCart: 'flushCart',
            flushOrder: 'flushOrder',
            flushPreorder: 'flushPreorder',
            flushGiftCertificateOrder: 'flushGiftCertificateOrder'
        })
    },
    mounted() {
        if (this.$route.params.status === 'error') {
            this.flushCart();
            this.flushOrder();
            this.flushPreorder();
        } else if ((this.$route.params.status === 'success' || this.$route.params.status === 'on_demand') && this.order != null) {
            this.flushCart();
            this.flushOrder();
            this.flushPreorder();

            let ga_transacton_products = [];
            let facebook_order_items = [];

            this.order.data.attributes.lines.data.forEach(
                (element) => {
                    ga_transacton_products.push({
                        sku: element.data.attributes.product.data.attributes.article_number + '' + element.data.attributes.size.data.size_id,
                        name: element.data.attributes.product.data.attributes.title,
                        category: element.data.attributes.product.data.attributes.category_title,
                        price: element.data.attributes.amount,
                        quantity: element.data.attributes.quantity
                    })
                    facebook_order_items.push(element.data.attributes.product.data.attributes.article_number + '' + element.data.attributes.size.data.size_id)
                }
            )

            this.$gtm.trackEvent({
                event: 'transaction',
                transactionId: this.order.data.order_id,
                transactionAffiliation: 'Present&Simple',
                transactionTotal: this.order.data.attributes.amount,
                transactionShipping: this.order.data.attributes.delivery.data.attributes.amount,
                transactionProducts: ga_transacton_products,
                noninteraction: false,
                orderNumberOfItems: this.order.data.attributes.lines.data.length,
                orderItems: facebook_order_items,
                orderAmount: this.order.data.attributes.amount,
            });

            this.$gtm.trackEvent({
                event: "cartFlush",
                noninteraction: false,
            });

            //Расширенная электронная торговля
            this.$gtm.trackEvent({
                event: 'flushVariables',
                ecommerce: null,
            });
            let products = []

            for (let item of this.order.data.attributes.lines.data) {
                products.push({
                    id: item.data.attributes.product.data.attributes.article_number,
                    name: item.data.attributes.product.data.attributes.title,
                    price: item.data.attributes.amount,
                    category: item.data.attributes.product.data.attributes.category_title,
                    quantity: item.data.attributes.quantity,
                    variant: item.data.attributes.product.data.attributes.article_number + '' + item.data.attributes.size.data.size_id,

                })
            }

            this.$gtm.trackEvent({
                event: 'purchase', // Event type [default = 'interaction'] (Optional)
                pageType: 'purchase',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: this.order.data.order_id,
                            affiliation: 'Present&Simple',
                            revenue: this.order.data.attributes.amount,
                            shipping: this.order.data.attributes.delivery.data.attributes.amount,
                            coupon: null
                        },
                        products: products,
                    }
                },
            });
        }
    }
}
</script>

<style scoped>

</style>
