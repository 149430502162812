<template>
    <div class="bg-transparent">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none repeat scroll 0% 0%; display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(71,50)">
                <g transform="rotate(0)">
                    <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="1">
                        <animateTransform attributeName="transform" type="scale" begin="-0.875s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.875s"></animate>
                    </circle>
                </g>
            </g><g transform="translate(64.8492424049175,64.8492424049175)">
            <g transform="rotate(45)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.875">
                    <animateTransform attributeName="transform" type="scale" begin="-0.75s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.75s"></animate>
                </circle>
            </g>
        </g><g transform="translate(50,71)">
            <g transform="rotate(90)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.75">
                    <animateTransform attributeName="transform" type="scale" begin="-0.625s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.625s"></animate>
                </circle>
            </g>
        </g><g transform="translate(35.1507575950825,64.8492424049175)">
            <g transform="rotate(135)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.625">
                    <animateTransform attributeName="transform" type="scale" begin="-0.5s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.5s"></animate>
                </circle>
            </g>
        </g><g transform="translate(29,50)">
            <g transform="rotate(180)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.5">
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.375s"></animate>
                </circle>
            </g>
        </g><g transform="translate(35.150757595082496,35.1507575950825)">
            <g transform="rotate(225)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.375">
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.25s"></animate>
                </circle>
            </g>
        </g><g transform="translate(49.99999999999999,29)">
            <g transform="rotate(270)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.25">
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.125s"></animate>
                </circle>
            </g>
        </g><g transform="translate(64.84924240491749,35.150757595082496)">
            <g transform="rotate(315)">
                <circle cx="0" cy="0" r="4" fill="#000000" fill-opacity="0.125">
                    <animateTransform attributeName="transform" type="scale" begin="0s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="0s"></animate>
                </circle>
            </g>
        </g>
            <!-- [ldio] generated by https://loading.io/ --></svg>
    </div>
</template>

<script>
export default {
name: "Loading"
}
</script>

<style scoped>

</style>
