<template>
    <div>

        <div v-if="productsStatus=='success'">
            <div class="my-12 w-full text-gray-main text-2xl font-normal tracking-widest text-center uppercase">Все</div>
            <div>
                <NonSwipableCategoryBlock  :category="products.data" />
            </div>
        </div>
        <Loading v-else class="mx-auto"/>




    </div>
</template>

<script>
import {mapGetters} from "vuex";
import NonSwipableCategoryBlock from "../components/NonSwipableCategoryBlock";
import Loading from "../components/Loading";

export default {
name: "AllProducts",
    components: {
        NonSwipableCategoryBlock, Loading
    }    ,
    mounted () {
        this.$store.dispatch('fetchProducts');
    },
    computed: {
        ...mapGetters({
            products: 'products',
            productsStatus: 'productsStatus',
        }),
    },
}
</script>

<style scoped>

</style>
