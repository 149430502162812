var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "flex-none" }, [
      _c("div", { ref: "pressBlockSwiper", staticClass: "swiper-container" }, [
        _c(
          "div",
          {
            staticClass:
              "mt-12 mb-4 w-full text-black text-lg font-medium tracking-widest text-left ml-3 lg:text-2xl lg:mt-15 lg:mb-8",
          },
          [_vm._v(_vm._s(_vm.block.data.attributes.title))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(
            _vm.block.data.attributes.press_block_image.data,
            function (image) {
              return _c(
                "div",
                {
                  key: image.data.press_block_image_id,
                  staticClass:
                    "swiper-slide px-0.5 items-center justify-center mb-15",
                },
                [
                  _c("div", [
                    _c("a", { attrs: { href: image.data.attributes.link } }, [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: image.data.attributes.image.data.attributes
                              .path,
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ]
              )
            }
          ),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }