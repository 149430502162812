var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-hidden scroll-down" }, [
    _vm.product.data.attributes.active === 0
      ? _c("div", [_c("ProductNotActiveCard")], 1)
      : _c("div", { staticClass: "overflow-hidden lg:flex" }, [
          _c(
            "div",
            { staticClass: "lg:w-1/2" },
            [_c("ProductImagesBlock", { attrs: { product: _vm.product } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full px-5 lg:w-1/2 lg:px-15 font-normal" },
            [
              _c(
                "div",
                { staticClass: "w-full lg:w-98 lg:mx-auto" },
                [
                  _c("ProductInfoBlock", { attrs: { product: _vm.product } }),
                  _vm._v(" "),
                  !_vm.product.data.attributes.not_for_sale
                    ? _c(
                        "div",
                        [
                          _c("ProductMainBlock", {
                            attrs: { product: _vm.product },
                          }),
                          _vm._v(" "),
                          _c("ProductSizeTableBlock", {
                            attrs: { product: _vm.product },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "my-4 w-full" }, [
                    _c(
                      "span",
                      { staticClass: "text-black text-left text-14" },
                      [
                        _vm._v(
                          "\n                    Артикул: " +
                            _vm._s(_vm.product.data.attributes.article_number) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.product.data.attributes.not_for_sale
                    ? _c(
                        "div",
                        { staticClass: "mb-7 mt-3" },
                        [
                          _c("ProductAvailabilityInRetailStocks", {
                            attrs: { product: _vm.product },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ProductDetailsBlock", {
                    attrs: { product: _vm.product },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }