var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mx-1 px-1 py-0 bg-white text-gray-light text-xs lowercase lg:text-base",
    },
    [_vm._v("\n    " + _vm._s(_vm.tag.data.attributes.title) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }