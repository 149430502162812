var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isShowInputCode
      ? _c(
          "form",
          {
            staticClass: "flex flex-col gap-6 items-center mt-8",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.sendPhone.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "text-19 text-center font-normal" }, [
              _vm._v("\n            Сменить номер телефона\n        "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "max-w-md w-full" },
              [
                _c("vue-tel-input", {
                  attrs: {
                    inputOptions: { placeholder: "Номер телефона" },
                    preferredCountries: ["ru", "by", "kz"],
                    defaultCountry: "ru",
                    mode: "international",
                    required: true,
                    styleClasses: _vm.styleClasses,
                  },
                  model: {
                    value: _vm.phone,
                    callback: function ($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone",
                  },
                }),
                _vm._v(" "),
                _vm.$v.phone.$invalid && _vm.$v.phone.$dirty
                  ? _c("p", { staticClass: "text-12 text-red-light-2 mt-2" }, [
                      _vm._v(
                        "\n                Пожалуйста введите корректный номер телефона\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorMessage
                  ? _c("p", { staticClass: "text-12 text-red-light-2 mt-2" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.errorMessage) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "max-w-max w-full pl-8 pr-8 h-10 text-white bg-blue-500 font-normal",
                attrs: { type: "submit" },
              },
              [_vm._v("\n            Изменить номер\n        ")]
            ),
          ]
        )
      : _c(
          "form",
          { staticClass: "flex flex-col gap-6 items-center mt-8" },
          [
            _c("InputCode", {
              attrs: {
                "error-message": _vm.errorMessage,
                "restart-timer": _vm.restartTimer,
              },
              on: {
                endTime: (val) => ((_vm.isEndTimeCode = val), (_vm.code = "")),
              },
              model: {
                value: _vm.code,
                callback: function ($$v) {
                  _vm.code = $$v
                },
                expression: "code",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "max-w-max w-full pl-8 pr-8 h-10 text-white bg-blue-500 font-normal",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.isEndTimeCode ? _vm.getCode() : _vm.sendCode()
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.isEndTimeCode ? "Получить код" : "Отправить код"
                    ) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }