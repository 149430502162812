var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "container" } }, [
    _c("div", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchGoods,
            expression: "searchGoods",
          },
        ],
        staticClass: "form-control col-sm-4",
        attrs: { type: "text", placeholder: "Search" },
        domProps: { value: _vm.searchGoods },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchGoods = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.filtered.length
        ? _c("div", [
            _vm.goodsList.length
              ? _c(
                  "ul",
                  { staticClass: "col-sm-4 list-group" },
                  _vm._l(_vm.filtered, function (item) {
                    return _c(
                      "li",
                      {
                        staticClass: "list-group-item list-group-item-action",
                        class: { active: _vm.active.id === item.id },
                        on: {
                          click: function ($event) {
                            return _vm.addGoods(item.id)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [_vm._v("Products: " + _vm._s(JSON.stringify(_vm.products)))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }