<template>
<div  v-if="announcementBarStatus == 'success'">
    <div v-if="announcementBar.data.attributes.active == true" :style="'background-color: ' + announcementBar.data.attributes.bg_color + ';'">
        <div class="flex py-3 px-4 text-center text-12 uppercase font-normal font-normal tracking-widest items-center justify-center  ">
            <router-link :to="announcementBar.data.attributes.link"
                         :style="'color: '+ announcementBar.data.attributes.text_color + ';'">{{announcementBar.data.attributes.text}}</router-link>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: "AnnouncementBar",
    computed: {
        ...mapGetters({
            announcementBar: 'announcementBar',
            announcementBarStatus: 'announcementBarStatus',
        }),

    },
    methods: {
        ...mapActions({
            fetchAnnouncementBar: 'fetchAnnouncementBar',
        }),

    },
    mounted () {
        this.$store.dispatch('fetchAnnouncementBar','news');
    },
}
</script>

<style scoped>

</style>
