<template>
    <div class="w-full">
       <div>
           <ProductCard v-if="productStatus === 'success'"
           :product="product"/>
           <Loading v-else/>
       </div>
        <!-- Вам может понравится -->
        <div v-if="lookalikeProductsStatus === 'success' && computedActiveProductsForLookalike.length > 0 && product.data.attributes.active === 1">
            <SwipableCategoryBlock  :productsForBlock="computedActiveProductsForLookalike" :title="'Носите вместе'"/>
        </div>
        <div data-popmechanic-embed="39794"></div>
        <div data-popmechanic-embed="80591"></div>
    </div>
</template>

<script>

import ProductCard from "../components/ProductCard";
import {mapGetters} from "vuex";
import SwipableCategoryBlock from "../components/SwipableCategoryBlock";
import Loading from "../components/Loading";

export default {

    name: "Product",
    components: {
        Loading,
        SwipableCategoryBlock, ProductCard
    },
    mounted () {
        this.$store.dispatch('fetchProduct', this.$route.params.product)
        this.$store.dispatch('fetchLookalikeProducts', this.$route.params.product)
    },
    computed: {
        ...mapGetters({
            product: 'product',
            productStatus: 'productStatus',
            lookalikeProducts: 'lookalikeProducts',
            lookalikeProductsStatus: 'lookalikeProductsStatus',
            subscribeItemStatus:'subscribeItemStatus',
        }),
        computedActiveProductsForLookalike() {
           let activeProducts = [];
            for(let i = 0; i < this.lookalikeProducts.data.length; i++){
                if((this.lookalikeProducts.data[i].data.attributes.active_stock_balance
                    || this.lookalikeProducts.data[i].data.attributes.coming_soon
                    || this.lookalikeProducts.data[i].data.attributes.hasPreorderStockBalance)
                    && this.lookalikeProducts.data[i].data.attributes.active === 1 ){
                    activeProducts.push(this.lookalikeProducts.data[i])
                }
            }
            return activeProducts
        },
    },
    watch: {
        $route () {
            this.$store.dispatch('fetchProduct', this.$route.params.product)
            this.$store.dispatch('fetchLookalikeProducts', this.$route.params.product)
        }
    }
}
</script>

<style scoped>



</style>
