<template>
    <div class="mt-10 w-full h-full lg:w-256 lg:mx-auto justify-center">
        <div>
            <div class="mx-auto flex justify-center uppercase text-16 font-light lg:text-24">
                <div @click="showDeliveryInfo"
                     :class="deliveryInfo ? 'text-black underlineWithPadding cursor-pointer' : 'text-gray-main cursor-pointer'">
                    Доставка и оплата
                </div>
                <div class="ml-10 lg:ml-15"
                     :class="returnInfo ? 'text-black underlineWithPadding cursor-pointer' : 'text-gray-main cursor-pointer'"
                     @click="showReturnInfo">
                    Возврат
                </div>
            </div>
            <div v-if="deliveryInfo">
                <accordion class="w-full mt-10 px-5 mx-auto lg:w-163">
                    <accordion-item class="">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Экспресс-доставка</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <ul class="list-outside ml-4 mt-2 text-gray-light">
                                    <li> При оформлении заказа с 10:00 до 17:00 доставка произойдет в течение 4
                                        часов c момента оплаты
                                    </li>
                                    <li>При оформлении заказа после 17:00 — доставка на следующий рабочий день</li>
                                    <li> При оформлении заказа с 00:00 до 10:00 — доставка до 14:00</li>
                                </ul>
                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Банковской картой на сайте</li>
                                    <li> Долями</li>
                                </ul>
                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    790 рублей
                                </div>
                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li>Только в рабочие дни</li>
                                    <li> По Москве внутри МКАД</li>
                                    <li> Для экспресс-доставки недоступны примерка и частичный выкуп</li>
                                    <li> Для подтверждения заказа и временных интервалов вам позвонит сотрудник
                                        клиентского сервиса
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Курьерская доставка по Москве курьером Present & Simple или СДЭК</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    1-3 дня
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> На сайте (картой, Samsung Pay)</li>
                                    <li> Наложенным платежом при получении (с примеркой,не распространяется на заказы
                                        товаров по
                                        предоплате*)
                                    </li>
                                    <li> Долями</li>
                                </ul>
                                <div class="mt-2 text-14 text-gray-light">
                                    * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,
                                    а также избранные модели из основной коллекции можно приобрести только по
                                    предоплате.
                                    Подробности уточняйте по телефону: <a href="tel:+78003010424"
                                                                          class="underlineWithPadding"> +7 (800)
                                    301-04-24 </a>
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Бесплатно для предоплаченных заказов от 30 000 рублей</li>
                                    <li> 550 рублей по Москве</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Примерка
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> У вас есть 15 минут на примерку и выбор</li>
                                    <li> Если вещь не подошла, верните ее курьеру</li>
                                    <li> Не более 5 изделий в заказах</li>
                                    <li> Стоимость доставки оплачивается вне зависимости от покупки</li>
                                    <li> Подготовьте паспорт для получения предоплаченного заказа</li>
                                </ul>
                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Отправка курьером СДЭК только в будние дни</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Курьерская доставка по Санкт-Петербургу (СДЭК)</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    2-5 дней
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> На сайте (картой, Samsung Pay)</li>
                                    <li> Наложенным платежом при получении (с примеркой, не распространяется на заказы
                                        товаров по
                                        предоплате*)
                                    </li>
                                    <li> Долями</li>
                                </ul>
                                <div class="mt-2 text-14 text-gray-light">
                                    * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,
                                    а также избранные модели из основной коллекции можно приобрести только по
                                    предоплате.
                                    Подробности уточняйте по телефону: <a href="tel:+78003010424"
                                                                          class="underlineWithPadding"> +7 (800)
                                    301-04-24 </a>
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Бесплатно для предоплаченных заказов от 30 000 рублей</li>
                                    <li> 550 рублей по Санкт-Петербургу</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Примерка
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> У вас есть 15 минут на примерку и выбор</li>
                                    <li> Если вещь не подошла, верните ее курьеру</li>
                                    <li> Не более 5 изделий в заказах</li>
                                    <li> Стоимость доставки оплачивается вне зависимости от покупки</li>
                                </ul>
                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Доставка осуществляется курьерской службой СДЭК</li>
                                    <li> Подготовьте паспорт для получения предоплаченного заказа</li>
                                    <li> Отправка только в будние дни</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Курьерская доставка по России (СДЭК)</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    2-8 дней
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>

                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> На сайте (картой, Samsung Pay)</li>
                                    <li> Наложенным платежом при получении (с примеркой, не распространяется на заказы
                                        товаров по
                                        предоплате*)
                                    </li>
                                    <li> Долями</li>
                                </ul>
                                <div class="mt-2 text-14 text-gray-light">
                                    * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,
                                    а также избранные модели из основной коллекции можно приобрести только по
                                    предоплате.
                                    Подробности уточняйте по телефону: <a href="tel:+78003010424"
                                                                          class="underlineWithPadding"> +7 (800)
                                    301-04-24 </a>
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Бесплатно для предоплаченных заказов от 30 000 рублей</li>
                                    <li> 690 рублей по России</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Примерка
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> У вас есть 15 минут на примерку и выбор</li>
                                    <li> Если вещь не подошла, верните ее курьеру</li>
                                    <li> Не более 5 изделий в заказах</li>
                                    <li> Стоимость доставки оплачивается вне зависимости от покупки</li>
                                </ul>
                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Доставка осуществляется курьерской службой СДЭК</li>
                                    <li> Подготовьте паспорт для получения предоплаченного заказа</li>
                                    <li> Отправка только в будние дни</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Ускоренная курьерская доставка по России (СДЭК-ЭКСПРЕСС)</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    1-7 дней по России в зависимости от выбранного региона
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li>На сайте (картой, Samsung Pay)</li>
                                    <li>Наложенным платежом при получении (с примеркой, не распространяется на заказы
                                        товаров по
                                        предоплате*)
                                    </li>
                                    <li>Долями</li>
                                </ul>
                                <div class="mt-2 text-14 text-gray-light">
                                    * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,
                                    а также избранные модели из основной коллекции можно приобрести только по
                                    предоплате.
                                    Подробности уточняйте по телефону: <a href="tel:+78003010424"
                                                                          class="underlineWithPadding"> +7 (800)
                                    301-04-24 </a>
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    790 рублей по России
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Примерка
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li>У вас есть 15 минут на примерку и выбор</li>
                                    <li>Если вещь не подошла, верните ее курьеру</li>
                                    <li>Доступна для заказов по России</li>
                                    <li>Не более 5 изделий в заказах</li>
                                    <li>Стоимость доставки оплачивается вне зависимости от покупки</li>
                                </ul>
                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li>Доставка осуществляется курьерской службой СДЭК</li>
                                    <li>Подготовьте паспорт для получения предоплаченного заказа</li>
                                    <li> Отправка только в будние дни</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Самовывоз Boxberry</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    2-10 дней по России в зависимости от выбранного региона
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Банковской картой на сайте</li>
                                    <li> Долями</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    190 рублей
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Срок хранения заказа — 7 дней с возможностью продления на 7 дней (доступно один
                                        раз для заказа)
                                    </li>
                                    <li> Если вы откажетесь от заказа, стоимость доставки не возвращается</li>
                                    <li> Отправка только в будние дни</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Самовывоз из магазина в Москве</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    1-3 дня
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Банковской картой на сайте</li>
                                    <li> Долями</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    Бесплатно
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Срок хранения предоплаченного заказа — 7 дней</li>
                                    <li> Продлить срок хранения можно один раз по истечении 7 дней</li>
                                    <li> Самовывоз доступен только из магазина по адресу Благовещенский переулок, 1А
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Самовывоз из магазина в Санкт-Петербурге</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    2-4 дня
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Банковской картой на сайте</li>
                                    <li> Долями</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    Бесплатно
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>

                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Срок хранения предоплаченного заказа — 7 дней</li>
                                    <li> Продлить срок хранения можно один раз по истечении 7 дней</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Самовывоз из магазина в Екатеринбурге</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    5-7 дней
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Банковской картой на сайте</li>
                                    <li> Долями</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    Бесплатно
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li> Срок хранения предоплаченного заказа — 7 дней</li>
                                    <li> Продлить срок хранения можно один раз по истечении 7 дней</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Международная доставка</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template slot="accordion-content">
                            <div class="my-7 pr-11 text-16">
                                <div class="uppercase text-gray-main">
                                    Сроки
                                </div>
                                <div class="mt-2 text-gray-light">
                                    5-12 дней
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Оплата
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li>На сайте (картой, Samsung Pay)</li>
                                    <li>Долями</li>
                                </ul>

                                <div class="mt-7 uppercase text-gray-main">
                                    Стоимость
                                </div>
                                <div class="mt-2 text-gray-light">
                                    990 рублей
                                </div>

                                <div class="mt-7 uppercase text-gray-main">
                                    Детали
                                </div>
                                <ul class="list-outside mt-2 ml-4 text-gray-light">
                                    <li>Доставка осуществляется курьерской службой СДЭК</li>
                                    <li>Подготовьте паспорт для получения предоплаченного заказа</li>
                                    <li>Отправка только в будние дни</li>
                                    <li>Present & Simple осуществляет доставку в Казахстан, Киргизию, Армению и Беларусь</li>
                                </ul>
                            </div>
                        </template>
                    </accordion-item>
                </accordion>
            </div>
            <div v-else>
                <div>
                    <accordion class="w-full mt-10 px-5 mx-auto lg:w-163">
                        <accordion-item class="">
                            <template v-slot:accordion-trigger="slotProps">
                                <div class="flex items-center justify-between text-16">
                                    <div class="cursor-pointer">
                                        <div>Основные правила возврата</div>
                                    </div>
                                    <div class="ml-11">
                                        <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                             width="8" height="5" viewBox="0 0 8 5" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                            <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                            <template slot="accordion-content">
                                <div class="mt-4 mb-5 pr-10 text-16">
                                    <ul class="list-outside mt-2 text-gray-light ml-3 lg:ml-4">
                                        <li>Для наших клиентов доступен бесплатный курьерский возврат по России с
                                            возмещением полной стоимости товара, исключая цену доставки.
                                        </li>
                                        <li class="mt-1">
                                            Оформить возврат возможно в течение 14 дней с момента получения заказа.*
                                        </li>
                                        <li class="mt-1">
                                            Если вы хотите обменять товар, то сначала нужно оформить возврат,
                                            а затем оформить новый заказ.
                                        </li>
                                        <li class="mt-1">
                                            Товар необходимо вернуть в его первоначальном виде: с бирками,
                                            оригинальной упаковкой, неношеным, нестираным, неповрежденным и
                                            неиспользованным**
                                        </li>
                                        <li class="mt-1">
                                            Деньги будут возвращены на счет в течение 3–10 календарных дней после
                                            получения и одобрения возврата. Убедитесь, пожалуйста, что вы верно
                                            заполнили
                                            реквизиты в заявлении на возврат. Скорость поступления денежных средств на
                                            ваш
                                            счет зависит от скорости обработки операций вашим банком и может достигать
                                            30 дней.
                                        </li>
                                        <li class="mt-1">
                                            Если при покупке или в процессе эксплуатации товара вы обнаружили брак,
                                            такой товар можно вернуть в пределах гарантийного срока — 30 дней***
                                        </li>
                                        <li class="mt-1">
                                            Возврат заказов с самовывозом возможно оформить только в том магазине,
                                            откуда забирали заказ.
                                        </li>
                                    </ul>
                                    <div class="mt-5 text-13 text-gray-light">
                                        * При возврате заказа с доставкой курьером рекомендуем связываться с нами в
                                        течение 7 дней с момента получения заказа
                                        <br>** В соответствии с пунктом 1 статьи 26 Закона РФ «О защите прав
                                        потребителей» от 07.02.1992 № 2300-1
                                        <br>*** В соответствии с пунктом 1 статьи 19 Закона РФ «О защите прав
                                        потребителей» от 07.02.1992 № 2300-1
                                    </div>
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item class="mt-4">
                            <template v-slot:accordion-trigger="slotProps">
                                <div class="flex items-center justify-between text-16">
                                    <div class="cursor-pointer">
                                        <div>Возврат онлайн-заказов по России с доставкой курьером или в пункт выдачи
                                        </div>
                                    </div>
                                    <div class="ml-11">
                                        <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                             width="8" height="5" viewBox="0 0 8 5" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                            <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                            <template slot="accordion-content">
                                <div class="mt-4 mb-5 pr-11 text-16">
                                    <ul class="list-outside mt-2 text-gray-light">
                                        Заказы, оформленные в интернет-магазине с доставкой курьером, можно вернуть
                                        только в интернет-магазин с помощью клиентского сервиса.
                                        <li class="mt-4 ml-3 lg:ml-4">
                                            Свяжитесь с нами по почте <a href="mailto:hello@presentandsimple.com"
                                                                         class="underlineWithPadding">
                                            hello@presentandsimple.com </a> или по телефону
                                            <br><a href="tel:+78003010424" class="underlineWithPadding"> +7 (800)
                                            301-04-24 </a> и сообщите о
                                            желании сделать возврат.
                                        </li>
                                        <li class="mt-1 ml-3 lg:ml-4">
                                            Сотрудник клиентского сервиса согласует с вами удобную дату и адрес для
                                            забора возврата.
                                        </li>
                                        <li class="mt-1 ml-3 lg:ml-4">
                                            Распечатайте транспортную накладную, которую мы отправим отдельным письмом.
                                        </li>
                                        <li class="mt-1 ml-3 lg:ml-4">
                                            Заполните <a class="underlineWithPadding"
                                                         href="https://drive.google.com/uc?export=download&amp;id=1zfU4kpfZLC8g13bwd1ZeKB7dnrWEgK8p">
                                            заявление на возврат</a>, надежно упакуйте вещи в коробку и передайте
                                            курьеру.
                                        </li>
                                        <li class="mt-1 ml-3 lg:ml-4">
                                            На передачу бесплатного возврата дается 2 попытки. Отследить статус доставки
                                            возврата можно по
                                            номеру накладной.
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item class="mt-4">
                            <template v-slot:accordion-trigger="slotProps">
                                <div class="flex items-center justify-between text-16">
                                    <div class="cursor-pointer">
                                        <div>Возврат онлайн-заказов с самовывозом из магазина</div>
                                    </div>
                                    <div class="ml-11">
                                        <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                             width="8" height="5" viewBox="0 0 8 5" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                            <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                            <template slot="accordion-content">
                                <div class="mt-4 mb-5 pr-11 text-16">
                                    <ul class="list-outside mt-2 text-gray-light">
                                        Заказы, оформленные в интернет-магазине и полученные лично в магазине (опция
                                        «Самовывоз»),
                                        можно вернуть только там, куда была осуществлена доставка заказа.
                                        <li class="mt-4 ml-3 lg:ml-4">
                                            Вы можете вернуть товар в течение 14 дней после получения вами заказа.
                                        </li>
                                        <li class="mt-1 ml-3 lg:ml-4">
                                            Для оформления возврата необходим паспорт.
                                        </li>
                                        <li class="mt-1 ml-3 lg:ml-4">
                                            Возврат денежных средств будет перечислен по указанным в заявлении
                                            реквизитам в течение 3–10
                                            календарных дней.
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item class="mt-4">
                            <template v-slot:accordion-trigger="slotProps">
                                <div class="flex items-center justify-between text-16">
                                    <div class="cursor-pointer">
                                        <div>Возврат покупок из магазина</div>
                                    </div>
                                    <div class="ml-11">
                                        <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                             width="8" height="5" viewBox="0 0 8 5" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                            <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                            <template slot="accordion-content">
                                <div class="ml-3 lg:ml-4">
                                    <ul class="list-outside mt-4 mb-5 pr-11 text-16 text-gray-light">
                                        <li class="mt-2">
                                            Вы можете вернуть товар, приобретенный в офлайн магазине, в течение 14 дней
                                            после покупки.
                                        </li>
                                        <li class="mt-1">
                                            Вернуть товар можно только в тот розничный магазин, где вы его приобрели.
                                        </li>
                                        <li class="mt-1">
                                            Для оформления возврата необходим паспорт. Если вы оплачивали покупку
                                            наличными,
                                            мы осуществим возврат наличными. Если же вы оплачивали покупку картой, для
                                            оформления возврата
                                            потребуется эта банковская карта.
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item class="mt-4">
                            <template v-slot:accordion-trigger="slotProps">
                                <div class="flex items-center justify-between text-16">
                                    <div class="cursor-pointer">
                                        <div>Самостоятельный возврат (включая международный возврат)</div>
                                    </div>
                                    <div class="ml-11">
                                        <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                             width="8" height="5" viewBox="0 0 8 5" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                            <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                            <template slot="accordion-content">
                                <div class="ml-3 lg:ml-4">
                                    <ul class="list-outside mt-4 mb-5 pr-11 text-16 text-gray-light">
                                        <li class="mt-2">
                                            По вопросам самостоятельного возврата свяжитесь с нами по телефону
                                            <br><a href="tel:+78003010424" class="underlineWithPadding"> +7 (800)
                                            301-04-24 </a> .
                                            Обратите, пожалуйста, внимание: если вы отправляете возврат сами,
                                            мы не можем гарантировать, что товар не потеряется и не получит повреждения
                                            при транспортировке.
                                        </li>
                                        <li class="mt-1">
                                            Заполните <a class="underlineWithPadding"
                                                         href="https://drive.google.com/uc?export=download&amp;id=1zfU4kpfZLC8g13bwd1ZeKB7dnrWEgK8p">
                                            заявление на возврат</a>, надежно упакуйте вещи в коробку и отправьте
                                            посылку курьерской
                                            службой (кроме Почты России) по адресу: 109369, г. Москва, Новочеркасский бульвар, 57к2
                                        </li>
                                        <li class="mt-1">
                                            Сообщите трек-номер для отслеживания по почте <a
                                            href="mailto:hello@presentandsimple.com" class="underlineWithPadding">
                                            hello@presentandsimple.com </a> или по телефону <a href="tel:+78003010424"
                                                                                               class="underlineWithPadding">
                                            +7 (800) 301-04-24 </a>,
                                            чтобы мы могли отследить посылку.
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </accordion-item>
                    </accordion>
                </div>
            </div>
            <div class="mt-20 mx-auto flex justify-center">
                <a href="tel:+78003010424" class="text-16 text-gray-light">
                    +7 (800) 301-04-24
                </a>
            </div>
            <div class="mt-7 pb-20 flex items-center justify-center">
                <a href="https://t.me/presentandsimple_com">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                            fill="#939393"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M4.52638 9.89436C7.44198 8.62436 9.38591 7.78703 10.3582 7.38236C13.1352 6.22736 13.7124 6.02656 14.0884 6.01996C14.1712 6.01856 14.3562 6.03996 14.476 6.13636C14.577 6.21836 14.605 6.32936 14.6182 6.40716C14.6385 6.53732 14.6442 6.66935 14.635 6.80076C14.4844 8.38196 13.8334 12.22 13.502 13.9904C13.362 14.7396 13.0858 14.9904 12.8186 15.0156C12.2386 15.069 11.797 14.6318 11.2344 14.263C10.3544 13.6862 9.85718 13.327 9.00278 12.764C8.01538 12.1134 8.65558 11.7558 9.21818 11.1714C9.36538 11.0184 11.9242 8.69136 11.9736 8.47996C11.9798 8.45356 11.9856 8.35516 11.927 8.30316C11.8684 8.25116 11.7824 8.26896 11.72 8.28316C11.6317 8.30316 10.2257 9.23249 7.50198 11.0712C7.10278 11.3452 6.74132 11.4785 6.41758 11.4712C6.06038 11.4634 5.37358 11.2692 4.86298 11.1032C4.23678 10.8996 3.73898 10.792 3.78298 10.4464C3.80525 10.2666 4.05305 10.0826 4.52638 9.89436Z"
                              fill="white"/>
                    </svg>
                </a>
                <a href="mailto:hello@presentandsimple.com" class="ml-12">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.85963 0.223001C8.28009 -0.0743334 11.7199 -0.0743337 15.1404 0.223001L16.7883 0.366253C17.8853 0.461617 18.8249 1.11596 19.3118 2.05196C19.374 2.1717 19.3274 2.31667 19.2126 2.38772L12.3753 6.62033C10.9089 7.5281 9.05987 7.54715 7.57508 6.66977L0.692091 2.60255C0.580889 2.53684 0.529846 2.40158 0.579961 2.28253C1.02533 1.22456 2.02664 0.469268 3.21169 0.366253L4.85963 0.223001Z"
                            fill="#939393"/>
                        <path
                            d="M0.57436 4.43416C0.404175 4.3336 0.187027 4.44182 0.167189 4.63849C-0.109682 7.38351 -0.0426718 10.1545 0.368221 12.8869C0.584836 14.3274 1.76048 15.4318 3.21169 15.5579L4.85963 15.7012C8.28009 15.9985 11.7199 15.9985 15.1404 15.7012L16.7883 15.5579C18.2395 15.4318 19.4152 14.3274 19.6318 12.8869C20.0546 10.0749 20.1133 7.22198 19.8077 4.39858C19.7863 4.20052 19.5655 4.09429 19.3961 4.19915L13.2368 8.01202C11.2529 9.24018 8.75124 9.26595 6.7424 8.07891L0.57436 4.43416Z"
                            fill="#939393"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import Accordion from "../../components/Accordion/Accordion.vue";
import AccordionItem from "../../components/Accordion/AccordionItem.vue";
import Loading from "../../components/Loading.vue";
import LivePage from "../LivePage.vue";

export default {
    name: "DeliveryAndReturnPage",
    components: {LivePage, Loading, AccordionItem, Accordion},
    data: () => ({
        deliveryInfo: false,
        returnInfo: false,
    }),
    methods: {
        showReturnInfo() {
            this.deliveryInfo = false
            this.returnInfo = true
        },
        showDeliveryInfo() {
            this.returnInfo = false
            this.deliveryInfo = true
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.return === 'true') {
            this.returnInfo = true
        } else {
            this.deliveryInfo = true
        }
    }
}

</script>

<style scoped>
.underlineWithPadding {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
}
</style>







