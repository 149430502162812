<template>
    <div>
        <div v-if="pageStatus === 'success'" class="overflow-hidden">
            <div v-for="page_block in page.data.attributes.page_blocks.data"
                 :key=page_block.data.page_block_id>

                <TwoHalfSizeImageBlock v-if="isTwoHalfSizeImageBlockActive(page_block)"
                                       :block=page_block />

                <FullSizeBlock v-if="isFullSizeBlockActive(page_block)"
                               :block=page_block />

                <HalfSizeImageBlock v-if="isHalfSizeImageBlockActive(page_block)"
                                    :block=page_block />

                <HalfSizeImageAndSubImageBlock v-if="isHalfSizeImageAndSubImageBlockActive(page_block)"
                                               :block=page_block />

                <NonSwipableBlock v-if="isNonSwipableBlockActive(page_block)"
                                  :block=page_block />

                <SwipableCategoryBlock v-if="isSwipableCategoryBlockActive(page_block)"
                                       :title=page_block.data.attributes.h2
                                       :productsForBlock=page_block.data.relations.productsForPageBlock.data
                                       :button_text=page_block.data.attributes.button_text
                                       :link=page_block.data.attributes.link
                                       :block=page_block />

                <SwipableFullSizeBlock v-if="isSwipableFullSizeBlockActive(page_block)"
                                       :block=page_block.data.attributes.swipable_full_size_block />

                <SwipableLookBookCategoryBlock v-if="isSwipableLookBookCategoryBlockActive(page_block)"
                                               :block=page_block.data.attributes.swipable_look_book_category_block
                                               :colorBg="page_block.data.attributes.background_color" />
            </div>

            <div data-popmechanic-embed="41244"></div>

            <!-- Временно нужно скрыть блок прессы
                <PressSwipableBlock :block=pressBlock />
            -->

        </div>
        <div>
            <Popup v-if="successSubscriptionFromPopupLocal"
                   @closePopup="closePopup">
                <div class="w-full h-full ">
                    <div class="text-normal text-center font-gothic text-gray-main">
                        Ваш email успешно подтвержден!
                    </div>
                    <button class="w-full mt-3  py-3 bg-brown-light text-white text-xl font-normal focus:outline-none"
                            @click="closePopup">
                        Хорошо
                    </button>
                </div>
            </Popup>
        </div>
    </div>

</template>
<script>

import {mapGetters} from 'vuex'
import NonSwipableCategoryBlock from "../components/NonSwipableCategoryBlock";
import SwipableCategoryBlock from "../components/SwipableCategoryBlock";
import FullSizeBlock from "../components/pageBlocks/FullSizeBlock";
import HalfSizeImageBlock from "../components/pageBlocks/HalfSizeImageBlock";
import NonSwipableBlock from "../components/pageBlocks/NonSwipableBlock";
import HalfSizeImageAndSubImageBlock from "../components/pageBlocks/HalfSizeImageAndSubImageBlock";
import PressSwipableBlock from "../components/pageBlocks/PressSwipableBlock";
import Popup from "../components/popup/Popup";
import SwipableFullSizeBlock from "../components/pageBlocks/SwipableFullSizeBlock";
import SwipableLookBookCategoryBlock from "../components/SwipableLookBookCategoryBlock";
import TwoHalfSizeImageBlock from "../components/pageBlocks/TwoHalfSizeImageBlock";

export default {
    name: "MainPage",
    data() {
        return {
            successSubscriptionFromPopupLocal: this.successSubscriptionFromPopup
        }
    },
    props: {
        successSubscriptionFromPopup: false
    },
    components: {
        TwoHalfSizeImageBlock,
        SwipableLookBookCategoryBlock,
        SwipableFullSizeBlock,
        PressSwipableBlock,
        HalfSizeImageAndSubImageBlock,
        NonSwipableBlock,
        HalfSizeImageBlock,
        FullSizeBlock,
        NonSwipableCategoryBlock,
        SwipableCategoryBlock,
        Popup
    },
    methods: {
        closePopup() {
            this.successSubscriptionFromPopupLocal = false
        },
        isTwoHalfSizeImageBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'two-half-image' && pageBlock.data.attributes.active;
        },
        isFullSizeBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'full-size' && pageBlock.data.attributes.active;
        },
        isHalfSizeImageBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'half-image' && pageBlock.data.attributes.active;
        },
        isHalfSizeImageAndSubImageBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'half-image-sub-image' && pageBlock.data.attributes.active;
        },
        isNonSwipableBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'non-swipable-category' && pageBlock.data.attributes.active;
        },
        isSwipableFullSizeBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'swipable-full-size-block' && pageBlock.data.attributes.active;
        },
        isSwipableCategoryBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'swipable-category' && pageBlock.data.attributes.active;
        },
        isSwipableLookBookCategoryBlockActive(pageBlock) {
            return pageBlock.data.attributes.type === 'swipable-look-book-category-block' && pageBlock.data.attributes.active;
        },
    },
    computed: {
        ...mapGetters({
            page: 'page',
            pageStatus: 'pageStatus',
            pressBlock: 'pressBlock',
            pressBlockStatus: 'pressBlockStatus'
        }),
    },

    mounted() {
        this.$store.dispatch('fetchPage', 'main');
        this.$store.dispatch('fetchPressBlock', 'press-block');
    },
    head: {
        title: {
            inner: 'Present & Simple: Стиль жизни и непреходящие ценности в мире моды'
        },
        meta: [
            {
                name: 'description', content: 'Present & Simple - это не только бренд одежды, ' +
                    'но и образ жизни, вдохновленный лучшими моментами жизни. Мы гордимся своим производством. ' +
                    'Вся наша продукция производится в Москве и Московской области, что позволяет нам контролировать ' +
                    'процесс от создания примеров до выпуска партии.'
            }
        ]
    },
}
</script>

<style scoped>

</style>
