<template>
    <div>
        <button class="my-4 h-9 px-3 lg:my-2 flex items-center border border-black" @click="loadTheWidget">
            <a class="uppercase text-11 lg:mx-auto lg:text-13"
               href='javascript:void(0)'>Выбрать ПВЗ</a>
        </button>
        <Popup v-if="isCdekPopupVisible"
            @closePopup="closePopup">
            <div id="WIDJET" style="height: 600px; margin-top:8px">
            </div>
        </Popup>

    </div>
</template>

<script>
import Popup from "../popup/Popup.vue";

export default {
    name: "CdekPickPointDelivery",
    components: {Popup},
    data: () =>({
        isCdekPopupVisible:false
    }),
    props: {
      country:null,
      city:null,
      pick_up_point_cdek:null,
      pick_up_point_id:null
    },
    methods: {
        closePopup(){
            this.isCdekPopupVisible = false
        },
        startsWidget() {
        },
        getWidget(wat) {
            this.$emit('getCdekPickUp',{
                pick_up_point_cdek : wat.PVZ.Address,
                pick_up_point_id : wat.id,
                cdek_delivery : wat.cityName
            })
            this.isCdekPopupVisible = false
        },
        loadTheWidget() {
                var ourWidjet = new ISDEKWidjet ({
                    popup: true,
                    defaultCity: this.city, //какой город отображается по умолчанию
                    cityFrom: this.city, // из какого города будет идти доставка
                    link: "WIDJET",
                    country: this.country, // можно выбрать страну, для которой отображать список ПВЗ
                    path: 'https://widget.cdek.ru/widget/scripts/', //директория с библиотеками
                    servicepath: 'https://presentandsimple.com/api/cdek',
                    choose: true,
                    hidedelt: true,
                    search: false,
                    onChoose: this.getWidget,
                    onReady: this.startsWidget
                });
                this.isCdekPopupVisible = true
        }
    },
    mounted() {
        const cdekScript = document.createElement('script')
        cdekScript.setAttribute('src', 'https://widget.cdek.ru/widget/widjet.js')
        cdekScript.setAttribute('type', 'text/javascript')
        document.body.appendChild(cdekScript)
    }
}
</script>

<style scoped>

</style>
