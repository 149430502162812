var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block pt-5" }, [
    _c("div", { staticClass: "flex items-center" }, [
      _c("div", { staticClass: "block w-full" }, [
        _c("div", { staticClass: "flex justify-between" }, [
          _c(
            "div",
            {
              staticClass:
                "uppercase text-13 text-black text-left flex-1 lg:text-16",
            },
            [_vm._v("\n                    Доставка\n                ")]
          ),
          _vm._v(" "),
          this.cart.data.attributes.hasDeliveryMethod
            ? _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "button",
                  { staticClass: "lg:hidden", on: { click: _vm.closeForm } },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "8",
                          height: "5",
                          viewBox: "0 0 8 5",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M4 1L1 4",
                            stroke: "black",
                            "stroke-linecap": "square",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M7 3.99976L4 0.999756",
                            stroke: "black",
                            "stroke-linecap": "square",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "hidden lg:block",
                    on: { click: _vm.closeForm },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "11",
                          height: "7",
                          viewBox: "0 0 11 7",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M5.5 1.00049L1 5.50049",
                            stroke: "black",
                            "stroke-linecap": "square",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M10 5.50024L5.5 1.00024",
                            stroke: "black",
                            "stroke-linecap": "square",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-10" }, [
          _c(
            "div",
            { staticClass: "my-4 lg:my-2 flex flex-col items-baseline gap-2" },
            [
              _c("div", { staticClass: "w-30 uppercase text-11 lg:text-13" }, [
                _vm._v("Страна"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "relative w-full h-9 mr-20 lg:h-11" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.country,
                      expression: "country",
                    },
                  ],
                  staticClass:
                    "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                  class: [
                    (_vm.submitted && _vm.$v.country.$invalid) ||
                    (!_vm.$v.country.sameAs && this.submit.country)
                      ? "border-red-light"
                      : "border-gray-lighter",
                  ],
                  attrs: { type: "text", name: "country" },
                  domProps: { value: _vm.country },
                  on: {
                    focus: function ($event) {
                      return _vm.focusOnly("country")
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.country = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitted && _vm.$v.country.$invalid
                  ? _c("div", [
                      !_vm.$v.country.required
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-red-light text-11 ml-1",
                            },
                            [_vm._v("Выберите страну из списка")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.submit.country
                  ? _c("div", [
                      !_vm.$v.country.sameAs
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-red-light text-11 ml-1",
                            },
                            [
                              _vm._v(
                                "\n                                Выберите страну из списка "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.focus.country
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute bg-white w-full px-2 left-0 mr-6 shadow z-20",
                      },
                      [
                        _vm.deliverySuggestions != null
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.deliverySuggestions.suggestions,
                                function (suggestion) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-white cursor-pointer w-full h-9 flex items-center text-11 lg:text-13",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCountry(suggestion)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(suggestion.value) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "my-4 lg:my-2 flex flex-col items-baseline gap-2" },
            [
              _c(
                "div",
                {
                  staticClass: "w-30 mr-5 uppercase text-11 lg:text-13 lg:mr-4",
                },
                [_vm._v("Населенный пункт")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "relative w-full h-9 mr-20 lg:h-11" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.city,
                      expression: "city",
                    },
                  ],
                  staticClass:
                    "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                  class: [
                    (_vm.submitted && _vm.$v.city.$invalid) ||
                    (!_vm.$v.city.sameAs && this.submit.city)
                      ? "bo    rder-red-light"
                      : "border-gray-lighter",
                  ],
                  attrs: { type: "text", name: "city" },
                  domProps: { value: _vm.city },
                  on: {
                    focus: function ($event) {
                      return _vm.focusOnly("city")
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.city = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.submitted && _vm.$v.city.$invalid
                  ? _c("div", [
                      !_vm.$v.city.required
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-red-light text-11 ml-1",
                            },
                            [_vm._v("Выберите город из списка")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.submit.city
                  ? _c("div", [
                      !_vm.$v.city.sameAs
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-red-light text-11 ml-1",
                            },
                            [
                              _vm._v(
                                "\n                                  Выберите город из списка "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.focus.city
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute bg-white w-full px-2 left-0 mr-6 shadow z-20",
                      },
                      [
                        _vm.deliverySuggestions != null
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.deliverySuggestions.suggestions,
                                function (suggestion) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-white cursor-pointer w-full h-10 flex items-center text-11 lg:text-13",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCity(suggestion)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(suggestion.value) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-full flex items-center" }, [
            _c("div", { staticClass: "w-30" }),
            _vm._v(" "),
            _c("div", { staticClass: "w-full mr-20" }, [
              _c("div", { staticClass: "flex justify-between" }, [
                _c("label", { staticClass: "flex items-center" }, [
                  _c("div", { staticClass: "flex items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDeliveryTypeCourierOrPickup,
                          expression: "selectedDeliveryTypeCourierOrPickup",
                        },
                      ],
                      staticClass:
                        "appearance-none rounded-none h-2 w-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked",
                      class: [
                        _vm.submitted &&
                        _vm.$v.selectedDeliveryTypeCourierOrPickup.$invalid
                          ? "border-red-light"
                          : "border-black",
                      ],
                      attrs: { type: "radio", value: "deliveryTypeCourier" },
                      domProps: {
                        checked: _vm._q(
                          _vm.selectedDeliveryTypeCourierOrPickup,
                          "deliveryTypeCourier"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          _vm.selectedDeliveryTypeCourierOrPickup =
                            "deliveryTypeCourier"
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-11 text-black lg:text-13" },
                    [
                      _vm._v(
                        "\n                                    Курьер\n                                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "flex items-center" }, [
                  _c("div", { staticClass: "flex items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDeliveryTypeCourierOrPickup,
                          expression: "selectedDeliveryTypeCourierOrPickup",
                        },
                      ],
                      staticClass:
                        "appearance-none rounded-none h-2 w-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked",
                      class: [
                        _vm.submitted &&
                        _vm.$v.selectedDeliveryTypeCourierOrPickup.$invalid
                          ? "border-red-light"
                          : "border-black",
                      ],
                      attrs: { type: "radio", value: "deliveryTypePickup" },
                      domProps: {
                        checked: _vm._q(
                          _vm.selectedDeliveryTypeCourierOrPickup,
                          "deliveryTypePickup"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          _vm.selectedDeliveryTypeCourierOrPickup =
                            "deliveryTypePickup"
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-11 text-black lg:text-13" },
                    [
                      _vm._v(
                        "\n                                    Самовывоз\n                                "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.submitted &&
              _vm.$v.selectedDeliveryTypeCourierOrPickup.$invalid
                ? _c("div", [
                    !_vm.$v.selectedDeliveryTypeCourierOrPickup.required
                      ? _c(
                          "span",
                          {
                            staticClass: "absolute text-red-light text-11 ml-1",
                          },
                          [_vm._v("Выберите способ доставки")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          !_vm.$v.country.$invalid && !_vm.$v.city.$invalid
            ? _c("div", [
                _vm.selectedDeliveryTypeCourierOrPickup ===
                "deliveryTypeCourier"
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "my-4 lg:my-3 flex items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "w-30 uppercase text-11 lg:text-13",
                            },
                            [_vm._v("Улица")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative w-full ml-7 h-9 mr-20 lg:h-11",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.street,
                                    expression: "street",
                                  },
                                ],
                                staticClass:
                                  "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                class: [
                                  (_vm.submitted && _vm.$v.street.$invalid) ||
                                  (!_vm.$v.street.sameAs && this.submit.street)
                                    ? "border-red-light"
                                    : "border-gray-lighter",
                                ],
                                attrs: { type: "text", name: "street" },
                                domProps: { value: _vm.street },
                                on: {
                                  focus: function ($event) {
                                    return _vm.focusOnly("street")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.street = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.submitted && _vm.$v.street.$invalid
                                ? _c("div", [
                                    !_vm.$v.street.required
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "absolute text-red-light text-11 ml-1",
                                          },
                                          [_vm._v("Выберите улицу из списка")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              this.submit.street
                                ? _c("div", [
                                    !_vm.$v.street.sameAs
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "absolute text-red-light text-11 ml-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Выберите улицу из списка "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.focus.street
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "absolute bg-white w-full px-2 left-0 mr-6 shadow z-20",
                                    },
                                    [
                                      _vm.deliverySuggestions != null
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.deliverySuggestions
                                                .suggestions,
                                              function (suggestion) {
                                                return _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "bg-white cursor-pointer w-full h-9 flex items-center text-11 lg:text-13",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectStreet(
                                                          suggestion
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                              " +
                                                        _vm._s(
                                                          suggestion.value
                                                        ) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.show.pick_up_point == false &&
                      _vm.show.pick_up_point_cdek == false &&
                      _vm.show.moscow_showroom == false &&
                      _vm.show.spb_showroom == false &&
                      _vm.show.moscow_okeania == false &&
                      _vm.show.moscow_afimoll == false &&
                      _vm.show.ekb_showroom == false
                        ? _c(
                            "div",
                            { staticClass: "my-4 lg:my-2 flex items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-30 uppercase text-11 lg:text-13",
                                },
                                [_vm._v("Дом")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative w-full ml-7 h-9 mr-20 lg:h-11",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.house,
                                        expression: "house",
                                      },
                                    ],
                                    staticClass:
                                      "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                    class: [
                                      (_vm.submitted &&
                                        _vm.$v.house.$invalid) ||
                                      (!_vm.$v.house.sameAs &&
                                        this.submit.house)
                                        ? "border-red-light"
                                        : "border-gray-lighter",
                                    ],
                                    attrs: { type: "text", name: "house" },
                                    domProps: { value: _vm.house },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.focusOnly("house")
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.house = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.submitted && _vm.$v.house.$invalid
                                    ? _c("div", [
                                        !_vm.$v.house.required
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "absolute text-red-light text-11 ml-1",
                                              },
                                              [_vm._v("Выберите дом из списка")]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.submit.house
                                    ? _c("div", [
                                        !_vm.$v.house.sameAs
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "absolute text-red-light text-11 ml-1",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Выберите дом из списка "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.focus.house
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "absolute bg-white w-full px-2 left-0 mr-6 shadow z-20",
                                        },
                                        [
                                          _vm.deliverySuggestions != null
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.deliverySuggestions
                                                    .suggestions,
                                                  function (suggestion) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "bg-white cursor-pointer w-full h-9 flex items-center text-11 lg:text-13",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectHouse(
                                                              suggestion
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                              " +
                                                            _vm._s(
                                                              suggestion.data
                                                                .house
                                                            ) +
                                                            "\n                            "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.show.pick_up_point == false &&
                      _vm.show.pick_up_point_cdek == false &&
                      _vm.show.moscow_showroom == false &&
                      _vm.show.spb_showroom == false &&
                      _vm.show.moscow_okeania == false &&
                      _vm.show.moscow_afimoll == false &&
                      _vm.show.ekb_showroom == false
                        ? _c(
                            "div",
                            { staticClass: "my-4 lg:my-2 flex items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-30 uppercase text-11 lg:text-13",
                                },
                                [_vm._v("Корпус")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative w-full ml-7 h-9 mr-20 lg:h-11",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.building,
                                        expression: "building",
                                      },
                                    ],
                                    staticClass:
                                      "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                    attrs: { type: "text", name: "building" },
                                    domProps: { value: _vm.building },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.building = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.show.pick_up_point == false &&
                      _vm.show.pick_up_point_cdek == false &&
                      _vm.show.moscow_showroom == false &&
                      _vm.show.spb_showroom == false &&
                      _vm.show.moscow_okeania == false &&
                      _vm.show.moscow_afimoll == false &&
                      _vm.show.ekb_showroom == false
                        ? _c(
                            "div",
                            { staticClass: "my-4 lg:my-2 flex items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-30 uppercase text-11 lg:text-13",
                                },
                                [_vm._v("Квартира")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative w-full ml-7 h-9 mr-20 lg:h-11",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.apartment,
                                        expression: "apartment",
                                      },
                                    ],
                                    staticClass:
                                      "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                    class: [
                                      _vm.submitted && _vm.$v.apartment.$invalid
                                        ? "border-red-light"
                                        : "border-gray-lighter",
                                    ],
                                    attrs: { type: "text", name: "apartment" },
                                    domProps: { value: _vm.apartment },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.apartment = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.submitted && _vm.$v.apartment.$invalid
                                    ? _c("div", [
                                        !_vm.$v.apartment.required
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "absolute text-red-light text-11 ml-1",
                                              },
                                              [_vm._v("Укажите квартиру")]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.show.pick_up_point == false &&
                      _vm.show.pick_up_point_cdek == false &&
                      _vm.show.moscow_showroom == false &&
                      _vm.show.spb_showroom == false &&
                      _vm.show.moscow_okeania == false &&
                      _vm.show.moscow_afimoll == false &&
                      _vm.show.ekb_showroom == false
                        ? _c(
                            "div",
                            { staticClass: "my-4 lg:my-2 flex items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-30 uppercase text-11 lg:text-13",
                                },
                                [_vm._v("Индекс")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative w-full ml-7 h-9 mr-20 lg:h-11",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.postal_code,
                                        expression: "postal_code",
                                      },
                                    ],
                                    staticClass:
                                      "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                    class: [
                                      _vm.submitted &&
                                      _vm.$v.postal_code.$invalid
                                        ? "border-red-light"
                                        : "border-gray-lighter",
                                    ],
                                    attrs: {
                                      type: "text",
                                      name: "postal_code",
                                    },
                                    domProps: { value: _vm.postal_code },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.postal_code = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.submitted && _vm.$v.postal_code.$invalid
                                    ? _c("div", [
                                        !_vm.$v.postal_code.required
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "absolute text-red-light text-11 ml-1",
                                              },
                                              [_vm._v("Укажите индекс")]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.deliveryRegionDetailsStatus === "success"
                  ? _c("div", { staticClass: "my-6 flex flex-row items-top" }, [
                      _vm.deliveryRegionDetails.data.length > 0
                        ? _c(
                            "div",
                            { staticClass: "w-full flex items-center" },
                            [
                              _c("div", { staticClass: "flex flex-col" }, [
                                _vm.deliveryRegionDetailsStatus === "success" &&
                                _vm.selectedDeliveryTypeCourierOrPickup ===
                                  "deliveryTypePickup"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.deliveryRegionDetails.data,
                                          function (deliveryTypeItem) {
                                            return _c(
                                              "div",
                                              { key: deliveryTypeItem.id },
                                              [
                                                deliveryTypeItem.relationships
                                                  .deliveryType.data
                                                  .relationships.deliveryCompany
                                                  .data.id === 1 ||
                                                deliveryTypeItem.relationships
                                                  .deliveryType.data
                                                  .relationships.deliveryCompany
                                                  .data.id === 3 ||
                                                deliveryTypeItem.relationships
                                                  .deliveryType.data
                                                  .relationships.deliveryCompany
                                                  .data.id === 10
                                                  ? _c("div", [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "mt-1 w-full flex items-center",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex items-center",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.selectedDeliveryRegionDetail,
                                                                    expression:
                                                                      "selectedDeliveryRegionDetail",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "appearance-none rounded-none w-2 h-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked",
                                                                class: [
                                                                  _vm.submitted &&
                                                                  _vm.$v
                                                                    .selectedDeliveryRegionDetail
                                                                    .$invalid
                                                                    ? "border-red-light"
                                                                    : "border-black",
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "deliveryRegionDetail ",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    deliveryTypeItem,
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm.selectedDeliveryRegionDetail,
                                                                      deliveryTypeItem
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.selectedDeliveryRegionDetail =
                                                                        deliveryTypeItem
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ml-3 text-11 text-black lg:text-13",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .relationships
                                                                      .deliveryType
                                                                      .data
                                                                      .attributes
                                                                      .title
                                                                  ) +
                                                                  ", " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .attributes
                                                                      .cost
                                                                  ) +
                                                                  " Р, от " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .attributes
                                                                      .deliveryDaysFrom
                                                                  ) +
                                                                  " до " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .attributes
                                                                      .deliveryDaysTo
                                                                  ) +
                                                                  " дней.\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.deliveryRegionDetail != null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "appearance-none rounded-none w-full text-11 text-gray-light px-2 mt-3 lg:text-13",
                                              },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.deliveryRegionDetail
                                                        .attributes.description
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm.deliveryRegionDetailsStatus ===
                                      "success" &&
                                    _vm.selectedDeliveryTypeCourierOrPickup ===
                                      "deliveryTypeCourier"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.deliveryRegionDetails.data,
                                          function (deliveryTypeItem) {
                                            return _c(
                                              "div",
                                              { key: deliveryTypeItem.id },
                                              [
                                                deliveryTypeItem.relationships
                                                  .deliveryType.data
                                                  .relationships.deliveryCompany
                                                  .data.id !== 1 &&
                                                deliveryTypeItem.relationships
                                                  .deliveryType.data
                                                  .relationships.deliveryCompany
                                                  .data.id !== 3 &&
                                                deliveryTypeItem.relationships
                                                  .deliveryType.data
                                                  .relationships.deliveryCompany
                                                  .data.id !== 10
                                                  ? _c("div", [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "mt-1 w-full flex items-center",
                                                        },
                                                        [
                                                          _c("div", {}, [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.selectedDeliveryRegionDetail,
                                                                  expression:
                                                                    "selectedDeliveryRegionDetail",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "appearance-none rounded-none w-2 h-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked",
                                                              class: [
                                                                _vm.submitted &&
                                                                _vm.$v
                                                                  .selectedDeliveryRegionDetail
                                                                  .$invalid
                                                                  ? "border-red-light"
                                                                  : "border-black",
                                                              ],
                                                              attrs: {
                                                                type: "radio",
                                                                name: "deliveryRegionDetail",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  deliveryTypeItem,
                                                                checked: _vm._q(
                                                                  _vm.selectedDeliveryRegionDetail,
                                                                  deliveryTypeItem
                                                                ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.selectedDeliveryRegionDetail =
                                                                      deliveryTypeItem
                                                                  },
                                                              },
                                                            }),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ml-3 text-11 text-black lg:text-13",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .relationships
                                                                      .deliveryType
                                                                      .data
                                                                      .attributes
                                                                      .title
                                                                  ) +
                                                                  ", " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .attributes
                                                                      .cost
                                                                  ) +
                                                                  " Р, от " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .attributes
                                                                      .deliveryDaysFrom
                                                                  ) +
                                                                  " до " +
                                                                  _vm._s(
                                                                    deliveryTypeItem
                                                                      .attributes
                                                                      .deliveryDaysTo
                                                                  ) +
                                                                  " дней.\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.deliveryRegionDetail != null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "appearance-none rounded-none w-full text-11 text-gray-light px-2 mt-3 lg:text-13",
                                              },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.deliveryRegionDetail
                                                        .attributes.description
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        : _c("div", { staticClass: "text-13" }, [
                            _vm._v(
                              "\n                      Доставка в выбранный регион недоступна\n                    "
                            ),
                          ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.show.pick_up_point === true &&
                _vm.selectedDeliveryTypeCourierOrPickup === "deliveryTypePickup"
                  ? _c("div", [
                      _vm.pick_up_point && _vm.pick_up_point.length > 1
                        ? _c(
                            "div",
                            { staticClass: "my-4 lg:my-2 flex items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-30 uppercase text-11 lg:text-13",
                                },
                                [_vm._v("Адрес пункта самовывоза")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "w-full ml-7 h-9 mr-20 lg:h-11",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.pick_up_point,
                                        expression: "pick_up_point",
                                      },
                                    ],
                                    staticClass:
                                      "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                    class: [
                                      _vm.submitted &&
                                      _vm.$v.pick_up_point.$invalid
                                        ? "border-red-light"
                                        : "border-gray-lighter",
                                    ],
                                    attrs: {
                                      readonly: "readonly",
                                      type: "text",
                                      name: "pick_up_point",
                                    },
                                    domProps: { value: _vm.pick_up_point },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.pick_up_point = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.submitted && _vm.$v.pick_up_point.$invalid
                                ? _c("div", [
                                    !_vm.$v.pick_up_point.required
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "absolute text-red-light text-11 ml-1",
                                          },
                                          [
                                            _vm._v(
                                              "Укажите адрес пункта самовывоза"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "my-4 h-9 px-3 lg:my-2 flex items-center border border-black",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "uppercase text-11 lg:mx-auto lg:text-13",
                              attrs: { href: "#" },
                              on: { click: _vm.getBoxberryPickUpPoints },
                            },
                            [_vm._v("Выбрать ПВЗ")]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.show.pick_up_point_cdek === true &&
                _vm.selectedDeliveryTypeCourierOrPickup === "deliveryTypePickup"
                  ? _c(
                      "div",
                      [
                        _vm.pick_up_point && _vm.pick_up_point.length > 1
                          ? _c(
                              "div",
                              { staticClass: "my-4 lg:my-2 flex items-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-30 uppercase text-11 lg:text-13",
                                  },
                                  [_vm._v("Адрес пункта самовывоза")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full ml-7 h-9 mr-20 lg:h-11",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.pick_up_point,
                                          expression: "pick_up_point",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13",
                                      class: [
                                        _vm.submitted &&
                                        _vm.$v.pick_up_point.$invalid
                                          ? "border-red-light"
                                          : "border-gray-lighter",
                                      ],
                                      attrs: {
                                        readonly: "readonly",
                                        type: "text",
                                        name: "pick_up_point_cdek",
                                      },
                                      domProps: { value: _vm.pick_up_point },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.pick_up_point =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.submitted && _vm.$v.pick_up_point.$invalid
                                  ? _c("div", [
                                      !_vm.$v.pick_up_point.required
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "absolute text-red-light text-11 ml-1",
                                            },
                                            [
                                              _vm._v(
                                                "Укажите адрес пункта самовывоза"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("CdekPickPointDelivery", {
                          attrs: {
                            country: this.delivery.country,
                            city: this.deliverySelectedSuggestion.data.city,
                            pick_up_point_cdek: this.pick_up_point_cdek,
                            pick_up_point_id: this.pick_up_point_id,
                          },
                          on: { getCdekPickUp: _vm.onCdekPickUp },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.deliveryRegionDetail !== null
                  ? _c("div", { staticClass: "mb-7" }, [
                      _vm.deliveryRegionDetail.relationships.deliveryType.data
                        .id === 1
                        ? _c(
                            "div",
                            [
                              _c("CartDeliveryDateIntervalBlock", {
                                attrs: {
                                  deliveryRegionDetail:
                                    this.deliveryRegionDetail,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-col-reverse items-baseline gap-2 mt-3" },
            [
              _c(
                "div",
                { staticClass: "w-30 text-11 text-gray-light lg:text-13" },
                [_vm._v("Комментарий")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-full h-9 mr-20 lg:h-11" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.comment,
                      expression: "comment",
                    },
                  ],
                  staticClass:
                    "appearance-none text-11 text-gray-light resize-none rounded-none w-full h-12 border px-2 focus:outline-none focus:border-black lg:text-13 border-gray-lighter",
                  attrs: { type: "text", name: "comment" },
                  domProps: { value: _vm.comment },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.comment = $event.target.value
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "my-7" }, [
            _c(
              "button",
              {
                staticClass:
                  "w-full h-9 py-1 px-5 border custom-border bg-transparent text-black text-11 r focus:outline-none lg:text-11 lg:text-13",
                on: {
                  click: function ($event) {
                    return _vm.sendDeliveryInfo()
                  },
                },
              },
              [_vm._v("\n                        Далее\n                    ")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }