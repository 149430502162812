var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-tel-input", {
        attrs: {
          inputOptions: { placeholder: "Номер телефона" },
          preferredCountries: ["ru", "by", "kz"],
          defaultCountry: "ru",
          mode: "international",
          required: true,
          styleClasses: _vm.styleClasses,
        },
        on: { input: _vm.telValidate },
        model: {
          value: _vm.phone,
          callback: function ($$v) {
            _vm.phone = $$v
          },
          expression: "phone",
        },
      }),
      _vm._v(" "),
      _vm.$v.phone.$invalid && _vm.$v.phone.$dirty
        ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
            _vm._v(
              "\n        Пожалуйста введите корректный номер телефона\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      this.authWayErrorMessage
        ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
            _vm._v("\n        " + _vm._s(this.authWayErrorMessage) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCodeSent
        ? _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.code,
                  expression: "code",
                },
              ],
              staticClass:
                "mt-2 appearance-none text-11 text-gray-light rounded-none w-full h-9 focus:border px-2 focus:outline-none focus:border-black lg:text-13",
              attrs: {
                type: "number",
                maxlength: "4",
                placeholder: "Введите код из СМС",
              },
              domProps: { value: _vm.code },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.code = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            this.loginErrorMessage
              ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(this.loginErrorMessage) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isButtonActive
        ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
            _vm._v(
              "\n        Вы можете повторно отправить код через " +
                _vm._s(_vm.timeLeft) +
                " секунд\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      (this.authStatus === false && !_vm.isButtonActive) ||
      (_vm.authInfo?.relations.authUser.data.attributes.isPhoneVerified ===
        false &&
        !_vm.isButtonActive)
        ? _c(
            "button",
            {
              staticClass:
                "w-full mt-5 uppercase h-9 py-1 px-5 border border-black bg-black text-white text-11 r focus:outline-none lg:text-13",
              on: { click: _vm.getCode },
            },
            [_vm._v("\n        Получить код\n    ")]
          )
        : _c(
            "button",
            {
              staticClass:
                "w-full mt-5 uppercase h-9 py-1 px-5 border border-black bg-black text-white text-11 r focus:outline-none lg:text-13",
              on: { click: _vm.authorize },
            },
            [_vm._v("\n        Авторизоваться\n    ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }