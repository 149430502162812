<template>
    <div>
            <router-link :to="block.data.attributes.link">
                <div v-if="block.data.attributes.video == true">
                    <div class="relative">

                        <div class="md:hidden">
                            <vimeo-player class="vimeo" ref="player" :video-id="block.data.attributes.video_phone_id"  @ready="onReady" :options="options" ></vimeo-player>
                        </div>

                        <div class="hidden md:block">
                            <vimeo-player class="vimeo" ref="player" :video-id="block.data.attributes.video_id" @ready="onReady"  :options="options" ></vimeo-player>
                        </div>

                        <div :class="[block.data.attributes.text_align == 'center' ? 'absolute w-full top-1/2  flex flex-col items-center' : 'absolute w-full inset-x-0 bottom-0 flex flex-col items-center pb-15']">
                            <div :class="[block.data.attributes.text_case == 'lowercase'
                                ? 'lowercase'
                                : 'uppercase']"
                                 :style="'color: ' + block.data.attributes.text_color + ';'">

                                <div :class="[block.data.attributes.text_size == 'large'
                                ? 'sm:text-lg lg:text-2xl mt-4 text-center  font-normal tracking-widest'
                                : 'text-lg mt-4 text-center  font-normal tracking-widest']">
                                    {{block.data.attributes.h3}}
                                </div>

                                <div :class="[block.data.attributes.text_size == 'large'
                                ? 'sm:text-2xl lg:text-4xl mt-4 text-center  font-normal tracking-widest'
                                : 'text-2xl mt-4 text-center font-normal tracking-widest']">
                                    {{block.data.attributes.h2}}
                                </div>
                            </div>
                            <div v-if="block.data.attributes.button_text != null">
                                <div  class="mt-4 mx:auto flex items-center justify-center">
                                <router-link :to="block.data.attributes.link">
                                    <button
                                        :style="'border-color: ' + block.data.attributes.border_button_color + ';'+'color: ' + block.data.attributes.text_color + ';'"
                                        class="py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm">{{ block.data.attributes.button_text }}</button>
                                </router-link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class = "w-full h-195 sm:h-full relative">

                        <div class="hidden md:block">
                            <img class="w-full h-195 sm:h-full object-cover" :src=block.data.attributes.image.data.attributes.path alt="">
                        </div>

                        <div class="md:hidden">
                            <img class="w-full h-195 object-cover" :src=block.data.attributes.image_phone.data.attributes.path alt="">
                        </div>

                         <div :class="[block.data.attributes.text_align == 'center' ? 'absolute w-full top-1/2  flex flex-col items-center' : 'absolute w-full inset-x-0 bottom-0 flex flex-col items-center pb-15']">
                             <div :class="[block.data.attributes.text_case == 'lowercase'
                                ? 'lowercase'
                                : 'uppercase']"
                                  :style="'color: ' + block.data.attributes.text_color + ';'">
                                 <div :class="[block.data.attributes.text_size == 'large'
                                      ? 'sm:text-lg lg:text-2xl mt-4 text-center font-normal tracking-widest'
                                      : 'text-lg mt-4 text-center font-normal tracking-widest']">
                                        {{block.data.attributes.h3}}
                                </div>

                                <div v-if="block.data.attributes.text_size === 'small'"
                                     class="sm:text-2xl lg:text-4xl mt-4 text-center font-normal tracking-widest'">
                                    {{block.data.attributes.h2}}
                                </div>

                                 <div v-else
                                      :class="[block.data.attributes.text_size === 'the largest'
                                      ? 'text-4xl lg:text-9xl mt-4 text-center font-normal tracking-widest'
                                      : 'text-4xl lg:text-8xl mt-4 text-center font-normal widest']">
                                     {{block.data.attributes.h2}}
                                 </div>
                            </div>
                            <div v-if="block.data.attributes.button_text != null">
                                 <div  class="mt-4 mx:auto flex items-center justify-center">
                                 <router-link :to="block.data.attributes.link">
                                     <button :style="'border-color: ' + block.data.attributes.border_button_color + ';'+'color: ' + block.data.attributes.text_color + ';'"
                                             class="py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm">{{ block.data.attributes.button_text }}</button>
                                 </router-link>
                             </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>

            <!--<img :src=block.data.attributes.image.data.attributes.path :alt=block.data.attributes.image.data.attributes.name> -->
    </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
export default {
    name: "FullSizeBlock",
    props: {
        block: null,
    },

    data() {
        return {

            options: {
                muted: true,
                autoplay: true,
                loop:true,
                controls:false,
                responsive: true,
                autopause:false

            },

            playerReady: true,


        }
    },

    methods: {
        onReady() {
            this.playerReady = true
        },
        mute () {
            this.$refs.player.mute()
        }
    }
}
</script>

<style lang="scss" scoped>
.vimeo {
    height: 100%;
    width: 100%;
   // max-height: 200px;

}
</style>
