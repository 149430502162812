var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.isErrorInCreatingOrder || this.cartStatus === "error"
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c(
              "div",
              {
                staticClass:
                  "mt-4 w-full justify-center items-center flex-none text-gray-main",
              },
              [
                _c("div", { staticClass: "flex-none justify-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full uppercase text-center text-16 justify-center flex-none",
                    },
                    [
                      _vm._v(
                        "\n                    Кажется что-то пошло не так!\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-4 text-left text-16 text-black" },
                    [
                      _c("div", [
                        _vm._v(
                          "\n                        Мы уже работаем над проблемой. Пожалуйста попробуйте обновить страницу или зайдите позже.\n                        Если ошибка повторится свяжитесь с нашей поддержкой любым удобным для вас способом.\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("a", { attrs: { href: "tel:+78003010424" } }, [
                          _vm._v("По телефону +7 800 301 04 24"),
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "a",
                          { attrs: { href: "https://wa.me/+78003010424" } },
                          [_vm._v("Написав в WhatsApp: +7 800 301 04 24")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:hello@presentandsimple.com",
                            },
                          },
                          [
                            _vm._v(
                              "По электронной почте\n                            hello@presentandsimple.com"
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-full px-6 mx-auto overflow-hidden lg:w-240 xl:w-360",
        },
        [
          _c("router-link", { attrs: { to: "/cart" } }, [
            _c("div", { staticClass: "mt-10 flex items-center" }, [
              _c("div", { staticClass: "hidden lg:block" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "13",
                      height: "11",
                      viewBox: "0 0 13 11",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M1 5.5L5.5 10",
                        stroke: "#5C5C5C",
                        "stroke-linecap": "square",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M1.75 5.49988H12.25",
                        stroke: "#5C5C5C",
                        "stroke-linecap": "square",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M5.5 1L1 5.5",
                        stroke: "#5C5C5C",
                        "stroke-linecap": "square",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lg:hidden" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "9",
                      height: "8",
                      viewBox: "0 0 9 8",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M1.00024 4L4.00024 7",
                        stroke: "#5C5C5C",
                        "stroke-linecap": "square",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M1.50012 3.99988H8.50012",
                        stroke: "#5C5C5C",
                        "stroke-linecap": "square",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M4 1L1 4",
                        stroke: "#5C5C5C",
                        "stroke-linecap": "square",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-2 text-11 text-gray-main lg:text-13" },
                [_vm._v("Вернуться к редактированию корзины")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex flex-col-reverse lg:block" }, [
            _c("div", [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {},
                [
                  _c("CartCheckoutOrderLines", { attrs: { cart: _vm.cart } }),
                  _vm._v(" "),
                  _c("CartAdditionalOrderLines", { attrs: { cart: _vm.cart } }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-none mt-10 lg:flex" }, [
              _c(
                "div",
                { staticClass: "w-full lg:w-1/2" },
                [
                  _c("CartCheckoutUserDeliveryPaymentInfo", {
                    attrs: {
                      cart: _vm.cart,
                      userWantsToUseNewPhoneNumberToAuth:
                        _vm.userWantsToUseNewPhoneNumberToAuth,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-full mt-10 lg:w-1/2 lg:mt-0 lg:ml-15" },
                [
                  _c("CartDeliveryAndReturnInfo"),
                  _vm._v(" "),
                  _c("CartTotalCalculations", { attrs: { cart: _vm.cart } }),
                  _vm._v(" "),
                  _vm.cart?.data?.relationships.cartPaymentMethod &&
                  _vm.cart?.data?.relationships?.cartPaymentMethod?.data?.id ===
                    8
                    ? _c(
                        "button",
                        {
                          class: [
                            _vm.cart?.data?.attributes?.isPossibleToSend
                              ? "w-full uppercase h-9 mb-5 py-1 px-5 flex items-center justify-center bg-black text-white text-11 r focus:outline-none lg:text-13"
                              : "w-full uppercase h-9 mb-5 py-1 px-5 flex items-center justify-center bg-gray-light text-white text-11 r focus:outline-none lg:text-13",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.createOrderInCartCheckout(_vm.cart)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Оплатить\n                        "
                          ),
                          _c("span", { staticClass: "ml-1.5" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "76",
                                  height: "11",
                                  viewBox: "0 0 76 11",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M13.4897 0H11.6781V9.18682H13.4897V0Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M9.59702 0.514064H7.78538V9.70069H9.59702V0.514064Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M5.70433 1.08797H3.89269V10.2763H5.70433V1.08797Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M1.81164 1.66825H0L6.90316e-06 10.8577H1.81164L1.81164 1.66825Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M61.3381 4.89452L58.4211 1.6513H56.7158V9.16047H58.4963V4.36948L61.1594 7.20798H61.4884L64.1058 4.36948V9.16047H65.8863V1.6513H64.181L61.3381 4.89452Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M74.3688 1.6513L69.9557 6.53199V1.6513H68.1752V9.16047H69.8064L74.2195 4.27978V9.16047H76V1.6513H74.3688Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M47.1256 4.39901C47.1256 5.59457 47.7794 6.53636 48.783 6.93124L46.9011 9.16047H49.0804L50.7923 7.1325H52.6447V9.16047H54.4252V1.6513H49.8922C48.2022 1.6513 47.1256 2.80748 47.1256 4.39901ZM52.6458 3.30518V5.52566H50.2224C49.4139 5.52566 48.9802 5.075 48.9802 4.41432C48.9802 3.75365 49.4291 3.30299 50.2224 3.30299L52.6458 3.30518Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M38.8518 3.15313C38.7428 5.73568 38.198 7.40268 37.1312 7.40268H36.8643V9.20532L37.1487 9.22063C39.2877 9.33986 40.4699 7.46284 40.6639 3.36315H43.4773V9.16047H45.2546V1.6513H38.9117L38.8518 3.15313Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M32.1495 1.54639C29.7413 1.54639 28.0088 3.21339 28.0088 5.40543C28.0088 7.67295 29.8939 9.28088 32.1495 9.28088C34.4987 9.28088 36.3239 7.58325 36.3239 5.40543C36.3239 3.22761 34.4987 1.54639 32.1495 1.54639ZM32.1495 7.47825C30.7885 7.47825 29.8612 6.59224 29.8612 5.40543C29.8612 4.18909 30.7896 3.32715 32.1495 3.32715C33.5093 3.32715 34.4682 4.22847 34.4682 5.40543C34.4682 6.5824 33.4963 7.47825 32.1495 7.47825Z",
                                    fill: "white",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M26.1205 1.66547H19.8365L19.7765 3.1673C19.6872 5.3309 19.1228 7.3884 18.056 7.41794L17.5624 7.43325V10.8577L19.3581 10.8541V9.16151H25.5963V10.8541H27.4073V7.41794H26.1205V1.66547ZM24.34 7.41794H20.5404C21.1833 6.44224 21.5276 5.0301 21.5875 3.37841H24.34V7.41794Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          class: [
                            _vm.cart.data.attributes.isPossibleToSend
                              ? "w-full h-9 py-1 px-5 bg-black text-white text-11 r focus:outline-none lg:text-13"
                              : "w-full h-9 py-1 px-5 bg-gray-light text-white text-11 r focus:outline-none lg:text-13",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.createOrderInCartCheckout(_vm.cart)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Оформить заказ\n                    "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-10 lg:hidden" }, [
      _c(
        "div",
        {
          staticClass:
            "uppercase text-16 text-gray-main text-center lg:text-24",
        },
        [_vm._v("Оформление заказа")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-10" }, [
      _c(
        "div",
        {
          staticClass:
            "uppercase text-16 text-gray-main text-center lg:text-24",
        },
        [_vm._v("Состав заказа")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }