<template>
    <div class="">
        <div class="p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main text-base font-normal">
            <h1 >Информация</h1>
            <div v-if="infosStatus=='success'">
                <div class="w-full text-center"
                    v-for="info in infos.data"
                    :key="info.data.info_id">
                    <h2 v-if="info.data.attributes.active_on_info_page == true">
                        <router-link :to="{name: 'InfoBlock', params: {info: info.links.meta.slug}}">
                            {{info.data.attributes.title}}
                        </router-link>
                    </h2>
                </div>
            </div>
            <Loading v-else></Loading>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loading from "../components/Loading";

export default {
    name: "Info",
    components: {
        Loading
    },
    mounted () {
        this.$store.dispatch('fetchInfos');
    },
    computed: {
        ...mapGetters({
            infos: 'infos',
            infosStatus: 'infosStatus',

        }),
    }



}
</script>

<style scoped>

</style>
