<template>
    <Popup @closePopup="$emit('closePopup')">
        <div>
            <div class="-mt-3 text-gray-main text-16">Спасибо!</div>
            <div class="mt-2 text-gray-main">Товар успешно добавлен в корзину</div>
            <div class="mt-4">
                <router-link :to="{name: 'PreorderCart'}">
                    <button class="w-full py-3 bg-black text-white text-16 font-normal  focus:outline-none">
                        Оформить предзаказ
                    </button>
                </router-link>
            </div>
            <div class="mt-4 underline cursor-pointer text-gray-main text-center"
                 @click="$emit('closePopup')">Продолжить покупки</div>
        </div>
    </Popup>
</template>

<script>

import Popup from "./Popup.vue";

export default {
    name: "SuccessAddToCartPreorderPopup",
    components: {Popup},
}

</script>

<style scoped>
</style>
