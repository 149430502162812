var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "" }, [
      _c("div", [
        _c("div", [
          _c("div", { staticClass: "flex mt-10 items-center justify-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "text-gray-main text-lg font-normal uppercase mx-3",
              },
              [_vm._v("\n                    Описание\n                ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
              _vm._v("\n                    Описание\n                "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text-gray-main text-lg font-bold uppercase mx-3",
              },
              [_vm._v("\n                    Описание\n                ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-lg font-bold uppercase mx-3" }, [
              _vm._v("\n                    Описание\n                "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex items-center justify-center mt-10" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-gothic text-gray-main text-lg font-normal uppercase mx-3",
              },
              [_vm._v("\n                    Описание\n                ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "font-gothic text-lg font-normal uppercase mx-3" },
              [_vm._v("\n                    Описание\n                ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "font-gothic text-gray-main text-lg font-bold uppercase mx-3",
              },
              [_vm._v("\n                    Описание\n                ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "font-gothic text-lg font-bold uppercase mx-3" },
              [_vm._v("\n                    Описание\n                ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center",
            },
            [
              _vm._v(
                "\n                 Futura заголовок и Century Gothic описание, разное межбуквенное расстояние у описания\n             "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 p-4 w-full" }, [
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
                _vm._v(
                  "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "font-gothic text-gray-main text-base font-normal mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
                _vm._v(
                  "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "font-gothic text-gray-main text-base font-normal tracking-tight mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
                _vm._v(
                  "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "font-gothic text-gray-main text-base font-normal tracking-wider mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center",
            },
            [
              _vm._v(
                "\n                 Century Gothic заголовок и Futura описание, разное межбуквенное расстояние у описания\n             "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 p-4 w-full" }, [
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "font-gothic text-lg font-normal uppercase mx-3",
                },
                [
                  _vm._v(
                    "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-main text-xl font-normal leading-tight mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "font-gothic text-lg leading-tight font-normal uppercase mx-3",
                },
                [
                  _vm._v(
                    "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-main text-xl leading-tight font-normal tracking-tight mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "font-gothic text-lg font-normal uppercase mx-3",
                },
                [
                  _vm._v(
                    "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-main text-xl leading-tight font-normal tracking-wider mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center",
            },
            [
              _vm._v(
                "\n                 Всё Futura, разное межбуквенное расстояние у описания\n             "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 p-4 w-full" }, [
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
                _vm._v(
                  "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-main text-base text-xl leading-tight font-normal mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
                _vm._v(
                  "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-main text-xl font-normal leading-tight tracking-tight mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c("div", { staticClass: "text-lg font-normal uppercase mx-3" }, [
                _vm._v(
                  "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-main text-xl font-normal leading-tight tracking-wider mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center",
            },
            [
              _vm._v(
                "\n                 Всё Century Gothic, разное межбуквенное расстояние у описания\n             "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 p-4 w-full" }, [
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "text-gothic text-lg font-normal uppercase mx-3",
                },
                [
                  _vm._v(
                    "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gothic text-gray-main text-base text-xl leading-tight font-normal mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "text-gothic text-lg font-normal uppercase mx-3",
                },
                [
                  _vm._v(
                    "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gothic text-gray-main text-xl font-normal leading-tight tracking-tight mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "text-gothic text-lg font-normal uppercase mx-3",
                },
                [
                  _vm._v(
                    "\n                         СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gothic text-gray-main text-xl font-normal leading-tight tracking-wider mx-3 mt-3",
                },
                [
                  _vm._v(
                    "\n                         Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.\n                     "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center",
            },
            [_vm._v("\n                 Кнопка Century Gothic\n             ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 p-4 w-full" }, [
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider mt-3 uppercase focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider mt-3 uppercase focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider mt-3 uppercase focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center",
            },
            [_vm._v("\n                 Кнопка Futura\n             ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 p-4 w-full" }, [
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider mt-3 uppercase focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider mt-3 uppercase focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/4 p-5 mx-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider mt-3 uppercase focus:outline-none",
                },
                [
                  _vm._v(
                    "\n                         Оплатить\n                     "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }