var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "w-full h-220 sm:h-full relative" }, [
      _c("div", { staticClass: "hidden md:block" }, [
        _c("img", {
          staticClass: "w-full h-220 sm:h-full object-cover",
          attrs: {
            src: _vm.block.data.attributes.image.data.attributes.path,
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "md:hidden" }, [
        _c("img", {
          staticClass: "w-full h-220 sm:h-full object-cover",
          attrs: {
            src: _vm.block.data.attributes.image_phone.data.attributes.path,
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "absolute w-full inset-x-0 top-0 pt-15" }, [
        _vm.feedbackStatus === "success-first-step"
          ? _c(
              "div",
              { staticClass: "w-full px-4 flex flex-col items-center" },
              [
                _c(
                  "div",
                  {
                    style:
                      "color: " + _vm.block.data.attributes.text_color + ";",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-2xl lg:text-4xl m-4 text-center font-normal tracking-widest uppercase",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.block.data.attributes.h2) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-lg lg:text-2xl m-4 text-center font-normal widest",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.block.data.attributes.h3) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.feedbackText,
                      expression: "feedbackText",
                    },
                  ],
                  staticClass:
                    "m-4 p-2 w-full md:w-1/2 h-64 appearance-none rounded-none border border-gray-lighter focus:outline-none focus:border-brown-light",
                  attrs: { type: "text", name: "feedback" },
                  domProps: { value: _vm.feedbackText },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.feedbackText = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.block.data.attributes.button_text != null
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-4 mx:auto flex items-center justify-center",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "py-4 px-7 border-2 tracking-widest uppercase appearance-none rounded-none",
                              style:
                                "border-color: " +
                                _vm.block.data.attributes.border_button_color +
                                ";" +
                                "color: " +
                                _vm.block.data.attributes.text_color +
                                ";",
                              on: {
                                click: function ($event) {
                                  return _vm.leaveNpsFeedbackSecondStep(
                                    _vm.user.id
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.block.data.attributes.button_text
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm.feedbackStatus === "success-second-step"
          ? _c(
              "div",
              {
                staticClass:
                  "absolute w-full inset-x-0 top-0 flex flex-col items-center pt-15",
              },
              [
                _c(
                  "div",
                  {
                    style:
                      "color: " + _vm.block.data.attributes.text_color + ";",
                  },
                  [
                    _c("div", {
                      staticClass:
                        "text-2xl mt-4 text-center font-normal widest",
                      domProps: {
                        innerHTML: _vm._s(_vm.block.data.attributes.text),
                      },
                    }),
                  ]
                ),
              ]
            )
          : _c("div", [_c("Loading")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }