import axios from "axios";

const state = {
    productStockBalance: null,
    productStockBalanceStatus: 'initial',
};

const getters = {
    productStockBalance: state => {
        return state.productStockBalance;
    },
    productStockBalanceStatus: state => {
        return state.productStockBalanceStatus;
    },
};

const actions = {
    fetchProductStockBalance({commit, state, dispatch}, {productId, stockType}) {
        commit('setProductStockBalanceStatus', 'loading');
        axios.get('/api/stock-balances/' + productId + '/' + stockType)
            .then(res => {
                commit('setProductStockBalance', res.data)
                commit('setProductStockBalanceStatus', 'success')
            })
            .catch(error => {
                commit('setProductStockBalanceStatus', 'error')
            });
    },

};

const mutations = {
    setProductStockBalance(state, productStockBalance) {
        state.productStockBalance = productStockBalance;
    },
    setProductStockBalanceStatus(state, status) {
        state.productStockBalanceStatus = status;
    }
};


export default {
    state, getters, actions, mutations,
}


