<template>
<div>
    <div class="flex flex-col border-t border-b border-gray-lighter text-gray-main text-base text-center"
        :class="[block.data.attributes.half_size_image =='image-right' ? 'sm:flex-row' : 'sm:flex-row-reverse']">
            <div class="w-full sm:w-1/2 flex items-center">
                <div class="ml-10 mr-12 my-12 sm:max-w-md lg:mx-auto">

                    <h3 v-if="block.data.attributes.h3 != null" class="text-black text-16 font-normal tracking-widest">{{block.data.attributes.h3}}</h3>

                    <h2 v-if="block.data.attributes.h2 != null" class="mt-4 text-black text-xl font-normal tracking-widest lg:text-2xl">{{block.data.attributes.h2}}</h2>

                    <div v-html="block.data.attributes.text" v-if="block.data.attributes.text != null" class="mt-4 text-16 text-black mb-4 font-normal lg:text-17"></div>

                    <div v-if="block.data.attributes.link != null && block.data.attributes.text_on_link != null">
                        <a :href="block.data.attributes.link">
                            <button class="py-3 px-4 text-xs border-2  border-black text-black tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm">
                                {{block.data.attributes.text_on_link}}
                            </button>
                        </a>
                    </div >
                </div>
            </div>
            <a :href="block.data.attributes.link" class="w-full sm:w-1/2 sm:flex sm:items-center">
                <img class="w-full" :src=block.data.attributes.image.data.attributes.path  alt="">
            </a>
    </div>
</div>
</template>

<script>
export default {
name: "HalfSizeImageBlock",
    props: {
        block: null,
    },
}

</script>

<style scoped>

</style>
