import axios from "axios";
import router from "../../router";

const state = {

    lookbook: null,
    lookbookStatus: 'loading',
    lookbooks: null,
    lookbooksStatus: 'loading',

};

const getters = {
    lookbook: state => {
        return state.lookbook;
    },
    lookbookStatus: state => {
        return state.lookbookStatus;
    },
    lookbooks: state => {
        return state.lookbooks;
    },
    lookbooksStatus: state => {
        return state.lookbooksStatus;
    },

};

const actions = {
    fetchLookbook({commit, dispatch}, lookbookSlug) {
        commit('setLookbookStatus', 'loading')
        axios.get('/api/lookbooks/'+lookbookSlug+'/')
            .then(res => {
                commit('setLookbook', res.data)
                commit('setLookbookStatus', 'success')
            })
            .catch(error => {
                if (error.response.status === 404) {
                    router.push({name: 'NotFound'})
                }
                else{
                    commit('setLookbookStatus', 'error')
                }
            });
    },
    fetchLookbooks({commit, dispatch}) {
        axios.get('/api/lookbooks/')
            .then(res => {
                commit('setLookbooks', res.data)
                commit('setLookbooksStatus', 'success')
            })
            .catch(error => {
                commit('setLookbooksStatus', 'error')
            });
    },

};

const mutations = {
    setLookbook(state, lookbook) {
        state.lookbook = lookbook;
    },
    setLookbookStatus(state, status) {
        state.lookbookStatus = status;
    },
    setLookbooks(state, lookbooks) {
        state.lookbooks = lookbooks;
    },
    setLookbooksStatus(state, status) {
        state.lookbooksStatus = status;
    },

};

export default {
    state, getters, actions, mutations,
}
