<template>
    <div>
        <div v-if="pageStatus==='success'">
            <div v-for="page_block in page.data.attributes.page_blocks.data"
                 :key=page_block.data.page_block_id>

                <NpsBlock v-if="page_block.data.attributes.type==='nps' && page_block.data.attributes.active===1"
                               :block = page_block />
            </div>

        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import NpsBlock from "../../components/pageBlocks/NpsBlock";

export default {
    name: "Nps",
    components: {
        NpsBlock,
    },
    computed: {
        ...mapGetters({
            page:'page',
            pageStatus:'pageStatus',
            feedbackMindboxUserId: 'feedbackMindboxUserId',
            feedbackScore: 'feedbackScore',
            feedback: 'feedback',
            feedbackStatus: 'feedbackStatus',
            user: 'user',
        }),
    },
    methods: {
        ...mapActions({
            setFeedbackMindboxUserId: 'setFeedbackMindboxUserId',
            setFeedbackScore: 'setFeedbackScore',
            leaveNpsFeedbackFirstStep: 'leaveNpsFeedbackFirstStep',
        }),
    },
    mounted () {
        this.$store.dispatch('fetchPage', 'nps');
        this.setFeedbackMindboxUserId(this.$route.query.id);
        this.setFeedbackScore(this.$route.query.npsscore);
        if(this.feedbackMindboxUserId && this.feedbackScore){
            this.leaveNpsFeedbackFirstStep(this.user.id);
        }
    },
}
</script>

<style scoped>

</style>
