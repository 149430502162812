<template>
    <div data-popmechanic-embed="65299"></div>
</template>

<script>
export default {
    name: "EmailForCorpClientsSubscriptionPage"
}
</script>

<style scoped>

</style>
