var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        { staticClass: "w-full h-full flex items-center justify-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "my-12 text-gray-main uppercase font-normal tracking-wide text-center",
            },
            [
              _c("h2", { staticClass: "text-3xl" }, [_vm._v("404")]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-6 text-lg" }, [
                _vm._v("Такой страницы не существует"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-320 lg:mx-auto" }, [
        _c("div", { attrs: { "data-popmechanic-embed": "40996" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }