var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.email,
          expression: "email",
        },
      ],
      staticClass:
        "appearance-none border-gray-lightest rounded-none w-full h-10 px-5 focus:border-black focus:ring-0 focus:outline-none text-14",
      attrs: { id: "email", type: "text", placeholder: "Email" },
      domProps: { value: _vm.email },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.email = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _vm.$v.email.$invalid && _vm.$v.email.$dirty
      ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                "Пожалуйста, введите корректный email или номер телефона"
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    (_vm.isPasswordVisible ? "text" : "password") === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass:
            "mt-2 appearance-none border-gray-lightest text-gray-light rounded-none w-full h-10 px-5 focus:border-black focus:ring-0 focus:outline-none text-14",
          attrs: { id: "password", placeholder: "Пароль", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.password)
              ? _vm._i(_vm.password, null) > -1
              : _vm.password,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.password,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.password = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.password = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.password = $$c
              }
            },
          },
        })
      : (_vm.isPasswordVisible ? "text" : "password") === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass:
            "mt-2 appearance-none border-gray-lightest text-gray-light rounded-none w-full h-10 px-5 focus:border-black focus:ring-0 focus:outline-none text-14",
          attrs: { id: "password", placeholder: "Пароль", type: "radio" },
          domProps: { checked: _vm._q(_vm.password, null) },
          on: {
            change: function ($event) {
              _vm.password = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass:
            "mt-2 appearance-none border-gray-lightest text-gray-light rounded-none w-full h-10 px-5 focus:border-black focus:ring-0 focus:outline-none text-14",
          attrs: {
            id: "password",
            placeholder: "Пароль",
            type: _vm.isPasswordVisible ? "text" : "password",
          },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "text-11 text-black items-end lg:text-13",
        on: {
          click: function ($event) {
            return _vm.togglePasswordVisibility()
          },
        },
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.isPasswordVisible ? "Скрыть пароль" : "Показать пароль"
            ) +
            "\n    "
        ),
      ]
    ),
    _vm._v(" "),
    _vm.authLoadStatus
      ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
          _vm._v("\n        " + _vm._s("Загрузка...") + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.authWayErrorMessage
      ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
          _vm._v("\n        " + _vm._s(_vm.authWayErrorMessage) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14 border border-black bg-black",
        on: {
          click: function ($event) {
            return _vm.authorizeByCredentials()
          },
        },
      },
      [_vm._v("\n        " + _vm._s("Войти") + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass:
          "ml-26 w-full flex items-center justify-center text-11 text-gray-light lg:text-13",
        on: { click: _vm.closePopupBecauseOfForgotPassport },
      },
      [
        _c("router-link", { attrs: { to: { name: "ForgotPassword" } } }, [
          _vm._v(_vm._s("Забыли пароль ?")),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }