var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full px-6 lg:px-0 lg:w-256 lg:mx-auto" }, [
    _vm.$route.params.status === "success"
      ? _c("div", [
          _c(
            "h2",
            {
              staticClass:
                "my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center",
            },
            [_vm._v("\n            Спасибо за ваш заказ!")]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", [
              _vm._v("Заказ\n                "),
              _vm.order != null
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.order.data.order_id) + " "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.giftCertificateOrder != null
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.giftCertificateOrder.data
                            .gift_certificate_order_id
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm._v("\n                успешно оформлен.\n            "),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v("Спасибо!")]),
          ]),
        ])
      : _vm.$route.params.status === "error"
      ? _c("div", [
          _c(
            "h2",
            {
              staticClass:
                "my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center",
            },
            [_vm._v("\n            Ошибка")]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", [
              _vm._v("К сожалению, при оплате заказа\n                "),
              _vm.order !== null
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.order.data.order_id) + " "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.giftCertificateOrder !== null
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.giftCertificateOrder.data
                            .gift_certificate_order_id
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm._v("\n                произошла ошибка.\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v(
                  "Вы можете продолжить оформление заказа из\n                "
                ),
                _c(
                  "router-link",
                  { staticClass: "underline", attrs: { to: "/account" } },
                  [_vm._v("личного кабинета")]
                ),
                _vm._v(
                  "\n                ,\n                либо\n                "
                ),
                _c(
                  "router-link",
                  { staticClass: "underline", attrs: { to: "/info/contacts" } },
                  [_vm._v("связаться с нами")]
                ),
                _vm._v("\n                для уточнения деталей\n            "),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [_vm._v("Спасибо!")]),
          ]),
        ])
      : _vm.$route.params.status === "on_demand"
      ? _c("div", [
          _c(
            "h2",
            {
              staticClass:
                "my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center",
            },
            [_vm._v("\n            Спасибо за ваш заказ!")]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", [
              _vm._v("Ваш заказ\n                "),
              _vm.order !== null
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.order.data.order_id) + " "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.giftCertificateOrder !== null
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.giftCertificateOrder.data
                            .gift_certificate_order_id
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm._v("\n                оформлен.\n            "),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "При изменении статуса заказа, мы отправим email на вашу почту."
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "Если у вас остались вопросы свяжитесь с нами любым удобным способом:"
              ),
            ]),
            _vm._v(" "),
            _c("a", { attrs: { href: "tel:+78003010424" } }, [
              _vm._v("По телефону +7 800 301 04 24"),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("a", { attrs: { href: "https://wa.me/+78003010424" } }, [
              _vm._v("В WhatsApp: +7 800 301 04 24"),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("a", { attrs: { href: "mailto:hello@presentandsimple.com" } }, [
              _vm._v("По почте hello@presentandsimple.com"),
            ]),
          ]),
        ])
      : _vm.$route.params.status === "rejected"
      ? _c("div", [
          _c(
            "h2",
            {
              staticClass:
                "my-4 lg:my-10 w-full text-gray-main text-xl lg:text-2xl font-gothic font-normal uppercase tracking-wider text-center",
            },
            [_vm._v("\n            Платеж отклонен")]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", [
              _vm._v(
                "К сожалению, мы вынуждены отменить заказ\n                "
              ),
              _vm.order !== null
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.order.data.order_id) + " "),
                  ])
                : _vm._e(),
              _vm._v(
                " — сервис «Долями» отказывает в его\n                оплате.\n                "
              ),
              _vm._m(0),
            ]),
          ]),
        ])
      : _c("div", [_vm._v("\n        Произошла какая-то ошибка...\n    ")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [
      _vm._v(
        "\n                    Мы не знаем причину отказа, так как все заявки обрабатываются\n                    на стороне сервиса. "
      ),
      _c("br"),
      _vm._v("Обратитесь в службу поддержки покупателей "),
      _c(
        "a",
        { staticClass: "underline", attrs: { href: "https://dolyame.ru" } },
        [_vm._v("«Долями»")]
      ),
      _vm._v(
        "\n                    на сайте сервиса или в мобильном приложении.\n                    "
      ),
      _c("div", { staticClass: "my-2" }, [
        _vm._v(
          "Вы можете оформить покупку заново и оплатить ее любыми другими доступными\n                        способами.\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }