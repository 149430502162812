<template>
  <div>
    <div class="ml-5 text-11 text-gray-light lg:w-88 lg:text-13">
      Оплатите 25% от стоимости покупки, а оставшиеся 3 части спишутся автоматически с шагом в две недели.
      <a class="underlinedPositions"
         href="https://dolyame.ru/">Подробнее</a>
    </div>
    <div class="pr-5 mt-5 ml-5 h-20 bg-gray-lightest bg-opacity-30 lg:pr-0 lg:w-88">
      <div class="ml-6 grid grid-cols-4 lg:flex">
        <div v-for="(dateAndPartToPayDolyame, index) in dateAndPricesToPayDolyame"
             class="pl-1.5">
          <div class="mt-4"
               :class="index === 0 ? 'text-black' : 'text-gray-bright'">
            <div class="text-11">
              {{dateAndPartToPayDolyame.dateOfPay}}
            </div>
            <div class="mt-2 text-13">
              {{dateAndPartToPayDolyame.part | formatNumber}} ₽
            </div>
          </div>
          <div :class="index === 0 ? 'lineActive' : 'lineNoActive'"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: "DolyameCart",

  data: () =>({
    isMoreInfoAboutDolyameShown: false,
    options : {
      month: 'long',
      day: 'numeric',
    },
  }),
  props: {
    cart: null
  },
  methods: {
    ...mapActions ({
      getPricesForDolyame: 'getPricesForDolyame',
    }),
  },
  computed: {
    ...mapGetters ({
      dateAndPricesToPayDolyame: 'dateAndPricesToPayDolyame',
    }),
  },
  mounted() {
    this.$store.dispatch('getPricesForDolyame', this.cart)
  },
  watch: {
    cart() {
      this.$store.dispatch('getPricesForDolyame', this.cart)
    }
  }
}

</script>

<style scoped>
.underlinedPositions{
  text-decoration: underline;
  text-underline-offset: 3px;
}
.lineActive {
  width: 66px;
  border-top: 2px solid #C08950;
}
.lineNoActive {
  width: 66px;
  border-top: 2px solid #B6B6B6;
}
</style>
