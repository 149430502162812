var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { on: { click: _vm.open } },
        [_vm._t("accordion-trigger", null, { visible: _vm.visible })],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "accordion" },
          on: {
            enter: _vm.start,
            "after-enter": _vm.end,
            "before-leave": _vm.start,
            "after-leave": _vm.end,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
            },
            [_vm._t("accordion-content")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }