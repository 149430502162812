var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "hidden sm:block" }, [
      _c("div", { staticClass: "flex flex-row" }, [
        _c(
          "div",
          { staticClass: "w-1/2 flex items-center justify-end relative" },
          [
            _c("a", { attrs: { href: _vm.block.data.attributes.link } }, [
              _c("img", {
                staticClass: "object-cover",
                attrs: {
                  src: _vm.block.data.attributes.image.data.attributes.path,
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "absolute pr-6 text-white" }, [
              _c(
                "a",
                {
                  staticClass: "text-3xl",
                  attrs: { href: _vm.block.data.attributes.link },
                },
                [_vm._v(_vm._s(_vm.block.data.attributes.h2))]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-1/2 flex items-center justify-start relative" },
          [
            _c(
              "a",
              { attrs: { href: _vm.block.data.attributes.text_on_link } },
              [
                _c("img", {
                  attrs: {
                    src: _vm.block.data.attributes.sub_image.data.attributes
                      .path,
                    alt: "",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "absolute pl-6 text-white" }, [
              _c(
                "a",
                {
                  staticClass: "text-3xl",
                  attrs: { href: _vm.block.data.attributes.text_on_link },
                },
                [_vm._v(_vm._s(_vm.block.data.attributes.h3))]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "sm:hidden" }, [
      _c(
        "div",
        { staticClass: "w-full flex items-center justify-start relative" },
        [
          _c("a", { attrs: { href: _vm.block.data.attributes.link } }, [
            _c("div", {}, [
              _c("img", {
                staticClass: "object-cover",
                attrs: {
                  src: _vm.block.data.attributes.image_phone.data.attributes
                    .path,
                  alt: "",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "absolute pl-6 text-white" }, [
            _c(
              "a",
              {
                staticClass: "text-xl",
                attrs: { href: _vm.block.data.attributes.link },
              },
              [_vm._v(_vm._s(_vm.block.data.attributes.h2))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "absolute pl-6 mt-18 text-white" }, [
            _c(
              "a",
              {
                staticClass: "text-xl",
                attrs: { href: _vm.block.data.attributes.text_on_link },
              },
              [_vm._v(_vm._s(_vm.block.data.attributes.h3))]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }