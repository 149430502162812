<template>
    <div>
        <!-- Попап при успешном копировании-->
        <Popup
            v-if="isSuccessPromoCopied"
            @closePopup="closePopup">
            <div class="">
                <div class="mt-2 font-gothic text-gray-main">Промокод успешно скопирован</div>
            </div>
        </Popup>
        <!-- Попап при копировании с ошибкой -->
        <Popup
            v-if="isErrorPromoCopied"
            @closePopup="closePopup">
            <div class="">
                <div class="mt-2 font-gothic text-gray-main">Возникла ошибка при копировании промокода, попробуйте еще раз</div>
            </div>
        </Popup>
        <div class = "w-full sm:h-full">
            <div  class="w-full inset-x-0 top-0 pt-7">
                <div>
                    <div class="px-2 mb-7">
                        <div v-html="block.data.attributes.text" class="text-2xl mt-4 text-center font-normal widest"></div>
                        <div class=" text-center text-xl lg:my-4 lg:text-2xl">Ваш промокод: {{block.data.attributes.h2}}</div>
                        <div class="text-center text-md lg:text-xl">{{block.data.attributes.h3}}</div>
                        <div class="text-center text-md lg:text-xl">Приятных покупок!</div>
                        <div>
                            <div  class="mt-4 mx:auto flex items-center justify-center">
                                   <button v-clipboard:copy="promo"
                                           v-clipboard:success="onCopy"
                                           v-clipboard:error="onError"
                                           :style="'border-color: ' + block.data.attributes.border_button_color + ';'+'color: ' + block.data.attributes.text_color + ';'"
                                           class="py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm">{{ block.data.attributes.button_text }}
                                   </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Popup from "../popup/Popup";

export default {
    name: "PromoBlock",
    props: {
        block: null,
    },
    components: {
        Popup
    },
    data: function () {
        return {
            promo: this.block.data.attributes.h2,

            isSuccessPromoCopied:false,
            isErrorPromoCopied:false,
        }
    },
    methods: {
        onCopy: function (e) {
            this.isSuccessPromoCopied = true
        },
        onError: function (e) {
            this.isErrorPromoCopied = true
        },
        closePopup(){
            this.isSuccessPromoCopied = false
            this.isErrorPromoCopied=false
        },
    },
}
</script>

<style scoped>

</style>
