<template>
    <div>
        <!-- Desktop -->
        <div class="hidden sm:block">
            <div class="flex flex-row">
                <div class="w-1/2 flex items-center justify-end relative">
                    <a class="" :href="block.data.attributes.link">
                        <img class="object-cover" :src=block.data.attributes.image.data.attributes.path alt="">
                    </a>
                    <div  class="absolute pr-6 text-white">
                        <a :href="block.data.attributes.link" class="text-3xl">{{block.data.attributes.h2}}</a>
                    </div>
                </div>
                <div class="w-1/2 flex items-center justify-start relative">
                    <a class="" :href="block.data.attributes.text_on_link">
                        <img class="" :src=block.data.attributes.sub_image.data.attributes.path alt="">
                    </a>
                    <div  class="absolute pl-6 text-white">
                        <a :href="block.data.attributes.text_on_link" class="text-3xl">{{block.data.attributes.h3}}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Mobile -->
        <div class="sm:hidden">
            <div class="w-full flex  items-center justify-start relative">
                <a class="" :href="block.data.attributes.link">
                    <div class="">
                        <img class="object-cover" :src=block.data.attributes.image_phone.data.attributes.path alt="">
                    </div>
                </a>
                <div class="absolute pl-6 text-white">
                    <a :href="block.data.attributes.link" class="text-xl">{{block.data.attributes.h2}}</a>
                </div>
                <div class="absolute pl-6 mt-18 text-white">
                    <a :href="block.data.attributes.text_on_link" class="text-xl">{{block.data.attributes.h3}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TwoHalfSizeImageBlock",
    props: {
        block: null,
    },
}
</script>

<style scoped>

</style>
