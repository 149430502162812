var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-7 mb-8" }, [
    _c("div", { staticClass: "w-full -ml-3 flex items-left justify-start" }, [
      _c(
        "div",
        { staticClass: "flex grid grid-cols-10" },
        _vm._l(_vm.computedObj, function (color) {
          return _c("div", [
            _c(
              "div",
              { staticClass: "mt-2 ml-3" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Product",
                        params: {
                          category: color.links.meta.categorySlug,
                          product: color.links.meta.productSlug,
                        },
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          color.data.product_id === _vm.product.data.product_id
                            ? "p-0.5 -mt-1 flex items-center justify-center border border-black rounded-full"
                            : "flex items-center justify-center rounded-full",
                      },
                      [
                        color.data.attributes.color.data.attributes.code
                          ? _c("div", {
                              staticClass: "w-4 h-4 rounded-full",
                              style:
                                "background-color: " +
                                color.data.attributes.color.data.attributes
                                  .code +
                                ";",
                            })
                          : color.data.attributes.color.data.attributes.image
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-4 h-4 rounded-full overflow-hidden",
                              },
                              [
                                _c("img", {
                                  staticClass: "object-cover",
                                  attrs: {
                                    src: color.data.attributes.color.links
                                      .color_image,
                                    alt: "product color image",
                                  },
                                }),
                              ]
                            )
                          : _c("div", {
                              staticClass:
                                "w-4 h-4 border border-border-gray-light rounded-full overflow-hidden",
                            }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-2" }, [
      _c("div", { staticClass: "text-11 font-normal text-black" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.product.data.attributes.color.data.attributes.title) +
            "\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }