import router from "../../router";
import axios from "axios";

const state = {
    userEmail: null,
    userPhone: null,
    userName: null,
    userSurname:null,


    userOrders: null,
    userOrdersStatus: 'loading'
};

const getters = {
    userOrders: state => {
        return state.userOrders;
    },
    userOrdersStatus: state => {
        return state.userOrdersStatus;
    },

    userEmail: state => {
        return state.userEmail;
    },
    userPhone: state => {
        return state.userPhone;
    },
    userName: state => {
        return state.userName;
    },
    userSurname: state => {
        return state.userSurname;
    },
};

const actions = {


    fetchUserOrders({commit, dispatch}) {
        commit('SET_USER_ORDERS_STATUS', 'loading')

        axios.get('/api/user/orders')
            .then(res => {
                commit('SET_USER_ORDERS', res.data)
                commit('SET_USER_ORDERS_STATUS', 'success')
            })
            .catch(error => {
                commit('SET_USER_ORDERS_STATUS', 'error')

            });
    },

};

const mutations = {
    SET_USER_ORDERS (state, orders) {
        state.userOrders = orders ;
    },
    SET_USER_ORDERS_STATUS (state, status) {
        state.userOrdersStatus = status
    },


};

export default {
    state, getters, actions, mutations
}
