var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PopupOnRightSide",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c("div", [
        _vm.subscribeItemStatus !== "success"
          ? _c("div", { staticClass: "w-full h-full" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "mt-15 text-18 text-black line-height-23" },
                  [
                    _vm._v(
                      "\n                    Сообщить о поступлении\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-3 text-14 text-black line-height-15" },
                  [
                    _vm._v(
                      "\n                    Подпишитесь и узнайте первыми, когда товар поступит в продажу\n                "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-8" }, [
                _vm.getVerificationOfUser
                  ? _c(
                      "div",
                      [
                        _c("AuthByPhone", {
                          on: {
                            authByPhoneSuccessClosePopup:
                              _vm.authByPhoneSuccessClosePopup,
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          staticClass:
                            "mt-6 appearance-none text-11 border outline-none border-gray-lighter text-gray-light rounded-none w-full h-10 px-2 focus:outline-none focus:border-black lg:text-13 no-spinner pointer-events-none",
                          attrs: {
                            readonly: "",
                            id: "name",
                            type: "text",
                            placeholder: "Ваше имя",
                          },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.email,
                              expression: "form.email",
                            },
                          ],
                          staticClass:
                            "mt-2 appearance-none text-11 border outline-none border-gray-lighter text-gray-light rounded-none w-full h-10 px-2 focus:outline-none focus:border-black lg:text-13 no-spinner pointer-events-none",
                          attrs: {
                            readonly: "",
                            id: "email",
                            type: "email",
                            placeholder: "Email",
                          },
                          domProps: { value: _vm.form.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "email", $event.target.value)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone",
                          },
                        ],
                        staticClass:
                          "appearance-none text-11 border outline-none border-gray-lighter text-gray-light rounded-none w-full h-10 px-2 focus:outline-none focus:border-black lg:text-13 no-spinner pointer-events-none",
                        attrs: { id: "phone", readonly: "" },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass:
                          "h-10 w-full mt-3 pl-4 text-11 font-normal border-1 outline-none border-gray-lighter focus:box-border focus:border focus:border-action-black focus:text-black hover:border-black focus:border-black lg:text-13",
                        class: [
                          _vm.$v.form.name.$error
                            ? "border-red-400"
                            : "border-gray-lighter",
                        ],
                        attrs: { id: "name", type: "text", placeholder: "Имя" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.form.name.$invalid
                        ? _c(
                            "div",
                            { staticClass: "pl-4 text-red-400 text-11" },
                            [
                              _vm.$v.form.name.$error
                                ? _c("span", [_vm._v("Введите имя")])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.surname,
                            expression: "form.surname",
                          },
                        ],
                        staticClass:
                          "h-10 w-full mt-3 pl-4 text-11 font-normal border-1 outline-none border-gray-lighter focus:box-border focus:border focus:border-action-black focus:text-black hover:border-black focus:border-black lg:text-13",
                        class: [
                          _vm.$v.form.surname.$error
                            ? "border-red-400"
                            : "border-gray-lighter",
                        ],
                        attrs: {
                          id: "name",
                          type: "text",
                          placeholder: "Фамилия",
                        },
                        domProps: { value: _vm.form.surname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "surname", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.form.surname.$invalid
                        ? _c(
                            "div",
                            { staticClass: "pl-4 text-red-400 text-11" },
                            [
                              _vm.$v.form.surname.$error
                                ? _c("span", [_vm._v("Введите фамилию")])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.email,
                              expression: "form.email",
                            },
                          ],
                          staticClass:
                            "h-10 w-full mt-3 pl-4 text-11 font-normal border-1 outline-none focus:box-border focus:border focus:border-action-black focus:text-black hover:border-black focus:border-black lg:text-13",
                          class: [
                            _vm.$v.form.email.$error
                              ? "border-red-400"
                              : "border-gray-lighter",
                          ],
                          attrs: {
                            id: "email",
                            type: "email",
                            placeholder: "Email",
                          },
                          domProps: { value: _vm.form.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "email", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.form.email.$invalid
                          ? _c(
                              "div",
                              { staticClass: "pl-4 text-red-400 text-11" },
                              [
                                _vm.$v.form.email.$error
                                  ? _c("span", [_vm._v("Неверный email")])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.subscribeItemStatus === "loading"
                        ? _c("div", { staticClass: "text-xs text-gray-main" }, [
                            _vm._v(
                              "\n                        Загрузка..\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subscribeItemStatus === "error"
                        ? _c("div", { staticClass: "text-xs text-red-500" }, [
                            _vm._v(
                              "\n                        Ошибка!\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessage
                        ? _c(
                            "p",
                            { staticClass: "text-11 text-red-light-2 mt-2" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.errorMessage) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-4 text-sm text-gray-main flex items-center",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.checkbox,
                                expression: "form.checkbox",
                              },
                            ],
                            staticClass:
                              "h-4 w-4 form-checkbox text-black border border-black border-transparent focus:border-brown-light focus:outline-none",
                            attrs: {
                              type: "checkbox",
                              name: "checkbox",
                              checked: "",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.form.checkbox)
                                ? _vm._i(_vm.form.checkbox, null) > -1
                                : _vm.form.checkbox,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.checkbox,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "checkbox",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "checkbox",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "checkbox", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-2" }, [
                            _vm._v("Подписаться на рассылку"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-full mt-2 h-9 py-1 px-5 text-white r focus:outline-none text-14 border border-black bg-black",
                          on: {
                            click: function ($event) {
                              return _vm.subscribeToProductAvailability(
                                _vm.product,
                                _vm.form
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Сообщить о поступлении\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-xs mt-2" },
                        [
                          _vm._v(
                            "\n                        Нажимая на кнопку «сообщить», я соглашаюсь на обработку моих персональных данных и\n                        ознакомлен(а) с\n                        "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "underline",
                              attrs: {
                                to: "/info/privacy-policy",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "условиями\n                            конфиденциальности\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
              ]),
            ])
          : _c("div", { staticClass: "w-full h-full mt-15" }, [
              _c("div", [
                _c("div", { staticClass: "-mt-3 text-gray-main text-2xl" }, [
                  _vm._v("Спасибо!"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-4 text-gray-main" }, [
                  _vm._v("Подписка на товар оформлена"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-4 text-gray-main underline cursor-pointer text-center",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("closePopup")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Продолжить покупки\n                "
                    ),
                  ]
                ),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }