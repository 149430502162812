<template>
    <div v-if="pageStatus==='success'">
        <div v-for="page_block in page.data.attributes.page_blocks.data"
             :key=page_block.data.page_block_id>
            <PromoBlock v-if="page_block.data.attributes.type==='promo' && page_block.data.attributes.active===1"
                      :block = page_block />
        </div>
    </div>
</template>

<script>
import PromoBlock from "../components/pageBlocks/PromoBlock";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "PromoPage",
    components: {
        PromoBlock,
    },
    computed: {
        ...mapGetters({
            page:'page',
            pageStatus:'pageStatus',
        }),
    },
    methods: {
        ...mapActions({
        }),
    },
    mounted () {
        this.$store.dispatch('fetchPage', 'promo');

    },
}
</script>

<style scoped>

</style>
