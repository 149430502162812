var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "xl:w-475 xl:mx-auto" },
    [
      _vm.categoryIdForNonSwipableBlock !== null
        ? _c(
            "div",
            [
              _c("NonSwipableCategoryBlock", {
                attrs: {
                  "category-id": _vm.categoryIdForNonSwipableBlock,
                  categoryTitle: this.categoryTitle,
                  "block-type": "non-swipable-category-block",
                  "gtm-list": "Категории товаров",
                  "gtm-page-type": "category",
                },
              }),
            ],
            1
          )
        : _vm.categoryStatus === "error"
        ? _c("NotFound")
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { "data-popmechanic-embed": "93144" } }),
      _vm._v(" "),
      _c("div", { attrs: { "data-popmechanic-embed": "93079" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }