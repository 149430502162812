<template>
  <div class="flex justify-center">
    <div data-popmechanic-embed="73451"></div>
  </div>
</template>

<script>
export default {
  name: "OfflineTsvetnoyLendingMindboxPage"
}
</script>

<style scoped>

</style>






