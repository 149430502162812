<template>
    <div>
        <div v-for="(item, index) in cart.data.relationships.orderLines.data"
             :key="'cart_item_'+item.relationships.product.data.product_id+'size'+item.relationships.size_id" >
            <div v-if="item.relationships.product.data.attributes.isPreorder === true" class="pt-4 text-11 text-brown-dark text-left lg:text-13">
                Вы оформляете предзаказ на эту модель. Ориентировочная дата отправки
                — с {{  new Date(item.relationships.product.data.attributes.preorderDeliveryDateFrom).toLocaleDateString('ru').slice(0,5) }}
                по {{ new Date(item.relationships.product.data.attributes.preorderDeliveryDateTo ).toLocaleDateString('ru').slice(0,5)}} включительно
            </div>
            <div :class="[index === (cart.data.relationships.orderLines.data.length - 1) ? 'flex py-4 justify-between' : 'flex py-4 justify-between border-b border-gray-lighter']">
                <div class="flex">
                    <div class="relative">
                        <div :class="[(item.attributes.isProductActive === false || item.attributes.orderQuantity === 0 )? 'w-21 h-full flex-none overflow-hidden lg:w-26 opacity-25' : 'w-21 h-full flex-none overflow-hidden lg:w-26']">
                            <router-link :to="{ name: 'Product', params: { category: item.relationships.product.links.meta.categorySlug, product: item.relationships.product.links.meta.productSlug}}">
                                <img class="w-full object-cover" :src="item.relationships.product.data.attributes.images.data[0].data.attributes.path" :alt="item.relationships.product.data.attributes.title">
                            </router-link>
                        </div>
                        <div v-if="item.attributes.isProductActive === false || item.attributes.orderQuantity === 0"
                             class="absolute top-12 left-1 z-10 text-black text-13 opacity-block opacity-100 lg:top-15 lg:left-3">
                            Нет в наличии
                        </div>
                    </div>
                    <div :class="[(item.attributes.isProductActive === false || item.attributes.orderQuantity === 0)? 'ml-4 lg:ml-5 opacity-25' : 'ml-4 lg:ml-5 ']">
                        <div class="uppercase text-11 text-black font-normal lg:w-95 lg:text-16">
                            <router-link :to="{ name: 'Product', params: { category: item.relationships.product.links.meta.categorySlug, product: item.relationships.product.links.meta.productSlug}}">
                                {{item.relationships.product.data.attributes.title}}
                            </router-link>
                        </div>
                        <div class="items-center text-11 text-gray-light font-normal lg:text-16 ">
                            <div>
                                Артикул: {{item.relationships.product.data.attributes.article_number}}
                            </div>
                            <div class="flex mt-0.5 items-center">
                                <div class="w-3.5 h-3.5 mr-1.5 border border-black rounded rounded-full flex items-center justify-center">
                                    <div v-if="item.relationships.product.data.attributes.color.data.attributes.code"
                                         class="w-2.5 h-2.5 rounded rounded-full border-border-gray-light"
                                         :style="'background-color: ' + item.relationships.product.data.attributes.color.data.attributes.code + ';'">
                                    </div>
                                </div>
                                <div class="">
                                    {{item.relationships.product.data.attributes.color.data.attributes.title}}
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 uppercase text-11 text-black font-normal lg:text-16">
                            <CartSizeSelect
                                :sizes="item.relationships.product.data.attributes.sizes.data"
                                :selectedSize="item.attributes.sizeId"
                                @changeSize="changeOrderLineSizeInCart(index, $event)"/>
                        </div>
                    </div>
                </div>
                <div class="grid justify-items-end">
                    <div class="pr-2 focus:outline-none">
                        <button  @click="showSureWantToDeletePopup(item.id, index)" class="hidden lg:block">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 10L10 1" stroke="#939393" stroke-linecap="square"/>
                                <path d="M1 1.00024L10 10.0002" stroke="#939393" stroke-linecap="square"/>
                            </svg>
                        </button>
                        <button @click="showSureWantToDeletePopup(item.id, index)" class="lg:hidden">
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.00001 8.07107L8.07108 1" stroke="#939393" stroke-linecap="square"/>
                                <path d="M1.00001 1.00022L8.07108 8.07129" stroke="#939393" stroke-linecap="square"/>
                            </svg>
                        </button>
                    </div>
                    <Popup v-if="isCustomerSureWantsToDeleteOrderLinePopupVisible"
                           @closePopup="closePopup">
                        <div class="mt-4 w-full justify-center items-center flex-none text-gray-main">
                            <div class="flex-none justify-items-center">
                                <div class="mt-4 w-full text-center text-xl justify-center flex-none">
                                    Удалить товар из корзины?
                                </div>
                                <div class="flex items-center justify-center mt-5">
                                    <button @click="closePopup" class="w-30 h-10 py-2 px-5 bg-black text-white text-16 focus:outline-none">
                                        Оставить
                                    </button>
                                </div>
                                <div class="flex items-center justify-center mt-2">
                                    <button @click="deleteOrderLineFromCart()"
                                            class="w-30 mt-2 h-10 py-2 px-5 bg-white text-black border border-black text-16 focus:outline-none">
                                        Удалить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Popup>
                    <div class="w-30 grid justify-items-end content-end lg:w-45">
                        <div v-if="item.attributes.isProductActive === false || item.attributes.orderQuantity === 0"
                             class="w-30 opacity-25 grid justify-items-end content-end lg:w-45">
                            <div>
                                <div class="w-21 h-10  flex items-end justify-between text-11 text-gray-light lg:text-16">
                                    <button class="w-8 h-full focus:outline-none">
                                        -
                                    </button>
                                    <div class="w-9 h-full flex items-center justify-center">0</div>
                                    <button class="w-8 h-full focus:outline-none">
                                        +
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div class="mr-2 flex text-11 text-black text-right lg:text-16">
                                    {{item.attributes.totalPrice | formatNumber}} ₽
                                </div>
                            </div>
                        </div>
                        <div v-else class="w-30 grid justify-items-end content-end lg:w-45">
                            <div>
                                <CartIncrementDecrementQuantityOfOrderLine
                                    :item="item"
                                    :index="index"
                                    :cartItemsForMindbox="cartItemsForMindbox"/>
                            </div>
                            <div>
                                <div v-if="item.attributes.totalDiscount === 0" class="mr-2 flex text-11 text-black text-right lg:text-16">
                                    {{item.attributes.totalPrice | formatNumber}} ₽
                                </div>
                                <div v-else class="flex pr-2 text-11 text-black text-right lg:text-16">
                                    <div>{{item.attributes.totalPrice | formatNumber}} ₽ </div>
                                    <span class="ml-3 text-gray-light text-right text-11 font-normal line-through lg:text-16">{{item.attributes.basicPrice | formatNumber}} ₽</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CartSizeSelect from "./CartSizeSelect.vue";
import CartIncrementDecrementQuantityOfOrderLine from "./CartIncrementDecrementQuantityOfOrderLine.vue";
import {mapActions} from "vuex";
import Popup from "../popup/Popup.vue";

export default {
    name: "CartOrderLines",
    components: {Popup, CartIncrementDecrementQuantityOfOrderLine, CartSizeSelect},
    data: () =>({
        isCustomerSureWantsToDeleteOrderLinePopupVisible: false,
        orderLineIdForDeletion: null,
        orderLineIdForDeletionGtm: null,
    }),
    props:{
        cart:null,
        cartItemsForMindbox:null,
        cartItemsForEccomGTM:null,
        cartItemsForGTM:null,
    },
    methods:{
        ...mapActions({
            getCart:'getCart',
            deleteOrderLineFromCartVuex:'deleteOrderLineFromCart',
            changeOrderLineSizeInCartVuex:'changeOrderLineSizeInCart',
        }),
        async changeOrderLineSizeInCart(index, size) {
            let cartOrderLine = this.cart.data.relationships.orderLines.data[index]
            let cartOrderLineId = this.cart.data.relationships.orderLines.data[index].id
            await this.changeOrderLineSizeInCartVuex({size, cartOrderLine, cartOrderLineId})
                .then(() => {
                    this.sendMindboxCartToGTM()
                })
        },
        showSureWantToDeletePopup(itemId, index) {
            this.orderLineIdForDelition = null
            this.orderLineIdForDelitionGtm = null

            this.orderLineIdForDeletion = itemId
            this.orderLineIdForDeletionGtm = index
            this.isCustomerSureWantsToDeleteOrderLinePopupVisible = true
        },
        async deleteOrderLineFromCart() {
            let cartOrderLineId = this.cart.data.relationships.orderLines.data[this.orderLineIdForDeletionGtm]

            await this.$gtm.trackEvent({
                event: 'flushVariables',
                ecommerce: null,
            });
            await this.$gtm.trackEvent({
                event: "remove_from_cart", // Event type [default = 'interaction'] (Optional)
                ecommerce: {
                    remove: {
                        actionField: {
                            list: 'Корзина'
                        },
                        products: [{
                            name: cartOrderLineId.relationships.product.data.attributes.title,
                            id: cartOrderLineId.relationships.product.data.attributes.article_number,
                            price: cartOrderLineId.attributes.price,
                            category: cartOrderLineId.relationships.product.data.attributes.category.data.attributes.title,
                            quantity: cartOrderLineId.attributes.userQuantity,
                            variant: cartOrderLineId.relationships.product.data.attributes.article_number + "" + cartOrderLineId.attributes.sizeId,
                        }]
                    }
                }
            });

            await this.deleteOrderLineFromCartVuex(this.orderLineIdForDeletion)
                .then(() => {
                    this.isCustomerSureWantsToDeleteOrderLinePopupVisible = false
                    this.sendMindboxCartToGTM()
                })
        },
        initiateAndBeginCheckoutSendInfoToGTM(){
            this.$gtm.trackEvent({
                event: "initiateCheckout", // Event type [default = 'interaction'] (Optional)
                cartItems: this.cartItemsForGTM,
                cartAmount: this.cart.data.attributes.priceToPay,
                cartNumberOfItems: this.cart.data.attributes.itemsQuantity,
                noninteraction: false,
            });

            //Расширенная электронная торговля
            this.$gtm.trackEvent({
                event: 'flushVariables',
                ecommerce: null,
            });
            this.$gtm.trackEvent({
                event: 'begin_checkout', // Event type [default = 'interaction'] (Optional)
                pageType: 'cart',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1
                        },
                        products: this.cartItemsForEccomGTM,
                    }
                },
            });

        },
        closePopup(){
            this.isCustomerSureWantsToDeleteOrderLinePopupVisible = false
        },
    },
    mounted() {
//        this.sendMindboxCartToGTM()
        this.initiateAndBeginCheckoutSendInfoToGTM()
    }
}
</script>

<style scoped>

</style>
