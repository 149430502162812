<template>
    <div class="mb-10">
      <CartMain :type="'cart'"
                :cart="cart"
                :preorder-cart="preorderCart" />
        <div v-if="cart?.data?.relationships?.orderLines?.data?.length > 0" class="w-full px-5 mx-auto overflow-hidden lg:w-240 xl:w-360 ">

<!-- Order lines with additional order lines-->
            <CartOrderLines :cart="cart"
                            :cartItemsForMindbox="cartItemsForMindbox"
                            :cartItemsForEccomGTM="cartItemsForEccomGTM"
                            :cartItemsForGTM="cartItemsForGTM"/>
            <CartAdditionalOrderLines :cart="cart"/>
            <div class="flex-none mt-6 lg:flex">
<!-- PromoCode and GiftCertificate -->
                <div class="w-full lg:w-1/2">
                    <CartPromoCodeBlock :cart="cart"/>
                    <CartGiftCertificateBlock :cart="cart"/>
                </div>
                <div class="w-full mt-10 lg:w-1/2 lg:mt-0 lg:ml-15">
                    <CartDeliveryAndReturnInfo />
<!-- Cart total -->
                    <div>
                        <CartTotalCalculations :cart="cart" />
                        <div class="flex items-center justify-center">
                            <button @click="createOrder"
                                    :class="[isUserIdentifiedAndUnauthorizedButtonActive
                                    || cart.data.attributes.itemsQuantity < 1
                                    ? 'w-full h-10 py-2 px-5 bg-gray-light text-white text-16 r focus:outline-none'
                                    : 'w-full h-10 py-2 px-5 bg-black text-white text-16 r focus:outline-none']">
                                <span>Оформить заказ</span>
                            </button>
                        </div>
                    </div>
                    <!-- Блок для пользователя верифицированного и не авторизованного-->
<!--                    <div v-if="isUserIdentifiedAndUnauthorizedButtonActive && isUserIdentifiedAndUnauthorized">
                        <div class="flex items-center justify-center my-5 text-14">
                            {{ "Это ваш номер телефона " + userIdentifiedAndUnauthorizedPhone + " ?" }}
                        </div>
                        <div class="flex w-full mt-5">
                            <div class="w-1/2">
                                <button @click="authUserByIdentification"
                                        class="w-full h-7 py-1 px-1 bg-black text-white text-11 r focus:outline-none lg:text-13">
                                    <span>{{ "Да" }}</span>
                                </button>
                            </div>
                            <div class="w-1/2 ml-2">

                                    <button @click="logoutUser"
                                        class="w-full h-7 py-1 px-5 border border-black bg-white text-black text-11 r focus:outline-none lg:text-13">
                                        <span>{{ "Нет" }}</span>
                                    </button>
                            </div>
                        </div>
                    </div>-->
                    <div v-if="loginInCart">
                        <Login :isPageCart="true"/>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="flex items-center justify-center text-11 my-10 lg:text-24">Ваша корзина пуста</div>
        </div>
        <div class="w-full px-5 mx-auto overflow-hidden lg:w-240 xl:w-360">
          <div data-popmechanic-embed="40986"></div>
          <div data-popmechanic-embed="100897"></div>
        </div>
    </div>
</template>

<script>

import {Navigation, Swiper, Thumbs} from "swiper";
import 'swiper/swiper-bundle.min.css';
import {mapActions, mapGetters} from "vuex";
import CartSizeSelect from "../components/Cart 2.0/CartSizeSelect.vue";
import Login from "../components/Auth/Login"
import CartPromoCodeBlock from "../components/Cart 2.0/CartPromoCodeBlock.vue";
import CartGiftCertificateBlock from "../components/Cart 2.0/CartGiftCertificateBlock.vue";
import CartIncrementDecrementQuantityOfOrderLine
    from "../components/Cart 2.0/CartIncrementDecrementQuantityOfOrderLine.vue";
import CartTotalCalculations from "../components/Cart 2.0/CartTotalCalculations.vue";
import CartOrderLines from "../components/Cart 2.0/CartOrderLines.vue";
import CartAdditionalOrderLines from "../components/Cart 2.0/CartAdditionalOrderLines.vue";
import CartDeliveryAndReturnPopups from "../components/Cart 2.0/CartDeliveryAndReturnInfo.vue";
import CartDeliveryAndReturnInfo from "../components/Cart 2.0/CartDeliveryAndReturnInfo.vue";
import CartMain from "./cart/CartMain.vue";
import preorderCart from "./cart/PreorderCart.vue";

Swiper.use([Thumbs, Navigation]);

export default {
    name: "Cart2",
    components: {
        CartMain,
        CartDeliveryAndReturnInfo,
        CartDeliveryAndReturnPopups,
        CartAdditionalOrderLines,
        CartOrderLines,
        CartTotalCalculations,
        CartIncrementDecrementQuantityOfOrderLine,
        CartGiftCertificateBlock,
        CartPromoCodeBlock,
        CartSizeSelect,
        Login
    },
    data: () => ({
        isUserIdentifiedAndUnauthorizedButtonActive: false,
        loginInCart: false,
        isCustomerSureWantsToDeleteOrderLinePopupVisible: false,
        userIdentifiedAndUnauthorizedPhone: false,
    }),
    methods: {
        ...mapActions({
            getCartVuex: 'getCart',
            addOrderLineToCartVuex: 'addOrderLineToCart',
            deleteOrderLineFromCartVuex: 'deleteOrderLineFromCart',
            changeOrderLineSizeInCartVuex: 'changeOrderLineSizeInCart',
            signOut: 'signOut',
            signIn: 'signIn',
            authIdentifiedAndUnauthorizedUserByNewPhone: 'authIdentifiedAndUnauthorizedUserByNewPhone',
        }),
        sendMindboxCartToGTM() {
            this.$gtm.trackEvent({
                event: "flushVariables",
                cartForMindbox: undefined,

            });
            if(this.cart?.data?.attributes?.itemsQuantity > 0) {
                this.$gtm.trackEvent({
                    event: "cartChange", // Event type [default = 'interaction'] (Optional)
                    cartForMindbox: this.cartItemsForMindbox,
                    noninteraction: false,
                });
            } else {
                this.$gtm.trackEvent({
                    event: "cartFlush",
                    noninteraction: false,
                });
            }
        },
        createOrder() {
             if(this.cart.data.attributes.itemsQuantity < 1 ) {
                 this.isUserIdentifiedAndUnauthorizedButtonActive = false
             } else {
                 this.isUserIdentifiedAndUnauthorizedButtonActive = true
                 window.location.href = '/cart-checkout'
             }
        },
        async authUserByIdentification() {
            await this.signIn({'phone' : this.authInfo.relations.unAuthUser?.data.attributes.phone} )
            window.location.href = '/cart-checkout'
        },

        async queryString() {
            if (this.$route.query.action) {
                if (typeof (this.$route.query.orderlines) === "string") {
                    let productId = Number(this.$route.query.orderlines.split('_')[0]);
                    let sizeId = Number(this.$route.query.orderlines.split('_')[1]);
                    await this.addOrderLineToCartVuex([productId, sizeId])
                    await this.getCartVuex()
                } else {
                    let indexOfTheOrderLine = 0
                    while (indexOfTheOrderLine < this.$route.query.orderlines.length) {
                        let productId = Number(this.$route.query.orderlines[indexOfTheOrderLine].split('_')[0]);
                        let sizeId = Number(this.$route.query.orderlines[indexOfTheOrderLine].split('_')[1]);
                        await this.addOrderLineToCartVuex([productId, sizeId])
                        await this.getCartVuex()
                        indexOfTheOrderLine++
                    }
                }
                this.$router.replace('/cart')
            }
        },

        loginToCreateOrder() {
            this.loginInCart = true
        },
        changeOrderLineSizeInCart(index, size) {
            let cartOrderLine = this.cart.data.relationships.orderLines.data[index]
            let cartOrderLineId = this.cart.data.relationships.orderLines.data[index].id
            this.changeOrderLineSizeInCartVuex({size, cartOrderLine, cartOrderLineId})
        },
        deleteOrderLineFromCart() {
            this.isCustomerSureWantsToDeleteOrderLinePopupVisible = true
        },
        closePopup() {
            this.isCustomerSureWantsToDeleteOrderLinePopupVisible = false
        },
    },
    computed: {
        isUserIdentifiedAndUnauthorized() {
            this.userIdentifiedAndUnauthorizedPhone = this.authInfo?.relations.unAuthUser?.data.relations.authUser.data.attributes.phone
            return !this.authInfo.authStatus && this.authInfo.identificationStatus
        },

        ...mapGetters({
            cart: 'cart',
            preorderCart: 'preorderCart',
            cartStatus: 'cartStatus',
            authInfo: 'authInfo',
        }),
        cartItemsForGTM() {
            //collect an array of articles
            let result = [];
            for (let item of this.cart.data.relationships.orderLines.data) {
                result.push(item.relationships.product.data.attributes.article_number);
            }
            return result;
        },
        cartItemsForEccomGTM() {
            let result = [];
            for (let item of this.cart.data.relationships.orderLines.data) {
                result.push({
                    name: item.relationships.product.data.attributes.title,
                    id: item.relationships.product.data.attributes.article_number,
                    price: item.attributes.price,
                    category: item.relationships.product.data.attributes.category.data.attributes.title,
                    quantity: item.attributes.userQuantity,
                    variant: item.relationships.product.data.attributes.article_number + "" + item.attributes.sizeId,

                })
            }
            return result;
        },
        cartItemsForMindbox() {
            let result = [];
            for (let item of this.cart.data.relationships.orderLines.data) {
                result.push(
                    {
                        "product": {
                            "ids": {
                                "website": item.relationships.product.data.attributes.article_number + "" + item.attributes.sizeId
                            }
                        },
                        "count": item.attributes.userQuantity,
                        "priceOfLine": item.attributes.totalPrice
                    }
                );
            }
            return result;
        },
    },
    mounted() {
        this.getCartVuex()
        this.$store.dispatch('getAuthStatus')
        this.$store.dispatch('getPreorderCart')
        this.queryString()
        this.sendMindboxCartToGTM()
    },
}
</script>
<style scoped>

</style>
