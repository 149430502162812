<template>
    <div>
        <Popup v-if="isPreorderCartPopupActive"
               @closePopup="closePopup">
            <div class="my-4 w-full justify-center items-center flex-none text-black">
                <div class="flex-none justify-items-center">
                    <div class="mt-4 w-full text-center text-18 justify-center flex-none">
                        Условия предзаказа
                    </div>
                    <div class="mt-5 w-full flex-none justify-center">
                        <div class="text-left text-14 text-black">
                            Предзаказ доступен по полной предоплате.
                        </div>
                        <div class="mt-3 text-left text-14 text-black">
                            Стоимость забронированных вещей нельзя оплатить
                            подарочными сертификатами или использовать промокод.
                        </div>
                        <div class="mt-3 text-left text-14 text-black">
                            Оформить экспресс-доставку или доставку
                            в дальнее зарубежье для предзаказа не получится. Доступные способы получить заказ:
                        </div>

                        <div class="mt-5">
                            <div class="text-left text-14 text-black">
                                Самовывоз из магазинов в Москве, Петербурге и Екатеринбурге
                            </div>
                            <div class="text-left text-14 text-gray-main-2">
                                Срок: 2-10 дней <br>
                                Стоимость: бесплатно
                            </div>
                        </div>
                        <div class="mt-5">
                            <div class="text-left text-14 text-black">
                                Курьерская доставка СДЭК
                            </div>
                            <div class="text-left text-14 text-gray-main-2">
                                Срок: 1–10 дней <br>
                                Стоимость: бесплатно
                            </div>
                        </div>
                        <div class="mt-5">
                            <div class="text-left text-14 text-black">
                                Самовывоз из пункта выдачи Boxberry, СДЭК
                            </div>
                            <div class="text-left text-14 text-gray-main-2">
                                Срок: 2–10 дней <br>
                                Стоимость: бесплатно
                            </div>
                        </div>
                        <div class="mt-5">
                            <div class="text-left text-14 text-black">
                                Курьерская доставка СДЭК в СНГ
                            </div>
                            <div class="text-left text-14 text-gray-main-2">
                                Срок: 1–10 дней <br>
                                Стоимость: от 990 ₽
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
        <div class="text-11 tracking-wide text-gray-main lg:text-13">
            <button @click="preorderInfo" class="flex underline uppercase focus:outline-none">
                условия предзаказа
            </button>
        </div>
    </div>
</template>

<script>
import Popup from "../popup/Popup.vue";

export default {
    name: "PreorderCartInfoPopup",
    data: () =>({
        isPreorderCartPopupActive: false,
    }),
    components: {Popup},
    methods: {
        preorderInfo(){
            this.isPreorderCartPopupActive = true
        },
        closePopup(){
            this.isPreorderCartPopupActive = false
        },
    }
}
</script>

<style scoped>

</style>
