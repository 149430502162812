var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.announcementBarStatus == "success"
    ? _c("div", [
        _vm.announcementBar.data.attributes.active == true
          ? _c(
              "div",
              {
                style:
                  "background-color: " +
                  _vm.announcementBar.data.attributes.bg_color +
                  ";",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex py-3 px-4 text-center text-12 uppercase font-normal font-normal tracking-widest items-center justify-center",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        style:
                          "color: " +
                          _vm.announcementBar.data.attributes.text_color +
                          ";",
                        attrs: { to: _vm.announcementBar.data.attributes.link },
                      },
                      [_vm._v(_vm._s(_vm.announcementBar.data.attributes.text))]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }