var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full h-full" }, [
    _c(
      "div",
      { staticClass: "text-gray-main text-base font-normal" },
      [
        _vm.lookbookStatus == "success"
          ? _c(
              "div",
              [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.lookbook.data.attributes.html),
                  },
                }),
                _vm._v(" "),
                _vm._l(
                  _vm.lookbook.data.attributes.look_book_images.data,
                  function (image) {
                    return _c(
                      "div",
                      { key: image.data.look_book_image_id },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: image.data.attributes.link } },
                          [
                            _c("img", {
                              staticClass: "w-full object-center object-cover",
                              attrs: {
                                src: image.data.attributes.image.data.attributes
                                  .path,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        image.data.attributes.text != null &&
                        image.data.attributes.text_link != null
                          ? _c(
                              "router-link",
                              {
                                attrs: { to: image.data.attributes.text_link },
                              },
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "text-gray-main text-xl font-normal tracking-widest text-center",
                                  },
                                  [_vm._v(_vm._s(image.data.attributes.text))]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            )
          : _c("Loading"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }