<template>
  <div class="bg-bg-brown bg-opacity-25 px-2 h-full w-full fixed z-40 flex left-0 right-0 bottom-0 items-center justify-center" ref="popup_wrapper" >
    <div class="p-8 w-110 bg-white rounded shadow z-50" >
      <div class="w-full flex justify-end items-center relative"  >
        <div class="cursor-pointer absolute " @click="closePopup">
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L10 1" stroke="#D9D9D9" stroke-linecap="square"/>
            <path d="M1 1.00024L10 10.0002" stroke="#D9D9D9" stroke-linecap="square"/>
          </svg>
        </div>
      </div>
      <div class="">
        <slot>

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",

  methods: {
    closePopup() {
      this.$emit('closePopup')
    },

  },
  mounted () {
    let vm = this;
    document.addEventListener('click', function (item) {
      if (item.target === vm.$refs['popup_wrapper']) {
        vm.closePopup()
      }
    })
  }
}
</script>

<style scoped>

</style>
