<template>
 <div>
     <Popup v-if="isDeliveryInfoPopupVisible"
            @closePopup="closePopup">
         <div class="mt-4 w-full justify-center items-center flex-none text-gray-main">
             <div class="flex-none justify-items-center">
                 <div class="mt-4 w-full uppercase text-center text-16 justify-center flex-none">
                     Получить заказ можно следующими способами:
                 </div>
                 <div class="mt-8 w-full flex-none justify-center">
                     <div>
                         <div class="text-left text-13 text-black">
                             • Экспресс-доставка по Москве
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Срок: в течение рабочего дня <br> Стоимость: 790 ₽
                         </div>
                     </div>
                     <div class="mt-2">
                         <div class="text-left text-13 text-black">
                             • Курьерская доставка СДЭК, СДЭК Экспресс
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Срок: 1–10 дней <br> Стоимость: 550 ₽ по Москве и Петербургу; от 690 ₽ по России
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                            Отправка: по России только в будние дни
                         </div>
                     </div>
                     <div class="mt-2">
                         <div class="text-left text-13 text-black">
                             • Самовывоз из магазинов в Москве, Петербурге и Екатеринбурге
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Срок: 2–10 дней <br> Стоимость: бесплатно
                         </div>
                     </div>
                     <div class="mt-2">
                         <div class="text-left text-13 text-black">
                             • Самовывоз из пункта выдачи Boxberry
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Срок: 2–10 дней <br> Стоимость: 190 ₽
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Отправка: только в будние дни
                         </div>
                     </div>
                     <div class="mt-2">
                         <div class="text-left text-13 text-black">
                             • Самовывоз из пункта выдачи СДЭК
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Срок: 2–10 дней <br> Стоимость: 290 ₽
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Отправка: только в будние дни
                         </div>
                     </div>
                     <div class="mt-2">
                         <div class="text-left text-13 text-black">
                             • Курьерская доставка СДЭК в СНГ
                         </div>
                         <div class="ml-4 text-left text-11 text-gray-light">
                             Срок: 1–10 дней <br>
                             Стоимость: от 990 ₽
                         </div>
                     </div>
                 </div>
                 <div class="flex items-center justify-center mt-5">
                     <router-link to="/delivery-and-return" class="flex items-center">
                         <button class="w-45 h-10 py-2  bg-black uppercase text-white text-16 focus:outline-none">
                             Подробнее
                         </button>
                     </router-link>
                 </div>
             </div>
         </div>
     </Popup>
     <Popup v-if="isReturnInfoPopupVisible"
            @closePopup="closePopup">
         <div class="mt-4 w-full justify-center items-center flex-none text-gray-main">
             <div class="flex-none justify-items-center">
                 <div class="mt-4 w-full uppercase text-center text-16 justify-center flex-none">
                     ВОЗВРАТ
                 </div>
                 <div class="mt-4 w-full text-left text-13 text-black justify-center flex-none lg:text-16">
                     Доступен бесплатный курьерский возврат по России с возмещением полной стоимости товара, исключая цену доставки. Мы рекомендуем оформить его в течение первых 7 дней с момента получения заказа,
                     чтобы уложиться в установленные сроки. Передать возврат курьеру можно в течение 14 дней. <br>
                     <br>
                     Возврат заказов с самовывозом возможно оформить только в том магазине, откуда забирали заказ.<br>
                     <br>
                     Деньги будут возвращены на счет в течение 3-10 рабочих дней после получения и одобрения возврата.
                 </div>
                 <div class="flex items-center justify-center mt-5">
                     <router-link to="/delivery-and-return?return=true" class="flex items-center">
                         <button class="w-45 h-10 py-2  bg-black uppercase text-white text-16 focus:outline-none">
                             Подробнее
                         </button>
                     </router-link>
                 </div>
             </div>
         </div>
     </Popup>
     <div class="text-11 tracking-wide text-gray-main lg:text-13">
         <button @click="deliveryInfo" class="my-3 flex underline uppercase focus:outline-none">
             условия доставки
         </button>
         <button @click="returnInfo" class="my-3 flex underline uppercase focus:outline-none">
             условия возврата
         </button>
     </div>
 </div>
</template>

<script>
import Popup from "../popup/Popup.vue";

export default {
    name: "CartDeliveryAndReturnInfo",
    data: () =>({
        isDeliveryInfoPopupVisible: false,
        isReturnInfoPopupVisible: false,
    }),
    components: {Popup},
    methods:{
        deliveryInfo(){
            this.isDeliveryInfoPopupVisible = true
        },
        returnInfo(){
            this.isReturnInfoPopupVisible = true
        },
        closePopup(){
            this.isDeliveryInfoPopupVisible = false
            this.isReturnInfoPopupVisible = false
        },
    }
}
</script>

<style scoped>

</style>
