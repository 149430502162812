<template>
    <div class="">
        <div class="">
            <div class="py-4 border-t border-gray-lighter flex justify-between " v-for="(item) in cart.data.relationships.additionalOrderLines.data"
                 :key="'cart_item_'+item.relationships.product.data.product_id+'size'+item.relationships.size_id" >
                <div class="flex">
                    <div class="w-21 h-full flex-none overflow-hidden lg:w-26">
                        <img class="w-full object-cover" :src="item.relationships.product.data.attributes.images.data[0].data.attributes.path" :alt="item.relationships.product.data.attributes.title">
                    </div>
                    <div class="">
                        <div class=" ml-4 uppercase text-11 text-black font-normal lg:w-95 lg:ml-5 lg:text-16">
                            {{item.relationships.product.data.attributes.title}}
                        </div>
                        <div class="ml-4 items-center text-11 text-gray-light font-normal lg:ml-5 lg:text-16 ">
                            <div>
                                Артикул: {{item.relationships.product.data.attributes.article_number}}
                            </div>
                            <div class="flex mt-0.5 items-center">
                                <div class="w-3.5 h-3.5 mr-1.5 border border-black rounded rounded-full flex items-center justify-center">
                                    <div v-if="item.relationships.product.data.attributes.color.data.attributes.code"
                                         class="w-2.5 h-2.5 rounded rounded-full border-border-gray-light"
                                         :style="'background-color: ' + item.relationships.product.data.attributes.color.data.attributes.code + ';'">
                                    </div>
                                </div>
                                <div class="">
                                    {{item.relationships.product.data.attributes.color.data.attributes.title}}
                                </div>
                            </div>
                        </div>
                        <div class="ml-4 pt-4 uppercase text-11 text-black font-normal lg:text-16 lg:ml-5">
                            <div v-for="size in item.relationships.product.data.attributes.sizes.data"
                                 :key="size.id"
                                 class="bg-white">
                                <div v-if="size.data.size_id === item.attributes.sizeId">
                                    {{size.data.attributes.title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid justify-items-end">
                    <div class="grid justify-items-end content-end ">
                        <div class="mr-2 flex text-11 text-gray-light text-right lg:text-16">
                            {{item.attributes.userQuantity}} шт.
                        </div>
                        <div class="">
                            <div class="mr-2 flex text-11 text-black text-right lg:text-16">
                                {{0 | formatNumber }} ₽
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "CartAdditionalOrderLines",
    props:{
        cart:null
    },
}
</script>

<style scoped>

</style>
