var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-10" },
    [
      _c("CartMain", {
        attrs: {
          type: "cart",
          cart: _vm.cart,
          "preorder-cart": _vm.preorderCart,
        },
      }),
      _vm._v(" "),
      _vm.cart?.data?.relationships?.orderLines?.data?.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "w-full px-5 mx-auto overflow-hidden lg:w-240 xl:w-360",
            },
            [
              _c("CartOrderLines", {
                attrs: {
                  cart: _vm.cart,
                  cartItemsForMindbox: _vm.cartItemsForMindbox,
                  cartItemsForEccomGTM: _vm.cartItemsForEccomGTM,
                  cartItemsForGTM: _vm.cartItemsForGTM,
                },
              }),
              _vm._v(" "),
              _c("CartAdditionalOrderLines", { attrs: { cart: _vm.cart } }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-none mt-6 lg:flex" }, [
                _c(
                  "div",
                  { staticClass: "w-full lg:w-1/2" },
                  [
                    _c("CartPromoCodeBlock", { attrs: { cart: _vm.cart } }),
                    _vm._v(" "),
                    _c("CartGiftCertificateBlock", {
                      attrs: { cart: _vm.cart },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full mt-10 lg:w-1/2 lg:mt-0 lg:ml-15" },
                  [
                    _c("CartDeliveryAndReturnInfo"),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("CartTotalCalculations", {
                          attrs: { cart: _vm.cart },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-center" },
                          [
                            _c(
                              "button",
                              {
                                class: [
                                  _vm.isUserIdentifiedAndUnauthorizedButtonActive ||
                                  _vm.cart.data.attributes.itemsQuantity < 1
                                    ? "w-full h-10 py-2 px-5 bg-gray-light text-white text-16 r focus:outline-none"
                                    : "w-full h-10 py-2 px-5 bg-black text-white text-16 r focus:outline-none",
                                ],
                                on: { click: _vm.createOrder },
                              },
                              [_c("span", [_vm._v("Оформить заказ")])]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.loginInCart
                      ? _c(
                          "div",
                          [_c("Login", { attrs: { isPageCart: true } })],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "flex items-center justify-center text-11 my-10 lg:text-24",
              },
              [_vm._v("Ваша корзина пуста")]
            ),
          ]),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "w-full px-5 mx-auto overflow-hidden lg:w-240 xl:w-360" },
      [
        _c("div", { attrs: { "data-popmechanic-embed": "40986" } }),
        _vm._v(" "),
        _c("div", { attrs: { "data-popmechanic-embed": "100897" } }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }