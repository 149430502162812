var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "alert-message",
      class: {
        "alert-message__show": _vm.show,
        "alert-message__close": !_vm.show,
      },
    },
    [
      _c("div", { staticClass: "alert-message__wrapper" }, [
        _c("div", { staticClass: "alert-message__text" }, [
          _vm._v("\n            " + _vm._s(_vm.message) + "\n        "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }