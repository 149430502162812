<template>
    <PopupOnRightSide @closePopup="$emit('closePopup')">
        <div>
            <div v-if="subscribeItemStatus !== 'success'"
                 class="w-full h-full">
                <div>
                    <div class="mt-15 text-18 text-black line-height-23">
                        Сообщить о поступлении
                    </div>
                    <div class="mt-3 text-14 text-black line-height-15">
                        Подпишитесь и узнайте первыми, когда товар поступит в продажу
                    </div>
                </div>
                <div class="mt-8">
                    <div v-if="getVerificationOfUser">
                        <AuthByPhone @authByPhoneSuccessClosePopup="authByPhoneSuccessClosePopup"/>
                        <input v-model="form.name" readonly
                               id="name"
                               type="text"
                               placeholder="Ваше имя"
                               class="mt-6 appearance-none text-11 border outline-none
                         border-gray-lighter text-gray-light rounded-none w-full h-10 px-2
                       focus:outline-none focus:border-black lg:text-13 no-spinner pointer-events-none">
                        <input v-model="form.email" readonly
                               id="email"
                               type="email"
                               placeholder="Email"
                               class="mt-2 appearance-none text-11 border outline-none
                         border-gray-lighter text-gray-light rounded-none w-full h-10 px-2
                       focus:outline-none focus:border-black lg:text-13 no-spinner pointer-events-none">
                    </div>
                    <div v-else>
                        <input v-model="form.phone"
                               id="phone" readonly
                               class="appearance-none text-11 border outline-none
                         border-gray-lighter text-gray-light rounded-none w-full h-10 px-2
                       focus:outline-none focus:border-black lg:text-13 no-spinner pointer-events-none">
                        <input v-model="form.name"
                               id="name"
                               type="text"
                               placeholder="Имя"
                               class="h-10 w-full mt-3 pl-4 text-11 font-normal border-1 outline-none
                               border-gray-lighter focus:box-border focus:border focus:border-action-black focus:text-black
                               hover:border-black focus:border-black lg:text-13"
                               :class="[$v.form.name.$error ? 'border-red-400' : 'border-gray-lighter']">
                        <div v-if="$v.form.name.$invalid" class="pl-4 text-red-400 text-11">
                            <span v-if="$v.form.name.$error">Введите имя</span>
                        </div>
                        <input v-model="form.surname"
                               id="name"
                               type="text"
                               placeholder="Фамилия"
                               class="h-10 w-full mt-3 pl-4 text-11 font-normal border-1 outline-none
                               border-gray-lighter focus:box-border focus:border focus:border-action-black focus:text-black
                               hover:border-black focus:border-black lg:text-13"
                               :class="[$v.form.surname.$error ? 'border-red-400' : 'border-gray-lighter']">
                        <div v-if="$v.form.surname.$invalid" class="pl-4 text-red-400 text-11">
                            <span v-if="$v.form.surname.$error">Введите фамилию</span>
                        </div>
                        <div>
                            <input v-model="form.email"
                                   id="email"
                                   type="email"
                                   placeholder="Email"
                                   class="h-10 w-full mt-3 pl-4 text-11 font-normal  border-1 outline-none
                              focus:box-border focus:border focus:border-action-black focus:text-black
                              hover:border-black focus:border-black lg:text-13"
                                   :class="[$v.form.email.$error ? 'border-red-400' : 'border-gray-lighter']">
                            <div v-if="$v.form.email.$invalid" class="pl-4 text-red-400 text-11">
                                <span v-if="$v.form.email.$error">Неверный email</span>
                            </div>
                        </div>
                        <div class="text-xs text-gray-main" v-if="subscribeItemStatus==='loading'">
                            Загрузка..
                        </div>
                        <div class="text-xs text-red-500" v-if="subscribeItemStatus==='error'">
                            Ошибка!
                        </div>
                        <p v-if="errorMessage" class="text-11 text-red-light-2 mt-2">
                            {{ errorMessage }}
                        </p>

                        <div class="mt-4 text-sm  text-gray-main flex items-center">
                            <input v-model="form.checkbox"
                                   type="checkbox"
                                   name="checkbox"
                                   class="h-4 w-4 form-checkbox text-black border border-black border-transparent
                        focus:border-brown-light focus:outline-none"
                                   checked>
                            <div class="ml-2">Подписаться на рассылку</div>
                        </div>


                        <button
                            class="w-full mt-2 h-9 py-1 px-5 text-white r focus:outline-none text-14 border border-black bg-black"
                            @click="subscribeToProductAvailability(product, form)">
                            Сообщить о поступлении
                        </button>
                        <div class="text-xs mt-2">
                            Нажимая на кнопку «сообщить», я соглашаюсь на обработку моих персональных данных и
                            ознакомлен(а) с
                            <router-link class="underline" to="/info/privacy-policy" target="_blank">условиями
                                конфиденциальности
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="w-full h-full mt-15">
                <div>
                    <div class="-mt-3 text-gray-main text-2xl">Спасибо!</div>
                    <div class="mt-4 text-gray-main">Подписка на товар оформлена</div>
                    <div class="mt-4 text-gray-main underline cursor-pointer text-center"
                         @click="$emit('closePopup')">
                        Продолжить покупки
                    </div>
                </div>
            </div>
        </div>
    </PopupOnRightSide>
</template>

<script>
import Popup from "./Popup";
import {mapActions, mapGetters} from "vuex";
import {required, email} from 'vuelidate/lib/validators'
import PopupOnRightSide from "./PopupOnRightSide"
import AuthByPhone from "../Auth/AuthByPhone.vue"
import axios from "axios";
import Test from "../../views/Test.vue";


export default {
    name: "SizeSubscriptionPopup",
    components: {
        Test,
        AuthByPhone,
        PopupOnRightSide,
        Popup,
    },
    props: {
        product: {
            id: "",
            articleNumber: "",
            sizeId: "",
        },
    },
    data: () => ({
        form: {
            name: null,
            phone: null,
            email: null,
            surname: null,
            checkbox: true,
        },
        phoneIsNotCorrect: false,
        errorMessage: false,
        subscribeItemStatus: false,
    }),
    validations: {
        form: {
            email: {required, email},
            phone: {required},
            name: {required},
            surname: {required},
        }
    },
    computed: {
        ...mapGetters({
            errors: 'registerErrors',
            status: 'registerStatus',
            authStatus: 'authStatus',
            authInfo: 'authInfo',
        }),
        getUserEmailInfo() {
            return this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail
                ? this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail?.data?.attributes?.email
                : this.authInfo?.relations?.authUser?.data?.relations?.currentMarketingEmail?.data?.attributes?.email
        },
        getVerificationOfUser() {
            return !this.authInfo?.authStatus || (this.authInfo?.authStatus
                && !this.authInfo?.relations?.authUser?.data?.attributes?.isPhoneVerified)
        },
    },
    methods: {
        ...mapActions({
            addSubscribeItem: 'addSubscribeItem',
            setSubscribeItemStatus: 'setSubscribeItemStatus',
            getAuthStatus: 'getAuthStatus',
        }),
        async getUserAuthData() {
            await this.$store.dispatch('getAuthStatus')
            this.form.email = this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail
                ? this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail?.data?.attributes?.email
                : this.authInfo?.relations?.authUser?.data?.relations?.currentMarketingEmail?.data?.attributes?.email
            this.form.name = this.authInfo?.relations?.authUser?.data?.attributes?.name
            this.form.surname = this.authInfo?.relations?.authUser?.data?.attributes?.surname
            this.form.phone = this.authInfo?.relations?.authUser?.data?.attributes?.phone
        },
        async authByPhoneSuccessClosePopup() {
            await this.getAuthStatus()
            await this.getUserAuthData()
        },
        checkTheError(error) {
            this.errorMessage = null
            const errorMessagesMap = {
                'Email already taken': 'Данный email привязан к другому личному кабинету, используйте другой',
            };
            this.errorMessage = errorMessagesMap[error] || 'Произошла ошибка. Обратитесь, пожалуйста, в поддержку'
        },
        async subscribeToProductAvailability(product, form) {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await axios.patch('/api/user/attributes', {
                    'name': form.name,
                    'email': form.email,
                    'surname': form.surname
                })
                await axios.post('/api/size-subscription', {
                    'name': form.name,
                    'phone_number': this.authInfo?.relations?.authUser?.data?.attributes?.phone,
                    'email': form.email,
                    'check_mark_mb': form.checkbox,
                    'product_id': product.id,
                    'size_id': product.sizeId,
                    'surname': form.surname
                })
                    .then(res => {
                        this.subscribeItemStatus = 'success'
                    })
                    .catch(error => {
                        this.checkTheError(error.response?.data.errors
                            ? error.response?.data.errors.password[0]
                            : error.response?.data.message)
                    })

                this.$gtm.trackEvent({
                    event: "custom_event", // Event type [default = 'interaction'] (Optional)
                    event_category: "forms",
                    event_action: "sent_successfully",
                    event_label: "popup_inform_about_admission",
                    product_group_id: product.id,
                    product_id: product.articleNumber + '' + product.sizeId
                });
            }
        },
    },
    mounted() {
        this.getUserAuthData()
        this.form.email = this.getUserEmailInfo

    }
}
</script>

<style scoped>
.line-height-23 {
    line-height: 23.08px;
}

.line-height-15 {
    line-height: 15.4px;
}

.vue-tel-input {
    border-radius: 0 !important;
    border: 2px solid #e5e7eb !important;
    box-shadow: none !important;
}

.vue-tel-input:focus {
    border: 2px solid black !important;
}

.vue-tel-input:hover {
    border: 2px solid black !important;
}

.vue-tel-input-error {
    border: 2px solid #f87171 !important;
}
</style>
