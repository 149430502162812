var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    _c(
      "div",
      [
        _vm.productStatus === "success"
          ? _c("ProductCard", { attrs: { product: _vm.product } })
          : _c("Loading"),
      ],
      1
    ),
    _vm._v(" "),
    _vm.lookalikeProductsStatus === "success" &&
    _vm.computedActiveProductsForLookalike.length > 0 &&
    _vm.product.data.attributes.active === 1
      ? _c(
          "div",
          [
            _c("SwipableCategoryBlock", {
              attrs: {
                productsForBlock: _vm.computedActiveProductsForLookalike,
                title: "Носите вместе",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { "data-popmechanic-embed": "39794" } }),
    _vm._v(" "),
    _c("div", { attrs: { "data-popmechanic-embed": "80591" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }