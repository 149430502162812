var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PopupOnRightSide",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c("div", { staticClass: "mt-7 flex text-14 text-black" }, [
        _c(
          "div",
          {
            staticClass: "cursor-pointer",
            class: _vm.sizeTableIsShown ? "underlinedPositions" : "",
            on: { click: _vm.showSizeTable },
          },
          [_vm._v("\n            Таблица размеров\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cursor-pointer",
            class: _vm.measurementsTableIsShown
              ? "underlinedPositions ml-6"
              : "ml-6",
            on: { click: _vm.showMeasurementsTable },
          },
          [_vm._v("\n            Обмеры изделия\n        ")]
        ),
      ]),
      _vm._v(" "),
      _vm.sizeTableIsShown
        ? _c("div", [
            _c("div", { staticClass: "mt-5 text-14" }, [
              _vm._v(
                "\n            Общая таблица размеров показывает нашу стандартную размерную линейку.\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "h-full mt-6 py-4 border-b border-t border-gray-lightest",
              },
              [
                _c("div", {
                  staticClass: "h-full text-13",
                  domProps: {
                    innerHTML: _vm._s(_vm.sizeTableInfo.data.attributes.html),
                  },
                }),
              ]
            ),
          ])
        : _vm.measurementsTableIsShown
        ? _c("div", [
            _c("div", { staticClass: "mt-5 text-14" }, [
              _vm._v("Все замеры указаны в см"),
            ]),
            _vm._v(" "),
            _vm.product.data.attributes.details.data.attributes
              .measurements_table
              ? _c(
                  "div",
                  {
                    staticClass:
                      "h-full mt-6 py-6 border-b border-t border-gray-lightest",
                  },
                  [
                    _c("MeasurementsTable", {
                      attrs: {
                        "measurements-table":
                          _vm.product.data.attributes.details.data.attributes
                            .measurements_table,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "h-full mt-6 py-4 border-b border-t border-gray-lightest",
                  },
                  [
                    _c("div", {
                      staticClass: "h-full text-13",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.product.data.attributes.details.data.attributes
                            .measurements
                        ),
                      },
                    }),
                  ]
                ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt-6" }, [
        _c("div", {
          staticClass: "mt-5 text-14 text-black",
          domProps: {
            innerHTML: _vm._s(
              _vm.product.data.attributes.details.data.attributes
                .model_details_on_photo
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _c("ContactsInfoBlock", { staticClass: "mt-6" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }