import Vue from 'vue';
import Vuex from 'vuex';
import Product from "./modules/product.js";
import Category from "./modules/category.js";
import Catalog from "./modules/catalog.js";
import Cart from "./modules/cart";
import PreorderCart from "./modules/preorder_cart";
import User from "./modules/user";
import Delivery from "./modules/delivery";
import PreorderCartDelivery from "./modules/preorder_cart_delivery";
import Order from "./modules/order";
import LookalikeProducts from "./modules/lookalike_products";
import Payment from "./modules/payment"
import GiftCertificate from "./modules/gift_certificate"
import Info from "./modules/info";
import Navigation from "./modules/navigation"
import PageBlock from "./modules/page_blocks"
import Page from "./modules/page";
import Lookbook from "./modules/lookbook";
import Subscribe from "./modules/subscribe"
import Auth from "./modules/auth"
import Footer from "./modules/footer"
import Popup from "./modules/popup";
import AnnouncementBar from "./modules/announcement_bar";
import PressBlock from "./modules/press_block";
import PressBlockImages from "./modules/press_block_images";
import ProductStockBalance from "./modules/product_stock_balance";
import Feedback from "./modules/feedback";
import GiftCertigicateOrder from "./modules/gift_certificate_order"
import Filters from "./modules/filters"
import Dolyame from "./modules/dolyame"
import Alert from "./modules/alert";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Alert,
        Product, Category, Catalog, User, Delivery, Order, LookalikeProducts, Payment, Info,
        Navigation, PageBlock, Page, Lookbook, Subscribe, Auth, Footer, Popup, AnnouncementBar,
        PressBlock, PressBlockImages, GiftCertificate, ProductStockBalance, Feedback, GiftCertigicateOrder, Cart, Filters,
        Dolyame, PreorderCart, PreorderCartDelivery
    }
})
