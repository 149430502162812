var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.isSortShown
    ? _c(
        "div",
        {
          staticClass:
            "w-51 -mt-2 text-right cursor-pointer absolute z-20 justify-end right-0 border border-gray-lightest lg:mr-3",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closePopup,
                  expression: "closePopup",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "pr-5 text-right h-8 bg-white flex items-center justify-end text-14 text-gray-main cursor-pointer border border-white hover:border-black",
                  class: _vm.sortBy === "default" ? "underlinedPositions " : "",
                  on: {
                    click: function ($event) {
                      return _vm.sort("default")
                    },
                  },
                },
                [_vm._v("\n            Наш выбор\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pr-5 text-right h-8 bg-white flex items-center justify-end text-14 text-gray-main cursor-pointer border border-white hover:border-black",
                  class: _vm.sortBy === "price" ? "underlinedPositions" : "",
                  on: {
                    click: function ($event) {
                      return _vm.sort("price")
                    },
                  },
                },
                [_vm._v("\n            По возрастанию цены\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pr-5 text-right h-8 bg-white flex items-center justify-end text-14 text-gray-main cursor-pointer border border-white hover:border-black",
                  class: _vm.sortBy === "-price" ? "underlinedPositions" : "",
                  on: {
                    click: function ($event) {
                      return _vm.sort("-price")
                    },
                  },
                },
                [_vm._v("\n            По убыванию цены\n        ")]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }