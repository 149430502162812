var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex justify-center" }, [
    _c(
      "div",
      { staticClass: "my-10" },
      [
        _c(
          "router-link",
          {
            staticClass:
              "uppercase text-16 text-gray-main font-normal text-center",
            class: _vm.type === "cart" ? "underlinedPositions" : "",
            attrs: { to: { name: "Cart2" } },
          },
          [
            _vm._v("\n      Основная корзина\n    "),
            _vm.cart?.data?.relationships?.orderLines?.data
              ? _c("span", [
                  _vm._v(
                    "\n        (" +
                      _vm._s(
                        _vm.cart.data.relationships.orderLines.data.length
                      ) +
                      ")\n    "
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass:
              "uppercase ml-7 text-16 text-gray-main font-normal text-center",
            class: _vm.type === "preorderCart" ? "underlinedPositions" : "",
            attrs: { to: { name: "PreorderCart" } },
          },
          [
            _vm._v("\n      Предзаказ\n      "),
            _vm.preorderCart?.data?.relationships?.orderLines?.data
              ? _c("span", [
                  _vm._v(
                    "\n          (" +
                      _vm._s(
                        _vm.preorderCart.data.relationships.orderLines.data
                          .length
                      ) +
                      ")\n      "
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }