var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { ref: "swipableFullSizeBlockSwiper", staticClass: "swiper-container" },
      [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(
            _vm.block.data.attributes.swipable_full_size_sub_blocks.data,
            function (block_swiper) {
              return _c(
                "div",
                {
                  key: block_swiper.data.swipable_full_size_sub_block_id,
                  staticClass: "swiper-slide",
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "w-full h-220 sm:h-full relative" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "hidden md:block",
                            attrs: {
                              href: block_swiper.data.attributes.button_link,
                            },
                          },
                          [
                            _c("img", {
                              staticClass:
                                "w-full h-220 sm:h-full object-cover",
                              attrs: {
                                src: block_swiper.data.attributes.image.data
                                  .attributes.path,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "md:hidden",
                            attrs: {
                              href: block_swiper.data.attributes.button_link,
                            },
                          },
                          [
                            _c("img", {
                              staticClass:
                                "w-full h-220 sm:h-full object-cover",
                              attrs: {
                                src: block_swiper.data.attributes.mobile_image
                                  .data.attributes.path,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              block_swiper.data.attributes.text_align ==
                              "center"
                                ? "absolute w-full top-1/2  flex flex-col items-center"
                                : "absolute w-full inset-x-0 bottom-0 flex flex-col items-center pb-15",
                            ],
                          },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  block_swiper.data.attributes.text_case ==
                                  "lowercase"
                                    ? "lowercase"
                                    : "uppercase",
                                ],
                                style:
                                  "color: " +
                                  block_swiper.data.attributes.text_color +
                                  ";",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      block_swiper.data.attributes.text_size ==
                                      "large"
                                        ? "sm:text-lg lg:text-2xl mt-4 text-center font-normal tracking-widest"
                                        : "text-lg mt-4 text-center font-normal tracking-widest",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          block_swiper.data.attributes.h3
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                block_swiper.data.attributes.text_size ===
                                "small"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sm:text-2xl lg:text-4xl mt-4 text-center font-normal tracking-widest'",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              block_swiper.data.attributes.h2
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        class: [
                                          block_swiper.data.attributes
                                            .text_size === "the largest"
                                            ? "text-4xl lg:text-9xl mt-4 text-center font-normal tracking-widest"
                                            : "text-4xl lg:text-8xl mt-4 text-center font-normal widest",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              block_swiper.data.attributes.h2
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                              ]
                            ),
                            _vm._v(" "),
                            block_swiper.data.attributes.button_text != null
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-4 mx:auto flex items-center justify-center",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: block_swiper.data.attributes
                                              .button_link,
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                                              style:
                                                "border-color: " +
                                                block_swiper.data.attributes
                                                  .border_button_color +
                                                ";" +
                                                "background-color: " +
                                                block_swiper.data.attributes
                                                  .background_button_color +
                                                ";" +
                                                "color: " +
                                                block_swiper.data.attributes
                                                  .button_text_color +
                                                ";",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  block_swiper.data.attributes
                                                    .button_text
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }
          ),
          0
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "swiper-pagination",
          attrs: { slot: "pagination" },
          slot: "pagination",
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }