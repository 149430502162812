var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full px-5 mx-auto overflow-hidden lg:w-240 xl:w-360" },
    [
      _c("CartMain", {
        attrs: {
          type: "preorderCart",
          "preorder-cart": _vm.preorderCart,
          cart: _vm.cart,
        },
      }),
      _vm._v(" "),
      _vm.preorderCart?.data.relationships.orderLines?.data.length > 0
        ? _c(
            "div",
            [
              _c("PreorderCartOrderLines", {
                attrs: { cart: _vm.preorderCart },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-none mt-10 lg:flex" }, [
                _c("div", { staticClass: "w-full lg:w-1/2" }, [
                  _c(
                    "div",
                    [
                      _c("PreorderCartUserDeliveryPaymentInfo", {
                        attrs: { cart: _vm.preorderCart },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full mt-10 lg:w-1/2 lg:mt-0 lg:ml-15" },
                  [
                    _c("PreorderCartInfoPopup"),
                    _vm._v(" "),
                    _c("CartDeliveryAndReturnInfo"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-10 mb-7 text-gray-main text-base font-normal",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between uppercase text-11 text-gray-light lg:text-13",
                          },
                          [
                            _c("div", [_vm._v("Доставка:")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pl-3" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      _vm.preorderCart?.data.attributes
                                        .deliveryPriceToPay
                                    )
                                  ) +
                                  " ₽\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-3 flex items-center justify-between uppercase text-13 text-black lg:text-16",
                          },
                          [
                            _c("div", [_vm._v("Итого:")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pl-3" }, [
                              _vm.preorderCart?.data.attributes.priceToPay !==
                              _vm.preorderCart?.data.attributes.totalBasicPrice
                                ? _c("div", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            _vm.preorderCart?.data.attributes
                                              .priceToPay
                                          )
                                        ) +
                                        " ₽\n                                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pl-1 text-gray-light font-normal line-through",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("formatNumber")(
                                                _vm.preorderCart?.data
                                                  .attributes.totalBasicPrice
                                              )
                                            ) +
                                            " ₽\n                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            _vm.preorderCart?.data.attributes
                                              .priceToPay
                                          )
                                        ) +
                                        " ₽\n                            "
                                    ),
                                  ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isPaymentByDolyame
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between text-13 text-brown-light-2",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                            Первый платеж, к оплате сейчас:\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          _vm.firstPartToPayDolyame
                                        )
                                      ) +
                                      " ₽\n                        "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class: [
                          this.preorderCart?.data.attributes.isPossibleToSend
                            ? "w-full uppercase h-9 py-1 px-5 bg-black text-white text-11 r focus:outline-none lg:text-13"
                            : "w-full uppercase h-9 py-1 px-5 bg-gray-light text-white text-11 r focus:outline-none lg:text-13",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.createPreorderFromCart(_vm.preorderCart)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Оформить предзаказ\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "flex items-center justify-center text-11 my-10 lg:text-24",
              },
              [_vm._v("Ваша корзина пуста")]
            ),
          ]),
      _vm._v(" "),
      _c("div", { attrs: { "data-popmechanic-embed": "40986" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }