var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full px-6 lg:px-0 lg:w-256 lg:mx-auto text-gray-main" },
    [
      _c(
        "h1",
        {
          staticClass:
            "my-10 w-full text-gray-main text-2xl font-normal uppercase tracking-widest text-center",
        },
        [_vm._v("\n        Мой Аккаунт\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full relative" }, [
        _c("div", { staticClass: "absolute top-0 right-0 flex items-center" }, [
          _c("img", {
            staticClass: "px-2 cursor-pointer",
            attrs: { src: "/storage/images/icons/edit.svg", alt: "edit" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "px-2 cursor-pointer",
            attrs: { src: "/storage/images/icons/log-out.svg", alt: "logout" },
            on: { click: _vm.signOut },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.authInfo?.relations?.authUser?.data?.attributes?.name
              ) +
              "\n            " +
              _vm._s(
                _vm.authInfo?.relations?.authUser?.data?.attributes?.surname
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _vm._v(
            _vm._s(_vm.authInfo?.relations?.authUser?.data?.attributes?.email)
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _vm._v(
            _vm._s(_vm.authInfo?.relations?.authUser?.data?.attributes?.phone)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass:
            "my-6 w-full text-gray-main text-xl font-normal uppercase text-center",
        },
        [_vm._v("\n        Заказы\n    ")]
      ),
      _vm._v(" "),
      _vm.userOrdersStatus === "success"
        ? _c("div", { staticClass: "w-full" }, [
            _vm.userOrders.data.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "accordion",
                      _vm._l(_vm.userOrders.data, function (order) {
                        return _c(
                          "accordion-item",
                          { key: order.id },
                          [
                            _c(
                              "template",
                              {
                                staticClass: "flex",
                                slot: "accordion-trigger",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full my-2 flex items-center justify-between",
                                  },
                                  [
                                    _c("div", [
                                      _c("div", {}, [
                                        _vm._v(
                                          "\n                                    №" +
                                            _vm._s(order.data.order_id) +
                                            " от\n                                    " +
                                            _vm._s(
                                              order.data.attributes.created_at
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-brown-dark" },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                order.data.attributes.status
                                                  .data.attributes.title
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "accordion-content" },
                              _vm._l(
                                order.data.attributes.lines.data,
                                function (line) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full flex items-center justify-start border-t last:border-b",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "h-25",
                                        attrs: {
                                          src: line.data.attributes.product.data
                                            .attributes.image_path,
                                          alt: line.data.attributes.product.data
                                            .attributes.title,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-2" }, [
                                        _c("div", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                line.data.attributes.product
                                                  .data.attributes.title
                                              ) +
                                              "\n                                "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "\n                                    размер:\n                                    " +
                                              _vm._s(
                                                line.data.attributes.size.data
                                                  .attributes.title
                                              ) +
                                              ",\n                                    " +
                                              _vm._s(
                                                line.data.attributes.quantity
                                              ) +
                                              " шт.\n                                "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                line.data.attributes.amount
                                              ) +
                                              " ₽\n                                "
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c("div", [_vm._v("У вас пока нет заказов...")]),
          ])
        : _c("div", [_c("Loading")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }