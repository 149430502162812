var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main text-base font-normal",
    },
    [
      _c("h1", { staticClass: "uppercase mb-12" }, [
        _vm._v("Инструкция: сарафан Camilla"),
      ]),
      _vm._v(" "),
      _c("vimeo-player", {
        ref: "player",
        staticClass: "w-full mx-auto lg:w-110",
        attrs: { "video-id": "829410793", options: _vm.options },
        on: { ready: _vm.onReady },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }