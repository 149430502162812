var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "Product",
              params: {
                category: _vm.product.links.meta.categorySlug,
                product: _vm.product.links.meta.productSlug,
              },
            },
          },
        },
        [
          _c("span", { on: { click: _vm.sendSelectItemToGTM } }, [
            _c("div", { staticClass: "relative" }, [
              _vm.product.data.attributes.tags != null
                ? _c(
                    "div",
                    {
                      class: [
                        _vm.product.data.attributes.full_screen == true
                          ? "lg:px-50 absolute bottom-0 mb-2 left-0 ml-1 mt-2 lg:top-0 z-10"
                          : "absolute bottom-0 mb-2 left-0 ml-1 mt-2 lg:top-0 z-10",
                        _vm.product.data.attributes.video_id != null
                          ? "h-full w-full"
                          : "",
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        _vm._l(
                          _vm.product.data.attributes.tags.data,
                          function (tag) {
                            return _c("ProductTag", {
                              key: tag.data.product_tag_id,
                              attrs: { tag: tag },
                            })
                          }
                        ),
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.blockType == "non-swipable-category-block"
                ? _c("div", [
                    _vm.product.data.attributes.full_screen == true
                      ? _c("div", [
                          _c("img", {
                            staticClass: "lg:px-50",
                            attrs: {
                              src: _vm.product.data.attributes
                                .image_for_full_screen.data.attributes.path,
                            },
                          }),
                        ])
                      : _vm.product.data.attributes.video_id != null
                      ? _c("div", [
                          _c(
                            "div",
                            {},
                            [
                              _c("vimeo-player", {
                                ref: "player",
                                staticClass: "vimeo",
                                attrs: {
                                  "video-id":
                                    _vm.product.data.attributes.video_id,
                                  "video-url": {
                                    name: "Product",
                                    params: {
                                      category:
                                        _vm.product.links.meta.categorySlug,
                                      product:
                                        _vm.product.links.meta.productSlug,
                                    },
                                  },
                                  options: _vm.options,
                                },
                                on: { ready: _vm.onReady },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _c("div", [
                          _c(
                            "div",
                            {
                              ref: "swipableFullSizeBlockSwiper",
                              staticClass: "swiper-container lg:hidden",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "swiper-wrapper" },
                                _vm._l(
                                  _vm.product.data.attributes.images.data.slice(
                                    0,
                                    3
                                  ),
                                  function (image, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: image.data.product_image_id,
                                        staticClass: "swiper-slide",
                                      },
                                      [
                                        _c("div", [
                                          !_vm.isProductInAnyStock
                                            ? _c("div", {
                                                staticClass:
                                                  "absolute top-0 h-full w-full bg-gray-200 bg-opacity-50",
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("img", {
                                            staticClass: "swiper-lazy",
                                            attrs: {
                                              "data-src": image.data.attributes
                                                .pathCatalogSize
                                                ? image.data.attributes
                                                    .pathCatalogSize
                                                : image.data.attributes.path,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass:
                                              "swiper-lazy-preloader swiper-lazy-preloader-black",
                                          }),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "swiper-pagination",
                                attrs: { slot: "pagination" },
                                slot: "pagination",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "hidden lg:block" }, [
                            _c("div", [
                              !_vm.isProductInAnyStock
                                ? _c("div", {
                                    staticClass:
                                      "absolute top-0 h-full w-full bg-gray-200 bg-opacity-50",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("img", {
                                attrs: { src: this.imageForDesktopMouseOn },
                                on: {
                                  mouseover: _vm.changeImageOnHover,
                                  mouseleave: _vm.changeImageOnLeave,
                                },
                              }),
                            ]),
                          ]),
                        ]),
                  ])
                : _c("div", [
                    _c("img", {
                      staticClass: "2xl:w-full",
                      attrs: {
                        src: _vm.product.data.attributes.preview_image.data
                          .attributes.pathCatalogSize
                          ? _vm.product.data.attributes.preview_image.data
                              .attributes.pathCatalogSize
                          : _vm.product.data.attributes.preview_image.data
                              .attributes.path,
                        alt: _vm.product.data.attributes.title,
                      },
                    }),
                  ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.blockType == "non-swipable-category-block"
        ? _c(
            "div",
            {
              class: [
                _vm.product.data.attributes.full_screen == true
                  ? "lg:px-50 mt-2 sm:mt-4 "
                  : "mt-2 sm:mt-4 ",
              ],
            },
            [
              !_vm.product.data.attributes.not_for_sale
                ? _c("div", [
                    !_vm.product.data.attributes.active_stock_balance
                      ? _c(
                          "div",
                          { staticClass: "text-green-dark font-bold" },
                          [
                            _vm.product.data.attributes.hasPreorderStockBalance
                              ? _c("span", [_vm._v("Предзаказ")])
                              : _vm.product.data.attributes.coming_soon
                              ? _c("span", [_vm._v("Скоро в продаже")])
                              : _vm.product.data.attributes.retail_stock_balance
                              ? _c("span", [_vm._v("Только в магазинах")])
                              : _c("span", [_vm._v("Нет в наличии")]),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "text-brown-dark font-bold" },
                          [
                            _vm.product.data.attributes.not_for_sale === 1 &&
                            !_vm.product.data.attributes.isPreorder
                              ? _c("span", [_vm._v("Только в магазинах")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.product.data.attributes.isPreorder
                              ? _c("span", [_vm._v("Предзаказ")])
                              : _vm._e(),
                          ]
                        ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Product",
                      params: {
                        category: _vm.product.links.meta.categorySlug,
                        product: _vm.product.links.meta.productSlug,
                      },
                    },
                  },
                },
                [
                  _c("span", { on: { click: _vm.sendSelectItemToGTM } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-1 text-gray-main text-xs sm:text-sm font-normal uppercase tracking-widest",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.product.data.attributes.title) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {},
                _vm._l(
                  _vm.product.data.attributes.color_related_products.data,
                  function (color) {
                    return _c("div", { staticClass: "inline-block" }, [
                      _c("div", { staticClass: "pr-2.5" }, [
                        color.data.attributes.color.data.attributes.code
                          ? _c("div", {
                              staticClass: "w-2.5 h-2.5 rounded-full",
                              style:
                                "background-color: " +
                                color.data.attributes.color.data.attributes
                                  .code +
                                ";",
                            })
                          : color.data.attributes.color.data.attributes.image
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "w-2.5 h-2.5 rounded-full overflow-hidden",
                              },
                              [
                                _c("img", {
                                  staticClass: "object-cover",
                                  attrs: {
                                    src: color.data.attributes.color.links
                                      .color_image,
                                    alt: "product color image",
                                  },
                                }),
                              ]
                            )
                          : _c("div", {
                              staticClass:
                                "w-2.5 h-2.5 rounded-full overflow-hidden",
                            }),
                      ]),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-light text-xs sm:text-sm font-normal uppercase tracking-widest",
                },
                [
                  _vm.product.data.attributes.discount != null
                    ? _c("div", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("formatNumber")(
                                _vm.product.data.attributes.cost -
                                  _vm.product.data.attributes.discount.data
                                    .attributes.amount
                              )
                            ) +
                            " ₽\n                "
                        ),
                        _c("span", { staticClass: "pl-1 line-through" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("formatNumber")(
                                  _vm.product.data.attributes.cost
                                )
                              ) +
                              " ₽\n                "
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("formatNumber")(
                                _vm.product.data.attributes.cost
                              )
                            ) +
                            " ₽\n            "
                        ),
                      ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }