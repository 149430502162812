var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.filteredProducts &&
    _vm.availableFiltersForCategoryLocal &&
    _vm.filteredProducts.meta &&
    _vm.availableFiltersForCategoryLocal.meta
      ? _c("div", { staticClass: "pt-5" }, [
          _c("div", { staticClass: "relative" }, [
            _c("div", { staticClass: "flex-none lg:flex lg:items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "ml-5 uppercase text-14 text-gray-main-2 tracking-widest lg:ml-9",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.categoryTitle) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.availableFiltersForCategoryLocal && _vm.countRemainderStocks
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mt-8 ml-5 flex text-14 text-gray-main cursor-pointer overflow-x-scroll no-scrollbar lg:ml-12 lg:mt-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeSubCategoryId === _vm.categoryId
                              ? "text-black"
                              : "text-gray-main-2",
                          on: {
                            click: function ($event) {
                              return _vm.filterBySubCategory(_vm.categoryId)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Все\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.availableFiltersForCategoryLocal.meta
                          .availableFilters.categories.data,
                        function (tag) {
                          return _c("div", { key: tag.data.category_id }, [
                            _c(
                              "div",
                              {
                                staticClass: "whitespace-nowrap",
                                class:
                                  _vm.activeSubCategoryId ===
                                  tag.data.category_id
                                    ? "ml-6 text-black"
                                    : "ml-6 text-gray-main-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.filterBySubCategory(
                                      tag.data.category_id
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(tag.data.attributes.title) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-9 gradient ver" }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-9 gradient ver" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }