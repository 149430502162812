var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col border-t border-b border-gray-lighter text-gray-main text-base text-center sm:text-center",
        class: [
          _vm.block.data.attributes.half_size_image == "image-right"
            ? "sm:flex-row"
            : "sm:flex-row-reverse",
        ],
        style:
          "background-color: " +
          _vm.block.data.attributes.background_color +
          ";",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "w-full sm:w-1/2 flex items-center mx:auto items-center justify-center",
          },
          [
            _c(
              "div",
              { staticClass: "pl-10 pr-12 mx:auto sm:max-w-md my-12 lg:my-0" },
              [
                _vm.block.data.attributes.h2
                  ? _c(
                      "h2",
                      {
                        staticClass:
                          "text-xl font-normal tracking-widest lg:text-2xl",
                        style:
                          "color: " +
                          _vm.block.data.attributes.text_color +
                          ";",
                      },
                      [_vm._v(_vm._s(_vm.block.data.attributes.h2))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.block.data.attributes.text != null
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-7 text-16 mb-4 font-normal lg:text-17",
                        style:
                          "color: " +
                          _vm.block.data.attributes.text_color +
                          ";",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.block.data.attributes.text) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "w-full sm:hidden lg:block lg:w-full mx-auto items-center justify-center",
                    attrs: { href: _vm.block.data.attributes.link },
                  },
                  [
                    _c("img", {
                      staticClass: "mt-12",
                      attrs: {
                        src: _vm.block.data.attributes.sub_image.data.attributes
                          .path,
                        alt: "",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-7 mx:auto flex items-center justify-center lg:mt-12",
                  },
                  [
                    _vm.block.data.attributes.button_text != null
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "uppercase underline font-normal",
                              attrs: { href: _vm.block.data.attributes.link },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                                  style:
                                    "border-color: " +
                                    _vm.block.data.attributes
                                      .border_button_color +
                                    ";" +
                                    "color: " +
                                    _vm.block.data.attributes.text_color +
                                    ";",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.block.data.attributes.button_text
                                    ) + "\n                           "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "w-full sm:w-1/2 sm:flex sm:items-center object-cover",
            attrs: { href: _vm.block.data.attributes.link },
          },
          [
            _c("img", {
              staticClass: "w-full",
              attrs: {
                src: _vm.block.data.attributes.image.data.attributes.path,
                alt: "",
              },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }