<template>
    <PopupOnRightSide @closePopup="$emit('closePopup')">
        <div class="mt-7 flex text-14 text-black">
            <div
                @click="showSizeTable"
                class="cursor-pointer"
                :class="sizeTableIsShown ? 'underlinedPositions' : ''"
            >
                Таблица размеров
            </div>
            <div
                @click="showMeasurementsTable"
                class="cursor-pointer"
                :class="measurementsTableIsShown ? 'underlinedPositions ml-6' : 'ml-6'"
            >
                Обмеры изделия
            </div>
        </div>
        <div v-if="sizeTableIsShown">
            <div class="mt-5 text-14">
                Общая таблица размеров показывает нашу стандартную размерную линейку.
            </div>
            <div class="h-full mt-6 py-4 border-b border-t border-gray-lightest">
                <div v-html="sizeTableInfo.data.attributes.html" class="h-full text-13"></div>
            </div>
        </div>
        <div v-else-if="measurementsTableIsShown">
            <div class="mt-5 text-14">Все замеры указаны в см</div>
            <div
                class="h-full mt-6 py-6 border-b border-t border-gray-lightest"
                v-if="product.data.attributes.details.data.attributes.measurements_table"
            >
                <MeasurementsTable
                    :measurements-table="product.data.attributes.details.data.attributes.measurements_table"
                />
            </div>
            <div v-else class="h-full mt-6 py-4 border-b border-t border-gray-lightest">
                <div
                    v-html="product.data.attributes.details.data.attributes.measurements"
                    class="h-full text-13"
                ></div>
            </div>
        </div>
        <div class="mt-6">
            <div
                v-html="product.data.attributes.details.data.attributes.model_details_on_photo"
                class="mt-5 text-14 text-black"
            ></div>
        </div>
        <ContactsInfoBlock class="mt-6" />
    </PopupOnRightSide>
</template>

<script>
import PopupOnRightSide from './PopupOnRightSide.vue'
import ContactsInfoBlock from '../ContactsInfoBlock.vue'
import MeasurementsTable from '../product/MeasurementsTable.vue'

export default {
    name: 'SizeAndMeasurementsTablePopup',
    props: {
        product: null,
        sizeTableInfo: null
    },
    data: () => ({
        sizeTableIsShown: true,
        measurementsTableIsShown: false
    }),
    components: {
        MeasurementsTable,
        ContactsInfoBlock,
        PopupOnRightSide
    },
    methods: {
        showSizeTable() {
            this.measurementsTableIsShown = false
            this.sizeTableIsShown = true
        },
        showMeasurementsTable() {
            this.sizeTableIsShown = false
            this.measurementsTableIsShown = true
        }
    }
}
</script>

<style lang="css">
b,
strong {
    font-weight: 400 !important;
}
.underlinedPositions {
    text-decoration: underline;
    text-underline-offset: 3px;
}
</style>
