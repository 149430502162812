var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex-none" },
      [
        _vm.title !== null
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "mt-12 mb-4 w-full text-black text-lg font-medium tracking-widest text-left ml-3 lg:text-2xl lg:mt-15 lg:mb-8",
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.title) + "\n            "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "categorySwiper",
            staticClass: "swiper-container w-full mx-auto",
          },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.productsForBlock, function (product, index) {
                return _c("ProductBlock", {
                  key: product.id,
                  staticClass:
                    "swiper-slide px-0.5 items-center justify-center",
                  attrs: {
                    product: product,
                    "gtm-position": index,
                    "gtm-list": _vm.title,
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "swiper-button-prev" }),
            _vm._v(" "),
            _c("div", { staticClass: "swiper-button-next" }),
          ]
        ),
        _vm._v(" "),
        _vm.button_text != null && _vm.link != null
          ? _c(
              "router-link",
              {
                staticClass:
                  "mt-7 mb-15 mx:auto flex items-center justify-center lg:mt-15 lg:mb-15",
                attrs: { to: _vm.link },
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "py-3 px-4 text-xs border-2 border-gray-main font-medium tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.button_text) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }