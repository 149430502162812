var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap w-full justify-center items-center" },
    [
      _c("div", { staticClass: "flex flex-wrap max-w-xl" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c("label", { staticClass: "w-full", attrs: { for: "name" } }, [
            _vm._v("Имя"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            staticClass:
              "appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal",
            class: [
              _vm.submitted && _vm.$v.form.name.$error
                ? "border-red-400"
                : "border-gray-lighter",
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.form.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.submitted && !_vm.$v.form.name.required
            ? _c("div", { staticClass: "text-red-400" }, [
                _vm._v("Заполните имя"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c("label", { staticClass: "w-full", attrs: { for: "surname" } }, [
            _vm._v("Фамилия"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.surname,
                expression: "form.surname",
              },
            ],
            staticClass:
              "appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal",
            class: [
              _vm.submitted && _vm.$v.form.surname.$error
                ? "border-red-400"
                : "border-gray-lighter",
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.form.surname },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "surname", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.submitted && !_vm.$v.form.surname.required
            ? _c("div", { staticClass: "text-red-400" }, [
                _vm._v("Заполните фамилию"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c("label", { attrs: { for: "email" } }, [_vm._v("E-mail")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email",
              },
            ],
            staticClass:
              "appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal",
            class: [
              _vm.submitted && _vm.$v.form.email.$error
                ? "border-red-400"
                : "border-gray-lighter",
            ],
            attrs: { type: "email" },
            domProps: { value: _vm.form.email },
            on: {
              click: this.resetRegisterEmailError,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "email", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          (_vm.submitted && _vm.$v.form.email.$error) || _vm.registerEmailError
            ? _c("div", { staticClass: "text-red-400" }, [
                !_vm.$v.form.email.required
                  ? _c("span", [_vm._v("Заполните email")])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.form.email.email
                  ? _c("span", [_vm._v("Неверный email")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.registerEmailError
                  ? _c("span", [_vm._v(_vm._s(_vm.registerEmailError[0]))])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-2 w-full" },
          [
            _c("label", { attrs: { for: "phone" } }, [_vm._v("Телефон")]),
            _vm._v(" "),
            _c(
              "vue-tel-input",
              {
                attrs: {
                  inputOptions: {
                    placeholder: "",
                  },
                  preferredCountries: ["ru", "by", "kz"],
                  defaultCountry: "ru",
                  styleClasses: _vm.styleClasses,
                  mode: "international",
                  required: true,
                },
                on: { validate: _vm.telValidate },
                model: {
                  value: _vm.form.phonenumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phonenumber", $$v)
                  },
                  expression: "form.phonenumber",
                },
              },
              [_vm._v("\n            >\n\n\n            ")]
            ),
            _vm._v(" "),
            (_vm.submitted && _vm.$v.form.phone.$error) ||
            _vm.registerPhoneError
              ? _c("div", { staticClass: "text-red-400" }, [
                  !_vm.$v.form.phone.required
                    ? _c("span", [_vm._v("Заполните телефон")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.registerPhoneError
                    ? _c("span", [_vm._v(_vm._s(_vm.registerPhoneError[0]))])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c("label", { attrs: { for: "password" } }, [_vm._v("Пароль")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.password,
                expression: "form.password",
              },
            ],
            staticClass:
              "appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal",
            class: [
              _vm.submitted && _vm.$v.form.password.$error
                ? "border-red-400"
                : "border-gray-lighter",
            ],
            attrs: { type: "password", name: "password" },
            domProps: { value: _vm.form.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "password", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.submitted && _vm.$v.form.password.$error
            ? _c("div", { staticClass: "text-red-400" }, [
                !_vm.$v.form.password.required
                  ? _c("span", [_vm._v("Пароль обязателен")])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.form.password.minLength
                  ? _c("span", [
                      _vm._v("Пароль должен содержать от 6 символов"),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c("label", { attrs: { for: "confirm_password" } }, [
            _vm._v("Подтверждение пароля"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.password_confirmation,
                expression: "form.password_confirmation",
              },
            ],
            staticClass:
              "appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal",
            class: [
              _vm.submitted && _vm.$v.form.password_confirmation.$error
                ? "border-red-400"
                : "border-gray-lighter",
            ],
            attrs: { type: "password", name: "password_confirmation" },
            domProps: { value: _vm.form.password_confirmation },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "password_confirmation", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.submitted && _vm.$v.form.password_confirmation.$error
            ? _c("div", { staticClass: "text-red-400" }, [
                !_vm.$v.form.password_confirmation.required
                  ? _c("span", [_vm._v("Подтверждение пароля обязательно")])
                  : !_vm.$v.form.password_confirmation.sameAsPassword
                  ? _c("span", [_vm._v("Пароли должны совпадать")])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "p-2 w-full mt-4",
            attrs: { disabled: _vm.$v.$invalid },
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "w-full py-3 px-5 bg-black text-white text-sm tracking-wider uppercase focus:outline-none",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.saveForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Зарегистрироваться")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-2 text-xs tracking-normal text-gray-light" },
              [
                _vm._v(
                  "\n                Нажимая на кнопку «Зарегистрироваться», я соглашаюсь на обработку моих персональных данных и ознакомлен(а) с условиями "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "underline",
                    attrs: { to: "/info/privacy-policy", target: "_blank" },
                  },
                  [_vm._v("конфиденциальности")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2 text-2xl text-gray-light" }, [
      _c("h1", [_vm._v("Регистрация")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }