var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.pageStatus === "success"
      ? _c(
          "div",
          { staticClass: "overflow-hidden" },
          [
            _vm._l(
              _vm.page.data.attributes.page_blocks.data,
              function (page_block) {
                return _c(
                  "div",
                  { key: page_block.data.page_block_id },
                  [
                    _vm.isTwoHalfSizeImageBlockActive(page_block)
                      ? _c("TwoHalfSizeImageBlock", {
                          attrs: { block: page_block },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isFullSizeBlockActive(page_block)
                      ? _c("FullSizeBlock", { attrs: { block: page_block } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isHalfSizeImageBlockActive(page_block)
                      ? _c("HalfSizeImageBlock", {
                          attrs: { block: page_block },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isHalfSizeImageAndSubImageBlockActive(page_block)
                      ? _c("HalfSizeImageAndSubImageBlock", {
                          attrs: { block: page_block },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNonSwipableBlockActive(page_block)
                      ? _c("NonSwipableBlock", { attrs: { block: page_block } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSwipableCategoryBlockActive(page_block)
                      ? _c("SwipableCategoryBlock", {
                          attrs: {
                            title: page_block.data.attributes.h2,
                            productsForBlock:
                              page_block.data.relations.productsForPageBlock
                                .data,
                            button_text: page_block.data.attributes.button_text,
                            link: page_block.data.attributes.link,
                            block: page_block,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSwipableFullSizeBlockActive(page_block)
                      ? _c("SwipableFullSizeBlock", {
                          attrs: {
                            block:
                              page_block.data.attributes
                                .swipable_full_size_block,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSwipableLookBookCategoryBlockActive(page_block)
                      ? _c("SwipableLookBookCategoryBlock", {
                          attrs: {
                            block:
                              page_block.data.attributes
                                .swipable_look_book_category_block,
                            colorBg:
                              page_block.data.attributes.background_color,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            _vm._v(" "),
            _c("div", { attrs: { "data-popmechanic-embed": "41244" } }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.successSubscriptionFromPopupLocal
          ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
              _c("div", { staticClass: "w-full h-full" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-normal text-center font-gothic text-gray-main",
                  },
                  [
                    _vm._v(
                      "\n                    Ваш email успешно подтвержден!\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "w-full mt-3 py-3 bg-brown-light text-white text-xl font-normal focus:outline-none",
                    on: { click: _vm.closePopup },
                  },
                  [_vm._v("\n                    Хорошо\n                ")]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }