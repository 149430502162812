<template>
    <Popup @closePopup="$emit('closePopup')">
        <div class="-mt-3 ml-4 font-gothic text-gray-main text-2xl">Предупреждение</div>
        <div class="mt-4 w-full text-left justify-center flex-none text-gray-main">
            {{ deliveryWarningMessage.data.attributes.message }}
        </div>
    </Popup>
</template>

<script>
import Popup from "./Popup";
import {mapGetters} from "vuex";

export default {
    name: "DeliveryWarningPopup",
    components :{
        Popup
    },
    computed: {
        ...mapGetters({
            deliveryWarningMessage: 'deliveryWarningMessage'
        })
    }
}
</script>

<style scoped>

</style>
