var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap w-full justify-center items-center" },
    [
      _c("div", { staticClass: "flex flex-wrap max-w-xl" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "p-2 w-full" }, [
          _c(
            "form",
            {
              attrs: { action: "#" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "p-2 w-full" }, [
                _c("label", { attrs: { for: "email" } }, [_vm._v("E-mail")]),
                _vm._v(" "),
                _vm.errors.email
                  ? _c("span", { staticClass: "w-full text-red-500" }, [
                      _vm._v(_vm._s(_vm.errors.email[0])),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass:
                    "appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-brown-light tracking-normal",
                  attrs: { id: "email", type: "email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "p-2 w-full" }, [
                _c("label", { attrs: { for: "password" } }, [_vm._v("Пароль")]),
                _vm._v(" "),
                _vm.errors.password
                  ? _c("span", { staticClass: "w-full text-red-500" }, [
                      _vm._v(_vm._s(_vm.errors.password[0])),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password",
                    },
                  ],
                  staticClass:
                    "appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-brown-light tracking-normal",
                  attrs: { id: "password", type: "password", name: "password" },
                  domProps: { value: _vm.form.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "password", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "p-2 w-full" }, [
                _c("label", { attrs: { for: "confirm_password" } }, [
                  _vm._v("Подтверждение пароля"),
                ]),
                _vm._v(" "),
                _vm.errors.password
                  ? _c("span", { staticClass: "w-full text-red-500" }, [
                      _vm._v(_vm._s(_vm.errors.password[0])),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password_confirmation,
                      expression: "form.password_confirmation",
                    },
                  ],
                  staticClass:
                    "appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-brown-light tracking-normal",
                  attrs: {
                    id: "confirm_password",
                    type: "password",
                    name: "password_confirmation",
                  },
                  domProps: { value: _vm.form.password_confirmation },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "password_confirmation",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2 text-2xl text-gray-light" }, [
      _c("h1", [_vm._v("Восстановление пароля")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2 w-full mt-4" }, [
      _c(
        "button",
        {
          staticClass:
            "w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider uppercase focus:outline-none",
          attrs: { type: "submit" },
        },
        [_vm._v("Восстановить пароль")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }