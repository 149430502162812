<template>
    <div>
        <div v-if="filteredProducts && availableFiltersForCategoryLocal && filteredProducts.meta && availableFiltersForCategoryLocal.meta"
             class="pt-5">
            <div class="relative">
                <div class="flex-none lg:flex lg:items-center">
                    <div class="ml-5 uppercase text-14 text-gray-main-2 tracking-widest lg:ml-9">
                        {{ categoryTitle }}
                    </div>
                    <div v-if="availableFiltersForCategoryLocal && countRemainderStocks"
                         class="mt-8 ml-5 flex text-14 text-gray-main cursor-pointer
                        overflow-x-scroll no-scrollbar lg:ml-12 lg:mt-0">
                        <div @click="filterBySubCategory(categoryId)"
                             :class="activeSubCategoryId === categoryId ? 'text-black' : 'text-gray-main-2'">
                            Все
                        </div>
                        <div v-for="tag in availableFiltersForCategoryLocal.meta.availableFilters.categories.data"
                             :key="tag.data.category_id"
                        >
                            <div @click="filterBySubCategory(tag.data.category_id)" class="whitespace-nowrap"
                                 :class="activeSubCategoryId === tag.data.category_id
                                 ? 'ml-6 text-black'
                                 : 'ml-6 text-gray-main-2'">
                                {{ tag.data.attributes.title }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-9 gradient ver"></div>
                </div>
            </div>
            <div class="mt-9 gradient ver"></div>
        </div>
    </div>
</template>

<script>
import Sorting from "./Sorting.vue";
import Filters from "./Filters.vue";


export default {
    name: "FiltersSubCategories",
    components: {Filters, Sorting},
    props: {
        categoryId: null,
        categoryTitle: null,
        filteredProducts: null,
        availableFiltersForCategoryLocal: null,
        countRemainderStocks: null,
    },
    computed: {
        activeSubCategoryId() {
            return this.filteredProducts.meta.currentFilters.categories.length > 0
                ? this.filteredProducts.meta.currentFilters.categories[0]
                : this.filteredProducts.meta.currentFilters.mainCategoryId
        },
    },
    methods: {
        async filterBySubCategory(id) {
            this.$emit('filterBySubCategory', {id: id})
        },
    },
}
</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.underlinedPositions {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.no-web-kit::-webkit-scrollbar {
    width: 0;
}

.gradient.ver {
    width: 40px;
    height: 30px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, .89) 70%, #fff 30%);
    top: 2px;
    bottom: 2px;
    left: auto;
    right: 0;
    margin-bottom: 0;
    position: absolute;
}
</style>
