const state = {
firstPartToPayDolyame: 0,
dateAndPricesToPayDolyame: null,

options : {
    month: 'long',
        day: 'numeric',
},
};

const getters = {
    firstPartToPayDolyame : state => {
        return state.firstPartToPayDolyame;
    },
    dateAndPricesToPayDolyame : state => {
        return state.dateAndPricesToPayDolyame;
    }
};

const actions = {
     getPricesForDolyame ({commit, state, dispatch}, cart) {
        let part = [[]]
        let modulo = 0
        let partToPay = 0
        const currentDate = new Date
        partToPay = Math.floor(cart?.data.attributes.priceToPay/4)
        modulo = cart?.data.attributes.priceToPay%4
        part = [
            {"part": partToPay, 'dateOfPay': 'Сегодня'},
            {"part": partToPay, 'dateOfPay': new Date(currentDate.setDate(currentDate.getDate() + 14)).toLocaleString("ru", state.options)},
            {"part": partToPay, 'dateOfPay': new Date(currentDate.setDate(currentDate.getDate() + 14)).toLocaleString("ru", state.options)},
            {"part":partToPay+modulo, 'dateOfPay': new Date(currentDate.setDate(currentDate.getDate() + 14)).toLocaleString("ru", state.options)}
        ]
         commit('fetchFirstPartToPayDolyame', partToPay)
         commit('fetchDateAndPricesToPayDolyame', part)
    },
};
const mutations = {
    fetchFirstPartToPayDolyame(state, firstPart) {
        state.firstPartToPayDolyame = firstPart
    },
    fetchDateAndPricesToPayDolyame (state, dateAndPrices) {
        state.dateAndPricesToPayDolyame = dateAndPrices
    }
};

export default {
    state, getters, actions, mutations,
}
