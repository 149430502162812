var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mt-4 w-full text-left justify-center flex-none text-gray-main",
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "mt-4 w-full text-center justify-center flex-none",
              },
              [
                _vm._v(
                  "\n                Мы запустили доставку по всему миру. Транспортировку заказов осуществляет компания DHL, срок доставки зависит от страны назначения и занимает от 5 до 10 дней. Оплата возможно только выпущенными в России картами."
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }