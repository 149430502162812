var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "items-center mt-5 lg:mb-10" }, [
      _c("div", { staticClass: "flex justify-between" }, [
        _c(
          "div",
          {
            staticClass:
              "uppercase text-13 text-black text-left flex-1 lg:text-16",
          },
          [_vm._v("\n        Оплата\n      ")]
        ),
        _vm._v(" "),
        this.cart && this.cart?.data.attributes.hasPaymentMethod
          ? _c("div", { staticClass: "flex items-center" }, [
              _c(
                "button",
                { staticClass: "lg:hidden", on: { click: _vm.closeForm } },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "8",
                        height: "5",
                        viewBox: "0 0 8 5",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M4 1L1 4",
                          stroke: "black",
                          "stroke-linecap": "square",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M7 3.99976L4 0.999756",
                          stroke: "black",
                          "stroke-linecap": "square",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "hidden lg:block",
                  on: { click: _vm.closeForm },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "11",
                        height: "7",
                        viewBox: "0 0 11 7",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M5.5 1.00049L1 5.50049",
                          stroke: "black",
                          "stroke-linecap": "square",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M10 5.50024L5.5 1.00024",
                          stroke: "black",
                          "stroke-linecap": "square",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full mt-2" },
        [
          _c(
            "div",
            { staticClass: "mb-5 uppercase text-11 text-black lg:text-13" },
            [_vm._v("\n        Выберите способ оплаты\n      ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.paymentTypesPreorder?.data, function (paymentTypeItem) {
            return _c("div", { key: paymentTypeItem.id }, [
              _c(
                "div",
                {
                  staticClass: "w-full mt-2",
                  on: {
                    click: function ($event) {
                      return _vm.checkPaymentType(paymentTypeItem.id)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex items-center" }, [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedPaymentType,
                            expression: "selectedPaymentType",
                          },
                        ],
                        staticClass:
                          "appearance-none h-2 w-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none",
                        class: [
                          _vm.submitted && _vm.$v.selectedPaymentType.$invalid
                            ? "border-red-400"
                            : "border-black",
                        ],
                        attrs: { type: "radio", name: "paymentType" },
                        domProps: {
                          value: paymentTypeItem,
                          checked: _vm._q(
                            _vm.selectedPaymentType,
                            paymentTypeItem
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedPaymentType = paymentTypeItem
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-3 text-11 text-black lg:text-13" },
                      [
                        paymentTypeItem.id === 8
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(paymentTypeItem.attributes.title) +
                                    " - 4 платежа по " +
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        _vm.firstPartToPayDolyame
                                      )
                                    ) +
                                    " ₽"
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(paymentTypeItem.attributes.title)),
                            ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  paymentTypeItem.id === 8
                    ? _c(
                        "div",
                        [_c("DolyameCart", { attrs: { cart: _vm.cart } })],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }