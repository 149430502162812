<template>
    <Popup @closePopup="$emit('closePopup')">
        <div>
            <div class="mt-4 text-14 font-normal text-black">
                Следующий предзаказ можно оформить только после оплаты заказа, который лежит в вашей корзине  «Предзаказ»
            </div>
            <div class="mt-4">
                <router-link :to="{name: 'PreorderCart'}">
                    <button class="w-full h-9 mt-2.5 bg-black text-14 font-normal text-white
            flex items-center justify-center focus:outline-none lg:w-98">Оформить предзаказ</button>
                </router-link>
                <button class="w-full h-9 mt-2.5 bg-white border border-black
            text-14 font-normal text-black flex items-center justify-center focus:outline-none lg:w-98"
                        @click="$emit('replaceProductInPreorderCart')">Заменить товар</button>
            </div>
        </div>
    </Popup>
</template>

<script>

import Popup from "./Popup.vue";

export default {
    name: "ProductIsAlreadyInPreorderCartPopup",
    components: {Popup},
    props: {
        product: null,
    }
}

</script>

<style scoped>
</style>
