import axios from "axios";


const state = {
    //Общий список типов доставки в корзине

    deliveryRegionDetails: null,
    deliveryRegionDetailsStatus: null,

    //поля ввода данных в корзине
    deliveryTypeCourierOrPickup: null,
    deliveryRegionDetail: null,
    deliveryCity: null,
    deliveryAddress: null,

    deliverySuggestions: null,

    selectFromSuggestion: false,

    delivery: {
        postal_code: "",
        country: "",
        country_iso_code: "",
        city: "",
        street: "",
        house: "",
        building: "",
        apartment: "",
        pick_up_point: "",
        pick_up_point_cdek: "",
        pick_up_point_id: "",
        date: "",
        time_interval_id: "",
        time_interval: "",
        comment:""
    },
    clicked: {
        country: false,
        city: false,
        street: false,
        house: false,
    },
    deliverySelectedSuggestion: null,

};

const getters = {
    clicked: state => {
        return state.clicked;
    },

    delivery: state => {
        return state.delivery;
    },

    deliverySelectedSuggestion: state => {
        return state.deliverySelectedSuggestion;
    },

    deliveryRegionDetail: state => {
        return state.deliveryRegionDetail;
    },

    deliveryTypeCourierOrPickup: state => {
        return state.deliveryTypeCourierOrPickup;
    },

    deliverySuggestions: state => {
        return state.deliverySuggestions;
    },

    deliveryRegionDetails: state => {
        return state.deliveryRegionDetails;
    },

    deliveryRegionDetailsStatus: state => {
        return state.deliveryRegionDetailsStatus;
    },
    deliveryWarningMessage: state => {
        return state.deliveryWarningMessage;
    },
    deliveryWarningMessageStatus: state => {
        return state.deliveryWarningMessageStatus;
    },
    deliveryRestrictedMessage: state => {
        return state.deliveryRestrictedMessage;
    },
    deliveryRestrictedMessageStatus: state => {
        return state.deliveryRestrictedMessageStatus;
    },


};

const actions = {
    setClickedCountry({commit}){
        commit('set_clicked_country', true);
    },

    setClickedCity({commit}){
        commit('set_clicked_city', true);
    },
    setClickedStreet({commit}){
        commit('set_clicked_street', true);
    },
    setClickedHouse({commit}){
        commit('set_clicked_house', true);
    },
    setDeliveryRegionDetail({commit, state}, deliveryRegionDetail){
        //Устанавливаем выбранный клиентом тип доставки
        commit('SET_DELIVERY_REGION_DETAIL', deliveryRegionDetail)
    },
    setDeliveryTypeCourierOrPickup({commit, dispatch}, deliveryTypeCourierOrPickup){
        //Устанавливаем выбранный клиентом тип доставки
        commit('SET_DELIVERY_TYPE_COURIER_OR_PICKUP', deliveryTypeCourierOrPickup);
        dispatch('setDeliveryRegionDetail', null)
    },

    setDeliveryCountry({commit, state}, country){
        commit('SET_DELIVERY_COUNTRY', country);
        commit('SET_DELIVERY_CITY', null);
        commit('SET_DELIVERY_STREET', null);
        commit('SET_DELIVERY_HOUSE', null);
        commit('SET_DELIVERY_BUILDING', null);
        commit('SET_DELIVERY_APARTMENT', null);
    },
    setDeliveryCity({commit, state}, city){
        commit('SET_DELIVERY_CITY', city);
        commit('SET_DELIVERY_STREET', null);
        commit('SET_DELIVERY_HOUSE', null);
        commit('SET_DELIVERY_BUILDING', null);
        commit('SET_DELIVERY_APARTMENT', null);

    },
    setDeliveryStreet({commit, state}, street){
        commit('SET_DELIVERY_STREET', street);
        commit('SET_DELIVERY_HOUSE', null);
        commit('SET_DELIVERY_BUILDING', null);
        commit('SET_DELIVERY_APARTMENT', null);
    },
    setDeliveryHouse({commit, state}, house){
        commit('SET_DELIVERY_HOUSE', house);
        commit('SET_DELIVERY_BUILDING', null);
        commit('SET_DELIVERY_APARTMENT', null);
    },
    setDeliveryBuilding({commit, state}, building){
        commit('SET_DELIVERY_BUILDING', building);
        commit('SET_DELIVERY_APARTMENT', null);
    },
    setDeliveryApartment({commit, state}, apartment){
        commit('SET_DELIVERY_APARTMENT', apartment);
    },
    setDeliveryComment({commit, state}, comment){
        commit('SET_DELIVERY_COMMENT', comment);
    },
    setDeliveryPickupPoint({commit, state}, pick_up_point){
        commit('SET_DELIVERY_PICK_UP_POINT', pick_up_point);
    },
    setDeliveryPickupPointId({commit, state}, pick_up_point_id){
        commit('SET_DELIVERY_PICK_UP_POINT_ID', pick_up_point_id);
    },
    setDeliverySelectedDate({commit, state}, dateDelivery){
        commit('SET_DELIVERY_SELECTED_DATE', dateDelivery);
    },
    setDeliveryTimeIntervalId({commit, state}, deliveryTimeIntervalId){
        commit('SET_DELIVERY_SELECTED_TIME_INTERVAL_ID', deliveryTimeIntervalId);
    },
    setDeliveryTimeInterval({commit, state}, deliveryTimeInterval){
        commit('SET_DELIVERY_SELECTED_TIME_INTERVAL', deliveryTimeInterval);
    },
    setDeliveryPostalCode({commit, state}, postal_code){
        commit('SET_DELIVERY_POSTAL_CODE', postal_code);
    },
    async fetchDeliveryTypes({commit, dispatch}, {iso, country, reg, city}) {
        commit('setDeliveryRegionDetailsStatus', 'loading');
        await axios.get('/api/cart/get-delivery-types?countryCode='+iso+'&country='+country+'&region='+reg+'&city='+city)
            .then(res => {
                commit('setDeliveryRegionDetails', res.data)
                commit('SET_INITIAL_DELIVERY_REGION_DETAILS', res.data)
                commit('setDeliveryRegionDetailsStatus', 'success')
            })
            .catch(error => {
                    commit('setDeliveryRegionDetailsStatus', 'error')
            });
    },

    setDeliveryType({commit, dispatch, state}, {deliveryRegionDetailId, country, countryCode,  city, street, house, building, postalCode, region, apartment, comment, pickUpPoint, pickUpPointId, deliveryDate, deliveryTimeInterval}) {
        axios.patch('/api/cart/delivery-method', {"deliveryRegionDetailId" : deliveryRegionDetailId,"city" : city, "region" : region, "country" : country, "countryCode" :countryCode,
        "street": street, "house":house, "building" :building, "apartment":apartment, "pickUpPoint":pickUpPoint,"comment": comment,"postalCode":postalCode,"pickUpPointId":pickUpPointId, "deliveryDate":deliveryDate, "deliveryTimeIntervalId":deliveryTimeInterval})
            .then(res => {
                dispatch('getCart')
                //ВАЖНО! когда серт будет покрывать доставку, это нужно будет переписать, поставить условие,
                //точно ли priceToPay != 0
                dispatch('getPaymentTypes')
            })
            .catch(error => {
            });
    },

    deleteDeliveryMethod({commit, dispatch}) {
        axios.delete('/api/cart/delivery-method')
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
            });
    },
    getDadataHouse({commit, state}, house) {

        commit('SET_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + process.env.MIX_DADATA_TOKEN
            },
            body: JSON.stringify({
                query: house,
                from_bound: { value: "house" },
                to_bound: { value: "house" },
                locations: [
                    {
                        country_iso_code: state.deliverySelectedSuggestion !=null ? state.deliverySelectedSuggestion.data.country_iso_code : "",
                        city_fias_id: state.deliverySelectedSuggestion !=null ? state.deliverySelectedSuggestion.data.city_fias_id : "",
                        street_fias_id: state.deliverySelectedSuggestion !=null ? state.deliverySelectedSuggestion.data.street_fias_id : ""
                    }
                ]
            })
        }

        fetch(process.env.MIX_DADATA_URL, options)
            .then(response => response.text())
            .then(result => {
                commit('SET_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },

    getDadataStreet ({commit, state}, street) {

        commit('SET_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + process.env.MIX_DADATA_TOKEN
            },
            body: JSON.stringify({
                query: street,
                from_bound: { value: "street" },
                to_bound: { value: "street" },
                locations: [
                    {
                        country_iso_code: state.deliverySelectedSuggestion !=null ? state.deliverySelectedSuggestion.data.country_iso_code : "",
                        city_fias_id: state.deliverySelectedSuggestion !=null ? state.deliverySelectedSuggestion.data.city_fias_id : ""
                    }
                ]
            })
        }

        fetch(process.env.MIX_DADATA_URL, options)
            .then(response => response.text())
            .then(result => {
                commit('SET_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },

    getDadataCity ({commit, state}, city) {

        commit('SET_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + process.env.MIX_DADATA_TOKEN
            },
            body: JSON.stringify({
                query: city,
                from_bound: { value: "city-settlement" },
                to_bound: { value: "city-settlement" },
                locations: [
                    {
                        country_iso_code: state.deliverySelectedSuggestion !=null ? state.deliverySelectedSuggestion.data.country_iso_code : "*"
                    }
                ]
            })
        }

        fetch(process.env.MIX_DADATA_URL, options)
            .then(response => response.text())
            .then(result => {
                commit('SET_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },
    getDadataCountry ({commit}, country) {

        commit('SET_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + process.env.MIX_DADATA_TOKEN
            },
            body: JSON.stringify({
                query: country,
                from_bound: { value: "country" },
                to_bound: { value: "country" },
                locations: [
                    {
                        country_iso_code: "*"
                    }
                ]
            })
        }

        fetch(process.env.MIX_DADATA_URL, options)
            .then(response => response.text())
            .then(result => {
                commit('SET_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },

    selectFromSuggestion ({commit}, value) {
        commit('SET_SELECTED_DELIVERY_SUGGESTION', value)
        commit('SET_DELIVERY_SUGGESTIONS', null)
    },

    flushDeliverySuggestions({commit}) {
        commit('SET_DELIVERY_SUGGESTIONS', null)
    }
};

const mutations = {
    set_clicked_country(state, country) {
        state.clicked.country = country;
    },
    set_clicked_city(state, city) {
        state.clicked.city = city;
    },
    set_clicked_street(state, street) {
        state.clicked.street = street;
    },
    set_clicked_house(state, house) {
        state.clicked.house = house;
    },

    SET_DELIVERY_SUGGESTIONS(state, suggesions) {
        state.deliverySuggestions = suggesions;
    },

    SET_DELIVERY_COUNTRY(state, country) {
        state.delivery.country = country;
    },

    SET_DELIVERY_CITY(state, city) {
        state.delivery.city = city;
    },

    SET_DELIVERY_STREET(state, street) {
        state.delivery.street = street;
    },

    SET_DELIVERY_HOUSE(state, house) {
        state.delivery.house = house;
    },
    SET_DELIVERY_BUILDING(state, building) {
        state.delivery.building = building;
    },

    SET_DELIVERY_APARTMENT(state, apartment) {
        state.delivery.apartment = apartment;
    },
    SET_DELIVERY_COMMENT(state, comment) {
        state.delivery.comment = comment;
    },

    SET_DELIVERY_PICK_UP_POINT(state, pick_up_point) {
        state.delivery.pick_up_point = pick_up_point;
    },
    SET_DELIVERY_PICK_UP_POINT_ID(state, pick_up_point_id) {
        state.delivery.pick_up_point_id = pick_up_point_id;
    },

    SET_DELIVERY_POSTAL_CODE(state, postal_code) {
        state.delivery.postal_code = postal_code;
    },

    SET_DELIVERY_SELECTED_DATE(state, dateDelivery) {
        state.delivery.date = dateDelivery;
    },
    SET_DELIVERY_SELECTED_TIME_INTERVAL_ID(state, deliveryTimeIntervalId) {
        state.delivery.time_interval_id = deliveryTimeIntervalId;
    },

    SET_DELIVERY_SELECTED_TIME_INTERVAL(state, deliveryTimeInterval) {
        state.delivery.time_interval = deliveryTimeInterval;
    },

    SET_DELIVERY_REGION_DETAIL(state, deliveryRegionDetail) {
        state.deliveryRegionDetail = deliveryRegionDetail ;
    },
    SET_DELIVERY_TYPE_COURIER_OR_PICKUP(state, deliveryTypeCourierOrPickup) {
        state.deliveryTypeCourierOrPickup = deliveryTypeCourierOrPickup ;
    },

    setDeliveryRegionDetails(state, deliveryRegionDetails) {
        state.deliveryRegionDetails = deliveryRegionDetails ;
    },

    SET_INITIAL_DELIVERY_REGION_DETAILS(state, deliveryRegionDetails) {
        state.initialDeliveryRegionDetails = deliveryRegionDetails ;
    },

    setDeliveryRegionDetailsStatus(state, status) {
        state.deliveryRegionDetailsStatus = status ;
    },

    SET_SELECTED_DELIVERY_SUGGESTION (state, suggestion) {
        state.deliverySelectedSuggestion = suggestion;
    },
};

export default {
    state, getters, actions, mutations
}
