var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative h-12 sm:h-15 items-left",
      on: {
        mouseover: function ($event) {
          _vm.showDropdown = true
        },
        mouseleave: function ($event) {
          _vm.showDropdown = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "h-12 sm:h-15 text-black flex items-center menu-item" },
        [
          _vm.item.data.attributes.desktop
            ? _c(
                "a",
                {
                  staticClass: "px-4 hover:border-b align-middle",
                  attrs: { href: _vm.item.data.attributes.path },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.item.data.attributes.title) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showDropdown && _vm.item.data.attributes.navigation_items.data.length
        ? _c(
            "nav",
            {
              staticClass:
                "max-h-93vh absolute text-sm text-gray-main top-0 ml-4 w-75 pt-6 pb-3 sm:mt-15 bg-white border border-gray-lighter border-opacity-100 overflow-y-scroll no-scrollbar",
            },
            [
              _c(
                "accordion",
                {},
                _vm._l(
                  _vm.item.data.attributes.navigation_items.data,
                  function (sub_item) {
                    return _c(
                      "accordion-item",
                      {
                        key: sub_item.data.attributes.navigation_item_id,
                        staticClass: "w-full px-6 pb-3",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "w-full cursor-pointer" },
                                    [
                                      sub_item.data.attributes
                                        .navigation_sub_items.data.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-14 text-gray-main tracking-normal flex items-center justify-between",
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    sub_item.data.attributes
                                                      .title
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "w-4 h-4" },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      class: [
                                                        slotProps.visible
                                                          ? "transform rotate-90"
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        fill: "none",
                                                        viewBox: "0 0 24 24",
                                                        stroke: "currentColor",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                          "stroke-width": "1",
                                                          d: "M9 5l7 7-7 7",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.showDropdown =
                                                    !_vm.showDropdown
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "w-full h-full",
                                                  attrs: {
                                                    href: sub_item.data
                                                      .attributes.path,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(
                                                        sub_item.data.attributes
                                                          .title
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "accordion-content" },
                          _vm._l(
                            sub_item.data.attributes.navigation_sub_items.data,
                            function (sub_sub_item) {
                              return _c(
                                "div",
                                {
                                  key: sub_sub_item.data.navigation_sub_item_id,
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showDropdown = !_vm.showDropdown
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "w-full h-full",
                                          attrs: {
                                            href: sub_sub_item.data.attributes
                                              .path,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pl-2 py-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-gray-main text-left text-sm font-normal tracking-wider uppercase",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        sub_sub_item.data
                                                          .attributes.title
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      2
                    )
                  }
                ),
                1
              ),
            ],
            1
          )
        : _vm.showDropdown &&
          _vm.item.data.attributes.navigation_items.data.length == 0
        ? _c("nav", { staticClass: "w-3/4 ml-4" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }