var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      staticClass:
        "mt-2 appearance-none text-11 text-gray-light rounded-none w-full h-9 focus:border px-2 focus:outline-none focus:border-black lg:text-13",
      class: { "border-red": _vm.errorMessage },
      attrs: {
        type: "text",
        maxlength: "4",
        placeholder: "Введите код из СМС",
      },
      on: { input: _vm.handleInputChange },
    }),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("p", { staticClass: "text-11 text-red-light-2 mt-2" }, [
          _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isButtonActive
      ? _c("p", { staticClass: "text-11 text-black-light-2 mt-2" }, [
          _vm._v(
            "\n        Вы можете повторно отправить код через " +
              _vm._s(_vm.timeLeft) +
              " секунд\n    "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }