<template>
    <div class="w-full mt-10 justify-center">
        <div class="uppercase text-16 text-normal text-gray-main text-center lg:text-24">
            Кажется, товар закончился <br> или страница больше неактивна
        </div>
        <div>
            <div class="w-76 mt-8 mx-auto text-13 text-normal text-black text-center lg:w-118 lg:text-16">
                Чтобы продолжить ваше путешествие по Present & Simple, предлагаем познакомиться с нашими новинками и найти
                для себя что-то интересное
            </div>
        </div>
        <router-link to="/catalog/novinki" class="flex mt-8 justify-center">
            <button class="h-9 py-1 px-5 uppercase text-16 text-white border border-black bg-black r focus:outline-none lg:px-16">
                <span>Перейти в каталог</span>
            </button>
        </router-link>
        <div data-popmechanic-embed="40996"></div>
    </div>
</template>

<script>
export default {
    name: "ProductNotActiveCard",
}
</script>

<style scoped>

</style>
