<template>
    <div> <!--
    <div class="flex flex-wrap w-full justify-center items-center overflow-hidden">
        <div class="flex flex-wrap"
             :class="[isPageCart ? 'w-full lg:max-w-xl' : 'max-w-xl mt-10']">
            <div v-if="isPageCart === false " class="w-full p-2 text-16 text-gray-light text-center lg:text-24"><h1>Вход</h1></div>
            <div class="p-2 mt-10 w-full">
                <form action="#" @submit.prevent="submit">
                    <div class="flex items-center uppercase py-2 ">
                        <label class="w-20 text-11 lg:text-13" for="email">E-mail</label>
                        <input class="appearance-none rounded-none w-full h-7 ml-10 border border-gray-lighter px-2 focus:outline-none focus:border-black tracking-wide lg:h-10"
                               type="text" name="email" id="email" v-model="form.email">
                    </div>
                    <div class="flex items-center uppercase py-2 w-full">
                        <label class="w-20 text-11 lg:text-13" for="password">Пароль</label>
                        <input class="appearance-none rounded-none w-full h-7 ml-10 border border-gray-lighter px-2 focus:outline-none focus:border-black tracking-norm lg:h-10"
                               type="password" name="password" id="password" v-model="form.password">
                    </div>
                    <span class="ml-26 w-full flex items-center justify-between text-11 text-gray-light lg:text-13">
                        <router-link :to="{name: 'ForgotPassword'}">Забыли пароль?</router-link>
                    </span>
                    <div class="flex items-center mt-4">
                        <div class="w-1/2">
                            <router-link :to="{name: 'Register'}">
                                <button class="w-full h-10 py-2 px-5 text-black text-sm focus:outline-none">Зарегистрироваться</button>
                            </router-link>
                        </div>
                        <div class="w-1/2 ml-3">
                            <button type="submit" class="w-full h-9 py-2 px-5 bg-black text-white text-sm focus:outline-none">Войти</button>
                        </div>
                    </div>
                </form>
            </div>
            <span class="p-2 text-xs text-gray-main" v-if="status=='loading'">
                        Загрузка...
                    </span>
            <span class="p-2 text-xs text-green-500"  v-if="status=='success'">
                        Успешный вход, перенаправление...
                    </span>
            <span class="p-2 text-xs text-red-500" v-if="status=='error'">
                        Ошибка :(
                    </span>
        </div>
    </div>-->
        <vue-tel-input
            v-model="phone"
            @input="telValidate"
            :inputOptions="{ placeholder: 'Номер телефона' }"
            :preferredCountries="['ru', 'by', 'kz']"
            :defaultCountry="'ru'"
            :mode="'international'"
            :required="true"
            :styleClasses="styleClasses"
        >
        </vue-tel-input>
        <p
            v-if="$v.phone.$invalid && $v.phone.$dirty"
            class="text-11 text-red-light-2 mt-2"
        >
            Пожалуйста введите корректный номер телефона
        </p>
        <p
            v-if="this.authWayErrorMessage"
            class="text-11 text-red-light-2 mt-2"
        >
            {{ this.authWayErrorMessage }}
        </p>

        <div v-if="hasCodeSent">
            <input
                v-model="code"
                type="number"
                maxlength="4"
                placeholder="Введите код из СМС"
                class="mt-2 appearance-none text-11 text-gray-light rounded-none w-full h-9 focus:border px-2 focus:outline-none focus:border-black lg:text-13"
            />
            <p
                v-if="this.loginErrorMessage"
                class="text-11 text-red-light-2 mt-2"
            >
                {{ this.loginErrorMessage }}
            </p>
        </div>
        <p v-if="isButtonActive" class="text-11 text-red-light-2 mt-2">
            Вы можете повторно отправить код через {{ timeLeft }} секунд
        </p>

        <button
            v-if="
                (this.authStatus === false && !isButtonActive) ||
                (authInfo?.relations.authUser.data.attributes
                    .isPhoneVerified === false &&
                    !isButtonActive)
            "
            class="w-full mt-5 uppercase h-9 py-1 px-5 border border-black bg-black text-white text-11 r focus:outline-none lg:text-13"
            @click="getCode"
        >
            Получить код
        </button>
        <button
            v-else
            class="w-full mt-5 uppercase h-9 py-1 px-5 border border-black bg-black text-white text-11 r focus:outline-none lg:text-13"
            @click="authorize"
        >
            Авторизоваться
        </button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    required,
    numeric,
    maxLength,
    minLength,
} from "vuelidate/lib/validators";

export default {
    name: "Test",
    data: () => ({
        phone: null,
        way: "phone",
        passwordEmail: null,
        code: null,
        hasCodeSent: false,
        isButtonActive: false,
        authWayStatus: null,
        authWayErrorMessage: null,
        timeLeft: 0,
        loginErrorMessage: null,
    }),
    props: {},
    validations() {
        return {
            phone: {
                required,
                regex: (value) =>
                    value
                        ? /(^\+7[0-7,9]\d{9}$)|(^\+([0-6]|[8-9])\d{6,14}$)/i.test(
                            value,
                        )
                        : true,
            },
            inputValue: {
                required,
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(4),
            },
        };
    },
    methods: {
        ...mapActions({
            initPhoneNumber: "initPhoneNumber",
            signIn: "signIn",
            getAuthStatus: "getAuthStatus",
            initVerificationPhoneNumber: "initVerixficationPhoneNumber",
            verifyPhone: "verifyPhone",
        }),
        telValidate(telnumber, telnumberObject) {
            if (telnumberObject.valid || telnumber.length < 1) {
                this.phone = telnumberObject.number;
                this.phoneIsNotCorrect = false;
            } else {
                this.phone = "";
                this.phoneIsNotCorrect = true;
            }
        },
        startTimer(duration) {
            this.isButtonActive = true;
            this.timeLeft = duration;
            const endTime = new Date(new Date().getTime() + duration * 1000);
            localStorage.setItem("endTime", endTime);

            const timer = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                    clearInterval(timer);
                    this.isButtonActive = false;
                    localStorage.removeItem("endTime");
                }
            }, 1000);
        },
        getCode() {
            this.authWayErrorMessage = null;
            this.loginErrorMessage = null;
            if (
                this.authInfo?.relations.authUser.data.attributes
                    .isPhoneVerified === false
            ) {
                this.initVerificationPhoneNumber(this.phone).then((res) => {
                    this.hasCodeSent = true;
                });
            } else {
                this.$v.$touch();
                if (!this.$v.phone.$invalid) {
                    this.initPhoneNumber(this.phone).then((res) => {
                        this.hasCodeSent = true;
                        this.startTimer(30);
                    });
                }
            }
        },
        authorize() {
            if (
                this.authInfo?.relations.authUser.data.attributes
                    .isPhoneVerified === false
            ) {
                this.verifyPhone({
                    phone: this.phone,
                    code: this.code,
                }).then((res) => {
                    this.isButtonDisabled = false;
                });
            } else {
                this.signIn({
                    phone: this.phone,
                    code: this.code,
                })
                    .then((res) => {
                        this.isButtonDisabled = false;
                    })
                    .catch(() => {
                        this.loginErrorMessage = "Введен неверный код";
                    });
            }
        },
    },
    computed: {
        ...mapGetters({
            initCodeForPhoneNumber: "initCodeForPhoneNumber",
            authStatus: "authStatus",
            authInfo: "authInfo",
        }),
        isUserAuthorized() {
            return (
                this.authInfo?.authStatus && this.authInfo?.relations.authUser
            );
        },
        isUserPhoneVerified() {
            return this.authInfo?.relations.authUser.data.attributes
                .isPhoneVerified;
        },
        styleClasses() {
            if (this.$v.phone.$error) {
                return 'vue-tel-input-error appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal ' +
                    ' text-11 text-gray-light  w-full h-9 focus:border px-2\n' +
                    '                          focus:border-red lg:text-13'
            } else {
                return 'appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal' +
                    ' text-11 text-gray-light w-full h-9 focus:border px-2\n' +
                    '                          focus:border-black lg:text-13'
            }
        },
    },
    mounted() {
        const endTime = localStorage.getItem("endTime");
        if (endTime) {
            const remainingTime = Math.floor(
                (new Date(endTime) - new Date()) / 1000,
            );
            if (remainingTime > 0) {
                this.startTimer(remainingTime);
            }
        }
    },
};
</script>

<style scoped></style>
