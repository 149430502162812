import Vue from "vue";
import router from "./router";
import vueVimeoPlayer from "vue-vimeo-player";
import store from "./store";
import Store from "./apps/Store";
import VueSelect from "vue-select";
import VueDadata from "vue-dadata";
import VueGtm from "vue-gtm";
import axios from "axios";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import "../css/root.css";
import Vuelidate from "vuelidate";

require("./bootstrap");

Vue.filter("formatNumber", function (value) {
    return new Intl.NumberFormat().format(value);
});

Vue.use(vueVimeoPlayer);
Vue.component("v-select", VueSelect);
Vue.component("vue-dadata", VueDadata);

if (process.env.MIX_GTM_ID !== "") {
    Vue.use(VueGtm, {
        id: process.env.MIX_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
        defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}
Vue.use(Vuelidate);
Vue.use(VueTelInput);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.MIX_APP_URL;

store.dispatch('getAuthStatus').then(() => {
    const app = new Vue({
        el: '#app',
        components: {
            Store
        },
        router,
        store,
    });
})
