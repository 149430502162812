<template>
    <div>
        <input v-model="email"
               id="email"
               type="text"
               placeholder="Email"
               class="appearance-none border-gray-lightest rounded-none w-full h-10 px-5
                     focus:border-black focus:ring-0 focus:outline-none text-14">
        <p v-if="$v.email.$invalid && $v.email.$dirty" class="text-11 text-red-light-2 mt-2">
            {{ "Пожалуйста, введите корректный email или номер телефона" }}
        </p>
        <input v-model="password"
               id="password"
               :type="isPasswordVisible ? 'text' : 'password'"
               placeholder="Пароль"
               class="mt-2 appearance-none border-gray-lightest text-gray-light rounded-none w-full h-10 px-5
                      focus:border-black focus:ring-0 focus:outline-none text-14">
        <button class="text-11 text-black items-end lg:text-13"
                @click="togglePasswordVisibility()">
            {{ isPasswordVisible ? "Скрыть пароль" : "Показать пароль" }}
        </button>
        <p v-if="authLoadStatus" class="text-11 text-red-light-2 mt-2">
            {{ "Загрузка..." }}
        </p>
        <p v-if="authWayErrorMessage" class="text-11 text-red-light-2 mt-2">
            {{ authWayErrorMessage }}
        </p>
        <button class="w-full mt-2 h-10 py-1 px-5 text-white r focus:outline-none text-14 border border-black bg-black"
                @click="authorizeByCredentials()">
            {{ "Войти" }}
        </button>
        <span @click="closePopupBecauseOfForgotPassport"
              class="ml-26 w-full flex items-center justify-center text-11 text-gray-light lg:text-13">
           <router-link :to="{name: 'ForgotPassword'}">{{ "Забыли пароль ?" }}</router-link>
        </span>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import {email, required} from "vuelidate/lib/validators";

export default {
    name: 'AuthByEmail',
    props: {},
    data() {
        return {
            email: null,
            isPasswordVisible: false,
            password: null,
            authWayErrorMessage: null,
            forgotPassword: false,
            authLoadStatus: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            authStatus: 'authStatus',
        })
    },
    validations() {
        return {
            email: {
                required,
                email
            }
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible;
        },
        ...mapActions({
            getCart: 'getCart'
        }),
        closePopupBecauseOfForgotPassport() {
            console.log('hdjg')
            this.$emit('closePopupBecauseOfForgotPassport')
        },

        checkTheError(error) {
            this.authWayErrorMessage = null
            const errorMessagesMap = {
                'user not found': 'Пользователь не найден',
                'No auth way': 'Пользователь не найден',
                'Verification code is invalid': 'Введен некорректный код',
                'validation.required': 'Введите пароль',
                'Verification has not started': 'Вы использовали все попытки проверки кода, перегенерируйте код, либо ' +
                    'обратитесь в техническую поддержку',
                'The provided credentials are incorect': 'Введен неккоректный пароль',
                'You cant resend the code again': 'Вы использовали все доступные попытки входа.' +
                    ' Пожалуйста, попробуйте позднее или обратитесь в техническую поддержку',
            };
            this.authWayErrorMessage = errorMessagesMap[error] || 'Произошла ошибка. Обратитесь, пожалуйста, в поддержку'
        },
        authorizeByCredentials() {
            this.authLoadStatus = true
            axios.post('/api/auth/login', {
                'login': this.email,
                'password': this.password
            })
                .then(() => {
                    this.authLoadStatus = false
                    this.$emit('authByEmailSuccessClosePopup')
                    this.$store.commit('setAlertShow', true)
                    this.$store.commit('setAlertMessage', {
                        message: 'Вы успешно авторизовались',
                    })
                })
                .catch(error => {
                    this.authLoadStatus = false
                    this.checkTheError(error.response.data.errors
                        ? error.response.data.errors.password[0]
                        : error.response.data.message)
                    this.authServerError = error.response.status === 500
                })
        },
        async submit() {
            await this.getCart()
            await this.$gtm.trackEvent({
                event: "login", // Event type [default = 'interaction'] (Optional)
                clientId: this.user.id,
                clientEmail: this.user.email,
                clientPhone: this.user.phone,
                noninteraction: false,
            });
        }
    },
    mounted() {
        this.$store.dispatch('getAuthStatus')
    },
}
</script>
<style lang="scss" scoped>
.placeholder {
    color: #8E8E8E;
}
</style>
