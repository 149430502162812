var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex flex-col border-t border-b border-gray-lighter text-gray-main text-base text-center",
        class: [
          _vm.block.data.attributes.half_size_image == "image-right"
            ? "sm:flex-row"
            : "sm:flex-row-reverse",
        ],
      },
      [
        _c("div", { staticClass: "w-full sm:w-1/2 flex items-center" }, [
          _c(
            "div",
            { staticClass: "ml-10 mr-12 my-12 sm:max-w-md lg:mx-auto" },
            [
              _vm.block.data.attributes.h3 != null
                ? _c(
                    "h3",
                    {
                      staticClass:
                        "text-black text-16 font-normal tracking-widest",
                    },
                    [_vm._v(_vm._s(_vm.block.data.attributes.h3))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.block.data.attributes.h2 != null
                ? _c(
                    "h2",
                    {
                      staticClass:
                        "mt-4 text-black text-xl font-normal tracking-widest lg:text-2xl",
                    },
                    [_vm._v(_vm._s(_vm.block.data.attributes.h2))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.block.data.attributes.text != null
                ? _c("div", {
                    staticClass:
                      "mt-4 text-16 text-black mb-4 font-normal lg:text-17",
                    domProps: {
                      innerHTML: _vm._s(_vm.block.data.attributes.text),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.block.data.attributes.link != null &&
              _vm.block.data.attributes.text_on_link != null
                ? _c("div", [
                    _c(
                      "a",
                      { attrs: { href: _vm.block.data.attributes.link } },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "py-3 px-4 text-xs border-2 border-black text-black tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.block.data.attributes.text_on_link) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "w-full sm:w-1/2 sm:flex sm:items-center",
            attrs: { href: _vm.block.data.attributes.link },
          },
          [
            _c("img", {
              staticClass: "w-full",
              attrs: {
                src: _vm.block.data.attributes.image.data.attributes.path,
                alt: "",
              },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }