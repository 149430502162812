<template>
    <div>
        <div class = "w-full h-220 sm:h-full relative">

            <div class="hidden md:block">
                <img class="w-full h-220 sm:h-full object-cover" :src=block.data.attributes.image.data.attributes.path alt="">
            </div>

            <div class="md:hidden">
                <img class="w-full h-220 sm:h-full object-cover" :src=block.data.attributes.image_phone.data.attributes.path alt="">
            </div>

            <div  class="absolute w-full inset-x-0 top-0 pt-15">
                <div v-if="feedbackStatus==='success-first-step'" class="w-full px-4 flex flex-col items-center">
                    <div :style="'color: ' + block.data.attributes.text_color + ';'">
                        <div class="text-2xl lg:text-4xl m-4 text-center font-normal tracking-widest uppercase">
                            {{block.data.attributes.h2}}
                        </div>

                        <div class="text-lg lg:text-2xl m-4 text-center font-normal widest">
                            {{block.data.attributes.h3}}
                        </div>
                    </div>
                    <textarea class="m-4 p-2 w-full md:w-1/2 h-64 appearance-none rounded-none border border-gray-lighter  focus:outline-none focus:border-brown-light "
                              v-model="feedbackText"
                              type="text"
                              name="feedback">

                </textarea>
                    <div v-if="block.data.attributes.button_text != null">
                        <div  class="mt-4 mx:auto flex items-center justify-center">
                            <button @click="leaveNpsFeedbackSecondStep(user.id)" :style="'border-color: ' + block.data.attributes.border_button_color + ';'
                                       +'color: ' + block.data.attributes.text_color + ';'"
                                    class="py-4 px-7 border-2 tracking-widest uppercase appearance-none rounded-none">
                                {{ block.data.attributes.button_text }}
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else-if="feedbackStatus==='success-second-step'" class="absolute w-full inset-x-0 top-0 flex flex-col items-center pt-15">
                    <div :style="'color: ' + block.data.attributes.text_color + ';'">
                        <div v-html="block.data.attributes.text" class="text-2xl mt-4 text-center font-normal widest">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <Loading />
                </div>

            </div>



        </div>
    </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import Loading from "../Loading";

export default {
    name: "NpsBlock",
    components: {
        Loading
    },

    props: {
        block: null,
    },
    computed: {
        ...mapGetters({
            feedback: 'feedback',
            feedbackStatus: 'feedbackStatus',
            feedbackTextVuex: 'feedbackText',
            user: 'user',
        }),
        feedbackText: {
            get(){
                return this.feedbackTextVuex;
            },
            set: _.debounce(function(text){
                this.setFeedbackText(text);
            }, 300),
        },

    },
    methods: {
        ...mapActions({
            setFeedbackText: 'setFeedbackText',
            leaveNpsFeedbackSecondStep: 'leaveNpsFeedbackSecondStep',
        }),
    }

}
</script>

<style scoped>

</style>
