var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "popup_wrapper",
      staticClass:
        "bg-bg-brown bg-opacity-25 h-full w-full fixed z-40 flex left-0 right-0 bottom-0 items-right justify-end",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "p-5 w-98 bg-white shadow z-50 overflow-y-auto no-scrollbar",
        },
        [
          _c(
            "div",
            { staticClass: "w-full flex justify-end items-center relative" },
            [
              _c(
                "div",
                {
                  staticClass: "cursor-pointer absolute",
                  on: { click: _vm.closePopup },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "17",
                        height: "17",
                        viewBox: "0 0 11 11",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1 10L10 1",
                          stroke: "rgba(147, 147, 147, 1)",
                          "stroke-linecap": "square",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M1 1.00024L10 10.0002",
                          stroke: "rgba(147, 147, 147, 1)",
                          "stroke-linecap": "square",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", {}, [_vm._t("default")], 2),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }