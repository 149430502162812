var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-12 sm:h-15 w-full sticky top-0 bg-white z-30 shadow flex items-center justify-between lg:px-12",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-12 sm:h-15 flex items-center ml-4 cursor-pointer lg:hidden",
          on: { click: _vm.toggleMenu },
        },
        [
          _c(
            "svg",
            {
              staticClass: "feather feather-menu",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                stroke: "#5c5c5c",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            },
            [
              _c("line", { attrs: { x1: "3", y1: "12", x2: "21", y2: "12" } }),
              _vm._v(" "),
              _c("line", { attrs: { x1: "3", y1: "6", x2: "21", y2: "6" } }),
              _vm._v(" "),
              _c("line", { attrs: { x1: "3", y1: "18", x2: "21", y2: "18" } }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.focus
        ? _c(
            "div",
            {
              staticClass:
                "absolute bg-gray-dark bg-opacity-25 text-gray-main w-full h-screen top-0 left-0 z-30 overflow-auto",
              on: { click: _vm.toggleMenu },
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-8 bg-white w-85 min-h-screen",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "focus:outline-none",
                      on: { click: _vm.toggleMenu },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/storage/images/icons/close.svg",
                          alt: "close menu",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.navigationStatus == "success"
                    ? _c("accordion", { staticClass: "z-30" }, [
                        _c(
                          "span",
                          { staticClass: "divide-y divide-border-gray-light" },
                          _vm._l(_vm.navigation.data, function (menu_item) {
                            return menu_item.data.attributes.mobile
                              ? _c(
                                  "accordion-item",
                                  {
                                    key: menu_item.data.attributes
                                      .navigation_category_id,
                                    staticClass: "mt-1",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "accordion-trigger",
                                          fn: function (slotProps) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-70 h-16 py-5 text-black text-base font-normal tracking-widest uppercase",
                                                },
                                                [
                                                  menu_item.data.attributes
                                                    .navigation_items.data
                                                    .length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex items-center justify-between",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                menu_item.data
                                                                  .attributes
                                                                  .title
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-4 h-4",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  class: [
                                                                    slotProps.visible
                                                                      ? "transform rotate-90"
                                                                      : "",
                                                                  ],
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    fill: "none",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    stroke:
                                                                      "currentColor",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      "stroke-linecap":
                                                                        "round",
                                                                      "stroke-linejoin":
                                                                        "round",
                                                                      "stroke-width":
                                                                        "1",
                                                                      d: "M9 5l7 7-7 7",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.toggleMenu,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "w-full h-full",
                                                              attrs: {
                                                                href: menu_item
                                                                  .data
                                                                  .attributes
                                                                  .path,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                        " +
                                                                  _vm._s(
                                                                    menu_item
                                                                      .data
                                                                      .attributes
                                                                      .title
                                                                  ) +
                                                                  "\n                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "accordion-content" },
                                      [
                                        _c(
                                          "accordion",
                                          {},
                                          _vm._l(
                                            menu_item.data.attributes
                                              .navigation_items.data,
                                            function (sub_item) {
                                              return _c(
                                                "accordion-item",
                                                {
                                                  key: sub_item.data.attributes
                                                    .navigation_item_id,
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "accordion-trigger",
                                                        fn: function (
                                                          subSlotProps
                                                        ) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "w-full flex py-3 text-gray-main text-sm font-normal tracking-widest uppercase",
                                                              },
                                                              [
                                                                sub_item.data
                                                                  .attributes
                                                                  .navigation_sub_items
                                                                  .data.length
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-full flex items-center justify-between",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                sub_item
                                                                                  .data
                                                                                  .attributes
                                                                                  .title
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "w-4 h-4",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "svg",
                                                                              {
                                                                                class:
                                                                                  [
                                                                                    subSlotProps.visible
                                                                                      ? "transform rotate-90"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    xmlns:
                                                                                      "http://www.w3.org/2000/svg",
                                                                                    fill: "none",
                                                                                    viewBox:
                                                                                      "0 0 24 24",
                                                                                    stroke:
                                                                                      "currentColor",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "path",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        "stroke-linecap":
                                                                                          "round",
                                                                                        "stroke-linejoin":
                                                                                          "round",
                                                                                        "stroke-width":
                                                                                          "1",
                                                                                        d: "M9 5l7 7-7 7",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        on: {
                                                                          click:
                                                                            _vm.toggleMenu,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "w-full h-full",
                                                                            attrs:
                                                                              {
                                                                                href: sub_item
                                                                                  .data
                                                                                  .attributes
                                                                                  .path,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    " +
                                                                                _vm._s(
                                                                                  sub_item
                                                                                    .data
                                                                                    .attributes
                                                                                    .title
                                                                                ) +
                                                                                "\n                                                "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "template",
                                                    {
                                                      slot: "accordion-content",
                                                    },
                                                    _vm._l(
                                                      sub_item.data.attributes
                                                        .navigation_sub_items
                                                        .data,
                                                      function (sub_sub_item) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: sub_sub_item
                                                              .data
                                                              .navigation_sub_item_id,
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                on: {
                                                                  click:
                                                                    _vm.toggleMenu,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: sub_sub_item
                                                                        .data
                                                                        .attributes
                                                                        .path,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "py-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "text-gray-light text-left text-sm font-normal tracking-widest uppercase",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  sub_sub_item
                                                                                    .data
                                                                                    .attributes
                                                                                    .title
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e()
                          }),
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inset-center lg:hidden" },
        [
          _c(
            "router-link",
            {
              staticClass: "outline-none focus:outline-none",
              attrs: { to: "/" },
            },
            [
              _c("img", {
                staticClass: "h-5",
                attrs: {
                  src: "/storage/images/logo.svg",
                  alt: "present simple logo",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hidden lg:block h-15 flex items-center" },
        [
          _c(
            "router-link",
            {
              staticClass:
                "outline-none focus:outline-none h-15 flex items-center",
              attrs: { to: "/" },
            },
            [
              _c("img", {
                staticClass: "h-6",
                attrs: {
                  src: "/storage/images/logo.svg",
                  alt: "present simple logo",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.navigationStatus == "success"
        ? _c(
            "div",
            {
              staticClass:
                "h-12 sm:h-15 mx-5 hidden lg:flex lg:flex-row uppercase text-base font-normal tracking-widest flex-1",
            },
            _vm._l(_vm.navigation.data, function (item) {
              return _c("Dropdown", {
                key: item.data.navigation_category_id,
                attrs: { item: item },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "h-12 sm:h-15 flex items-center" },
        [
          _c(
            "router-link",
            { staticClass: "hidden sm:block", attrs: { to: "/search" } },
            [
              _c("img", {
                attrs: {
                  src: "/storage/images/icons/search.svg",
                  alt: "search",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("button", { on: { click: _vm.goToLogin } }, [
            _c("img", {
              staticClass: "mx-5",
              attrs: { src: "/storage/images/icons/user.svg", alt: "account" },
            }),
          ]),
          _vm._v(" "),
          _vm.isPopupActive
            ? _c("Login", {
                attrs: { isPopupActive: _vm.isPopupActive },
                on: { closePopup: _vm.closeLoginPopup },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "mr-5 lg:mr-0",
              attrs: {
                to: _vm.goToPreorderCartWhenClikedIcon
                  ? { name: "PreorderCart" }
                  : { name: "Cart2" },
              },
            },
            [
              _c("div", { staticClass: "relative" }, [
                _c("img", {
                  attrs: {
                    src: "/storage/images/icons/shopping-bag.svg",
                    alt: "shopping bag",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute w-4 h-4 flex items-center justify-center bottom-0 right-0 -mb-1 -mr-1 rounded-full text-xs text-bg-white bg-black",
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.quantityOfProductsInAllCarts) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }