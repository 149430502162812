var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    _vm.isChangeUserDataActive === false &&
    _vm.authInfo.authStatus &&
    _vm.authInfo.identificationStatus &&
    _vm.authInfo?.relations.authUser?.data.attributes.isPhoneVerified
      ? _c("div", [
          _c(
            "div",
            { staticClass: "w-full pb-5 border-b border-gray-lighter" },
            [
              _c("div", { staticClass: "flex items-center justify-between" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "uppercase text-13 text-black text-left flex-1 lg:text-16",
                  },
                  [_vm._v("\n                    Получатель\n                ")]
                ),
                _vm._v(" "),
                this.authenticated === false &&
                this.cart?.data.attributes.hasUserData
                  ? _c(
                      "div",
                      { on: { click: _vm.changeUserData } },
                      [_c("ChangeSvgButton")],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.authInfo.authStatus && _vm.authInfo.identificationStatus
                  ? _c(
                      "div",
                      { staticClass: "text-11 text-gray-main lg:text-13" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.authInfo.relations.authUser?.data.attributes
                                .name
                            ) +
                              "\n                        " +
                              _vm._s(
                                _vm.authInfo.relations.authUser?.data.attributes
                                  .surname
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getUserEmailInfo) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.authInfo.relations.authUser?.data.attributes
                                .phone
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ])
      : _c(
          "div",
          [
            _c("CartUserBlock", {
              attrs: {
                cart: _vm.cart,
                userWantsToUseNewPhoneNumberToAuth:
                  _vm.userWantsToUseNewPhoneNumberToAuth,
              },
              on: { sendUserInfo: _vm.onUser },
            }),
          ],
          1
        ),
    _vm._v(" "),
    this.isChangeDeliveryMethodActive === false
      ? _c("div", [
          _c(
            "div",
            { staticClass: "w-full py-5 border-b border-gray-lighter" },
            [
              _c(
                "div",
                {
                  staticClass: "flex items-center justify-between",
                  on: { click: _vm.changeDeliveryMethod },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "uppercase text-13 text-black text-left flex-1 lg:text-16",
                    },
                    [_vm._v("\n                    Доставка\n                ")]
                  ),
                  _vm._v(" "),
                  _c("ChangeSvgButton"),
                ],
                1
              ),
              _vm._v(" "),
              this.cart?.data.attributes.hasDeliveryMethod
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "text-11 text-gray-main lg:text-13" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.cart.data.relationships.cartDeliveryMethod
                                  .data.relationships.deliveryType.data
                                  .attributes.title
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.cart.data.relationships.cartDeliveryMethod
                                .data.attributes.country
                            ) +
                              "\n                        " +
                              _vm._s(
                                _vm.cart.data.relationships.cartDeliveryMethod
                                  .data.attributes.city
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.cart.data.relationships.cartDeliveryMethod.data
                          .attributes.street !== null
                          ? _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.cart.data.relationships
                                      .cartDeliveryMethod.data.attributes.street
                                  ) +
                                  "\n                        " +
                                  _vm._s(
                                    _vm.cart.data.relationships
                                      .cartDeliveryMethod.data.attributes.house
                                  ) +
                                  "\n                        " +
                                  _vm._s(
                                    _vm.cart.data.relationships
                                      .cartDeliveryMethod.data.attributes
                                      .building
                                  ) +
                                  ",\n                        " +
                                  _vm._s(
                                    _vm.cart.data.relationships
                                      .cartDeliveryMethod.data.attributes
                                      .apartment
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cart.data.relationships.cartDeliveryMethod.data
                          .attributes.comment !== null
                          ? _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.cart.data.relationships
                                      .cartDeliveryMethod.data.attributes
                                      .comment
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _c("div", { staticClass: "text-gray-main text-13" }, [
                    _vm._v(
                      "\n                Выберите способ доставки\n            "
                    ),
                  ]),
            ]
          ),
        ])
      : _c(
          "div",
          [
            _c("DeliveryCartBlock", {
              attrs: { cart: _vm.cart },
              on: {
                sendDeliveryInfo: _vm.onDelivery,
                getDeliveryTypes: _vm.onDeliveryTypes,
              },
            }),
          ],
          1
        ),
    _vm._v(" "),
    this.isChangePaymentMethodActive === false
      ? _c("div", [
          _c("div", { staticClass: "w-full py-5" }, [
            _c(
              "div",
              {
                staticClass: "flex items-center",
                on: { click: _vm.changePaymentMethod },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "uppercase text-13 text-black text-left flex-1 lg:text-16",
                  },
                  [_vm._v("\n                    Оплата\n                ")]
                ),
                _vm._v(" "),
                _c("ChangeSvgButton"),
              ],
              1
            ),
            _vm._v(" "),
            _vm.cart?.data.relationships.cartPaymentMethod !== null
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "text-11 text-gray-main lg:text-13" },
                    [
                      _c("div", { staticClass: "mt-2" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              this.cart.data.relationships.cartPaymentMethod
                                .data.relationships.paymentType.data.attributes
                                .title
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isPaymentByDolyame
                    ? _c(
                        "div",
                        { staticClass: "text-11 text-gray-main lg:text-13" },
                        [
                          _vm._v(
                            "\n                    4 платежа по " +
                              _vm._s(
                                _vm._f("formatNumber")(
                                  _vm.firstPartToPayDolyame
                                )
                              ) +
                              " ₽\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "text-gray-main text-13" }, [
                  _vm._v(
                    "\n                Выберите способ оплаты\n            "
                  ),
                ]),
          ]),
        ])
      : _c(
          "div",
          [
            _c("CartPaymentBlock", {
              attrs: { cart: _vm.cart },
              on: {
                sendPaymentInfo: _vm.onPayment,
                getPaymentTypes: _vm.onPaymentTypes,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }