<template>
  <div>
    <div class="items-center mt-5 lg:mb-10 ">
      <div class="flex justify-between">
        <div class="uppercase text-13 text-black text-left flex-1 lg:text-16">
          Оплата
        </div>
        <div v-if="this.cart && this.cart?.data.attributes.hasPaymentMethod" class="flex items-center">
          <button @click="closeForm" class="lg:hidden">
            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 1L1 4" stroke="black" stroke-linecap="square"/>
              <path d="M7 3.99976L4 0.999756" stroke="black" stroke-linecap="square"/>
            </svg>
          </button>
          <button @click="closeForm" class="hidden lg:block">
            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 1.00049L1 5.50049" stroke="black" stroke-linecap="square"/>
              <path d="M10 5.50024L5.5 1.00024" stroke="black" stroke-linecap="square"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="w-full mt-2">
        <div class="mb-5 uppercase text-11 text-black lg:text-13">
          Выберите способ оплаты
        </div>
        <div v-for="(paymentTypeItem) in paymentTypesPreorder?.data"
             :key="paymentTypeItem.id">
          <div @click="checkPaymentType(paymentTypeItem.id)" class="w-full mt-2">
            <div class="flex items-center">
              <div class="flex items-center">
                <input type="radio" class="appearance-none h-2 w-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none"
                       :class="[submitted && $v.selectedPaymentType.$invalid ? 'border-red-400' : 'border-black'] "
                       name="paymentType"
                       :value=paymentTypeItem
                       v-model="selectedPaymentType">
              </div>
              <div class="ml-3 text-11 text-black lg:text-13">
                <div v-if="paymentTypeItem.id === 8">
                  <span>{{paymentTypeItem.attributes.title}} - 4 платежа по {{firstPartToPayDolyame | formatNumber}} ₽</span>
                </div>
                <span v-else>{{paymentTypeItem.attributes.title}}</span>
              </div>
            </div>
            <div v-if="paymentTypeItem.id === 8">
              <DolyameCart :cart="cart"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DolyameCart from "../Cart 2.0/DolyameCart.vue";

export default {
  name: "PreorderCartPaymentBlock",
  components: {DolyameCart},
  props:{
    cart:null,
    isChangePaymentMethodActive : null,
  },
  data: () => ({
    submitted: false,
    selectedPaymentTyped: null,
    setSelectedPaymentType: null,
  }),
  methods:{
    ...mapActions({
      getPaymentTypesPreorder:'getPaymentTypesPreorder',
      setPaymentTypePreorder:'setPaymentTypePreorder',
        }
    ),
    async checkPaymentType(index) {
      await this.setPaymentTypePreorder(index)
          .then(res => {
            this.$emit('sendPreorderPaymentInfo',{
              isChangePaymentMethodActive : false
            })
            this.sendSelectedPaymentTypeToGTM()
          })
    },
    closeForm(){
      this.$emit('sendPreorderPaymentInfo',{
        isChangePaymentMethodActive : false
      })
    },
    sendSelectedPaymentTypeToGTM(){
      this.$gtm.trackEvent({
        event: 'flushVariables',
        ecommerce: null,
      });
      let products = []
      for(let item of this.cart?.data.relationships.orderLines?.data){
        products.push({
          name: item.relationships.product?.data.attributes.title,
          id: item.relationships.product?.data.attributes.article_number,
          price: item.attributes.price,
          category: item.relationships.product?.data.attributes.category?.data.attributes.title,
          quantity: item.attributes.userQuantity,
          variant: item.relationships.product?.data.attributes.article_number + "" + item.attributes.sizeId,
        })
      }
      this.$gtm.trackEvent({
        event: "add_payment_info", // Event type [default = 'interaction'] (Optional)
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: this.selectedPaymentType?.data.attributes.title
            },
            products: products
          }

        }
      });
    },

  },
  computed: {
    ...mapGetters({
      paymentTypesPreorder: 'paymentTypesPreorder',
      firstPartToPayDolyame: 'firstPartToPayDolyame',
    }),
    selectedPaymentType: {
      get(){
        return this.selectedPaymentTyped;
      },
      set(type){
        this.setSelectedPaymentType = type;
      }
    },
  },
  mounted() {
      if (this.cart?.data.attributes.priceToPay > 0 && this.cart?.data.attributes.hasDeliveryMethod === true) {
          this.$emit('getPaymentTypesPreorder')
      }
  }

}
</script>

<style scoped>
.lineActive {
  width: 69px;
  border-top: 2px solid #C08950;
}
.lineNoActive {
  width: 69px;
  border-top: 2px solid #B6B6B6;
}
.underlineWithPadding{
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}
</style>
