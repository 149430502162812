<template>
  <div class="w-full h-full">
    <div class=" text-gray-main text-base font-normal">
      <div v-if="lookbookStatus=='success'">
        <div v-html="lookbook.data.attributes.html"></div>
        <!-- Внутри LookBook не нужно выводить превью-изображение из коллекций -->
        <div class=""
             v-for="image in lookbook.data.attributes.look_book_images.data"
             :key = image.data.look_book_image_id>

          <router-link :to="image.data.attributes.link"><img :src="image.data.attributes.image.data.attributes.path" class="w-full object-center object-cover" alt=""></router-link>


          <router-link v-if="image.data.attributes.text != null && image.data.attributes.text_link != null" :to="image.data.attributes.text_link">
            <h2 class=" text-gray-main text-xl font-normal tracking-widest text-center ">{{image.data.attributes.text}}</h2>
          </router-link>
        </div>

      </div>
      <Loading v-else></Loading>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loading from "../components/Loading";

export default {
  name: "LookbookWomenStoriesBlock",

  components: {
    Loading
  },

  mounted () {
    this.$store.dispatch('fetchLookbook', this.$route.params.lookbook);
  },
  computed: {
    ...mapGetters({
      lookbook: 'lookbook',
      lookbookStatus: 'lookbookStatus',

    }),
  },
  watch: {
    $route () {
      this.$store.dispatch('fetchLookbook', this.$route.params.lookbook)
    }
  }
}
</script>

<style scoped>

</style>
