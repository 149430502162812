<template>
  <div v-if="lookbooksStatus=='success'" class="py-10 w-full lg:w-256 lg:mx-auto">
    <div class="mx-6">
      <!--            <h2 class="w-full text-gray-main text-2xl font-normal tracking-widest text-center uppercase">Коллекции</h2>-->
      <div class="mt-10 text-gray-main text-base font-normal tracking-wide" v-for="lookbook in lookbooks.data" :key="lookbook.data.lookbook_id">
        <div v-if="lookbook.data.attributes.lookBookType === 'women-stories'">
          <router-link :to="{ name: 'LookbookWomenStoriesBlock', params: { lookbook: lookbook.links.meta.slug }}">
            <h2 class="text-gray-main text-xl font-normal tracking-widest text-center uppercase">
              {{lookbook.data.attributes.title}}
            </h2>
          </router-link>
          <router-link :to="{ name: 'LookbookWomenStoriesBlock', params: { lookbook: lookbook.links.meta.slug }}">
            <div class="mt-10">
              <img :src="lookbook.data.attributes.preview_image.data.attributes.path" :alt="lookbook.data.attributes.Ntitle">
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <Loading v-else />
</template>

<script>
import {mapGetters} from "vuex";
import Loading from "../components/Loading";

export default {
  name: "LookbookWomenStories",

  components: {
    Loading
  },

  mounted () {
    this.$store.dispatch('fetchLookbooks');
  },
  computed: {
    ...mapGetters({
      lookbooks: 'lookbooks',
      lookbooksStatus: 'lookbooksStatus',

    }),
  },

}
</script>

<style scoped>

</style>
