var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "xl:w-475 xl:mx-auto" },
    [
      _vm.categoriesStatus === "success"
        ? _c(
            "div",
            _vm._l(_vm.categories.data, function (category) {
              return _c(
                "div",
                { key: "category_" + category.data.category_id },
                [
                  category.data.attributes.products.data.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Category",
                                  params: {
                                    category: category.links.meta.slug,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "my-10 w-full text-gray-main text-2xl font-normal tracking-widest text-center uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(category.data.attributes.title) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "px-0.5" },
                            [
                              _c("NonSwipableCategoryBlock", {
                                attrs: {
                                  category:
                                    category.data.attributes.products.data,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _c("Loading"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }