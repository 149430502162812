<template>
    <div class="mb-4">
        <div class="-ml-5 flex overflow-x-scroll no-scrollbar lg:justify-between">
            <div v-for="detail in details" :key="detail.id" @click="selectDetail(detail)"
                 class="text-14 whitespace-nowrap ml-5 cursor-pointer"
                 :class="selectedDetail && selectedDetail.id === detail.id ? 'text-black' : 'text-gray-main-2'">
                {{ detail.name }}
            </div>
        </div>
        <div v-if="selectedDetail" class="mt-4 text-14">
            <div v-if="selectedDetail.moreInfo" class="mb-4">
                <div v-if="selectedDetail.moreInfo.compositions">
                    <div v-html="selectedDetail.moreInfo.compositions"></div>
                </div>
                <div v-if="selectedDetail.moreInfo.lining && selectedDetail.moreInfo.lining.length > 3">
                    <div v-html="'Подклад: '+ selectedDetail.moreInfo.lining"></div>
                </div>
                <div v-if="selectedDetail.moreInfo.insulation && selectedDetail.moreInfo.insulation.length > 3">
                    <div v-html="'Утеплитель: '+ selectedDetail.moreInfo.insulation"></div>
                </div>
                <div v-if="selectedDetail.moreInfo.trim && selectedDetail.moreInfo.trim.length > 3">
                    <div v-html="'Отделка: '+ selectedDetail.moreInfo.trim"></div>
                </div>
            </div>

            <div v-if="this.showShortText && selectedDetail.id === 1">
                <div>
                    <span class="text-eclipse" v-html="truncatedHtml"></span>
                    <span class="text-gray-main-2" @click="showFullText()">
                  ...ещё
                    </span>
                </div>
            </div>
            <div v-else-if="selectedDetail.id === 3 && this.product.data.attributes.details.data.attributes.measurements_table"
                 class="w-full">
                <MeasurementsTable
                    :measurements-table="this.product.data.attributes.details.data.attributes.measurements_table"/>
            </div>
            <div v-else>
                <div v-html="selectedDetail.value"></div>
                <div v-if="selectedDetail.id === 2 && selectedDetail.moreInfo" v-html="selectedDetail.moreInfo"></div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import MeasurementsTable from "./MeasurementsTable.vue";

export default {
    name: "ProductDetailsBlock",
    components: { MeasurementsTable },
    props: {
        product: null
    },
    data: () => ({
        selectedDetail: null,
        selectedDetailId: null,
        showShortText: true,
    }),
    computed: {
        details() {
            return [
                {
                    id: 1,
                    name: 'Описание',
                    value: this.product.data.attributes.details.data.attributes.description,
                    moreInfo: null,
                },
                {
                    id: 2,
                    name: 'Детали',
                    value: this.product.data.attributes.details.data.attributes.detail,
                    moreInfo: this.product.data.attributes.details.data.attributes.model_details_on_photo,
                },
                {
                    id: 3,
                    name: 'Обмеры',
                    value: this.product.data.attributes.details.data.attributes.measurements_table
                        ? this.product.data.attributes.details.data.attributes.measurements_table
                        : this.product.data.attributes.details.data.attributes.measurements,
                    moreInfo: null,
                },
                {
                    id: 4,
                    name: 'Состав и уход',
                    value: this.product.data.attributes.details.data.attributes.care,
                    moreInfo: {
                        compositions: this.product.data.attributes.details.data.attributes.composition,

                        lining: this.product.data.attributes.details.data.attributes.lining
                            ? this.product.data.attributes.details.data.attributes.lining : null,

                        insulation: this.product.data.attributes.details.data.attributes.insulation
                            ? this.product.data.attributes.details.data.attributes.insulation : null,

                        trim: this.product.data.attributes.details.data.attributes.trim
                            ? this.product.data.attributes.details.data.attributes.trim : null,
                    }
                },
                {
                    id: 5,
                    name: 'Доставка',
                    value: this.deliveryInfoInProductDetail && this.deliveryInfoInProductDetail.data.attributes.html
                        ? this.deliveryInfoInProductDetail.data.attributes.html : '',
                    moreInfo: null,
                },
            ]
        },
        ...mapGetters({
            deliveryInfoInProductDetail: 'deliveryInfo',
        }),
        truncatedHtml() {
            const htmlContent = this.selectedDetail.value; // исходный HTML
            return this.truncateHtml(htmlContent, 23); // 23 слова
        }
    },
    methods: {
        selectDetail(detail) {
            this.selectedDetail = detail;
            this.selectedDetailId = detail.id
        },
        showFullText() {
            this.showShortText = false
        },
        truncateHtml(html, limit) {
            const div = document.createElement('div');
            div.innerHTML = html;

            let text = div.textContent || div.innerText || "";
            let words = text.trim().split(' ');

            if (words.length > limit) {
                text = words.slice(0, limit).join(' ') + '...';
            }

            return text;
        }
    },
    mounted() {
        this.$store.dispatch('fetchDeliveryInfoForProductCard', 'delivery-in-product-card')
        this.selectDetail(this.details[0])
    }

}
</script>

<style scoped>

.text-eclipse {
    position: relative;
}

.text-eclipse::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 25%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
</style>
