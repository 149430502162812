var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      {
        staticClass:
          "p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main text-base font-normal",
      },
      [
        _c("h1", [_vm._v("Информация")]),
        _vm._v(" "),
        _vm.infosStatus == "success"
          ? _c(
              "div",
              _vm._l(_vm.infos.data, function (info) {
                return _c(
                  "div",
                  { key: info.data.info_id, staticClass: "w-full text-center" },
                  [
                    info.data.attributes.active_on_info_page == true
                      ? _c(
                          "h2",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "InfoBlock",
                                    params: { info: info.links.meta.slug },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(info.data.attributes.title) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _c("Loading"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }