<template>
    <div>
        <div class="flex flex-col border-t border-b border-gray-lighter text-gray-main text-base text-center sm:text-center"
             :class="[block.data.attributes.half_size_image =='image-right' ? 'sm:flex-row' : 'sm:flex-row-reverse']"
             :style="'background-color: ' + block.data.attributes.background_color + ';'">
            <div class="w-full sm:w-1/2 flex items-center mx:auto items-center justify-center">

                <div class="pl-10 pr-12 mx:auto sm:max-w-md my-12 lg:my-0">

                    <h2 v-if="block.data.attributes.h2" class=" text-xl font-normal tracking-widest lg:text-2xl"
                        :style="'color: ' + block.data.attributes.text_color + ';'">{{block.data.attributes.h2}}</h2>

                    <div v-if="block.data.attributes.text != null" class="mt-7 text-16 mb-4 font-normal lg:text-17"
                         :style="'color: ' + block.data.attributes.text_color + ';'">
                        {{block.data.attributes.text}}
                    </div>

                    <a :href="block.data.attributes.link" class="w-full sm:hidden lg:block lg:w-full mx-auto items-center justify-center">
                        <img class="mt-12" :src=block.data.attributes.sub_image.data.attributes.path  alt="">
                    </a>

                    <div  class="mt-7 mx:auto flex items-center justify-center lg:mt-12">
                       <div v-if="block.data.attributes.button_text != null">
                           <a :href="block.data.attributes.link" class="uppercase underline font-normal">
                               <button  class="py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm"
                                        :style="'border-color: ' + block.data.attributes.border_button_color + ';' + 'color: ' + block.data.attributes.text_color + ';'">{{ block.data.attributes.button_text }}
                               </button>
                           </a>
                       </div>
                    </div>
                </div>
            </div>
            <a :href="block.data.attributes.link" class="w-full sm:w-1/2 sm:flex sm:items-center object-cover">
                <img class="w-full" :src=block.data.attributes.image.data.attributes.path  alt="">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "HalfSizeImageAndSubImageBlock",
    props: {
        block: null,
    },
}
</script>

<style scoped>

</style>
