var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style:
        "background-color: " + _vm.block.data.attributes.background_color + ";",
    },
    [
      _c("div", { staticClass: "flex-none relative" }, [
        _c(
          "div",
          {
            ref: "categorySwiper",
            staticClass: "swiper-container mx-auto w-full",
          },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(
                _vm.block.data.attributes.swipable_look_book_category_sub_blocks
                  .data,
                function (item) {
                  return _c(
                    "div",
                    {
                      key: item.data.swipable_look_book_category_sub_block_id,
                      staticClass:
                        "swiper-slide px-0.5 items-center justify-center",
                    },
                    [
                      _c("div", [
                        _c(
                          "a",
                          { attrs: { href: item.data.attributes.link } },
                          [
                            _c("img", {
                              staticClass: "relative",
                              attrs: {
                                src: item.data.attributes.image.data.attributes
                                  .path,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        item.data.attributes.h2 != null
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "px-4 md:px-7 absolute left-0 ml-1 mt-2 top-0",
                              },
                              [
                                _c("div", [
                                  item.data.attributes.text_size === "small"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md:text-lg mt-4 uppercase font-bold pr-7 font-normal tracking-widest'",
                                          style:
                                            "color: " +
                                            item.data.attributes.text_color +
                                            ";",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.data.attributes.h2) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          class: [
                                            item.data.attributes.text_size ===
                                            "the largest"
                                              ? "md:text-3xl mt-4 text-center uppercase font-bold pr-7 tracking-widest"
                                              : "md:text-xl mt-4 text-center uppercase font-bold pr-7 widest",
                                          ],
                                          style:
                                            "color: " +
                                            item.data.attributes.text_color +
                                            ";",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.data.attributes.h2) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.data.attributes.button_text != null
                          ? _c("div", [
                              _c(
                                "a",
                                { attrs: { href: item.data.attributes.link } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-4 md:px-7 absolute bottom-0 mb-2 divide-y divide-white",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "first:pt-0 last:pb-0 md:text-xl font-bold",
                                          style:
                                            "color: " +
                                            item.data.attributes
                                              .button_text_color +
                                            ";",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                item.data.attributes.button_text
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {}),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hidden md:block" }, [
      _c("div", { staticClass: "swiper-button-prev absolute w-1/2" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hidden md:block" }, [
      _c("div", { staticClass: "swiper-button-next absolute w-1/2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }