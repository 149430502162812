var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-10 w-full h-full lg:w-256 lg:mx-auto justify-center" },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "mx-auto flex justify-center uppercase text-16 font-light lg:text-24",
          },
          [
            _c(
              "div",
              {
                class: _vm.deliveryInfo
                  ? "text-black underlineWithPadding cursor-pointer"
                  : "text-gray-main cursor-pointer",
                on: { click: _vm.showDeliveryInfo },
              },
              [_vm._v("\n                Доставка и оплата\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-10 lg:ml-15",
                class: _vm.returnInfo
                  ? "text-black underlineWithPadding cursor-pointer"
                  : "text-gray-main cursor-pointer",
                on: { click: _vm.showReturnInfo },
              },
              [_vm._v("\n                Возврат\n            ")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.deliveryInfo
          ? _c(
              "div",
              [
                _c(
                  "accordion",
                  { staticClass: "w-full mt-10 px-5 mx-auto lg:w-163" },
                  [
                    _c(
                      "accordion-item",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v("Экспресс-доставка"),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2133242470
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside ml-4 mt-2 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " При оформлении заказа с 10:00 до 17:00 доставка произойдет в течение 4\n                                    часов c момента оплаты\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "При оформлении заказа после 17:00 — доставка на следующий рабочий день"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " При оформлении заказа с 00:00 до 10:00 — доставка до 14:00"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" Банковской картой на сайте"),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                790 рублей\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [_vm._v("Только в рабочие дни")]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" По Москве внутри МКАД")]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Для экспресс-доставки недоступны примерка и частичный выкуп"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Для подтверждения заказа и временных интервалов вам позвонит сотрудник\n                                    клиентского сервиса\n                                "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Курьерская доставка по Москве курьером Present & Simple или СДЭК"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3086306548
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                1-3 дня\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" На сайте (картой, Samsung Pay)"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Наложенным платежом при получении (с примеркой,не распространяется на заказы\n                                    товаров по\n                                    предоплате*)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-2 text-14 text-gray-light" },
                              [
                                _vm._v(
                                  "\n                                * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,\n                                а также избранные модели из основной коллекции можно приобрести только по\n                                предоплате.\n                                Подробности уточняйте по телефону: "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "underlineWithPadding",
                                    attrs: { href: "tel:+78003010424" },
                                  },
                                  [
                                    _vm._v(
                                      " +7 (800)\n                                301-04-24 "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Бесплатно для предоплаченных заказов от 30 000 рублей"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" 550 рублей по Москве")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Примерка\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " У вас есть 15 минут на примерку и выбор"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Если вещь не подошла, верните ее курьеру"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Не более 5 изделий в заказах"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Стоимость доставки оплачивается вне зависимости от покупки"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Подготовьте паспорт для получения предоплаченного заказа"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Отправка курьером СДЭК только в будние дни"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Курьерская доставка по Санкт-Петербургу (СДЭК)"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1117682583
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                2-5 дней\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" На сайте (картой, Samsung Pay)"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Наложенным платежом при получении (с примеркой, не распространяется на заказы\n                                    товаров по\n                                    предоплате*)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-2 text-14 text-gray-light" },
                              [
                                _vm._v(
                                  "\n                                * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,\n                                а также избранные модели из основной коллекции можно приобрести только по\n                                предоплате.\n                                Подробности уточняйте по телефону: "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "underlineWithPadding",
                                    attrs: { href: "tel:+78003010424" },
                                  },
                                  [
                                    _vm._v(
                                      " +7 (800)\n                                301-04-24 "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Бесплатно для предоплаченных заказов от 30 000 рублей"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" 550 рублей по Санкт-Петербургу"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Примерка\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " У вас есть 15 минут на примерку и выбор"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Если вещь не подошла, верните ее курьеру"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Не более 5 изделий в заказах"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Стоимость доставки оплачивается вне зависимости от покупки"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Доставка осуществляется курьерской службой СДЭК"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Подготовьте паспорт для получения предоплаченного заказа"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Отправка только в будние дни"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Курьерская доставка по России (СДЭК)"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          561139055
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                2-8 дней\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" На сайте (картой, Samsung Pay)"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Наложенным платежом при получении (с примеркой, не распространяется на заказы\n                                    товаров по\n                                    предоплате*)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-2 text-14 text-gray-light" },
                              [
                                _vm._v(
                                  "\n                                * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,\n                                а также избранные модели из основной коллекции можно приобрести только по\n                                предоплате.\n                                Подробности уточняйте по телефону: "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "underlineWithPadding",
                                    attrs: { href: "tel:+78003010424" },
                                  },
                                  [
                                    _vm._v(
                                      " +7 (800)\n                                301-04-24 "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Бесплатно для предоплаченных заказов от 30 000 рублей"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" 690 рублей по России")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Примерка\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " У вас есть 15 минут на примерку и выбор"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Если вещь не подошла, верните ее курьеру"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Не более 5 изделий в заказах"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Стоимость доставки оплачивается вне зависимости от покупки"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Доставка осуществляется курьерской службой СДЭК"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Подготовьте паспорт для получения предоплаченного заказа"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Отправка только в будние дни"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Ускоренная курьерская доставка по России (СДЭК-ЭКСПРЕСС)"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          432381458
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                1-7 дней по России в зависимости от выбранного региона\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v("На сайте (картой, Samsung Pay)"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "Наложенным платежом при получении (с примеркой, не распространяется на заказы\n                                    товаров по\n                                    предоплате*)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v("Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-2 text-14 text-gray-light" },
                              [
                                _vm._v(
                                  "\n                                * Изделия из разделов Предзаказ и Аксессуары, товары из коллабораций,\n                                а также избранные модели из основной коллекции можно приобрести только по\n                                предоплате.\n                                Подробности уточняйте по телефону: "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "underlineWithPadding",
                                    attrs: { href: "tel:+78003010424" },
                                  },
                                  [
                                    _vm._v(
                                      " +7 (800)\n                                301-04-24 "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                790 рублей по России\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Примерка\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    "У вас есть 15 минут на примерку и выбор"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "Если вещь не подошла, верните ее курьеру"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v("Доступна для заказов по России"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v("Не более 5 изделий в заказах"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "Стоимость доставки оплачивается вне зависимости от покупки"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    "Доставка осуществляется курьерской службой СДЭК"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "Подготовьте паспорт для получения предоплаченного заказа"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Отправка только в будние дни"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v("Самовывоз Boxberry"),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1554673900
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                2-10 дней по России в зависимости от выбранного региона\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" Банковской картой на сайте"),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                190 рублей\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Срок хранения заказа — 7 дней с возможностью продления на 7 дней (доступно один\n                                    раз для заказа)\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Если вы откажетесь от заказа, стоимость доставки не возвращается"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(" Отправка только в будние дни"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Самовывоз из магазина в Москве"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1640172937
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                1-3 дня\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" Банковской картой на сайте"),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                Бесплатно\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Срок хранения предоплаченного заказа — 7 дней"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Продлить срок хранения можно один раз по истечении 7 дней"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Самовывоз доступен только из магазина по адресу Благовещенский переулок, 1А\n                                "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Самовывоз из магазина в Санкт-Петербурге"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1586769351
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                2-4 дня\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" Банковской картой на сайте"),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                Бесплатно\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Срок хранения предоплаченного заказа — 7 дней"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Продлить срок хранения можно один раз по истечении 7 дней"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Самовывоз из магазина в Екатеринбурге"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1867767342
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                5-7 дней\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(" Банковской картой на сайте"),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v(" Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                Бесплатно\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    " Срок хранения предоплаченного заказа — 7 дней"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    " Продлить срок хранения можно один раз по истечении 7 дней"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "accordion-item",
                      {
                        staticClass: "mt-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v("Международная доставка"),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2079919130
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "accordion-content" }, [
                          _c("div", { staticClass: "my-7 pr-11 text-16" }, [
                            _c(
                              "div",
                              { staticClass: "uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Сроки\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                5-12 дней\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Оплата\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v("На сайте (картой, Samsung Pay)"),
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v("Долями")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Стоимость\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2 text-gray-light" }, [
                              _vm._v(
                                "\n                                990 рублей\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-7 uppercase text-gray-main" },
                              [
                                _vm._v(
                                  "\n                                Детали\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-outside mt-2 ml-4 text-gray-light",
                              },
                              [
                                _c("li", [
                                  _vm._v(
                                    "Доставка осуществляется курьерской службой СДЭК"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "Подготовьте паспорт для получения предоплаченного заказа"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v("Отправка только в будние дни"),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "Present & Simple осуществляет доставку в Казахстан, Киргизию, Армению и Беларусь"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", [
              _c(
                "div",
                [
                  _c(
                    "accordion",
                    { staticClass: "w-full mt-10 px-5 mx-auto lg:w-163" },
                    [
                      _c(
                        "accordion-item",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v("Основные правила возврата"),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("template", { slot: "accordion-content" }, [
                            _c(
                              "div",
                              { staticClass: "mt-4 mb-5 pr-10 text-16" },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "list-outside mt-2 text-gray-light ml-3 lg:ml-4",
                                  },
                                  [
                                    _c("li", [
                                      _vm._v(
                                        "Для наших клиентов доступен бесплатный курьерский возврат по России с\n                                        возмещением полной стоимости товара, исключая цену доставки.\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                        Оформить возврат возможно в течение 14 дней с момента получения заказа.*\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                        Если вы хотите обменять товар, то сначала нужно оформить возврат,\n                                        а затем оформить новый заказ.\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                        Товар необходимо вернуть в его первоначальном виде: с бирками,\n                                        оригинальной упаковкой, неношеным, нестираным, неповрежденным и\n                                        неиспользованным**\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                        Деньги будут возвращены на счет в течение 3–10 календарных дней после\n                                        получения и одобрения возврата. Убедитесь, пожалуйста, что вы верно\n                                        заполнили\n                                        реквизиты в заявлении на возврат. Скорость поступления денежных средств на\n                                        ваш\n                                        счет зависит от скорости обработки операций вашим банком и может достигать\n                                        30 дней.\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                        Если при покупке или в процессе эксплуатации товара вы обнаружили брак,\n                                        такой товар можно вернуть в пределах гарантийного срока — 30 дней***\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "mt-1" }, [
                                      _vm._v(
                                        "\n                                        Возврат заказов с самовывозом возможно оформить только в том магазине,\n                                        откуда забирали заказ.\n                                    "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mt-5 text-13 text-gray-light",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    * При возврате заказа с доставкой курьером рекомендуем связываться с нами в\n                                    течение 7 дней с момента получения заказа\n                                    "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "** В соответствии с пунктом 1 статьи 26 Закона РФ «О защите прав\n                                    потребителей» от 07.02.1992 № 2300-1\n                                    "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "*** В соответствии с пунктом 1 статьи 19 Закона РФ «О защите прав\n                                    потребителей» от 07.02.1992 № 2300-1\n                                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "accordion-item",
                        {
                          staticClass: "mt-4",
                          scopedSlots: _vm._u([
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Возврат онлайн-заказов по России с доставкой курьером или в пункт выдачи\n                                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("template", { slot: "accordion-content" }, [
                            _c(
                              "div",
                              { staticClass: "mt-4 mb-5 pr-11 text-16" },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "list-outside mt-2 text-gray-light",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Заказы, оформленные в интернет-магазине с доставкой курьером, можно вернуть\n                                    только в интернет-магазин с помощью клиентского сервиса.\n                                    "
                                    ),
                                    _c(
                                      "li",
                                      { staticClass: "mt-4 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Свяжитесь с нами по почте "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "underlineWithPadding",
                                            attrs: {
                                              href: "mailto:hello@presentandsimple.com",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        hello@presentandsimple.com "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " или по телефону\n                                        "
                                        ),
                                        _c("br"),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "underlineWithPadding",
                                            attrs: { href: "tel:+78003010424" },
                                          },
                                          [
                                            _vm._v(
                                              " +7 (800)\n                                        301-04-24 "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " и сообщите о\n                                        желании сделать возврат.\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "mt-1 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Сотрудник клиентского сервиса согласует с вами удобную дату и адрес для\n                                        забора возврата.\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "mt-1 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Распечатайте транспортную накладную, которую мы отправим отдельным письмом.\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "mt-1 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Заполните "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "underlineWithPadding",
                                            attrs: {
                                              href: "https://drive.google.com/uc?export=download&id=1zfU4kpfZLC8g13bwd1ZeKB7dnrWEgK8p",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        заявление на возврат"
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          ", надежно упакуйте вещи в коробку и передайте\n                                        курьеру.\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "mt-1 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        На передачу бесплатного возврата дается 2 попытки. Отследить статус доставки\n                                        возврата можно по\n                                        номеру накладной.\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "accordion-item",
                        {
                          staticClass: "mt-4",
                          scopedSlots: _vm._u([
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Возврат онлайн-заказов с самовывозом из магазина"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("template", { slot: "accordion-content" }, [
                            _c(
                              "div",
                              { staticClass: "mt-4 mb-5 pr-11 text-16" },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "list-outside mt-2 text-gray-light",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Заказы, оформленные в интернет-магазине и полученные лично в магазине (опция\n                                    «Самовывоз»),\n                                    можно вернуть только там, куда была осуществлена доставка заказа.\n                                    "
                                    ),
                                    _c(
                                      "li",
                                      { staticClass: "mt-4 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Вы можете вернуть товар в течение 14 дней после получения вами заказа.\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "mt-1 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Для оформления возврата необходим паспорт.\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "mt-1 ml-3 lg:ml-4" },
                                      [
                                        _vm._v(
                                          "\n                                        Возврат денежных средств будет перечислен по указанным в заявлении\n                                        реквизитам в течение 3–10\n                                        календарных дней.\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "accordion-item",
                        {
                          staticClass: "mt-4",
                          scopedSlots: _vm._u([
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Возврат покупок из магазина"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("template", { slot: "accordion-content" }, [
                            _c("div", { staticClass: "ml-3 lg:ml-4" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "list-outside mt-4 mb-5 pr-11 text-16 text-gray-light",
                                },
                                [
                                  _c("li", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "\n                                        Вы можете вернуть товар, приобретенный в офлайн магазине, в течение 14 дней\n                                        после покупки.\n                                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      "\n                                        Вернуть товар можно только в тот розничный магазин, где вы его приобрели.\n                                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      "\n                                        Для оформления возврата необходим паспорт. Если вы оплачивали покупку\n                                        наличными,\n                                        мы осуществим возврат наличными. Если же вы оплачивали покупку картой, для\n                                        оформления возврата\n                                        потребуется эта банковская карта.\n                                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "accordion-item",
                        {
                          staticClass: "mt-4",
                          scopedSlots: _vm._u([
                            {
                              key: "accordion-trigger",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-between text-16",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Самостоятельный возврат (включая международный возврат)"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml-11" }, [
                                        _c(
                                          "svg",
                                          {
                                            class: [
                                              slotProps.visible
                                                ? "transform rotate-180"
                                                : "",
                                            ],
                                            attrs: {
                                              width: "8",
                                              height: "5",
                                              viewBox: "0 0 8 5",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M4 3.99976L7 0.999756",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1 1L4 4",
                                                stroke: "black",
                                                "stroke-linecap": "square",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("template", { slot: "accordion-content" }, [
                            _c("div", { staticClass: "ml-3 lg:ml-4" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "list-outside mt-4 mb-5 pr-11 text-16 text-gray-light",
                                },
                                [
                                  _c("li", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "\n                                        По вопросам самостоятельного возврата свяжитесь с нами по телефону\n                                        "
                                    ),
                                    _c("br"),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "underlineWithPadding",
                                        attrs: { href: "tel:+78003010424" },
                                      },
                                      [
                                        _vm._v(
                                          " +7 (800)\n                                        301-04-24 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      " .\n                                        Обратите, пожалуйста, внимание: если вы отправляете возврат сами,\n                                        мы не можем гарантировать, что товар не потеряется и не получит повреждения\n                                        при транспортировке.\n                                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      "\n                                        Заполните "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "underlineWithPadding",
                                        attrs: {
                                          href: "https://drive.google.com/uc?export=download&id=1zfU4kpfZLC8g13bwd1ZeKB7dnrWEgK8p",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        заявление на возврат"
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      ", надежно упакуйте вещи в коробку и отправьте\n                                        посылку курьерской\n                                        службой (кроме Почты России) по адресу: 109369, г. Москва, Новочеркасский бульвар, 57к2\n                                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "mt-1" }, [
                                    _vm._v(
                                      "\n                                        Сообщите трек-номер для отслеживания по почте "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "underlineWithPadding",
                                        attrs: {
                                          href: "mailto:hello@presentandsimple.com",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        hello@presentandsimple.com "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" или по телефону "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "underlineWithPadding",
                                        attrs: { href: "tel:+78003010424" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        +7 (800) 301-04-24 "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      ",\n                                        чтобы мы могли отследить посылку.\n                                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-7 pb-20 flex items-center justify-center" },
          [
            _c("a", { attrs: { href: "https://t.me/presentandsimple_com" } }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z",
                      fill: "#939393",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M4.52638 9.89436C7.44198 8.62436 9.38591 7.78703 10.3582 7.38236C13.1352 6.22736 13.7124 6.02656 14.0884 6.01996C14.1712 6.01856 14.3562 6.03996 14.476 6.13636C14.577 6.21836 14.605 6.32936 14.6182 6.40716C14.6385 6.53732 14.6442 6.66935 14.635 6.80076C14.4844 8.38196 13.8334 12.22 13.502 13.9904C13.362 14.7396 13.0858 14.9904 12.8186 15.0156C12.2386 15.069 11.797 14.6318 11.2344 14.263C10.3544 13.6862 9.85718 13.327 9.00278 12.764C8.01538 12.1134 8.65558 11.7558 9.21818 11.1714C9.36538 11.0184 11.9242 8.69136 11.9736 8.47996C11.9798 8.45356 11.9856 8.35516 11.927 8.30316C11.8684 8.25116 11.7824 8.26896 11.72 8.28316C11.6317 8.30316 10.2257 9.23249 7.50198 11.0712C7.10278 11.3452 6.74132 11.4785 6.41758 11.4712C6.06038 11.4634 5.37358 11.2692 4.86298 11.1032C4.23678 10.8996 3.73898 10.792 3.78298 10.4464C3.80525 10.2666 4.05305 10.0826 4.52638 9.89436Z",
                      fill: "white",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ml-12",
                attrs: { href: "mailto:hello@presentandsimple.com" },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "16",
                      viewBox: "0 0 20 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M4.85963 0.223001C8.28009 -0.0743334 11.7199 -0.0743337 15.1404 0.223001L16.7883 0.366253C17.8853 0.461617 18.8249 1.11596 19.3118 2.05196C19.374 2.1717 19.3274 2.31667 19.2126 2.38772L12.3753 6.62033C10.9089 7.5281 9.05987 7.54715 7.57508 6.66977L0.692091 2.60255C0.580889 2.53684 0.529846 2.40158 0.579961 2.28253C1.02533 1.22456 2.02664 0.469268 3.21169 0.366253L4.85963 0.223001Z",
                        fill: "#939393",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M0.57436 4.43416C0.404175 4.3336 0.187027 4.44182 0.167189 4.63849C-0.109682 7.38351 -0.0426718 10.1545 0.368221 12.8869C0.584836 14.3274 1.76048 15.4318 3.21169 15.5579L4.85963 15.7012C8.28009 15.9985 11.7199 15.9985 15.1404 15.7012L16.7883 15.5579C18.2395 15.4318 19.4152 14.3274 19.6318 12.8869C20.0546 10.0749 20.1133 7.22198 19.8077 4.39858C19.7863 4.20052 19.5655 4.09429 19.3961 4.19915L13.2368 8.01202C11.2529 9.24018 8.75124 9.26595 6.7424 8.07891L0.57436 4.43416Z",
                        fill: "#939393",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-20 mx-auto flex justify-center" }, [
      _c(
        "a",
        {
          staticClass: "text-16 text-gray-light",
          attrs: { href: "tel:+78003010424" },
        },
        [_vm._v("\n                +7 (800) 301-04-24\n            ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }