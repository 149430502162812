var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col min-h-screen" },
    [
      _c("AnnouncementBar"),
      _vm._v(" "),
      _c("Nav"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-1" },
        [
          _vm.isSubscribePopupVisible &&
          _vm.newsSubscriptionPopup.data.attributes.active == true
            ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
                _c("div", { staticClass: "w-full h-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "-mt-3 text-2xl font-gothic text-gray-main mr-7",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.newsSubscriptionPopup.data.attributes.title
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-normal font-gothic text-gray-main" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.newsSubscriptionPopup.data.attributes.main_text
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-normal text-sm font-gothic mt-3 text-gray-main",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.newsSubscriptionPopup.data.attributes
                              .text_before_button
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass:
                        "h-10 w-full pl-4 mt-1 text-body-18 font-normal font-gothic border-2 outline-none focus:box-border focus:border focus:border-action-brown-dark-digital focus:text-black hover:border-brown-light focus:border-brown-dark appearance-none rounded-none",
                      attrs: {
                        id: "email",
                        type: "email",
                        placeholder: "Email",
                        required: "",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm.subscriptionPopupStatus == "loading"
                    ? _c(
                        "div",
                        { staticClass: "text-xs font-gothic text-gray-main" },
                        [
                          _vm._v(
                            "\n                    Загрузка..\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subscriptionPopupStatus == "success"
                    ? _c(
                        "div",
                        { staticClass: "text-xs font-gothic text-green-500" },
                        [
                          _vm._v(
                            "\n                    Успех! Не забудьте перейти по ссылке в email, для подтверждения подписки.\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subscriptionPopupStatus == "error"
                    ? _c(
                        "div",
                        { staticClass: "text-xs font-gothic text-red-500" },
                        [
                          _vm._v(
                            "\n                    Ошибка!\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-full mt-3 py-3 bg-brown-light text-white text-xl font-normal focus:outline-none",
                      on: {
                        click: function ($event) {
                          return _vm.subscribe(_vm.email)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Подписаться\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-xs" },
                    [
                      _vm._v(
                        "\n                    Нажимая на кнопку «Подписаться», я соглашаюсь на обработку моих персональных данных и ознакомлен(а) с  "
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "underline",
                          attrs: {
                            to: "/info/privacy-policy",
                            target: "_blank",
                          },
                        },
                        [_vm._v("условиями конфиденциальности")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.isSubscribeSuccessPopupVisible
            ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
                _c("div", { staticClass: "w-full h-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-xl text-center font-gothic text-gray-main",
                    },
                    [_vm._v("\n                    Успех!\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-normal font-gothic text-center text-gray-main",
                    },
                    [
                      _vm._v(
                        "\n                    Не забудьте подтвердить email в почте\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-full mt-3 py-3 bg-brown-light text-white text-xl font-normal focus:outline-none",
                      on: { click: _vm.closePopup },
                    },
                    [_vm._v("\n                    Хорошо\n                ")]
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("AlertMessage"),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }