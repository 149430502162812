<template>
    <div class="flex flex-wrap w-full justify-center items-center">
        <div class="flex flex-wrap max-w-xl">
            <div class="p-2 text-2xl text-gray-light "><h1>Восстановление пароля</h1></div>
            <div class="p-2 w-full">
                <form action="#" @submit.prevent="submit">
                    <div class="p-2 w-full">
                        <label for="email">E-mail</label>
                        <input class="appearance-none rounded-none w-full h-10 border border-gray-lighter px-2 focus:outline-none focus:border-black tracking-wide"
                               type="text" name="email" id="email" v-model="form.email">
                    </div>
                    <span class="p-2 text-xs text-gray-main" v-if="status=='loading'">
                        Загрузка...
                    </span>
                    <span class="p-2 text-xs text-green-500"  v-if="status=='success'">
                        Ссылка на восстановление пароля была отправлена на вашу почту!
                    </span>
                    <span class="p-2 text-xs text-red-500" v-if="status=='error'">
                        Ошибка :(
                    </span>
                    <div class="p-2 w-full mt-4">
                        <button type="submit" class="w-full py-3 px-5 bg-black text-white text-sm tracking-wider uppercase focus:outline-none">Восстановить пароль</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ForgotPassword',

    data () {
        return {
            form: {
                email: ''
            },
        }
    },
    computed: {
        ...mapGetters({
            status: "forgotPasswordStatus",
            error: "forgotPasswordErrors"
        })
    },

    methods: {
        ...mapActions({
            forgotPassword: 'forgotPassword'
        }),

        async submit () {
            await this.forgotPassword(this.form)

        }
    }
}
</script>


<style scoped>

</style>
