var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.footerStatus == "success"
    ? _c(
        "div",
        {
          staticClass:
            "mx-6 sm:mx-12 flex flex-col-reverse sm:flex-row items-top border-t border-gray-lighter",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.footer.data, function (item_category, index) {
            return _c(
              "div",
              {
                key: item_category.data.footer_category_id,
                staticClass: "my-6 sm:my-12 sm:w-1/4",
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "text-gray-main text-base font-normal uppercase",
                  },
                  [_vm._v(_vm._s(item_category.data.attributes.title))]
                ),
                _vm._v(" "),
                _vm._l(
                  item_category.data.attributes.footer_items.data,
                  function (sub_item_category) {
                    return _c(
                      "div",
                      {
                        key: sub_item_category.data.footer_item_id,
                        staticClass: "mt-2",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "text-gray-light text-base font-normal",
                            attrs: {
                              to: sub_item_category.data.attributes.path,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(sub_item_category.data.attributes.title)
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm.footerSubscription
            ? _c("div", { staticClass: "my-6 sm:my-12 w-full sm:w-1/4" }, [
                _c(
                  "h2",
                  {
                    staticClass:
                      "text-gray-main text-base font-normal uppercase",
                  },
                  [_vm._v(_vm._s(_vm.footerSubscription.data.attributes.title))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "block text-gray-light text-base font-normal mt-2",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.footerSubscription.data.attributes.main_text
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "block text-gray-light text-base font-normal mt-1",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.footerSubscription.data.attributes
                            .text_before_button
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("form", { staticClass: "w-full max-w-sm" }, [
                  _c("div", { staticClass: "flex items-baseline pt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass:
                        "appearance-none rounded-none bg-transparent border-b border-gray-light w-full text-gray-main mr-3 py-1 px-2 leading-tight focus:outline-none",
                      attrs: {
                        type: "text",
                        placeholder: "example@mail.com",
                        "aria-label": "E-mail",
                        name: "email",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "flex-shrink-0 bg-black border-black text-sm border-4 text-white py-1 px-2 uppercase tracking-wide focus:outline-none",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.subscribeFromFooter(_vm.email)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Подписаться\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-2 text-xs tracking-normal text-gray-light",
                    },
                    [
                      _vm._v(
                        "\n                Нажимая на кнопку «Подписаться», я соглашаюсь на обработку моих персональных данных и ознакомлен(а) с условиями "
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "underline",
                          attrs: {
                            to: "/info/privacy-policy",
                            target: "_blank",
                          },
                        },
                        [_vm._v("конфиденциальности")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.subscribeEmailStatus == "loading"
                    ? _c("span", { staticClass: "text-xs text-gray-main" }, [
                        _vm._v(
                          "\n                    Оформляем подписку...\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subscribeEmailStatus == "success"
                    ? _c("span", { staticClass: "text-xs text-green-500" }, [
                        _vm._v(
                          "\n                    Успех!\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subscribeEmailStatus == "error"
                    ? _c("span", { staticClass: "text-xs text-red-500" }, [
                        _vm._v(
                          "\n                    Ошибка\n                "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-6 sm:my-12 w-full sm:w-1/4" }, [
      _c(
        "h2",
        { staticClass: "text-gray-main text-base font-normal normal-case" },
        [_vm._v("Present & Simple")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-6 w-full flex" }, [
        _c(
          "a",
          {
            staticClass: "text-gray-light text-base font-normal",
            attrs: { href: "https://wa.me/78003010424", target: "_blank" },
          },
          [_vm._v("\n                WhatsApp\n            ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-gray-light text-base font-normal ml-3",
            attrs: {
              href: "https://t.me/presentandsimple_com",
              target: "_blank",
            },
          },
          [_vm._v("\n                Telegram\n            ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-gray-light text-base font-normal ml-3",
            attrs: {
              href: "https://vk.com/presentandsimplecom",
              target: "_blank",
            },
          },
          [_vm._v("\n                VK\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-6" }, [
        _c(
          "a",
          {
            staticClass: "text-16 text-gray-light",
            attrs: { href: "tel:+78003010424" },
          },
          [_vm._v("\n                +7 (800) 301-04-24\n            ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-11 text-gray-lightest" }, [
          _vm._v("\n                Ежедневно 10:00–19:00 мск\n            "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }