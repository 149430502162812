<template>
    <div class="flex justify-center">
        <div data-popmechanic-embed="57583"></div>
    </div>
</template>

<script>
export default {
    name: "OfflineOkeaniaLendingMindboxPage"
}
</script>

<style scoped>

</style>
