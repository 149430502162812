var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isMoreInfoAboutDolyameShown
        ? _c("DolyameProductCardPopup", { on: { closePopup: _vm.closePopup } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isInformAboutSizeSubscriptionVisible
        ? _c("SizeSubscriptionPopup", {
            attrs: {
              product: {
                id: _vm.product.data.product_id,
                articleNumber: _vm.product.data.attributes.article_number,
                sizeId: _vm.product.size.data.id,
              },
            },
            on: { closePopup: _vm.closePopup },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSuccessPopupVisible
        ? _c("SuccessAddToCartPopup", { on: { closePopup: _vm.closePopup } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSuccessPreorderPopupVisible
        ? _c("SuccessAddToCartPreorderPopup", {
            on: { closePopup: _vm.closePopup },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isProductAlreadyInPreorderCart
        ? _c("ProductIsAlreadyInPreorderCartPopup", {
            attrs: { product: _vm.product },
            on: {
              closePopup: _vm.closePopup,
              replaceProductInPreorderCart: function ($event) {
                return _vm.replaceProductInPreorderCart(_vm.product)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _vm.dolyameFirstPartToPay <= 50000 && _vm.productCost <= 200000
            ? _c("div", { staticClass: "w-full z-40 lg:text-left" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-2.5 text-11 text-gray-light-2 tracking-wider",
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n                    4 платежа по\n                    "
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatNumber")(_vm.dolyameFirstPartToPay)
                          ) + " ₽."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "underlinedPositions cursor-pointer",
                          on: { click: _vm.showMoreInfoAboutDolyame },
                        },
                        [_vm._v("Подробнее")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("ProductColorInfoBlock", { attrs: { product: _vm.product } }),
          _vm._v(" "),
          _vm.sizeAvailableToPreorder ||
          _vm.hasProductPreorderSizesWithPreorderDetails
            ? _c("div", { staticClass: "text-14 text-green-dark" }, [
                _vm._v(
                  "\n            На эту модель можно оформить предзаказ. "
                ),
                _c("br"),
                _vm._v(
                  " Ориентировочная дата отправки\n            — с " +
                    _vm._s(_vm.preorderDateFrom) +
                    " по " +
                    _vm._s(_vm.preorderDateTo) +
                    " включительно\n            "
                ),
                _c("br"),
                _vm._v(
                  " Как только изделие поступит на склад, вы получите подтверждение с ссылкой для отслеживания посылки.\n            Доставка по России для товаров, оформленных по предзаказу, бесплатная.\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full mt-4" },
            [
              _c("ProductSizeInfoBlock", {
                attrs: {
                  product: _vm.product,
                  isNonActiveButtonAddToCartClicked:
                    _vm.isNonActiveButtonAddToCartClicked,
                },
                on: { sizeIsSelected: _vm.sizeIsSelected },
              }),
              _vm._v(" "),
              _vm.isAvailableAddToCart
                ? _c(
                    "button",
                    {
                      staticClass:
                        "w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white flex items-center justify-center focus:outline-none hover:bg-gray-main-3 lg:w-98",
                      on: {
                        click: function ($event) {
                          return _vm.addProductToCart(_vm.product)
                        },
                      },
                    },
                    [_vm._v("Добавить в корзину")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAvailableAddRoPreorderCart
                ? _c(
                    "button",
                    {
                      staticClass:
                        "w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white flex items-center justify-center focus:outline-none lg:w-98",
                      on: {
                        click: function ($event) {
                          return _vm.addProductToPreorderCart(_vm.product)
                        },
                      },
                    },
                    [_vm._v("Оформить предзаказ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAvailableSizeSubscription
                ? _c(
                    "button",
                    {
                      staticClass:
                        "w-full h-9 mt-2.5 text-14 font-normal flex items-center justify-center focus:outline-none lg:w-98",
                      class: _vm.isMakeSizeSubscriptionTheOnlyOperation
                        ? "border border-none bg-black-main text-white"
                        : "border border-black bg-white text-black",
                      on: { click: _vm.addToSizeSubscription },
                    },
                    [_vm._v("Сообщить о поступлении")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isNoAvailableOperationsWithProduct
                ? _c("div", { on: { click: _vm.openSizeSelection } }, [
                    this.product.data.attributes.hasPreorderStockBalance
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white flex items-center justify-center focus:outline-none lg:w-98",
                          },
                          [_c("span", [_vm._v("Оформить предзаказ")])]
                        )
                      : this.product.data.attributes.active_stock_balance
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white flex items-center justify-center focus:outline-none lg:w-98",
                          },
                          [_c("span", [_vm._v("Добавить в корзину")])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMakeSizeSubscriptionTheOnlyOperationWhileSizeIsNotChecked ||
                    this.product.data.attributes.hasPreorderStockBalance
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "w-full h-9 mt-2.5 text-14 font-normal flex items-center justify-center focus:outline-none lg:w-98",
                            class:
                              _vm.isMakeSizeSubscriptionTheOnlyOperationWhileSizeIsNotChecked &&
                              !this.product.data.attributes
                                .hasPreorderStockBalance
                                ? "border border-none bg-black-main text-white"
                                : "border border-black bg-white text-black",
                          },
                          [_c("span", [_vm._v("Сообщить о поступлении")])]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }