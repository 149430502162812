var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.pageStatus === "success"
      ? _c(
          "div",
          _vm._l(
            _vm.page.data.attributes.page_blocks.data,
            function (page_block) {
              return _c(
                "div",
                { key: page_block.data.page_block_id },
                [
                  page_block.data.attributes.type === "nps" &&
                  page_block.data.attributes.active === 1
                    ? _c("NpsBlock", { attrs: { block: page_block } })
                    : _vm._e(),
                ],
                1
              )
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }