var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full text-13 lg:overflow-x-auto" }, [
    _c("table", { staticClass: "w-full lg:scrollbar-none" }, [
      _c("thead", [
        _c(
          "tr",
          { staticClass: "text-black" },
          [
            _c("th", { staticClass: "text-left" }, [_vm._v("Размер")]),
            _vm._v(" "),
            _vm._l(_vm.uniqueSizeTitles, function (sizeTitle) {
              return _c(
                "th",
                { key: sizeTitle, staticClass: "pl-3 text-center" },
                [_vm._v(_vm._s(sizeTitle))]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        { staticClass: "text-gray-main-2" },
        _vm._l(_vm.uniqueParameters, function (parameter) {
          return _c(
            "tr",
            { key: parameter },
            [
              _c("td", { staticClass: "pt-5" }, [
                _vm._v(" " + _vm._s(parameter)),
              ]),
              _vm._v(" "),
              _vm._l(_vm.uniqueSizeTitles, function (sizeTitle) {
                return _c(
                  "td",
                  { key: sizeTitle, staticClass: "pl-3 pt-5 text-center" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getValue(parameter, sizeTitle)) +
                        "\n            "
                    ),
                  ]
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }