<template>
  <div class="flex justify-center">
    <div data-popmechanic-embed="75937"></div>
  </div>
</template>

<script>
export default {
  name: "EmailForSaleLendingMindboxPage"
}
</script>

<style scoped>

</style>

