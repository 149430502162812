var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-none" }, [
    _c("div", { staticClass: "flex-none lg:flex" }, [
      _c("div", { staticClass: "w-full lg:w-1/2" }, [
        _c("div", { staticClass: "lg:h-180 overflow-hidden relative" }, [
          _c("img", {
            staticClass: "object-cover h-full w-full",
            attrs: {
              src: _vm.giftCertificateBlock.data.attributes.certificate_image
                .data.attributes.path,
              alt: "",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-1/2 lg:mx-auto font-normal" }, [
        _c(
          "div",
          {
            staticClass:
              "px-6 text-gray-main text-lg sticky lg:px-0 lg:pl-15 lg:text-left lg:w-100",
          },
          [
            _c("div", { staticClass: "text-gray-main text-base font-normal" }, [
              _c(
                "div",
                {
                  staticClass:
                    "w-full mt-4 text-xl uppercase text-gray-main text-center lg:ml-4 lg:text-left",
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.giftCertificateBlock.data.attributes.title) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.getVerificationOfUser
                    ? _c("AuthByPhone", {
                        on: {
                          authByPhoneSuccessClosePopup: _vm.getUserAuthData,
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass:
                            "w-full mt-4 text-base text-gray-main lg:ml-4 lg:text-left",
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 lg:p-0 lg:items-left lg:justify-left lg:pr-4",
                              },
                              [
                                _c("div", { staticClass: "w-full" }, [
                                  _vm._v("Выберите сумму"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "click-outside",
                                        rawName: "v-click-outside",
                                        value: _vm.hideSelect,
                                        expression: "hideSelect",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "relative h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:mx-0",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-full border border-gray-lighter cursor-pointer align-middle flex items-center justify-left pl-3 h-10",
                                            on: {
                                              click: function ($event) {
                                                _vm.areOptionsVisible =
                                                  !_vm.areOptionsVisible
                                              },
                                            },
                                          },
                                          [
                                            this.sum === 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      _vm.submitted &&
                                                      _vm.$v.sum.$error
                                                        ? "border-red-400"
                                                        : "border-gray-lighter",
                                                    ],
                                                  },
                                                  [
                                                    _vm.submitted &&
                                                    !_vm.$v.sum.required
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-medium tracking-wide text-xs font-gothic text-red-500 mt-1 ml-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        Выберите сумму\n                                                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(
                                                        _vm._f("formatNumber")(
                                                          this.sum
                                                        )
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.areOptionsVisible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-full absolute left-0 top-0 mt-10 z-10 border-l border-r border-b bg-white cursor-pointer",
                                              },
                                              _vm._l(_vm.sums, function (sum) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: sum.id,
                                                    staticClass:
                                                      "w-full hover:bg-black hover:text-white bg-white h-10 align-middle flex items-center justify-left pl-3 cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeSumOfCertificate(
                                                          sum
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatNumber"
                                                          )(sum.title)
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                              },
                              [
                                _c("div", { staticClass: "w-full" }, [
                                  _vm._v("Имя отправителя"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mx-0",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.senderName,
                                          expression: "senderName",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black",
                                      class: [
                                        _vm.submitted &&
                                        _vm.$v.senderName.$error
                                          ? "border-red-400"
                                          : "border-gray-lighter",
                                      ],
                                      attrs: {
                                        type: "text",
                                        name: "senderName",
                                      },
                                      domProps: { value: _vm.senderName },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.senderName = $event.target.value
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.submitted && !_vm.$v.senderName.required
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            Заполните\n                                            имя отправителя\n                                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                              },
                              [
                                _c("div", { staticClass: "w-full" }, [
                                  _vm._v("E-mail отправителя"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mx-0",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.senderEmail,
                                          expression: "senderEmail",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black",
                                      class: [
                                        _vm.submitted &&
                                        _vm.$v.senderEmail.$error
                                          ? "border-red-400"
                                          : "border-gray-lighter",
                                      ],
                                      attrs: { type: "text", name: "name" },
                                      domProps: { value: _vm.senderEmail },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.senderEmail = $event.target.value
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.submitted &&
                                    !_vm.$v.senderEmail.required
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            Заполните\n                                            email отправителя\n                                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                              },
                              [
                                _c("div", { staticClass: "w-full" }, [
                                  _vm._v("Номер телефона отправителя"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mx-0",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.senderPhoneNumber,
                                          expression: "senderPhoneNumber",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black",
                                      attrs: { readonly: "" },
                                      domProps: {
                                        value: _vm.senderPhoneNumber,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.senderPhoneNumber =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pr-2 mt-4 text-gray-main flex items-center",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkbox,
                                      expression: "checkbox",
                                    },
                                  ],
                                  staticClass:
                                    "ml-2 h-4 w-4 form-checkbox text-black border border-black border-transparent focus:border-black focus:outline-none lg:ml-0",
                                  attrs: {
                                    type: "checkbox",
                                    name: "checkbox",
                                    checked: "",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.checkbox)
                                      ? _vm._i(_vm.checkbox, null) > -1
                                      : _vm.checkbox,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.checkbox,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.checkbox = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.checkbox = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.checkbox = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-3" }, [
                                  _vm._v(
                                    "Отправить сертификат мне, я сам(а) вручу его получателю!"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.checkbox !== true
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                                    },
                                    [
                                      _c("div", { staticClass: "w-full" }, [
                                        _vm._v("Имя получателя"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mx-0",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.recipientName,
                                                expression: "recipientName",
                                              },
                                            ],
                                            staticClass:
                                              "appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black",
                                            class: [
                                              _vm.submitted &&
                                              _vm.$v.recipientName.$error
                                                ? "border-red-400"
                                                : "border-gray-lighter",
                                            ],
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: _vm.recipientName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.recipientName =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.submitted &&
                                          !_vm.$v.recipientName.required &&
                                          _vm.$v.recipientName.$error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                Заполните имя получателя\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                                    },
                                    [
                                      _c("div", { staticClass: "w-full" }, [
                                        _vm._v("E-mail получателя"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mx-0",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.recipientEmail,
                                                expression: "recipientEmail",
                                              },
                                            ],
                                            staticClass:
                                              "appearance-none rounded-none w-full h-full border px-2 focus:outline-none focus:border-black",
                                            class: [
                                              _vm.submitted &&
                                              _vm.$v.recipientEmail.$error
                                                ? "border-red-400"
                                                : "border-gray-lighter",
                                            ],
                                            attrs: { type: "email" },
                                            domProps: {
                                              value: _vm.recipientEmail,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.recipientEmail =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.submitted &&
                                          !_vm.$v.recipientEmail.required &&
                                          _vm.$v.recipientEmail.$error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                Заполните email получателя\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                                    },
                                    [
                                      _c("div", { staticClass: "w-full" }, [
                                        _vm._v("Дата и время отправки"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mb-0 lg:mx-0",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.dateTime,
                                                expression: "dateTime",
                                              },
                                            ],
                                            staticClass:
                                              "w-full h-10 rounded-none border border-gray-lighter px-2 focus:outline-none focus:border-black",
                                            class: [
                                              _vm.submitted &&
                                              _vm.$v.dateTime.$error
                                                ? "border-red-400"
                                                : "border-gray-lighter",
                                            ],
                                            attrs: {
                                              type: "datetime-local",
                                              id: "dateTime",
                                            },
                                            domProps: { value: _vm.dateTime },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.dateTime =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.submitted &&
                                          !_vm.$v.dateTime.required &&
                                          _vm.$v.dateTime.$error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-medium tracking-wide text-xs font-gothic text-red-500 text-xs mt-1 ml-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                Выберите\n                                                дату и время отправки\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v("*По Московскому времени"),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-4 px-2 lg:p-0 lg:items-left lg:justify-left",
                                    },
                                    [
                                      _c("div", { staticClass: "w-full" }, [
                                        _vm._v("Сообщение получателю"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4 lg:mb-4 lg:mx-0",
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.message,
                                                expression: "message",
                                              },
                                            ],
                                            staticClass:
                                              "w-full h-18 appearance-none rounded-none border border-gray-lighter px-2 focus:outline-none focus:border-black",
                                            attrs: {
                                              type: "text",
                                              name: "comment",
                                            },
                                            domProps: { value: _vm.message },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.message =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-15 px-2 lg:p-0 lg:items-left lg:justify-left",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-10 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "w-full py-3 bg-black text-white border border-black text-xl font-normal focus:outline-none",
                                        on: { click: _vm.buyCertificate },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            Приобрести\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-5 px-2 lg:p-0 lg:items-left lg:justify-left",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-8 items-center justify-center lg:w-full lg:mx-auto lg:ml-0 lg:mr-0 lg:pr-4",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "w-full py-3 bg-white text-black border border-black text-xl font-normal focus:outline-none",
                                        attrs: {
                                          "data-popmechanic-call-form": "77584",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            Намекнуть о подарке\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main font-normal",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "my-4 text-xl text-gray-main text-center font-normal uppercase flex-1",
          },
          [_vm._v("Условия использования\n            сертификата\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-2 lg:p-4" },
          [
            _c(
              "accordion",
              { staticClass: "mt-7" },
              [
                _c(
                  "accordion-item",
                  [
                    _c("template", { slot: "accordion-trigger" }, [
                      _c("div", { staticClass: "w-full py-3 flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-gray-main text-left text-lg font-semibold flex-1",
                          },
                          [
                            _vm._v(
                              "\n                                О продукте\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mr-4 flex-initial" }, [
                          _c("span", { staticClass: "Collapsible__Plus" }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "accordion-content" }, [
                      _c("div", { staticClass: "pt-1 pb-3" }, [
                        _c("div", {
                          staticClass: "text-gray-main text-left text-lg",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.giftCertificateBlock.data.attributes
                                .description
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "accordion-item",
                  [
                    _c("template", { slot: "accordion-trigger" }, [
                      _c("div", { staticClass: "w-full py-3 flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-gray-main text-left text-lg font-semibold flex-1",
                          },
                          [
                            _vm._v(
                              "\n                                Правила использования сертификата\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mr-4 flex-initial" }, [
                          _c("span", { staticClass: "Collapsible__Plus" }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "accordion-content" }, [
                      _c("div", { staticClass: "pt-1 pb-3" }, [
                        _c("div", {
                          staticClass: "text-gray-main text-left text-lg",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.giftCertificateBlock.data.attributes
                                .more_information
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }