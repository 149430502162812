const state = {
    alertShow: false,
    message: '',
}
const getters = {
    getAlertShow: state => {
        return state.alertShow
    },
    getMessageAlert: state => {
        return state.message
    }
}
const mutations = {
    setAlertShow(state, show) {
        state.alertShow = show;
    },
    setAlertMessage(state, {message, timeout = 1200}) {
        state.message = message;
        setTimeout(() => {
            state.alertShow = false;
            setTimeout(() => {
                state.message = ''
            }, timeout + 250)
        }, timeout + 830)
    }
}
const actions = {
}

export default {
    state,
    getters,
    mutations,
    actions
}
