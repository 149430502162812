<template>
  <div>
    <div class="mt-4 w-full">
      <button class="focus:outline-none text-black text-left text-14 flex-1 border-b border-black"
              @click="showSizeAndMeasurementsPopup">
        Таблица размеров
      </button>
    </div>
    <SizeAndMeasurementsTablePopup v-if="isSizeAndMeasurementsPopupVisible"
                                   @closePopup="closePopup"
                                   :product="product"
                                   :sizeTableInfo="sizeTableInfo"/>
  </div>
</template>

<script>

import SizeAndMeasurementsTablePopup from "../popup/SizeAndMeasurementsTablePopup.vue";
import {mapGetters} from "vuex";

export default {
  name: "ProductSizeTableBlock",
  components: {SizeAndMeasurementsTablePopup},
  props: {
    product: null
  },
  data: () => ({
    isSizeAndMeasurementsPopupVisible: false,
  }),
  computed: {
    ...mapGetters({
      sizeTableInfo: 'sizeTableInfo',
      sizeTableInfoStatus: 'sizeTableInfoStatus',
    }),
  },
  methods: {
    showSizeAndMeasurementsPopup() {
      this.$store.dispatch('fetchSizeTableInfoForProductCard', 'size-table');
      this.isSizeAndMeasurementsPopupVisible = true;
    },
    closePopup() {
      this.isSizeAndMeasurementsPopupVisible = false
    },
  },
}
</script>

<style scoped>

</style>



