var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PopupOnRightSide",
    {
      on: {
        closePopup: function ($event) {
          return _vm.$emit("closePopup")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mt-7 uppercase text-14 text-gray-lightest tracking-widest",
        },
        [_vm._v(" Наличие в магазинах ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-5 text-14 text-black" }, [
        _vm._v(
          "\n    Перед тем, как ехать в магазин, рекомендуем уточнить наличие вещи по телефону.\n    Иногда информация на сайте обновляется не сразу\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.product.data.attributes.retail_stock_balance
        ? _c(
            "div",
            { staticClass: "mt-5" },
            _vm._l(_vm.productStockBalance.data, function (stock) {
              return _c("div", { key: stock.data.id }, [
                _vm.hasAnyStockBalancesInStock(stock)
                  ? _c(
                      "div",
                      { staticClass: "border-t border-gray-lightest py-5" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex justify-between items-center" },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "uppercase text-14 text-black" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(stock.data.attributes.title) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt-2 text-11 text-black" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(stock.data.attributes.address) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-1 text-11 text-black" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex justify-end items-right",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "tel:" +
                                            stock.data.attributes.phone,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex justify-end items-right mb-2",
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "11",
                                                  height: "11",
                                                  viewBox: "0 0 11 11",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M0.556474 4.38462C1.83662 7.1744 4.11448 9.39244 6.94648 10.5949L7.40044 10.7973C8.43904\n                      11.2603 9.65997 10.9085 10.2935 9.96373L10.8867 9.07898C11.0796 8.79133 11.0208 8.40389\n                      10.7514 8.18639L8.7395 6.56235C8.444 6.32381 8.00946 6.37937 7.78339 6.6846L7.161\n                      7.52494C5.56396 6.73668 4.26734 5.4393 3.47955 3.84132L4.31939 3.21857C4.62443 2.99237\n                      4.67996 2.55758 4.44156 2.26189L2.81842 0.248747C2.60109 -0.0208006 2.21399 -0.0795968\n                      1.92651 0.113279L1.03618 0.710615C0.0860593 1.34806 -0.263556 2.5798 0.209873\n                      3.62178L0.555953 4.38348L0.556474 4.38462Z",
                                                    fill: "black",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              stock.data.attributes.phone
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-3" }, [
                          _c(
                            "div",
                            _vm._l(
                              stock.data.relations.stockBalances,
                              function (size) {
                                return _c("div", { key: size.data.id }, [
                                  size.data.attributes.quantity > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-15 flex justify-between",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-11 text-black",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    size.data.attributes
                                                      .sizeTitle
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          size.data.attributes.quantity < 3
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-11 text-gray-bright",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  мало\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _c("div", [
            _c("div", { staticClass: "mt-5 text-14 text-black" }, [
              _vm._v(
                "\n      К сожалению, на текущий момент изделия нет в наличии в магазинах.\n    "
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }