var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "w-full" },
      _vm._l(
        _vm.cart.data.relationships.orderLines.data,
        function (item, index) {
          return _c(
            "div",
            {
              key:
                "cart_item_" +
                item.relationships.product.data.product_id +
                "size" +
                item.relationships.size_id,
            },
            [
              item.attributes.isProductActive === true &&
              item.attributes.maxQuantity > 0
                ? _c("div", [
                    item.relationships.product.data.attributes.isPreorder ===
                    true
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pt-4 text-11 text-brown-dark text-left lg:text-13",
                          },
                          [
                            _vm._v(
                              "\n                    Вы оформляете предзаказ на эту модель. Ориентировочная дата отправки\n                    — с " +
                                _vm._s(
                                  new Date(
                                    item.relationships.product.data.attributes.preorderDeliveryDateFrom
                                  )
                                    .toLocaleDateString("ru")
                                    .slice(0, 5)
                                ) +
                                "\n                    по " +
                                _vm._s(
                                  new Date(
                                    item.relationships.product.data.attributes.preorderDeliveryDateTo
                                  )
                                    .toLocaleDateString("ru")
                                    .slice(0, 5)
                                ) +
                                " включительно\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          index ===
                          _vm.cart.data.relationships.orderLines.data.length - 1
                            ? "flex py-4 justify-between"
                            : "flex py-4 justify-between border-b border-gray-lighter",
                        ],
                      },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-21 h-full flex-none overflow-hidden lg:w-26",
                            },
                            [
                              _c("img", {
                                staticClass: "w-full object-cover",
                                attrs: {
                                  src: item.relationships.product.data
                                    .attributes.images.data[0].data.attributes
                                    .path,
                                  alt: item.relationships.product.data
                                    .attributes.title,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ml-4 uppercase text-11 text-black font-normal lg:w-95 lg:ml-5 lg:text-16",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      item.relationships.product.data.attributes
                                        .title
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ml-4 items-center text-11 text-gray-light font-normal lg:ml-5 lg:text-16",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                                    Артикул: " +
                                      _vm._s(
                                        item.relationships.product.data
                                          .attributes.article_number
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex mt-0.5 items-center" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-3.5 h-3.5 mr-1.5 border border-black rounded rounded-full flex items-center justify-center",
                                      },
                                      [
                                        item.relationships.product.data
                                          .attributes.color.data.attributes.code
                                          ? _c("div", {
                                              staticClass:
                                                "w-2.5 h-2.5 rounded rounded-full border-border-gray-light",
                                              style:
                                                "background-color: " +
                                                item.relationships.product.data
                                                  .attributes.color.data
                                                  .attributes.code +
                                                ";",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            item.relationships.product.data
                                              .attributes.color.data.attributes
                                              .title
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ml-4 pt-4 uppercase text-11 text-black font-normal lg:text-16 lg:ml-5",
                              },
                              _vm._l(
                                item.relationships.product.data.attributes.sizes
                                  .data,
                                function (size) {
                                  return _c(
                                    "div",
                                    { key: size.id, staticClass: "bg-white" },
                                    [
                                      size.data.size_id ===
                                      item.attributes.sizeId
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  size.data.attributes.title
                                                ) +
                                                "\n                                    "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid justify-items-end" }, [
                          _c("div"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-30 grid justify-items-end content-end lg:w-45",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mr-2 flex text-11 text-gray-light text-right lg:text-16",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.attributes.userQuantity) +
                                      " шт.\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {}, [
                                item.attributes.totalDiscount === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mr-2 flex text-11 text-black text-right lg:text-16",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("formatNumber")(
                                                item.attributes.totalPrice
                                              )
                                            ) +
                                            " ₽\n                                "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex pr-2 text-11 text-black text-right lg:text-16",
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatNumber")(
                                                item.attributes.totalPrice
                                              )
                                            ) + " ₽"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ml-3 text-gray-light text-right text-11 font-normal line-through lg:text-16",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.attributes.basicPrice
                                                )
                                              ) + " ₽"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }