<template>
    <div>
        <div class="w-full h-full flex items-center justify-center">
            <div class="my-12 text-gray-main uppercase font-normal tracking-wide text-center">
                <h2 class="text-3xl">404</h2>
                <div class="mt-6 text-lg">Такой страницы не существует</div>

            </div>
        </div>
        <div class="w-full lg:w-320 lg:mx-auto">
            <div data-popmechanic-embed="40996" ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>
