<template>
    <div>
        <button @click="openStockBalancesTable(product.data.product_id)"
                class="-ml-0.5 flex items-center justify-center focus:outline-none">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.99984 0C4.32098 0 2.1416 2.17969 2.1416 4.85862C2.1416 7.43733 6.54956 13.4621 6.73725 13.7172L6.91241 13.9556C6.9329 13.9836 6.96552 14 6.99984 14C7.03469 14 7.06709 13.9836 7.08779 13.9556L7.26285 13.7172C7.45064 13.4621 11.8585 7.43733 11.8585 4.85862C11.8585 2.17969 9.67877 0 6.99984 0ZM6.99984 3.11827C7.95968 3.11827 8.74019 3.89881 8.74019 4.85862C8.74019 5.81794 7.95965 6.59897 6.99984 6.59897C6.04056 6.59897 5.25949 5.81794 5.25949 4.85862C5.25949 3.89881 6.04052 3.11827 6.99984 3.11827Z"
                    fill="black"/>
            </svg>
            <span class="ml-2 text-14 text-black text-left font-semibold flex-1 border-b border-black">
                Посмотреть наличие в магазинах
            </span>
        </button>
        <AvailabilityInRetailStocksPopup v-if="isOpenStockBalancesPopup"
                                         @closePopup="closePopup"
                                         :product="product"
                                         :productStockBalance="productStockBalance" />

    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import PopupOnRightSide from "../popup/PopupOnRightSide.vue";
import AvailabilityInRetailStocksPopup from "../popup/AvailabilityInRetailStocksPopup.vue";

export default {
    name: "ProductAvailabilityInRetailStocks",
    components: {AvailabilityInRetailStocksPopup, PopupOnRightSide},
    props: {
        product: null
    },
    data: () => ({
        isOpenStockBalancesPopup: false,
    }),
    computed: {
        ...mapGetters({
            productStockBalance: 'productStockBalance',
        }),
    },
    methods: {
        ...mapActions({
            fetchProductStockBalance: 'fetchProductStockBalance',
        }),
        openStockBalancesTable(product_id) {
            let stockType = 'retail'
            if (!this.isOpenStockBalancesPopup) {
                this.fetchProductStockBalance({productId: product_id, stockType: stockType});
            }
            this.isOpenStockBalancesPopup = !this.isOpenStockBalancesPopup;
        },
        closePopup() {
            this.isOpenStockBalancesPopup = false
        },
    },
}
</script>

<style scoped>

.text-eclipse {
    position: relative;
}

.text-eclipse::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 20%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
</style>


