var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "-ml-0.5 flex items-center justify-center focus:outline-none",
          on: {
            click: function ($event) {
              return _vm.openStockBalancesTable(_vm.product.data.product_id)
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "14",
                height: "14",
                viewBox: "0 0 14 14",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M6.99984 0C4.32098 0 2.1416 2.17969 2.1416 4.85862C2.1416 7.43733 6.54956 13.4621 6.73725 13.7172L6.91241 13.9556C6.9329 13.9836 6.96552 14 6.99984 14C7.03469 14 7.06709 13.9836 7.08779 13.9556L7.26285 13.7172C7.45064 13.4621 11.8585 7.43733 11.8585 4.85862C11.8585 2.17969 9.67877 0 6.99984 0ZM6.99984 3.11827C7.95968 3.11827 8.74019 3.89881 8.74019 4.85862C8.74019 5.81794 7.95965 6.59897 6.99984 6.59897C6.04056 6.59897 5.25949 5.81794 5.25949 4.85862C5.25949 3.89881 6.04052 3.11827 6.99984 3.11827Z",
                  fill: "black",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "ml-2 text-14 text-black text-left font-semibold flex-1 border-b border-black",
            },
            [_vm._v("\n            Посмотреть наличие в магазинах\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isOpenStockBalancesPopup
        ? _c("AvailabilityInRetailStocksPopup", {
            attrs: {
              product: _vm.product,
              productStockBalance: _vm.productStockBalance,
            },
            on: { closePopup: _vm.closePopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }