var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isSuccessPromoCopied
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c("div", {}, [
              _c("div", { staticClass: "mt-2 font-gothic text-gray-main" }, [
                _vm._v("Промокод успешно скопирован"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isErrorPromoCopied
        ? _c("Popup", { on: { closePopup: _vm.closePopup } }, [
            _c("div", {}, [
              _c("div", { staticClass: "mt-2 font-gothic text-gray-main" }, [
                _vm._v(
                  "Возникла ошибка при копировании промокода, попробуйте еще раз"
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-full sm:h-full" }, [
        _c("div", { staticClass: "w-full inset-x-0 top-0 pt-7" }, [
          _c("div", [
            _c("div", { staticClass: "px-2 mb-7" }, [
              _c("div", {
                staticClass: "text-2xl mt-4 text-center font-normal widest",
                domProps: { innerHTML: _vm._s(_vm.block.data.attributes.text) },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center text-xl lg:my-4 lg:text-2xl" },
                [
                  _vm._v(
                    "Ваш промокод: " + _vm._s(_vm.block.data.attributes.h2)
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-center text-md lg:text-xl" }, [
                _vm._v(_vm._s(_vm.block.data.attributes.h3)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-center text-md lg:text-xl" }, [
                _vm._v("Приятных покупок!"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-4 mx:auto flex items-center justify-center",
                  },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.promo,
                            expression: "promo",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        staticClass:
                          "py-3 px-4 text-xs border-2 tracking-widest uppercase lg:py-4 lg:px-7 lg:text-sm",
                        style:
                          "border-color: " +
                          _vm.block.data.attributes.border_button_color +
                          ";" +
                          "color: " +
                          _vm.block.data.attributes.text_color +
                          ";",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.block.data.attributes.button_text) +
                            "\n                               "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }