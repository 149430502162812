<template>
  <div class="flex justify-center">
    <div class="my-10">
      <router-link :to="{name: 'Cart2'}"
                   class="uppercase text-16 text-gray-main font-normal text-center"
                   :class="type === 'cart' ? 'underlinedPositions' : ''" >
        Основная корзина
      <span v-if="cart?.data?.relationships?.orderLines?.data">
          ({{cart.data.relationships.orderLines.data.length }})
      </span>
      </router-link>
      <router-link :to="{name: 'PreorderCart'}"
                   class="uppercase ml-7 text-16 text-gray-main font-normal text-center"
                   :class="type === 'preorderCart' ? 'underlinedPositions' : ''">
        Предзаказ
        <span v-if="preorderCart?.data?.relationships?.orderLines?.data">
            ({{preorderCart.data.relationships.orderLines.data.length }})
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CartMain",
  props: {
    type: null,
    cart: null,
    preorderCart: null
  },
  computed: {
  },
  mounted() {
  }
}
</script>

<style scoped>

.underlinedPositions {
  text-decoration: underline;
  text-underline-offset: 3px;
}

</style>


