var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.filteredStatus !== "error"
        ? _c(
            "div",
            [
              _c("FiltersAndSorting", {
                attrs: {
                  categoryId: _vm.categoryId,
                  categoryTitle: _vm.categoryTitle,
                },
                on: { applyFiltersAndSorting: _vm.filteredProducts },
              }),
              _vm._v(" "),
              _vm.filteredStatus === "success"
                ? _c(
                    "div",
                    { staticClass: "px-0.5" },
                    _vm._l(_vm.filtered, function (product, index) {
                      return product.data.attributes.showInThisCategories
                        ? _c("ProductBlock", {
                            key: product.data.product_id,
                            class: [
                              product.data.attributes.full_screen
                                ? "grid justify-center mb-12 "
                                : "w-1/2 lg:w-1/4 mx-auto inline-block align-top px-0.5 mb-12",
                            ],
                            attrs: {
                              product: product,
                              "gtm-position": index,
                              "gtm-list": _vm.gtmList,
                              "block-type": _vm.blockType,
                            },
                          })
                        : _vm._e()
                    }),
                    1
                  )
                : _vm.filteredStatus === "loading"
                ? _c("Loading")
                : _vm._e(),
            ],
            1
          )
        : _c("NotFound"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }