import axios from "axios";
import router from "../../router";


const state = {
    product: null,
    productStatus: 'loading',
    products: null,
    productsStatus: 'loading',
};

const getters = {
    product: state => {
        return state.product;
    },
    productStatus: state => {
        return state.productStatus;
    },
    products: state => {
        return state.products;
    },
    productsStatus: state => {
        return state.productsStatus;
    }
};

const actions = {
    fetchProduct({commit, dispatch}, productSlug) {
        commit('setProductStatus', 'loading');
        axios.get('/api/products/'+productSlug)
            .then(res => {
                commit('setProduct', res.data)
                commit('setProductStatus', 'success')
            })
            .catch(error => {
                if (error.response.status === 404) {
                    router.push({name: 'NotFound'})
                }
                else{

                    commit('setProductStatus', 'error')
                }

            });
    },
    fetchProducts({commit, dispatch}) {
        commit('setProductsStatus', 'loading');
        axios.get('/api/products/')
            .then(res => {
                commit('setProducts', res.data)
                commit('setProductsStatus', 'success')
            })
            .catch(error => {

                    commit('setProductStatus', 'error')

            });
    }

};

const mutations = {
    setProduct(state, product) {
        state.product = product;
    },
    setProductStatus(state, status) {
        state.productStatus = status;
    }    ,
    setProducts(state, products) {
        state.products = products;
    },
    setProductsStatus(state, status) {
        state.productsStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
