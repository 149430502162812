var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-10 mb-7 text-gray-main text-base font-normal" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-between uppercase text-11 text-gray-light lg:text-13",
        },
        [
          _c("div", [_vm._v("Доставка:")]),
          _vm._v(" "),
          _c("div", { staticClass: "pl-3" }, [
            _vm.cart?.data?.attributes?.deliveryPriceToPay !==
            _vm.cart?.data?.attributes?.basicDeliveryPrice
              ? _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatNumber")(
                          _vm.cart?.data?.attributes?.deliveryPriceToPay
                        )
                      ) +
                      " ₽\n                "
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "pl-1 text-gray-light font-normal line-through",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("formatNumber")(
                              _vm.cart?.data?.attributes?.basicDeliveryPrice
                            )
                          ) +
                          " ₽\n                "
                      ),
                    ]
                  ),
                ])
              : _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatNumber")(
                          _vm.cart?.data?.attributes?.deliveryPriceToPay
                        )
                      ) +
                      " ₽\n            "
                  ),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "mt-1 flex items-center justify-between uppercase text-11 text-gray-light lg:text-13",
        },
        [
          _c("div", [_vm._v("Скидка по товарам:")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(
                _vm._f("formatNumber")(
                  _vm.cart?.data?.attributes?.totalProductDiscount
                )
              ) + " ₽"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "mt-1 flex items-center justify-between uppercase text-11 text-gray-light lg:text-13",
        },
        [
          _c("div", [_vm._v("Сертификат:")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(
                _vm._f("formatNumber")(
                  _vm.cart?.data?.attributes?.giftCertificateDiscount
                )
              ) + " ₽"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "mt-3 flex items-center justify-between uppercase text-13 text-black lg:text-16",
        },
        [
          _c("div", [_vm._v("Итого:")]),
          _vm._v(" "),
          _c("div", { staticClass: "pl-3" }, [
            _vm.cart?.data?.attributes?.priceToPay !==
            _vm.cart?.data?.attributes?.totalBasicPrice
              ? _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatNumber")(
                          _vm.cart?.data?.attributes?.priceToPay
                        )
                      ) +
                      " ₽\n                "
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "pl-1 text-gray-light font-normal line-through",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("formatNumber")(
                              _vm.cart?.data?.attributes?.totalBasicPrice
                            )
                          ) +
                          " ₽\n                "
                      ),
                    ]
                  ),
                ])
              : _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatNumber")(
                          _vm.cart?.data?.attributes?.priceToPay
                        )
                      ) +
                      " ₽\n            "
                  ),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isPaymentByDolyame
        ? _c(
            "div",
            {
              staticClass:
                "flex items-center justify-between text-13 text-brown-light-2",
            },
            [
              _c("div", [
                _vm._v("\n      Первый платеж, к оплате сейчас:\n    "),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("formatNumber")(_vm.firstPartToPayDolyame)) +
                    " ₽\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }