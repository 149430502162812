import axios from "axios";

const state = {
    subscribeEmail: null,
    subscribeEmailStatus: null,
    subscribeItem: null,
    subscribeItemStatus:null
};

const getters = {
    subscribeEmail: state => {
        return state.subscribeEmail;
    },
    subscribeEmailStatus: state => {
        return state.subscribeEmailStatus;
    },
    subscribeItem: state => {
        return state.subscribeItem;
    },
    subscribeItemStatus: state => {
        return state.subscribeItemStatus;
    },

};

const actions = {
    subscribe({commit, state}, contactData) {
        commit('changeSubscribeEmailStatus', 'loading')
        axios.post('/api/subscribe', contactData)
            .then(res => {
                commit('changeSubscribeEmailStatus', 'success')
            })
            .catch(error => {
                commit('changeSubscribeEmailStatus', 'error')

            });

    },
    setSubscribeEmail({commit, state}, subEmail){
        commit('changeSubscribeEmail', subEmail);
    },

    setSubscribeItemStatus({commit, state}, status){
        commit('changeSubscribeItemStatus', status);
    },

    addSubscribeItem ({commit, state}, sizeSubscription) {
        commit('changeSubscribeItemStatus', 'loading')
        axios.post('/api/size-subscription', sizeSubscription)
            .then(res =>{
                commit('changeSubscribeItemStatus', 'success')
            })
            .catch( error =>{
                commit('changeSubscribeItemStatus', 'error')
            })
    },
};

const mutations = {

    changeSubscribeEmail(state, subEmail) {
        state.subscribeEmail = subEmail;
    },
    changeSubscribeEmailStatus(state, status) {
        state.subscribeEmailStatus = status;
    },
    changeSubscribeItemStatus(state,status){
        state.subscribeItemStatus = status;
    },

};

export default {
    state, getters, actions, mutations,
}
