import axios from "axios";


const state = {
    press: null,
    pressStatus: 'loading',
};

const getters = {
    press: state => {
        return state.press;
    },
    pressStatus: state => {
        return state.pressStatus;
    },
};

const actions = {
    fetchPress({commit, dispatch}, pressType) {
        commit('setPressStatus', 'loading')

        axios.get('/api/press-block/'+pressType)
            .then(res => {
                commit('setPress', res.data)
                commit('setPressStatus', 'success')
            })
            .catch(error => {
                commit('setPressStatus', 'error')

            });

    },

};

const mutations = {

    setPress(state, press) {
        state.press = press;
    },
    setPressStatus(state, status) {
        state.pressStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
