var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-4" }, [
    _c(
      "div",
      {
        staticClass:
          "-ml-5 flex overflow-x-scroll no-scrollbar lg:justify-between",
      },
      _vm._l(_vm.details, function (detail) {
        return _c(
          "div",
          {
            key: detail.id,
            staticClass: "text-14 whitespace-nowrap ml-5 cursor-pointer",
            class:
              _vm.selectedDetail && _vm.selectedDetail.id === detail.id
                ? "text-black"
                : "text-gray-main-2",
            on: {
              click: function ($event) {
                return _vm.selectDetail(detail)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(detail.name) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.selectedDetail
      ? _c("div", { staticClass: "mt-4 text-14" }, [
          _vm.selectedDetail.moreInfo
            ? _c("div", { staticClass: "mb-4" }, [
                _vm.selectedDetail.moreInfo.compositions
                  ? _c("div", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.selectedDetail.moreInfo.compositions
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedDetail.moreInfo.lining &&
                _vm.selectedDetail.moreInfo.lining.length > 3
                  ? _c("div", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            "Подклад: " + _vm.selectedDetail.moreInfo.lining
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedDetail.moreInfo.insulation &&
                _vm.selectedDetail.moreInfo.insulation.length > 3
                  ? _c("div", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            "Утеплитель: " +
                              _vm.selectedDetail.moreInfo.insulation
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedDetail.moreInfo.trim &&
                _vm.selectedDetail.moreInfo.trim.length > 3
                  ? _c("div", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            "Отделка: " + _vm.selectedDetail.moreInfo.trim
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.showShortText && _vm.selectedDetail.id === 1
            ? _c("div", [
                _c("div", [
                  _c("span", {
                    staticClass: "text-eclipse",
                    domProps: { innerHTML: _vm._s(_vm.truncatedHtml) },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "text-gray-main-2",
                      on: {
                        click: function ($event) {
                          return _vm.showFullText()
                        },
                      },
                    },
                    [_vm._v("\n              ...ещё\n                ")]
                  ),
                ]),
              ])
            : _vm.selectedDetail.id === 3 &&
              this.product.data.attributes.details.data.attributes
                .measurements_table
            ? _c(
                "div",
                { staticClass: "w-full" },
                [
                  _c("MeasurementsTable", {
                    attrs: {
                      "measurements-table":
                        this.product.data.attributes.details.data.attributes
                          .measurements_table,
                    },
                  }),
                ],
                1
              )
            : _c("div", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.selectedDetail.value) },
                }),
                _vm._v(" "),
                _vm.selectedDetail.id === 2 && _vm.selectedDetail.moreInfo
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.selectedDetail.moreInfo),
                      },
                    })
                  : _vm._e(),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }