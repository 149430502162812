<template>
    <div class="xl:w-475 xl:mx-auto">
        <div v-if="categoryIdForNonSwipableBlock !== null">
            <!--      <div class="ml-5 mt-5 text-gray-lightest text-14 font-normal tracking-widest text-left uppercase lg:ml-9 lg:mt-11 lg:hidden">
                    {{ this.categoryTitle }}
                  </div>-->
            <NonSwipableCategoryBlock :category-id="categoryIdForNonSwipableBlock"
                                      :categoryTitle="this.categoryTitle"
                                      block-type="non-swipable-category-block"
                                      gtm-list="Категории товаров"
                                      gtm-page-type="category"/>
        </div>
        <NotFound v-else-if="categoryStatus === 'error'"/>
        <div data-popmechanic-embed="93144"></div>
        <div data-popmechanic-embed="93079"></div>

        <!--TODO Сделать отображение нулевых категорий-->
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import NonSwipableCategoryBlock from "../components/NonSwipableCategoryBlock";
import Loading from "../components/Loading";
import NotFound from "./errors/NotFound";
import Filters from "../components/FiltersAndSorting/Filters.vue";

export default {
    name: "Category",
    components: {
        Filters,
        NotFound,
        NonSwipableCategoryBlock, Loading
    },
    computed: {
        ...mapGetters({
            categoryId: 'categoryId',
            category: 'category',
            categories: 'categories',
            categoryStatus: 'categoryStatus',
            filteredStatus: 'filteredStatus',
        }),
        categoryTitle() {
            return this.category?.data.attributes.title
        },
        categoryIdForNonSwipableBlock() {
            return this.categoryId
        }
    },

    async mounted() {
        await this.$store.dispatch('fetchCategories');
        await this.$store.dispatch('fetchCategory', this.$route.params.category);
    },

    watch: {
        $route() {
            this.$route.params.category
            this.$store.dispatch('fetchCategory', this.$route.params.category);
        },
        category() {
            this.$gtm.trackEvent({
                event: "categoryView", // Event type [default = 'interaction'] (Optional)
                categoryTitle: this.categoryTitle,
                categoryId: this.categoryId,
                noninteraction: false,
            })

        }
    },
    head: {
        title: function () {
            return  (this.categoryTitle) && {
                inner: this.categoryTitle + ' Present & Simple'
            }
        },
        meta: function () {
            return [
                {
                    name: 'description', content: this.categoryTitle +
                        '- Модная одежда и аксессуары от Российского производителя Present & Simple с доставкой'
                }
            ]
        }

    },
}
</script>

<style scoped>

</style>
