var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      {
        staticClass:
          "p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main text-base font-normal",
      },
      [
        _vm.infoStatus == "success"
          ? _c("div", [
              _c("h1", { staticClass: "uppercase" }, [
                _vm._v(_vm._s(_vm.info.data.attributes.title)),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.info.data.attributes.html) },
              }),
            ])
          : _c("Loading"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }