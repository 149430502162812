<template>
    <div data-popmechanic-embed="47404"></div>
</template>

<script>
export default {
    name: "EkbEmailSubscriptionPage"
}
</script>

<style scoped>

</style>
