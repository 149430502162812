<template>
    <div class="mt-10 mb-7 text-gray-main text-base font-normal">
        <div  class="flex items-center justify-between uppercase text-11 text-gray-light lg:text-13">
            <div>Доставка:</div>
            <div class="pl-3" >
                <div v-if="cart?.data?.attributes?.deliveryPriceToPay !== cart?.data?.attributes?.basicDeliveryPrice">
                    {{cart?.data?.attributes?.deliveryPriceToPay | formatNumber}} ₽
                    <span class="pl-1 text-gray-light font-normal line-through">
                        {{cart?.data?.attributes?.basicDeliveryPrice | formatNumber}} ₽
                    </span>
                </div>
                <div v-else>
                    {{cart?.data?.attributes?.deliveryPriceToPay | formatNumber}} ₽
                </div>
            </div>
        </div>
        <div  class="mt-1 flex items-center justify-between uppercase text-11 text-gray-light lg:text-13">
            <div>Скидка по товарам:</div>
            <div>{{ cart?.data?.attributes?.totalProductDiscount | formatNumber}} ₽</div>
        </div>
        <div  class="mt-1 flex items-center justify-between uppercase text-11 text-gray-light lg:text-13">
            <div>Сертификат:</div>
            <div>{{ cart?.data?.attributes?.giftCertificateDiscount | formatNumber }} ₽</div>
        </div>
        <div class="mt-3 flex items-center justify-between uppercase text-13 text-black lg:text-16">
            <div>Итого:</div>
            <div class="pl-3" >
                <div v-if="cart?.data?.attributes?.priceToPay !== cart?.data?.attributes?.totalBasicPrice">
                    {{cart?.data?.attributes?.priceToPay | formatNumber}} ₽
                    <span class="pl-1 text-gray-light font-normal line-through">
                        {{cart?.data?.attributes?.totalBasicPrice | formatNumber}} ₽
                    </span>
                </div>
                <div v-else>
                    {{cart?.data?.attributes?.priceToPay | formatNumber}} ₽
                </div>
            </div>
        </div>
      <div v-if="isPaymentByDolyame" class="flex items-center justify-between text-13 text-brown-light-2">
        <div>
          Первый платеж, к оплате сейчас:
        </div>
        <div>
          {{firstPartToPayDolyame | formatNumber}} ₽
        </div>
      </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "CartTotalCalculations",
    props: {
        cart:null
    },
    computed: {
      ...mapGetters ({
        firstPartToPayDolyame : 'firstPartToPayDolyame'
      }),
      isPaymentByDolyame() {
        return !!(this?.cart?.data?.relationships?.cartPaymentMethod && this?.cart?.data?.relationships?.cartPaymentMethod?.data?.id === 8
            && this?.firstPartToPayDolyame > 0);
      }
    },
    mounted() {
      this?.$store?.dispatch('getPricesForDolyame', this?.cart)
    }
}
</script>

<style scoped>

</style>
