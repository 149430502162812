var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "mt-4 w-full" }, [
        _c(
          "button",
          {
            staticClass:
              "focus:outline-none text-black text-left text-14 flex-1 border-b border-black",
            on: { click: _vm.showSizeAndMeasurementsPopup },
          },
          [_vm._v("\n      Таблица размеров\n    ")]
        ),
      ]),
      _vm._v(" "),
      _vm.isSizeAndMeasurementsPopupVisible
        ? _c("SizeAndMeasurementsTablePopup", {
            attrs: { product: _vm.product, sizeTableInfo: _vm.sizeTableInfo },
            on: { closePopup: _vm.closePopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }