var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "mx-6 flex items-center my-10" }, [
      _c(
        "svg",
        {
          staticClass: "sm:hidden mx-5 fill-current text-gray-main",
          attrs: {
            role: "presentation",
            viewBox: "0 0 18 17",
            height: "17px",
            width: "18px",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                transform: "translate(1 1)",
                stroke: "currentColor",
                fill: "none",
                "fill-rule": "evenodd",
                "stroke-linecap": "square",
              },
            },
            [
              _c("path", { attrs: { d: "M16 16l-5.0752-5.0752" } }),
              _vm._v(" "),
              _c("circle", { attrs: { cx: "6.4", cy: "6.4", r: "6.4" } }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "hidden sm:block mx-5 fill-current text-gray-main",
          attrs: {
            role: "presentation",
            viewBox: "0 0 21 21",
            height: "21px",
            width: "21px",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                transform: "translate(1 1)",
                stroke: "currentColor",
                "stroke-width": "2",
                fill: "none",
                "fill-rule": "evenodd",
                "stroke-linecap": "square",
              },
            },
            [
              _c("path", { attrs: { d: "M18 18l-5.7096-5.7096" } }),
              _vm._v(" "),
              _c("circle", { attrs: { cx: "7.2", cy: "7.2", r: "7.2" } }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchTerm,
            expression: "searchTerm",
          },
        ],
        staticClass:
          "appearance-none rounded-none w-full text-gray-main text-lg uppercase tracking-wide appearance-none bg-transparent border-b border-gray-light mr-3 py-1 px-2 leading-tight focus:outline-none",
        attrs: {
          type: "text",
          placeholder: "поиск...",
          "aria-label": "search",
          id: "searchTerm",
        },
        domProps: { value: _vm.searchTerm },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.searchTerm = $event.target.value
            },
            _vm.search,
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mx-5 cursor-pointer", on: { click: _vm.clearSearch } },
        [
          _c(
            "svg",
            {
              staticClass: "fill-current text-gray-main",
              attrs: {
                role: "presentation",
                viewBox: "0 0 16 14",
                width: "16px",
                height: "14px",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M15 0L1 14m14 0L1 0",
                  stroke: "currentColor",
                  fill: "none",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "w-full" }, [
      _vm.searchTerm.length < 3
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "my-10 w-full text-center text-gray-main text-lg font-normal uppercase tracking-wide",
              },
              [
                _vm._v(
                  "\n                Введите поисковый запрос...\n\n            "
                ),
              ]
            ),
          ])
        : _c("div", [
            _vm.searchResultsSortableBalance.length > 0
              ? _c(
                  "div",
                  { staticClass: "px-0.5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-10 w-full text-center text-gray-main text-lg font-normal uppercase tracking-wide",
                      },
                      [
                        _vm._v(
                          'Результаты поиска по запросу "' +
                            _vm._s(_vm.searchTerm) +
                            '":'
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.searchResultsSortableBalance,
                      function (product) {
                        return _c("ProductBlock", {
                          key: product.data.product_id,
                          class: [
                            product.data.attributes.full_screen == true
                              ? "grid justify-center mb-12 "
                              : "w-1/2 lg:w-1/4 mx-auto inline-block align-top px-0.5 mb-12 ",
                          ],
                          attrs: {
                            product: product,
                            "gtm-list": "Результаты поиска",
                            "gtm-page-type": "search_result",
                            "block-type": "non-swipable-category-block",
                          },
                        })
                      }
                    ),
                  ],
                  2
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "my-10 w-full text-center text-gray-main text-lg font-normal uppercase tracking-wide",
                    },
                    [
                      _vm._v(
                        'По запросу "' +
                          _vm._s(_vm.searchTerm) +
                          '" ничего не нашлось. Другие идеи для вас: '
                      ),
                    ]
                  ),
                ]),
          ]),
      _vm._v(" "),
      _c("div", { attrs: { "data-popmechanic-embed": "40994" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }