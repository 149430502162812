import axios from "axios";
import router from "../../router";

const state = {
    categories: null,
    categoriesStatus: 'loading',

    category: null,
    categoryId: null,
    categoryStatus: 'loading',
};

const getters = {
    category: state => {
        return state.category;
    },
    categoryId: state => {
        return state.categoryId;
    },
    categoryStatus: state => {
        return state.categoryStatus;
    },

    categories: state => {
        return state.categories;
    },
    categoriesStatus: state => {
        return state.categoriesStatus;
    },
};

const actions = {
    async fetchCategories({commit, dispatch}, categorySlug) {
        commit('setCategoriesStatus', 'loading')
        await axios.get('/api/categories/')
            .then(res => {
                commit('setCategories', res.data)
                commit('setCategoriesStatus', 'success')
            })
            .catch(error => {
                if (error.response.status === 404) {
                    router.push({name: 'NotFound'})
                } else {
                    commit('setCategoriesStatus', 'error')
                }
            });
    },

    fetchCategory({state, commit, dispatch}, categorySlug) {
        commit('setCategoryStatus', 'loading')
        commit('setCategoryId', null)
        commit('setCategory', null)
        state.categories.data.find((category) => {
            if (category.links.meta.slug === categorySlug) {
                commit('setCategoryId', category.data.id)
                commit('setCategory', category)
            }
        })
        if(state.categoryId) {
            commit('setCategoryStatus', 'success')
        } else {
            commit('setCategoryStatus', 'error')
        }
    },

};

const mutations = {
    setCategory(state, category) {
        state.category = category;
    },
    setCategoryId(state, categoryId) {
        state.categoryId = categoryId;
    },
    setCategoryStatus(state, status) {
        state.categoryStatus = status;
    },
    setCategories(state, categories) {
        state.categories = categories;
    },
    setCategoriesStatus(state, status) {
        state.categoriesStatus = status;
    },

};

export default {
    state, getters, actions, mutations,
}
