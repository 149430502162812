<template>
   <div class=" ">
       <div>
            <div>
                <!-- Futura Pt -->
               <div class="flex mt-10  items-center justify-center">
                   <div class="text-gray-main text-lg font-normal uppercase mx-3">
                       Описание
                   </div>
                   <div class=" text-lg font-normal uppercase  mx-3">
                       Описание
                   </div>
                   <div class=" text-gray-main text-lg font-bold uppercase mx-3 ">
                       Описание
                   </div>
                   <div class="text-lg font-bold uppercase  mx-3">
                       Описание
                   </div>
               </div>

                <!-- Century Gothic -->
               <div class="flex items-center justify-center mt-10 ">
                   <div class="font-gothic text-gray-main text-lg font-normal uppercase mx-3">
                       Описание
                   </div>
                   <div class=" font-gothic text-lg font-normal uppercase  mx-3">
                       Описание
                   </div>
                   <div class=" font-gothic text-gray-main text-lg font-bold uppercase mx-3 ">
                       Описание
                   </div>
                   <div class="font-gothic text-lg font-bold uppercase  mx-3">
                       Описание
                   </div>
               </div>

                <!-- Futura заголовок и Century Gothic описание, разное межбуквенное расстояние у описания-->
                <div class="text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center">
                    Futura заголовок и Century Gothic описание, разное межбуквенное расстояние у описания
                </div>
                <div class="flex mt-3 p-4 w-full ">
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class=" text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="font-gothic text-gray-main text-base font-normal  mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="font-gothic text-gray-main text-base font-normal tracking-tight mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="font-gothic text-gray-main text-base font-normal tracking-wider mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                </div>

                <!-- Century Gothic заголовок и Futura описание, разное межбуквенное расстояние у описания-->
                <div class="text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center">
                    Century Gothic заголовок и Futura описание, разное межбуквенное расстояние у описания
                </div>
                <div class="flex mt-3 p-4 w-full ">
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class=" font-gothic text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class=" text-gray-main text-xl font-normal leading-tight  mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="font-gothic text-lg leading-tight font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class=" text-gray-main text-xl leading-tight font-normal tracking-tight mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="font-gothic text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="text-gray-main text-xl leading-tight font-normal tracking-wider mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                </div>

                <!-- Всё Futura, разное межуквенное расстояние у описания -->
                <div class="text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center">
                    Всё Futura, разное межбуквенное расстояние у описания
                </div>
                <div class="flex mt-3 p-4 w-full ">
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class=" text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class=" text-gray-main text-base text-xl leading-tight  font-normal  mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class=" text-gray-main text-xl font-normal leading-tight tracking-tight mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="text-gray-main text-xl font-normal leading-tight tracking-wider mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                </div>
                <!-- Всё Century Gothic, разное межуквенное расстояние у описания -->
                <div class="text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center">
                    Всё Century Gothic, разное межбуквенное расстояние у описания
                </div>
                <div class="flex mt-3 p-4 w-full ">
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class=" text-gothic text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="text-gothic  text-gray-main text-base text-xl leading-tight  font-normal  mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="text-gothic text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class=" text-gothic text-gray-main text-xl font-normal leading-tight tracking-tight mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <div class="text-gothic text-lg font-normal uppercase mx-3">
                            СВИТЕР ИЗ 100% КАШЕМИРА LORO PIANA (БЕЛЫЙ)
                        </div>
                        <div class="text-gothic text-gray-main text-xl font-normal leading-tight tracking-wider mx-3 mt-3">
                            Кашемировое волокно изготавливают из подшерстка кашемировых козлят Capra hircus. Козы этой породы обитают только в Монголии, в пустыне Алашань. Козы Capra hircus дают кашемир с самыми тонкими ворсинками в мире, но еще более мягкий и ценный материал — пух козлят этой породы. Бэйби кашемир доступен в ограниченном количестве, ведь его можно состричь с козленка возрастом до 6 месяцев один раз в его жизни.
                        </div>
                    </div>
                </div>

                <!--Century Gothic button -->
                <div class="text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center">
                    Кнопка Century Gothic
                </div>
                <div class="flex mt-3 p-4 w-full ">
                    <div class=" w-1/4 p-5 mx-auto">
                        <button class="w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider  focus:outline-none">
                            Оплатить
                        </button>
                        <button class="w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider mt-3 uppercase focus:outline-none">
                            Оплатить
                        </button>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <button class="w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider  focus:outline-none">
                            Оплатить
                        </button>
                        <button class="w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider mt-3 uppercase focus:outline-none">
                            Оплатить
                        </button>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <button class="w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider  focus:outline-none">
                            Оплатить
                        </button>
                        <button class="w-full py-3 px-5 bg-brown-light text-gothic text-white text-sm tracking-wider mt-3 uppercase focus:outline-none">
                            Оплатить
                        </button>
                    </div>
                </div>

                <!--Futura button -->
                <div class="text-lg mt-10 font-normal uppercase mx-3 underline text-red-600 text-center">
                    Кнопка Futura
                </div>
                <div class="flex mt-3 p-4 w-full ">
                    <div class=" w-1/4 p-5 mx-auto">
                        <button class="w-full py-3 px-5 bg-brown-light  text-white text-sm tracking-wider  focus:outline-none">
                            Оплатить
                        </button>
                        <button class="w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider mt-3 uppercase focus:outline-none">
                            Оплатить
                        </button>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <button class="w-full py-3 px-5 bg-brown-light  text-white text-sm tracking-wider  focus:outline-none">
                            Оплатить
                        </button>
                        <button class="w-full py-3 px-5 bg-brown-light  text-white text-sm tracking-wider mt-3 uppercase focus:outline-none">
                            Оплатить
                        </button>
                    </div>
                    <div class=" w-1/4 p-5 mx-auto">
                        <button class="w-full py-3 px-5 bg-brown-light text-white text-sm tracking-wider  focus:outline-none">
                            Оплатить
                        </button>
                        <button class="w-full py-3 px-5 bg-brown-light  text-white text-sm tracking-wider mt-3 uppercase focus:outline-none">
                            Оплатить
                        </button>
                    </div>
                </div>

            </div>
   </div>
   </div>
</template>

<script>
export default {
    name: "FontsTest"
}
</script>

<style scoped>

</style>
