<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AlertMessage',
    computed: {
        ...mapGetters({
            show: 'getAlertShow',
            message: 'getMessageAlert',
        })
    }
}
</script>

<template>
    <div
        class="alert-message"
        :class="{
        'alert-message__show': show,
        'alert-message__close': !show
    }"
    >
        <div class="alert-message__wrapper">
            <div class="alert-message__text">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="css">
.alert-message {
    position: absolute;
    transform: translateY(-100px);
    right: 16px;
    background-color: rgb(97 228 73);
    z-index: 100;
    color: #fff;
    font-size: 17px;
    border-radius: 8px;
}
.alert-message__show {
    right: 16px;
    transform: translateY(44px);
    transition: all 1.5s ease-out;
}
.alert-message__close {
    right: 16px;
    transform: translateY(-100px);
    transition: all 3s ease-out;
}
.alert-message__wrapper {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
