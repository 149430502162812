var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lookbooksStatus == "success"
    ? _c("div", { staticClass: "py-10 w-full lg:w-256 lg:mx-auto" }, [
        _c(
          "div",
          { staticClass: "mx-6" },
          [
            _c(
              "h2",
              {
                staticClass:
                  "w-full text-gray-main text-2xl font-normal tracking-widest text-center uppercase",
              },
              [_vm._v("Коллекции")]
            ),
            _vm._v(" "),
            _vm._l(_vm.lookbooks.data, function (lookbook) {
              return _c(
                "div",
                {
                  key: lookbook.data.lookbook_id,
                  staticClass:
                    "mt-10 text-gray-main text-base font-normal tracking-wide",
                },
                [
                  lookbook.data.attributes.lookBookType === "default"
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "LookbookBlock",
                                  params: {
                                    lookbook: lookbook.links.meta.slug,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "text-gray-main text-xl font-normal tracking-widest text-center uppercase",
                                },
                                [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(lookbook.data.attributes.title) +
                                      "\n               "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "LookbookBlock",
                                  params: {
                                    lookbook: lookbook.links.meta.slug,
                                  },
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "mt-10" }, [
                                _c("img", {
                                  attrs: {
                                    src: lookbook.data.attributes.preview_image
                                      .data.attributes.path,
                                    alt: lookbook.data.attributes.Ntitle,
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ])
    : _c("Loading")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }