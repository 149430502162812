<template>
    <div data-popmechanic-embed="56511"></div>
</template>

<script>
export default {
    name: "EmailSubscriptionPage"
}
</script>

<style scoped>

</style>
