<template>
  <div>
    <div class="flex items-center justify-center text-14 text-black">
      Есть сомнения? Поможем в выборе размера:
    </div>
    <div class="mt-5 flex items-center justify-center">
      <a href="https://wa.me/78003010424" target="_blank">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.2232 6.125C18.7187 4.92634 17.9955 3.85045 17.0737 2.92634C16.1518 2.00446 15.0759 1.27902 13.8751 0.776786C12.6474 0.261161 11.3438 0 10.0001 0H9.95547C8.6028 0.00669643 7.29255 0.274554 6.06042 0.801339C4.87071 1.31027 3.80376 2.03348 2.89082 2.95536C1.97789 3.87723 1.26138 4.94866 0.765848 6.14286C0.252462 7.37946 -0.00646383 8.6942 0.000232512 10.0469C0.00692885 11.596 0.37746 13.1339 1.07165 14.5089V17.9018C1.07165 18.4688 1.53146 18.9286 2.09842 18.9286H5.49347C6.86845 19.6228 8.40638 19.9933 9.95547 20H10.0023C11.3394 20 12.6362 19.7411 13.8572 19.2344C15.0514 18.7366 16.125 18.0223 17.0447 17.1094C17.9665 16.1964 18.692 15.1295 19.1987 13.9397C19.7254 12.7076 19.9933 11.3973 20 10.0446C20.0067 8.68527 19.7433 7.36607 19.2232 6.125ZM15.8505 15.9018C14.2858 17.4509 12.2099 18.3036 10.0001 18.3036H9.96216C8.6162 18.2969 7.27916 17.9621 6.09837 17.3326L9.26991 18.9286L17.0447 17.1094L18.7766 10.0469L17.663 6.78348L17.1429 3.0506L13.221 2.34152L12.381 1.14583L6.06042 0.801339L0.765848 6.14286L1.07165 10.6696L2.76806 14.0893L2.66761 13.9018C6.06042 9.52381 1.70334 11.3839 1.69664 10.0379C1.68771 7.8125 2.53815 5.72321 4.0984 4.14955C5.65641 2.57589 7.73898 1.70536 9.96439 1.69643H10.0023C11.1184 1.69643 12.381 1.69643 13.221 2.34152C14.2166 2.75893 15.1094 3.35937 15.8773 4.12723C16.6429 4.89286 17.2456 5.78795 17.663 6.78348C17.1429 8.76488 18.3125 8.90848 18.308 10.0379C18.2946 12.2612 17.4219 14.3438 15.8505 15.9018Z" fill="black"/>
          <path d="M15.2099 11.5603C14.9666 11.4353 13.7546 10.8415 13.5291 10.7612C13.3037 10.6764 13.1385 10.6362 12.9755 10.8862C12.8104 11.134 12.3416 11.6853 12.1943 11.8527C12.0515 12.0179 11.9064 12.038 11.6631 11.9152C10.2167 11.192 9.268 10.625 8.31489 8.98887C8.06266 8.5536 8.56712 8.58485 9.0381 7.64512C9.11845 7.47994 9.07827 7.33931 9.01578 7.21431C8.95328 7.08931 8.46221 5.87949 8.25686 5.38619C8.0582 4.90628 7.85284 4.97324 7.70329 4.96431C7.56044 4.95538 7.39749 4.95538 7.23232 4.95538C7.06714 4.95538 6.80152 5.01788 6.57607 5.26119C6.35063 5.50896 5.71448 6.10494 5.71448 7.31476C5.71448 8.52458 6.59616 9.69645 6.7167 9.86163C6.8417 10.0268 8.45105 12.509 10.922 13.5782C12.4845 14.2523 13.0961 14.3103 13.8773 14.1942C14.3528 14.1228 15.3327 13.6005 15.5358 13.0223C15.7389 12.4465 15.7389 11.9532 15.6786 11.8505C15.6184 11.7411 15.4532 11.6786 15.2099 11.5603Z" fill="white"/>
        </svg>
      </a>
      <a href="https://t.me/presentandsimple_com" class="ml-12" target="_blank">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="black"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.52638 9.89448C7.44198 8.62448 9.38591 7.78715 10.3582 7.38248C13.1352 6.22748 13.7124 6.02668 14.0884 6.02008C14.1712 6.01868 14.3562 6.04008 14.476 6.13648C14.577 6.21848 14.605 6.32948 14.6182 6.40728C14.6385 6.53744 14.6442 6.66947 14.635 6.80088C14.4844 8.38208 13.8334 12.2201 13.502 13.9905C13.362 14.7397 13.0858 14.9905 12.8186 15.0157C12.2386 15.0691 11.797 14.6319 11.2344 14.2631C10.3544 13.6863 9.85718 13.3271 9.00278 12.7641C8.01538 12.1135 8.65558 11.7559 9.21818 11.1715C9.36538 11.0185 11.9242 8.69148 11.9736 8.48008C11.9798 8.45368 11.9856 8.35528 11.927 8.30328C11.8684 8.25128 11.7824 8.26908 11.72 8.28328C11.6317 8.30328 10.2257 9.23262 7.50198 11.0713C7.10278 11.3453 6.74132 11.4786 6.41758 11.4713C6.06038 11.4635 5.37358 11.2693 4.86298 11.1033C4.23678 10.8997 3.73898 10.7921 3.78298 10.4465C3.80525 10.2667 4.05305 10.0827 4.52638 9.89448Z" fill="white"/>
        </svg>
      </a>
      <a href="tel:+78003010424" class="ml-12" target="_blank">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.910594 7.17483C3.00537 11.7399 6.73279 15.3694 11.367 17.3371L12.1098 17.6682C13.8093 18.4259 15.8072 17.8503 16.8438 16.3043L17.8146 14.8565C18.1302 14.3858 18.0341 13.7518 17.5932 13.3959L14.301 10.7384C13.8174 10.3481 13.1064 10.439 12.7365 10.9384L11.718 12.3135C9.10466 11.0237 6.98293 8.90068 5.69381 6.28579L7.06809 5.26675C7.56726 4.8966 7.65812 4.18512 7.26801 3.70128L4.61197 0.407041C4.25634 -0.0340374 3.62289 -0.130249 3.15247 0.185365L1.69556 1.16282C0.140824 2.20592 -0.431273 4.22149 0.343428 5.92655L0.909741 7.17297L0.910594 7.17483Z" fill="black"/>
        </svg>
      </a>
      <a href="mailto:hello@presentandsimple.com" class="ml-12" target="_blank">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.85963 0.223001C8.28009 -0.0743334 11.7199 -0.0743337 15.1404 0.223001L16.7883 0.366253C17.8853 0.461617 18.8249 1.11596 19.3118 2.05196C19.374 2.1717 19.3274 2.31667 19.2126 2.38772L12.3753 6.62033C10.9089 7.5281 9.05987 7.54715 7.57508 6.66977L0.692091 2.60255C0.580889 2.53684 0.529846 2.40158 0.579961 2.28253C1.02533 1.22456 2.02664 0.469268 3.21169 0.366253L4.85963 0.223001Z" fill="black"/>
          <path d="M0.57436 4.43416C0.404175 4.3336 0.187027 4.44182 0.167189 4.63849C-0.109682 7.38351 -0.0426718 10.1545 0.368221 12.8869C0.584836 14.3274 1.76048 15.4318 3.21169 15.5579L4.85963 15.7012C8.28009 15.9985 11.7199 15.9985 15.1404 15.7012L16.7883 15.5579C18.2395 15.4318 19.4152 14.3274 19.6318 12.8869C20.0546 10.0749 20.1133 7.22198 19.8077 4.39858C19.7863 4.20052 19.5655 4.09429 19.3961 4.19915L13.2368 8.01202C11.2529 9.24018 8.75124 9.26595 6.7424 8.07891L0.57436 4.43416Z" fill="black"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: "ContactsInfoBlock",
}
</script>

<style scoped>

</style>




