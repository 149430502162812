var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", [
        _c("div", { staticClass: "relative" }, [
          _c(
            "div",
            {
              staticClass:
                "w-full h-9 px-5 text-14 cursor-pointer border border-gray-lightest flex items-center justify-between lg:w-98",
              on: { click: _vm.showSizeSelection },
            },
            [
              _vm.selectedSizeTitle
                ? _c(
                    "div",
                    {
                      class:
                        _vm.selectedSize.data.relations.availableStocks
                          .mainStockQuantity > 0 ||
                        _vm.selectedSize.data.relations.availableStocks
                          .hasPreorderStock
                          ? "text-black"
                          : "text-gray-main-2",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.selectedSizeTitle) +
                          "\n                    "
                      ),
                    ]
                  )
                : _c("div", { staticClass: "text-14" }, [
                    _vm._v(
                      "\n                        Выберите размер\n                    "
                    ),
                  ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "svg",
                  {
                    class: [
                      _vm.isSelectSizeActive ||
                      _vm.isNonActiveButtonAddToCartClicked
                        ? "transform rotate-180"
                        : "",
                    ],
                    attrs: {
                      width: "11",
                      height: "7",
                      viewBox: "0 0 11 7",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M5.5 5.5L10 1",
                        stroke: "black",
                        "stroke-linecap": "square",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M1 1.00049L5.5 5.50049",
                        stroke: "black",
                        "stroke-linecap": "square",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.isSelectSizeActive || _vm.isNonActiveButtonAddToCartClicked
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.onClickOutside,
                      expression: "onClickOutside",
                    },
                  ],
                  staticClass:
                    "absolute z-10 w-full bg-white border-b border-l border-r border-gray-lightest lg:w-98",
                },
                [
                  _c(
                    "div",
                    _vm._l(
                      _vm.product.data.attributes.sizes.data,
                      function (size, size_index) {
                        return _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.selectSize(size, size_index)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full flex items-center justify-between h-9 px-5 text-14 focus:outline-none border border-white hover:border hover:border-black cursor-pointer",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      size.data.relations.availableStocks
                                        .mainStockQuantity > 0 ||
                                      size.data.relations.availableStocks
                                        .hasPreorderStock
                                        ? "text-black"
                                        : "text-gray-main-2",
                                      size.data.relations.availableStocks
                                        .hasPreorderStock
                                        ? ""
                                        : "w-1/5",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(size.data.attributes.title) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                size.data.relations.availableStocks
                                  .hasRetailStocks &&
                                size.data.relations.availableStocks
                                  .mainStockQuantity < 1 &&
                                !size.data.relations.availableStocks
                                  .hasPreorderStock
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-gray-main-2 whitespace-nowrap",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    (в наличии в магазине)\n                                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class: size.data.relations.availableStocks
                                      .hasPreorderStock
                                      ? "text-gray-main-2 text-right lg:whitespace-nowrap"
                                      : "w-2/5 text-gray-main-2 text-right lg:whitespace-nowrap",
                                  },
                                  [
                                    size.data.relations.availableStocks
                                      .mainStockQuantity <= 0 ||
                                    _vm.product.data.attributes.coming_soon
                                      ? _c("div", [
                                          size.data.relations.availableStocks
                                            .hasPreorderStock
                                            ? _c("span", [
                                                _vm._v(
                                                  "предзаказ или подписка"
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v("подписаться"),
                                              ]),
                                        ])
                                      : size.data.relations.availableStocks
                                          .mainStockQuantity <= 3 &&
                                        !_vm.product.data.attributes.coming_soon
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                        мало\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }