var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full my-10 items-center justify-center" },
    [
      _c(
        "div",
        {
          staticClass:
            "uppercase text-16 text-normal text-gray-main text-center lg:text-24",
        },
        [_vm._v("\n        По вашему запросу ничего не найдено\n    ")]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt-8 justify-center" }, [
        _c(
          "button",
          {
            staticClass:
              "h-9 py-1 px-5 uppercase text-16 text-white border border-black bg-black r focus:outline-none lg:px-16",
            on: { click: _vm.resetAllFiltersAndSorting },
          },
          [_c("span", [_vm._v("Очистить фильтр")])]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { "data-popmechanic-embed": "40996" } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "w-76 mt-8 mx-auto text-13 text-normal text-black text-center lg:w-118 lg:text-16",
        },
        [
          _vm._v(
            "\n            Очистите фильтр для комфотного поиска\n        "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }