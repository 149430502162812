var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.productsStatus == "success"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "my-12 w-full text-gray-main text-2xl font-normal tracking-widest text-center uppercase",
              },
              [_vm._v("Все")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("NonSwipableCategoryBlock", {
                  attrs: { category: _vm.products.data },
                }),
              ],
              1
            ),
          ])
        : _c("Loading", { staticClass: "mx-auto" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }